import React from 'react';

const DiagnosisListPaginationListButton = () => {
    return (
        <svg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_93_2326)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.99999 11.3C6.61339 11.3 6.29999 11.6134 6.29999 12C6.29999 12.3866 6.61339 12.7 6.99999 12.7L15.3367 12.7L12.833 15.2606C12.5627 15.537 12.5677 15.9802 12.8441 16.2505C13.1205 16.5208 13.5637 16.5158 13.834 16.2394L17.5007 12.4894C17.7667 12.2173 17.7667 11.7827 17.5007 11.5106L13.834 7.76061C13.5637 7.48419 13.1205 7.47921 12.8441 7.74948C12.5677 8.01976 12.5627 8.46295 12.833 8.73937L15.3367 11.3L6.99999 11.3Z'
                    fill='#989BA2'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12 22.6C17.8542 22.6 22.6 17.8542 22.6 12C22.6 6.14578 17.8542 1.4 12 1.4C6.14578 1.4 1.4 6.14578 1.4 12C1.4 17.8542 6.14578 22.6 12 22.6ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z'
                    fill='#989BA2'
                />
            </g>
            <defs>
                <clipPath id='clip0_93_2326'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DiagnosisListPaginationListButton;
