import React from 'react';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { AddAssessmentModalProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { RowDivider } from 'styles/Global/commonStyles';
import BasicButton from 'components/Buttons/BasicButton';

const AddAssessmentModal = ({ selectedAssessment, toggle, handleAddAssessment, editState }: AddAssessmentModalProps) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px' $gap='16px'>
                <ModalTitleAndContentContainer>
                    <ModalTitle>{editState ? '해당 검사로 변경할까요?' : '해당 검사로 지정할까요?'}</ModalTitle>
                    <ContentWrapper>
                        <ModalContent $fontSize={font.size[16]} $fontWeight={font.weight.regular}>
                            지정된 검사는 검사 시작 전까지 변경이 가능합니다.
                        </ModalContent>
                        <ModalContent $fontSize={font.size[16]} $fontWeight={font.weight.regular}>
                            검사가 시작되면 변경이 불가능합니다.
                        </ModalContent>
                    </ContentWrapper>
                </ModalTitleAndContentContainer>
                <AssessmentListContainer>
                    {selectedAssessment.map((item, idx) => {
                        return (
                            <>
                                <AssessmentWrapper key={idx}>
                                    <NumberWrapper>{idx + 1}</NumberWrapper>
                                    <AssessmentGroup>{item.group}</AssessmentGroup>
                                    <AssessmentName>{item.assessmentName}</AssessmentName>
                                </AssessmentWrapper>
                                {selectedAssessment.length !== idx + 1 && <RowDivider $backgroundcolor={palette.testGray} />}
                            </>
                        );
                    })}
                </AssessmentListContainer>
                <ModalFooterWrapper>
                    <BasicButton $width='140px' $height='48px' onClick={toggle} $type='outLine'>
                        다시 선택하기
                    </BasicButton>
                    <BasicButton $width='140px' $height='48px' onClick={() => handleAddAssessment(editState ? 201 : 200)} $type='fill'>
                        {editState ? '변경하기' : '저장하기'}
                    </BasicButton>
                </ModalFooterWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default AddAssessmentModal;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 44px;
`;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    border: 1px solid ${palette.testGray};
    border-radius: 4px;
    gap: 12px;
`;

const AssessmentWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    gap: 6px;
`;

const NumberWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid ${palette.gray};
    border-radius: 6px;
    font-size: ${font.size[13]};
    font-weight: ${font.weight.medium};
    margin-right: 4px;
`;
const AssessmentGroup = styled.span`
    line-height: normal;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.bold};
`;

const AssessmentName = styled.span`
    line-height: 23px;
    font-size: ${font.size[15]};
    font-weight: ${font.weight.semiBold};
`;

const ModalFooterWrapper = styled.div`
    display: flex;
    gap: 8px;
`;
