import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { GovernmentPersonalInformationProps } from 'models/Government/agreePageTypes';
import { useEffect } from 'react';
import styled from 'styled-components';
import { AgreeAndCodeConfirmTitle as Title } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Body1_Medium, Body4_SemiBold, Body5_Medium, Caption1_Regular } from 'styles/Global/typography';

const GovernmentPersonalInformation = ({ $onClick, $agree }: GovernmentPersonalInformationProps) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <PersonalInfomationTitleContainer>
                <Title>개인정보 수집 및 이용약관</Title>
                <SubTitle>(필수)</SubTitle>
            </PersonalInfomationTitleContainer>
            <DescTitle>제1조 수집하는 개인정보 항목</DescTitle>
            <DescContainer>
                <Desc>주식회사 티엘씨헬스케어(이하 “회사"라 합니다)은 자녀 심리검사 서비스(이하 “서비스"라 합니다) 이용 시 이용자로부터 아래와 같은 개인 정보를 수집할 수 있습니다.</Desc>
                <DescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>① 검사자 개인</DescSubTitle>
                        <Desc>이름, 성별, 생년월일, 휴대전화 번호, 이메일 주소</Desc>
                    </SubDescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>② 검사자의 보호자</DescSubTitle>
                        <Desc>휴대전화 번호, 이메일 주소</Desc>
                    </SubDescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>③ 검사 응답 내용</DescSubTitle>
                        <Desc>검사자가 선택한 응답 내용이 저장되어 결과 도출을 위해 사용됨</Desc>
                    </SubDescWrapper>
                </DescWrapper>
                <Warning>
                    ※회사는 원칙적으로 이용자가 만 14세 미만자일 경우 개인정보를 수집하지 않습니다. 부득이 서비스 이용을 위하여 만 14세 미만자의 개인정보를 수집할 때에는 사전에 법정대리인의 동의를
                    구하고 관련 업무가 종료됨과 동시에 정보를 지체없이 파기토록 하겠으며 업무가 진행되는 동안 개인정보를 철저히 관리토록 하겠습니다.
                </Warning>
            </DescContainer>
            <DescTitle>제2조 개인정보의 수집 및 이용목적</DescTitle>
            <DescContainer>
                <Desc>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</Desc>
                <DescWrapper>
                    <SubDescWrapper>
                        <DescSubTitle>◦ 서비스 제공</DescSubTitle>
                        <Desc>
                            아동과 보호자에게 할당된 심리검사를 수행하게 하여 그 결과에 따른 보고서를 제공하며 전문 상담사가 제공한 연락처로 개별 연락을 하여 결과 설명 및 상담을 제공하고자 합니다.
                        </Desc>
                    </SubDescWrapper>
                </DescWrapper>
                <Warning>
                    ※회사는 원칙적으로 이용자가 만 14세 미만자일 경우 개인정보를 수집하지 않습니다. 부득이 서비스 이용을 위하여 만 14세 미만자의 개인정보를 수집할 때에는 사전에 법정대리인의 동의를
                    구하고 관련 업무가 종료됨과 동시에 정보를 지체없이 파기토록 하겠으며 업무가 진행되는 동안 개인정보를 철저히 관리토록 하겠습니다.
                </Warning>
            </DescContainer>
            <MobileBasicButton $type='fill' onClick={$onClick}>
                {!$agree ? '확인하고 동의하기' : '확인'}
            </MobileBasicButton>
        </Layout>
    );
};

export default GovernmentPersonalInformation;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PersonalInfomationTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;
`;

const SubTitle = styled(Body1_Medium)`
    color: ${PrimaryColor.color_primary_light};
`;

const DescTitle = styled(Body4_SemiBold)`
    margin-bottom: 4px;
`;

const SubDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
const DescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const DescSubTitle = styled(Body5_Medium)`
    color: ${TextColor.color_text_neutral};
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 36px;
`;

const Desc = styled(Caption1_Regular)`
    color: ${TextColor.color_text_light};
`;

const Warning = styled(Caption1_Regular)``;
