import { useCallback, useState } from 'react';

// type Return = readonly [boolean, () => void];

const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState);

    const toggle = useCallback(() => {
        setState((prevState) => !prevState);
    }, []);

    return [state, toggle] as const;
};

export default useToggle;
