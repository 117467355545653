import React from 'react';

const ArrowLineDown = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.8 6.00001C12.8 5.55818 12.4418 5.20001 12 5.20001C11.5581 5.20001 11.2 5.55818 11.2 6.00001L11.2 16.0989L8.05925 13.028C7.74334 12.7191 7.23684 12.7248 6.92795 13.0407C6.61906 13.3566 6.62475 13.8631 6.94066 14.172L11.4407 18.572C11.7516 18.876 12.2483 18.876 12.5592 18.572L17.0592 14.172C17.3752 13.8631 17.3808 13.3566 17.072 13.0407C16.7631 12.7248 16.2566 12.7191 15.9407 13.028L12.8 16.0989L12.8 6.00001Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default ArrowLineDown;
