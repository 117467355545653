import React from 'react';

const Add = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.9003 6.00001C12.9003 5.50295 12.4973 5.10001 12.0003 5.10001C11.5032 5.10001 11.1003 5.50295 11.1003 6.00001V11.1H6.0001C5.50304 11.1 5.1001 11.5029 5.1001 12C5.1001 12.4971 5.50304 12.9 6.0001 12.9H11.1003V18C11.1003 18.4971 11.5032 18.9 12.0003 18.9C12.4973 18.9 12.9003 18.4971 12.9003 18V12.9H18.0001C18.4972 12.9 18.9001 12.4971 18.9001 12C18.9001 11.5029 18.4972 11.1 18.0001 11.1H12.9003V6.00001Z'
                fill='white'
            />
        </svg>
    );
};

export default Add;
