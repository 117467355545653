import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import Account from 'assets/Icons/Account/Account';
import AccountAdmin from 'assets/Icons/Account/AccountAdmin';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import Noti_Add from 'assets/Icons/ToastIcons/Noti_Add';
import Noti_Change from 'assets/Icons/ToastIcons/Noti_Change';
import Noti_Check from 'assets/Icons/ToastIcons/Noti_Check';
import Noti_Delete from 'assets/Icons/ToastIcons/Noti_Delete';
import Noti_Start from 'assets/Icons/ToastIcons/Noti_Start';
import { MemberWhiteBadge, RoundBadgeXS, TodayAlarmRoundBadgeXS } from 'components/Common/Badge';
import { HomeCellTitle, HomeCellTitleContainer } from 'components/Hospital/HomeForms';
import { useAlarmListStore } from 'store/useToastStore';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { HomeNothingDataContainer, TestCircle24 } from 'styles/Global/hospitalStyles';
import { Radius } from 'styles/Global/objectStyles';
import { Body5_Regular } from 'styles/Global/typography';
import { Date, DiagnosisTimeWrapper, DoctoInfoWrapper, InfoContainer, InfoWrapper, MainText, SubText, ToastPatientName, TodayAlarmTitle, UserInfoWrapper } from 'styles/Toast/toastStyles';
import AlarmNone from 'assets/Icons/Common/AlarmNone';

const TodayAlarm = () => {
    const { alarmList } = useAlarmListStore();
    return (
        <>
            <HomeCellTitleContainer>
                <HomeCellTitle>오늘의 알림</HomeCellTitle>
            </HomeCellTitleContainer>
            <AlarmListContainer>
                {alarmList.map((alarm, idx) => {
                    return (
                        <AlarmListWrapper key={idx}>
                            <IconWrapper>
                                {(alarm.data?.code === '100' || alarm.data?.code === '400') && <Noti_Add $width='20px' $height='20px' />}
                                {(alarm.data?.code === '101' || alarm.data?.code === '300' || alarm.data?.code === '501' || alarm.data?.code === '602') && <Noti_Change $width='20px' $height='20px' />}
                                {(alarm.data?.code === '102' || alarm.data?.code === '500' || alarm.data?.code === '401' || alarm.data?.code === '603') && <Noti_Delete $width='20px' $height='20px' />}
                                {alarm.data?.code === '202' && <Noti_Start $width='20px' $height='20px' />}
                                {(alarm.data?.code === '200' || alarm.data?.code === '201' || alarm.data?.code === '203' || alarm.data?.code === '204') && <Noti_Check $width='20px' $height='20px' />}
                            </IconWrapper>
                            <TextWrapper>
                                <TodayAlarmTitle>{alarm.title}</TodayAlarmTitle>
                                <TodayAlarmComment>{alarm.comment}</TodayAlarmComment>
                                {/* 담당의 추가, 제거 alarm */}
                                {alarm.data?.doctorName && (
                                    <InfoContainer>
                                        <DoctoInfoWrapper>
                                            <DoctorIcon />
                                            <MainText>{alarm.data?.doctorName}</MainText>
                                            <SubText>원장님</SubText>
                                        </DoctoInfoWrapper>
                                    </InfoContainer>
                                )}
                                {/* 유저 정보 변경 toast */}
                                {alarm.data?.userName && (
                                    <InfoContainer>
                                        <DoctoInfoWrapper>
                                            {alarm.data.userLevel === '0' ? <Account /> : <AccountAdmin />}
                                            <MainText>{alarm.data.userName}</MainText>
                                            <MemberWhiteBadge>{alarm.data.userLevel === '0' ? '멤버' : '관리자'}</MemberWhiteBadge>
                                        </DoctoInfoWrapper>
                                    </InfoContainer>
                                )}
                                {/* 담당의 제외한 alarm */}
                                {alarm.data?.patientName && (
                                    <TodayAlarmInfoContainer>
                                        <TodayAlarmRoundBadgeXS>{alarm.data?.regNum}</TodayAlarmRoundBadgeXS>
                                        <InfoWrapper>
                                            <UserInfoWrapper>
                                                <ToastPatientName>{alarm.data.patientName}</ToastPatientName>
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                    {alarm.data.patientGender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                                </span>
                                                <span style={{ fontWeight: '400' }}>({alarm.data.patientIdentityNumber})</span>
                                            </UserInfoWrapper>
                                        </InfoWrapper>
                                        <div style={{ marginTop: '2px' }}>{alarm.data.diagnosisTime && <DiagnosisTimeWrapper>{alarm.data.diagnosisTime} 진료</DiagnosisTimeWrapper>}</div>
                                    </TodayAlarmInfoContainer>
                                )}
                                <Date>{alarm.data?.addTime}</Date>
                            </TextWrapper>
                        </AlarmListWrapper>
                    );
                })}
            </AlarmListContainer>
            {!alarmList ||
                (alarmList.length === 0 && (
                    <HomeNothingDataContainer>
                        <AlarmNone />
                        받은 알림이 없습니다.
                    </HomeNothingDataContainer>
                ))}
        </>
    );
};

export default TodayAlarm;

const AlarmListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    overflow-y: auto;
`;

const AlarmListWrapper = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 12px;
    gap: 10px;
    border-bottom: 1px solid ${BorderColor.color_border_weak};
`;

const IconWrapper = styled.div`
    margin-top: 2px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    height: 100%;
`;

const TodayAlarmInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    width: 100%;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_8};
    margin-bottom: 6px;
    gap: 4px;
`;

const TodayAlarmComment = styled(Body5_Regular)`
    margin-bottom: 6px;
`;
