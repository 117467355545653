import React from 'react';
import { ListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import { diagnosisListWidth } from 'constants/pagination';
import moment from 'moment';
import DiagnosisListPaginationListButton from 'assets/Icons/Arrow/ArrowLineRoundRight';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import { changeMobileForm } from 'functions/changeMobileForm';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { DiagnosisListpaginationTableProps } from 'models/Hospital/paginationTypes';

const DiagnosisListPaginationTable = ({ currentData }: DiagnosisListpaginationTableProps) => {
    const navigate = useNavigate();

    return (
        <>
            {currentData?.map((item, idx) => {
                const { birthDate } = calculateAge(item.patientIdentityNumber);
                return (
                    <ListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                        <TableCell $width={diagnosisListWidth[0]} $type='name'>
                            {item.patientName + ' '}
                            {item.patientGender === 'male' ? <GenderMan /> : <GenderWoman />}
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[1]}>{moment(birthDate, 'YYYYMMDD').format('YYYY/MM/DD')}</TableCell>
                        <TableCell $width={diagnosisListWidth[2]} $type='gradeName'>
                            {item.gradeName}
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[3]}>{changeMobileForm(item.patientMobile)}</TableCell>
                        <TableCell $width={diagnosisListWidth[4]}>{formattedTime(item.diagnosisDateTime)}</TableCell>
                        <TableCell $width={diagnosisListWidth[5]}>{item.doctorName}</TableCell>
                        <TableCell cellType='processBarCell' $width={diagnosisListWidth[6]} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                            <ProcessCheckBar diagnosisInfo={item} $height='40px' />
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[7]}>
                            <DiagnosisListPaginationListButton />
                        </TableCell>
                    </ListWrapper>
                );
            })}
        </>
    );
};

export default DiagnosisListPaginationTable;
