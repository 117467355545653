export const managingPatientsPageText = {
    pageLabelAndTitle: '등록 환자 관리',
    countDiagnosis: '명의 환자',
};

export const managingPatientsFilterList = ['전체', '이름', '생년월일', '연락처'];

export const managingPatientsDiagnosisNothingLists = {
    text: '등록된 환자가 없습니다.',
    subText: '진료 추가를 통해 새로운 환자를 등록하고, 편리하게 관리해보세요.',
    buttonText: '진료 추가',
};

export const managingPatientsDiagnosisNothingSearchLists = {
    text: '검색 결과가 없습니다.',
    subText: '작성하신 단어를 확인하고 다른 검색어로 검색해보세요.',
    buttonText: '새로고침',
};
