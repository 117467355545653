import React from 'react';

const Logout = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4C13 4.55228 12.5523 5 12 5H5V19H12C12.5523 19 13 19.4477 13 20C13 20.5523 12.5523 21 12 21H4C3.44772 21 3 20.5523 3 20V4ZM9 11.75C9 11.1977 9.44771 10.75 10 10.75L17.6238 10.75L15.6185 8.6991C15.2324 8.30421 15.2395 7.67109 15.6344 7.28497C16.0293 6.89886 16.6624 6.90598 17.0485 7.30086L20.7152 11.0509C21.0952 11.4395 21.0952 12.0605 20.7152 12.4491L17.0485 16.1991C16.6624 16.594 16.0293 16.6011 15.6344 16.215C15.2395 15.8289 15.2324 15.1958 15.6185 14.8009L17.6238 12.75L10 12.75C9.44772 12.75 9 12.3023 9 11.75Z'
                fill='#333438'
            />
        </svg>
    );
};

export default Logout;
