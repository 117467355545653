import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { CheckRegistrationStatusApiResponseType, CheckRegistrationStatusApiType } from 'models/Hospital/hospitalApiTypes';

class HospitalAPI {
    async patientFind(rqData: CheckRegistrationStatusApiType, token: string): Promise<CheckRegistrationStatusApiResponseType> {
        try {
            const response = await axios.post(apiPaths.patientFind, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const hospitalAPI = new HospitalAPI();
