import { header } from 'api/Auth/auth';
import axios, { AxiosResponse } from 'axios';
import { apiPaths } from 'constants/path';
import { ExistUserApiType, GetCompactListApiResopnse, SignUpApiResponseType } from 'models/SignUp/signUpApiTypes';

class SignUpApi {
    getCompactList(): Promise<GetCompactListApiResopnse> {
        return new Promise((resolve, reject) => {
            axios
                .get(apiPaths.compactList, {
                    headers: header,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    // oauth(rqData: any): Promise<SignUpApiResponseType> {
    oauth(rqData: any): Promise<SignUpApiResponseType> {
        return new Promise((resolve, reject) => {
            axios
                .post(apiPaths.signUp, rqData, {
                    headers: header,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                });
        });
    }

    existUser(email: string): Promise<ExistUserApiType> {
        return new Promise((resolve, reject) => {
            axios
                .get(apiPaths.existUser + email, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: process.env.REACT_APP_AUTH_KEY,
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
export const signUpApi = new SignUpApi();
