import styled from 'styled-components';
import { ChildrenType, ListWrapperProps } from 'models/Common/commonTypes';
import { font, palette } from 'styles/Global/globalStyles';
import { TableCellProps, TableCellTypes } from 'models/Hospital/paginationTypes';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';

export const Layout = ({ children }: ChildrenType) => {
    return <PaginationLayout>{children}</PaginationLayout>;
};

export const TableLayout = ({ children }: ChildrenType) => {
    return <TableLayoutCss>{children}</TableLayoutCss>;
};

export const HeaderWrapper = ({ children }: ChildrenType) => {
    return <HeaderWrapperCss>{children}</HeaderWrapperCss>;
};
export const AdminHeaderWrapper = ({ children }: ChildrenType) => {
    return <AdminHeaderWrapperCss>{children}</AdminHeaderWrapperCss>;
};

export const TableCell = ({ children, $width, $idx, $type, onClick, $justifyContent, hover, cellType }: TableCellProps) => {
    return (
        <TableCellCss
            $width={$width}
            $idx={$idx}
            $type={$type}
            onClick={onClick}
            $cellType={cellType}
            $justifyContent={$justifyContent}
            onMouseEnter={() => cellType && hover && hover(cellType)}
            onMouseLeave={() => cellType && hover && hover('')}
        >
            {children}
        </TableCellCss>
    );
};

export const ListWrapper = ({ children, onClick }: ListWrapperProps) => {
    return <ListWrapperCss onClick={onClick}>{children}</ListWrapperCss>;
};
export const AdminListWrapper = ({ children, onClick }: ListWrapperProps) => {
    return <AdminListWrapperCss onClick={onClick}>{children}</AdminListWrapperCss>;
};

export const FooterLayout = ({ children }: ChildrenType) => {
    return <FooterLayoutCss>{children}</FooterLayoutCss>;
};

const PaginationLayout = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
`;
const TableLayoutCss = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid ${BorderColor.color_border_normal};
    // overflow: hidden;
    & > div:last-child {
        border-bottom: none;
    }
`;

const HeaderWrapperCss = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_neutral};
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    & > div:last-child {
        border-right: none;
        max-width: 60px;
    }
`;
const AdminHeaderWrapperCss = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_neutral};
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    & > div:last-child {
        border-right: none;
    }
`;

const TableCellCss = styled.div<TableCellTypes>`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: ${(props) => props.$justifyContent};
    width: ${(props) => props.$width};
    height: 100%;
    border-right: 1px solid ${BorderColor.color_border_neutral};
    font-size: ${(props) => props.$type === 'header' && font.size[14]};
    font-weight: ${(props) => (props.$type === 'name' ? font.weight.semiBold : font.weight.medium)};
    color: ${(props) => props.$type === 'header' && palette.gray};
    padding: 0 14px;
    cursor: ${(props) => props.onClick && 'pointer'};
    overflow: ${(props) => props.$cellType === 'processBarCell' && 'visible'};
    @media (max-width: 1600px) {
        display: ${(props) => (props.$idx === 2 || props.$type === 'gradeName' || props.$type === 'noneDisplay') && 'none'};
    }
`;

const ListWrapperCss = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    cursor: pointer;
    & > div:last-child {
        border-right: none;
        max-width: 60px;
    }
    &:hover {
        background-color: ${BackgroundColor.color_background_light};
    }
`;
const AdminListWrapperCss = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    cursor: pointer;
    & > div:last-child {
        border-right: none;
    }
    &:hover {
        background-color: ${palette.lightGray};
    }
`;

const FooterLayoutCss = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-top: 36px;
    gap: 4px;
`;
