import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { DiagnosisApiResponse } from 'models/Hospital/hospitalApiTypes';
import { GetAssessmentListApiResponse } from 'models/Hospital/viewDetailDiagnosisPageTypes';

class ViewDetailDiagnosisPageApi {
    async getAssessmentList(token: string): Promise<GetAssessmentListApiResponse> {
        try {
            const response = await axios.get(apiPaths.getAssessmentList, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    async getDiagnosis(url: string, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.get(apiPaths.getDiagnosis + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async addAssessment(rqData: { _id: string; codeList: string[] }, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.put(apiPaths.assignAssessment, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async changeDiagnosisStatus(rqData: { _id: string; status: number }, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.put(apiPaths.changeDiagnosisStatus, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateDiagnosisInfo(
        rqData:
            | {
                  _id: string;
                  prescriptionMemo: string;
              }
            | {
                  _id: string;
                  assessmentAge: string;
              }
            | { _id: string; diagnosisDateTime: string; diagnosisTimestamp: number; doctorName: string; gradeCode: string; gradeName: string; assessmentAge: string },
        token: string
    ): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.put(apiPaths.updateDiagnosisInfo, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async clearDiagnosis(url: string, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.get(apiPaths.clearDiagnosis + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const viewDetailDiagnosisPageApi = new ViewDetailDiagnosisPageApi();
