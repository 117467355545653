import React from 'react';

const UnConfirmAlarmIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_2291_68306)'>
                <rect x='19' width='5' height='5' rx='2.5' fill='black' />
                <path
                    d='M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_2291_68306'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UnConfirmAlarmIcon;
