import BasicButton from 'components/Buttons/BasicButton';
import useToggle from 'hooks/CustomHooks/useToggle';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ColumnDivider, PageLayout, PageTitle } from 'styles/Global/commonStyles';
import { font } from 'styles/Global/globalStyles';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import { authApi } from 'api/Auth/auth';
import { InputWithLabel } from 'components/Input/InputWithLabel';
import ResetPassword from './ResetPassword';
import { signUpApi } from 'api/SignUp/signUpApi';
import { useLoginStore } from 'store/useLoginDataStore';
import { loginValidate } from 'functions/validationFunctions';
import { loginApiCall } from 'functions/apiFunctions';

import { commonText, palceholderText } from 'constants/commonConstants';
import { LoginPageError, signUpAlertContants } from 'constants/loginPageConstants';
import { useLoadingStore } from 'store/useLoadingStore';
import FindIdAndPassword from './FindIdAndPassword';
import { Body4_Medium_Button } from 'styles/Global/typography';
import { TextColor } from 'styles/Global/colorSemantic';
import AlertDialog from 'components/Modal/AlertDialog';
import Copyright from 'components/Common/Copyright';

const Login = () => {
    const navigate = useNavigate();

    const initValue = { email: '', password: '' };

    const [value, setValue] = useState(initValue);
    const [errMsg, setErrMsg] = useState(initValue);
    const [isAlertDialog, setIsAlertDialog] = useState(false);

    // custom hook
    const [isPasswordVisible, setIsPasswordVisible] = useToggle();
    const [isFindId, setIsFindId] = useToggle();
    const [isResetPasswordPage, setIsResetPasswordPage] = useToggle();
    const [isCompleteSendLink, setIsCompleteSendLink] = useToggle();

    const { setLoading } = useLoadingStore();

    const { loginData, setLoginData } = useLoginStore();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (loginData.status === 0) {
            localStorage.clear();
            return setIsAlertDialog(true);
        }
        return setIsAlertDialog(false);
    }, [loginData]);

    // input change function
    const handleChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        setValue({ ...value, [id]: e.target.value });

        if (id === 'password') {
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: '' }));
        } else {
            const error = loginValidate[id](e.target.value, id === 'password' ? value : '');
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: error }));
        }
    };

    // 로그인 버튼 클릭 function
    const handleClickLogin = async () => {
        setLoading(true);
        const emailError = loginValidate.email(value.email);
        const passwordError = loginValidate.password(value.password);

        if (emailError) {
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: emailError }));
            emailRef.current?.focus();
        }

        if (passwordError) {
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, password: passwordError }));
            passwordRef.current?.focus();
        }

        if (!emailError && !passwordError) {
            try {
                // account status Status 0번 ->  panding상태, Status 1번 -> 허가받은 상태, Status -1번 -> 거절된 상태
                await loginApiCall(value, setLoginData, setErrMsg, navigate, setLoading, emailRef, passwordRef);
            } catch (error) {
                console.log(error);
            }
        }
    };

    // 비밀번호 재설정 컴포넌트 on/off function
    const handleOnResetPasswordComponent = () => {
        setIsResetPasswordPage();
        handleReset();
    };

    // 아이디 찾기 컴포넌트 on/off function
    const handleOnFindIdComponent = () => {
        setIsFindId();
        handleReset();
    };

    // reset function
    const handleReset = () => {
        setValue(initValue);
        setErrMsg(initValue);
    };

    // 링크 완료 후 로그인 페이지 이동 function
    const clickToLoginPage = () => {
        setIsResetPasswordPage();
        setIsCompleteSendLink();
    };

    // 링크보내기 버튼 클릭 function
    const clickSendLinkToEmailButton = async () => {
        setLoading(true);
        try {
            if (errMsg.email === '' && value.email === '') {
                setLoading(false);
                return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: LoginPageError.enterEmailId }));
            } else {
                const existAccountApiResponse = await signUpApi.existUser(value.email);
                if (existAccountApiResponse.data) {
                    const resetPasswordLinkToEmailApiResponse = await authApi.resetPasswordLink(value.email);
                    if (resetPasswordLinkToEmailApiResponse.status === 'ok') {
                        setLoading(false);
                        setIsCompleteSendLink();
                    } else {
                        setLoading(false);
                        setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: LoginPageError.idExistenceCheckError }));
                    }
                } else {
                    setLoading(false);
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: LoginPageError.idExistenceCheckError }));
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const enterKey = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleClickLogin();
        }
    };
    return (
        <PageLayout>
            {isAlertDialog && (
                <AlertDialog
                    $title={signUpAlertContants.title}
                    $content={signUpAlertContants.content}
                    $subContent={signUpAlertContants.subContent}
                    $onClick={() => setIsAlertDialog(false)}
                    $label={signUpAlertContants.label}
                    $labelText={signUpAlertContants.labelText}
                />
            )}
            <PageInnerLayOut>
                {isFindId ? (
                    <FindIdAndPassword onLoginPage={setIsFindId} />
                ) : isResetPasswordPage ? (
                    <ResetPassword
                        clickCancelButton={handleOnResetPasswordComponent}
                        clickToLoginPage={clickToLoginPage}
                        clickSendLinkToEmailButton={clickSendLinkToEmailButton}
                        handleChange={handleChange}
                        errMsg={errMsg.email}
                        value={value.email}
                        isCompleteSendLink={isCompleteSendLink}
                    />
                ) : (
                    <>
                        <PageTitle>{commonText.login}</PageTitle>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', marginBottom: '12px' }}>
                            <InputWithLabel
                                ref={emailRef}
                                label={commonText.emailId}
                                placeholder={palceholderText.emailInput}
                                onChange={handleChange}
                                id='email'
                                focus={true}
                                errorMsg={errMsg.email}
                                value={value.email}
                                enterKey={enterKey}
                            />
                            <InputWithLabel
                                ref={passwordRef}
                                label={commonText.password}
                                placeholder={commonText.password}
                                onChange={handleChange}
                                isPasswordVisible={isPasswordVisible}
                                setIsPasswordVisible={setIsPasswordVisible}
                                id='password'
                                focus={false}
                                errorMsg={errMsg.password}
                                value={value.password}
                                enterKey={enterKey}
                            />
                        </div>
                        <div>
                            <BasicButton onClick={handleClickLogin} $type='fill'>
                                {commonText.login}
                            </BasicButton>
                            <LinkLayout>
                                <SecurityLayout>
                                    <Button onClick={handleOnFindIdComponent}>{commonText.findId}</Button>
                                    <ColumnDivider $height='16px' />
                                    <Button onClick={handleOnResetPasswordComponent}>{commonText.resetPassword}</Button>
                                </SecurityLayout>
                                <Link to={pagePaths.signUp}>
                                    <Button>{commonText.signUp}</Button>
                                </Link>
                            </LinkLayout>
                        </div>
                    </>
                )}
                <CopyrightNotice>
                    <Copyright />
                </CopyrightNotice>
            </PageInnerLayOut>
        </PageLayout>
    );
};

export default Login;

const CopyrightNotice = styled.span`
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.semiLight};
    opacity: 0.5;
    bottom: 32px;
`;

const PageInnerLayOut = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh-32px);
    width: 440px;
    row-gap: 40px;
    margin-top: 108px;
`;

const LinkLayout = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    a {
        color: ${TextColor.color_text_normal};
    }
`;

const SecurityLayout = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const Button = styled(Body4_Medium_Button)`
    color: ${TextColor.color_text_light};
`;
