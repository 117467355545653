import React from 'react'
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { Body3_Medium, Title1_SemiBold } from 'styles/Global/typography';

interface Props{
    setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
}

const NonAssessmentListModal = ({setShowModel}:Props) => {

    const handleCancelClick = () => {
        setShowModel(false);
    }

  return (
    <Wrapper>
      <ModalLayout>
        <ModelInfoLayout>
          <ModelTextLayout>
            <ModalTitle>지정된 검사가 없습니다.</ModalTitle>
            <ModalDescription>
                <ModalDescriptionText>해당 검사자에게 지정된 검사가 없습니다.</ModalDescriptionText>
                <ModalDescriptionText>진료 상세보기에서 검사를 지정하고 진행해주세요.</ModalDescriptionText>
            </ModalDescription>
          </ModelTextLayout>
        </ModelInfoLayout>
        <ButtonLayout onClick={handleCancelClick}>
            <span>확인</span>
        </ButtonLayout>
      </ModalLayout>
    </Wrapper>
  )
}

export default NonAssessmentListModal

const Wrapper = styled.div`
  z-index: 1;
  background: rgba(0, 0, 0, 0.60);
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalLayout = styled.div`
  position: relative;
  width: 600px;
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  padding: 48px 36px 32px 36px;
  background-color:${palette.white};
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
  align-items: center;
`

const ModelInfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

const ModelTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

const ModalTitle = styled(Title1_SemiBold)`
  text-align: center;
`

const ModalDescription = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const ModalDescriptionText = styled(Body3_Medium)``
const ButtonLayout = styled.div`
    width: 408px;
    height: 68px;
    border-radius: 4px;
    color: ${palette.white};
    background-color: #264C97;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`