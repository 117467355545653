import CheckBox from 'assets/Icons/ETC/Tablet/CheckBox';
import { AnswerModel } from 'models/Tablet/AnswerModel';
import { AssessmentAnswersType } from 'models/Tablet/AssessmentApiTypes';
import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import _ from 'lodash';
import NoResponseAnswerModal from './NoResponseAnswerModal';

type Props = {
    questionInfo: {
        prev: number;
        now: number;
        next: number;
        end: number;
    };
    minusScoreList: number[];
    setMinusScoreList: Dispatch<SetStateAction<number[]>>;
    answers: AssessmentAnswersType[];
    assessmentCode?: string;
    questionNumber: number;
    choiceList: ChoiceListType[];
    setChoiceList: Dispatch<SetStateAction<ChoiceListType[]>>;
    setQuestionInfo: React.Dispatch<React.SetStateAction<{ prev: number; now: number; next: number; end: number }>>;
    moveQuestion: (questionNumber: number) => void;
    maxNoResCount?: number;
};

const Answer = ({ questionInfo, minusScoreList, setMinusScoreList, answers, questionNumber, assessmentCode, choiceList, setQuestionInfo, setChoiceList, moveQuestion, maxNoResCount }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const createAnswer = (number: number, score: number) => {
        const model = new AnswerModel();
        model.questionNo = questionNumber;
        model.choiceNo = number.toString();
        model.choiceScore = score.toString();
        return model;
    };

    const changeAnswer = (number: number, score: number) => {
        const answer = createAnswer(number, score);
        const filteredChoiceList = choiceList.filter((item) => item.questionNo !== questionNumber);
        setChoiceList([...filteredChoiceList, answer]);
        if (questionInfo.end === questionNumber) {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionInfo.end, next: questionInfo.end }));
        } else {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionNumber + 1, next: questionNumber + 2 }));
        }
    };

    const addAnswer = (number: number, score: number) => {
        const answer = createAnswer(number, score);
        setChoiceList((prev) => [...prev, answer]);
        if (questionInfo.end === questionNumber) {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionInfo.end, next: questionInfo.end }));
        } else {
            setQuestionInfo((prev) => ({ ...prev, prev: questionNumber, now: questionNumber + 1, next: questionNumber + 2 }));
        }
    };

    const removeMinusList = (questionNumber: number) => {
        const filteredQuestionList = minusScoreList.filter((item) => item !== questionNumber);
        setMinusScoreList([...filteredQuestionList]);
    };

    const deleteChoiceAnswer = (questionNumber: number) => {
        const filteredChoiceList = choiceList.filter((item) => item.questionNo !== questionNumber);
        setChoiceList([...filteredChoiceList]);
    };
    const changeChoiceAnswer = (type: 'change' | 'prev') => {
        if (type === 'change') {
            removeMinusList(questionInfo.prev);
            setQuestionInfo((prev) => ({ ...prev, now: questionInfo.prev }));
            deleteChoiceAnswer(questionInfo.prev);
        }
        if (type === 'prev') {
            setQuestionInfo((prev) => ({ ...prev, now: minusScoreList[minusScoreList.length - 2] }));
            removeMinusList(minusScoreList[minusScoreList.length - 2]);
            deleteChoiceAnswer(minusScoreList[minusScoreList.length - 2]);
        }
        setShowModal(false);
    };
    // ! 추후 무응답 move 확인 및 수정할 때 확인 필요. 전체적인 로직 확인이 필요하다.
    // console.log(removeMinusList, questionInfo, deleteChoiceAnswer);

    const minusScoreCheck = () => {
        if (!minusScoreList.includes(questionNumber)) {
            console.log(1);
            setMinusScoreList((prev) => [...prev, questionNumber]);
            moveQuestion(questionNumber);
        } else if (minusScoreList.includes(questionNumber)) {
            console.log(2);
            moveQuestion(questionNumber);
        }
    };

    const deleteMinusScoreCheck = () => {
        const filteredList = minusScoreList.filter((item) => item !== questionNumber);
        setMinusScoreList(filteredList);
        moveQuestion(questionNumber);
    };

    const handleClick = (item: AssessmentAnswersType) => {
        const { number, score } = item;
        const findAnswer = choiceList.find((item) => item.questionNo === questionNumber);

        if (score === -1) {
            minusScoreCheck();
        } else {
            deleteMinusScoreCheck();
        }

        if (!_.isUndefined(findAnswer)) {
            //중복문항선택
            changeAnswer(number, score);
        } else {
            addAnswer(number, score);
        }
    };

    useEffect(() => {
        if (maxNoResCount && minusScoreList.length > maxNoResCount) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [minusScoreList]);

    return (
        <AnswerContainer>
            {showModal && <NoResponseAnswerModal setShowModal={setShowModal} changeChoiceAnswer={changeChoiceAnswer} maxNoResCount={maxNoResCount!} />}
            {answers.map((item) => (
                <AnswerArticle key={`${assessmentCode}-${questionNumber.toString()}-${item.number}`} onClick={() => handleClick(item)}>
                    {choiceList.some((choice) => choice.questionNo === questionNumber && choice.choiceNo === item.number.toString()) ? <CheckBox /> : <UnCheckButton />}
                    <AnswerLabel htmlFor={`${assessmentCode}-${questionNumber.toString()}-${item.number}`}>{item.answer}</AnswerLabel>
                </AnswerArticle>
            ))}
        </AnswerContainer>
    );
};

export default Answer;

const AnswerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
`;

const AnswerArticle = styled.div`
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    border-radius: 8px;

    &:hover {
        background-color: ${palette.lightGray};
    }
`;

const AnswerLabel = styled.label`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.regular};
    width: 100%;
    padding: 16px;
    border-radius: 8px;
`;
const UnCheckButton = styled.button`
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid #999;
`;
