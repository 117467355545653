import React from 'react';

const PasswordShow = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.0001 5.8325C9.62682 5.8325 7.44646 6.81823 5.71488 7.99618C3.98055 9.17599 2.63308 10.5912 1.90901 11.5566C1.72104 11.8073 1.71155 12.1492 1.88533 12.4098C5.32562 17.5703 9.81403 18.1674 12.0001 18.1674C14.1862 18.1674 18.6746 17.5703 22.1149 12.4098C22.2887 12.1492 22.2792 11.8073 22.0912 11.5566C21.3672 10.5912 20.0197 9.176 18.2854 7.99618C16.5538 6.81823 14.3734 5.83251 12.0001 5.8325ZM12.0001 16.6896C10.1766 16.6896 6.44397 16.2326 3.41971 12.0302C4.106 11.2101 5.19665 10.136 6.54608 9.21804C8.13949 8.13408 10.0306 7.31028 12.0001 7.31028C13.9697 7.31029 15.8607 8.13409 17.4542 9.21804C18.8036 10.136 19.8942 11.2102 20.5805 12.0302C17.5563 16.2326 13.8237 16.6897 12.0001 16.6896ZM10.0614 12C10.0614 10.9292 10.9294 10.0611 12.0003 10.0611C13.0711 10.0611 13.9392 10.9292 13.9392 12C13.9392 13.0708 13.0711 13.9389 12.0003 13.9389C10.9294 13.9389 10.0614 13.0708 10.0614 12ZM12.0003 8.66112C10.1562 8.66112 8.66138 10.156 8.66138 12C8.66138 13.844 10.1562 15.3389 12.0003 15.3389C13.8443 15.3389 15.3392 13.844 15.3392 12C15.3392 10.156 13.8443 8.66112 12.0003 8.66112Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default PasswordShow;
