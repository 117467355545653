export const viewDetailDiagnosisPageText = {
    pageLabel: '진료 목록',
    pageTitle: '진료 상세보기',
    changePatientInfo: '환자 정보 변경',
    deleteDiagnosis: '진료 삭제',
    changeDiagnosisInfo: '진료 정보 변경',
};

export const diagnosisTaps = ['검사 항목', '종합 결과', '문항별 응답', '처방 메모'];

export const programList = ['전체', 'K-GDA I', 'K-GDA II', 'K-GDA III', 'K-GDA IV'];

export const programToolTip = [
    { age: '', schoolAge: '' },
    { age: '만 6세 이상', schoolAge: '72~83개월' },
    { age: '만 7~9세', schoolAge: '초등 1~3학년' },
    { age: '만 10~12세', schoolAge: '초등 4~6학년' },
    { age: '만 13~18세', schoolAge: '중･고등 1~3학년' },
];

export const returnSelectedAssessmentModalText = {
    title: '검사를 지정하지 않고 나갈까요?',
    content: '지금 나가시면, 검사 지정 전으로 돌아갑니다.',
    firstButton: '계속 지정하기',
    secondButton: '나가기',
};
export const leaveModalText = {
    title: '검사를 변경하지 않고 나갈까요?',
    content: '지금 나가시면, 기존에 지정된 검사로 유지됩니다.',
    firstButton: '변경 계속하기',
    secondButton: '나가기',
};

export const InfoBodyComprehensiveResultsText = {
    title: '종합 결과',
    firstSubText: '검사의 결과는 검사 항목에 따라 확인이 가능합니다.',
    secondSubText: '종합 결과는 검사 항목 별로 저장 및 인쇄가 가능합니다.',
};
export const InfoBodyUserResponsesText = {
    title: '문항 별 응답',
    firstSubText: '검사의 문항 별 응답은 검사 항목에 따라 확인이 가능합니다.',
    secondSubText: '문항 별 응답은 검사 항목 별로 인쇄가 가능합니다.',
};
export const InfoBodyDiagnosisMemoText = {
    title: '처방 메모',
    firstSubText: '진료 결과에 대한 처방을 기록할 수 있습니다.',
    secondSubText: '처방 메모는 수정하기를 통해서 언제든지 수정이 가능합니다.',
    textAreaPlaceholder: '진료 결과에 대한 처방을 입력해주세요.',
};

export const NotPrescriptionMemoCancelModalText = {
    title: '처방 메모를 저장하지 않고 나갈까요?',
    subText: '지금 나가시면, 작성한 내용은 삭제됩니다.',
    firstButton: '작성 계속하기',
    secondButton: '나가기',
};

export const HavePrescriptionMemoCancelModalText = {
    title: '처방 메모를 저장하지 않고 나갈까요?',
    firstSubText: '지금 나가시면, 수정한 내용은 삭제되고',
    secondSubText: '기존에 작성된 내용으로 유지됩니다.',
    firstButton: '수정 계속하기',
    secondButton: '나가기',
};
