import React from 'react';

const Noti_Check = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '32'} height={$height || '32'} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_755_1190)'>
                <circle cx='16' cy='16' r='16' fill='#264C97' />
                <path d='M10 15.6L14.5 20.4L22 12.4' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </g>
            <defs>
                <clipPath id='clip0_755_1190'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Noti_Check;
