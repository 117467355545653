import React from 'react';

const Hospital = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.0572 9.38V8.45714H14.98C15.6538 8.45714 16.2 7.91093 16.2 7.23714V7.2H17.8V20.8H15V18C15 17.4477 14.5523 17 14 17H10C9.44772 17 9 17.4477 9 18V20.8H6.2V7.2H7.80005V7.23714C7.80005 7.91093 8.34626 8.45714 9.02005 8.45714H9.94294V9.38C9.94294 10.0538 10.4892 10.6 11.1629 10.6H12.8372C13.511 10.6 14.0572 10.0538 14.0572 9.38ZM7.80005 6H6C5.44772 6 5 6.44771 5 7V13H3C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H6H10H14H18H21C21.5523 22 22 21.5523 22 21V14C22 13.4477 21.5523 13 21 13H19V7C19 6.44771 18.5523 6 18 6H16.2V5.56285C16.2 4.88907 15.6538 4.34285 14.98 4.34285H14.0572V3.42C14.0572 2.74621 13.511 2.2 12.8372 2.2H11.1629C10.4892 2.2 9.94294 2.74621 9.94294 3.42V4.34285H9.02005C8.34626 4.34285 7.80005 4.88907 7.80005 5.56285V6ZM15 6V5.56285C15 5.55181 14.9911 5.54285 14.98 5.54285H12.8772C12.8662 5.54285 12.8572 5.5339 12.8572 5.52285V3.42C12.8572 3.40895 12.8483 3.4 12.8372 3.4H11.1629C11.1519 3.4 11.1429 3.40895 11.1429 3.42L11.1429 5.52285C11.1429 5.5339 11.134 5.54285 11.1229 5.54285H9.02005C9.009 5.54285 9.00005 5.55181 9.00005 5.56285V7.23714C9.00005 7.24819 9.009 7.25714 9.02005 7.25714H11.1229C11.134 7.25714 11.1429 7.26609 11.1429 7.27714V9.38C11.1429 9.39104 11.1519 9.4 11.1629 9.4H12.8372C12.8483 9.4 12.8572 9.39104 12.8572 9.38L12.8572 7.27714C12.8572 7.26609 12.8662 7.25714 12.8772 7.25714H14.98C14.9911 7.25714 15 7.24819 15 7.23714V7.2H15V6H15ZM13.8 20.8H10.2V18.2H13.8V20.8ZM19 20.8V14.2H20.8V20.8H19ZM5 14.2V20.8H3.2V14.2H5ZM8.19995 15C8.19995 14.6686 8.46858 14.4 8.79995 14.4H15.2C15.5313 14.4 15.8 14.6686 15.8 15C15.8 15.3314 15.5313 15.6 15.2 15.6H8.79995C8.46858 15.6 8.19995 15.3314 8.19995 15ZM8.79995 12C8.46858 12 8.19995 12.2686 8.19995 12.6C8.19995 12.9314 8.46858 13.2 8.79995 13.2H15.2C15.5313 13.2 15.8 12.9314 15.8 12.6C15.8 12.2686 15.5313 12 15.2 12H8.79995Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default Hospital;
