import React from 'react';
import { InverseColor } from 'styles/Global/colorSemantic';

const UserLevelToolTipHeader = ({ $top, $left }: { $top?: string; $left?: string }) => {
    return (
        <svg style={{ position: 'absolute', top: $top || '23px', left: $left || '0' }} width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4 0L0 4H8L4 0Z' fill={InverseColor.color_inverse_primary_over} />
        </svg>
    );
};

export default UserLevelToolTipHeader;
