import { Suspense, useEffect } from 'react';
import { GlobalStyles } from './styles/Global/globalStyles';
import Header from './components/Common/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideBar from 'components/Common/SideBar';
import styled from 'styled-components';
import axios from 'axios';
import Loading from 'components/Common/Loading';
import { useLoadingStore } from 'store/useLoadingStore';
import { pagePaths } from 'constants/path';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import AlertDialog from 'components/Modal/AlertDialog';
import { alertDialogConstants } from 'constants/appConstants';
import { set } from 'lodash';

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    const { loading, setLoading } = useLoadingStore();

    const { isLogoutModal, setIsLogoutModal } = useLeaveModalStateStore();

    useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                setLoading(false);
                if (error.response.status === 534) {
                    localStorage.clear();
                    setIsLogoutModal(true);
                    return navigate(pagePaths.login, { replace: true });
                }
                console.log(error, 'axiosInterceptResponseError');
                return Promise.reject(error);
            }
        );
    }, []);

    const renderHeader = () => {
        const pathsWithoutHeader = ['/', '/sign-up'];
        return !pathsWithoutHeader.includes(location.pathname);
    };

    return (
        <>
            <GlobalStyles />
            <AppLayout>
                {isLogoutModal && (
                    <AlertDialog $title={alertDialogConstants.title} $content={alertDialogConstants.content} $subContent={alertDialogConstants.subContent} $onClick={() => setIsLogoutModal(false)} />
                )}
                <Loading loading={loading} />
                <SideBar location={renderHeader()} />
                <InnerLayout>
                    {renderHeader() && <Header />}
                    <Outlet />
                </InnerLayout>
            </AppLayout>
        </>
    );
}

export default App;

const AppLayout = styled.div`
    position: relative;
    display: flex;
    width: 100vw;
    min-height: 100vh;
`;

const InnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 960px;
    // min-height: 100vh;
`;
