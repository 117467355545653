export const schoolAge = ['초등학교 이전', '초등학교', '중학교', '고등학교'];

export const primarySchool = [
    { school: '초등학교', semester: '1학년 1학기', value: '2020' },
    { school: '초등학교', semester: '1학년 2학기', value: '2021' },
    { school: '초등학교', semester: '2학년 1학기', value: '2022' },
    { school: '초등학교', semester: '2학년 2학기', value: '2023' },
    { school: '초등학교', semester: '3학년 1학기', value: '2024' },
    { school: '초등학교', semester: '3학년 2학기', value: '2025' },
    { school: '초등학교', semester: '4학년 1학기', value: '2026' },
    { school: '초등학교', semester: '4학년 2학기', value: '2027' },
    { school: '초등학교', semester: '5학년 1학기', value: '2028' },
    { school: '초등학교', semester: '5학년 2학기', value: '2029' },
    { school: '초등학교', semester: '6학년 1학기', value: '2030' },
    { school: '초등학교', semester: '6학년 2학기', value: '2031' },
    { school: '초등학교', semester: '졸업', value: '2032' },
];

export const middleSchool = [
    { school: '중학교', semester: '1학년 1학기', value: '2040' },
    { school: '중학교', semester: '1학년 2학기', value: '2041' },
    { school: '중학교', semester: '2학년 1학기', value: '2042' },
    { school: '중학교', semester: '2학년 2학기', value: '2043' },
    { school: '중학교', semester: '3학년 1학기', value: '2044' },
    { school: '중학교', semester: '3학년 2학기', value: '2045' },
    { school: '중학교', semester: '졸업', value: '2046' },
];

export const highSchool = [
    { school: '고등학교', semester: '1학년 1학기', value: '2050' },
    { school: '고등학교', semester: '1학년 2학기', value: '2051' },
    { school: '고등학교', semester: '2학년 1학기', value: '2052' },
    { school: '고등학교', semester: '2학년 2학기', value: '2053' },
    { school: '고등학교', semester: '3학년 1학기', value: '2054' },
    { school: '고등학교', semester: '3학년 2학기', value: '2055' },
    { school: '고등학교', semester: '졸업', value: '2056' },
];

// assessment code value range
export const RS_CO_SG_CA = [
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2040',
    '2041',
    '2042',
    '2043',
    '2044',
    '2045',
    '2046',
    '2050',
    '2051',
    '2052',
    '2053',
    '2054',
    '2055',
];

export const SCI2_CO_SG_E13 = ['2020', '2021', '2022', '2023', '2024', '2025'];

export const MindFitR_CO_SG_E = ['2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031'];
