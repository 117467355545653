import React from 'react';

const ToastNotice = () => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_2036_192583)'>
                <path
                    d='M7.33398 4.66658H8.66732V5.99992H7.33398V4.66658ZM7.33398 7.33325H8.66732V11.3333H7.33398V7.33325ZM8.00065 1.33325C4.32065 1.33325 1.33398 4.31992 1.33398 7.99992C1.33398 11.6799 4.32065 14.6666 8.00065 14.6666C11.6807 14.6666 14.6673 11.6799 14.6673 7.99992C14.6673 4.31992 11.6807 1.33325 8.00065 1.33325ZM8.00065 13.3333C5.06065 13.3333 2.66732 10.9399 2.66732 7.99992C2.66732 5.05992 5.06065 2.66659 8.00065 2.66659C10.9406 2.66659 13.334 5.05992 13.334 7.99992C13.334 10.9399 10.9406 13.3333 8.00065 13.3333Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_2036_192583'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ToastNotice;
