import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import styled from 'styled-components';
import { BackgroundColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body1_Medium_button, Display2_SemiBold } from 'styles/Global/typography';
import SurveyResult from 'assets/Icons/ETC/Tablet/survey_result.json';
import Lottie from 'react-lottie';
import { tabletPaths } from 'constants/path';
import { useInpsytParamDataStore } from 'store/useInpsytParamDataStore';
import _ from 'lodash';

const UserSurvey = () => {
    const { state } = useLocation();
    const { resetPatient } = usePatientStore();
    const { resetAssessment } = useAssessmentStore();
    const { resetInpsytParamData } = useInpsytParamDataStore();
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SurveyResult,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const handleClick = (path: 'login' | 'manager-login') => {
        setLoading(true);
        resetPatient();
        resetAssessment();
        resetInpsytParamData();
        if (path === 'login') {
            navigation(tabletPaths.login);
        } else {
            navigation(tabletPaths.managerLogin);
        }
    };

    return (
        <Wrapper>
            <ServeyLayout>
                <DetailLayout>
                    <Lottie options={defaultOptions} width={140} height={140} />
                    {state.step && state.step === 'All' ? (
                        <TextLayout>
                            <p>수고하셨습니다.</p>
                            <p>모든 검사가 끝났습니다!</p>
                        </TextLayout>
                    ) : (
                        <TextLayout>
                            <p>검사가 완료처리되었습니다. </p>
                            <p>웹 서비스에서 확인해주세요!</p>
                        </TextLayout>
                    )}
                </DetailLayout>
                {state.step === 'All' && <Button onClick={() => handleClick('login')}>나가기</Button>}
                {state.step === 'login' && <Button onClick={() => handleClick('login')}>환자 변경하러 나가기</Button>}
                {state.step === 'manager-login' && <Button onClick={() => handleClick('manager-login')}>로그아웃</Button>}
            </ServeyLayout>
        </Wrapper>
    );
};

export default UserSurvey;

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ServeyLayout = styled.div`
    width: 380px;
    row-gap: 40px;
`;

const DetailLayout = styled.div`
    width: 100%;
    height: 372px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
`;

const TextLayout = styled(Display2_SemiBold)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
`;

const Button = styled(Body1_Medium_button)`
    width: 380px;
    height: 72px;
    background-color: ${BackgroundColor.color_background_strong};
    color: ${TextColor.color_text_normal};
    border-radius: ${Radius.radius_4};

    &:active {
        background-color: ${BackgroundColor.color_background_over};
    }
`;
