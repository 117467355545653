import moment from 'moment';

// timestamp diagnosisTime 'YYYY/MM/DD' 형식으로 전환
export const calculationTimeStamp = (timestamp: number) => {
    let roundedTime;

    const time = moment(timestamp);
    const minutes = time.minutes();

    roundedTime = minutes === 30 ? time.startOf('hour').add(30, 'minutes') : time.startOf('hour');

    // const formattedTimestamp = roundedTime.minutes() === 0 ? roundedTime.format('YYYY/MM/DD A h시') : roundedTime.format('YYYY/MM/DD A h시 m분');
    const formattedTimestamp = roundedTime.minutes() === 0 ? roundedTime.format('YYYY/MM/DD A h:00') : roundedTime.format('YYYY/MM/DD A h:30');

    return formattedTimestamp;
};

// export const formattedTime = (time: string) => {
//     let date = moment(time, 'YYYYMMDDHHmm');

//     let formattedDate = date.minutes() === 30 ? date.format('YYYY/MM/DD A h시 30분') : date.format('YYYY/MM/DD A h시');
//     return formattedDate;
// };
export const formattedTime = (time: string) => {
    let date = moment(time, 'YYYYMMDDHHmm');

    let formattedDate = date.minutes() === 30 ? date.format('YYYY/MM/DD A h:30') : date.format('YYYY/MM/DD A h:00');
    return formattedDate;
};

export const ViewDianosisformattedTime = (time: string) => {
    let date = moment(time, 'YYYYMMDDHHmm');

    let formattedDate = date.minutes() === 30 ? date.format('YYYY/MM/DD A h:30') : date.format('YYYY/MM/DD A h시');
    return formattedDate;
};
