import React from 'react';

const Close = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.70708 3.29288C4.31656 2.90235 3.68341 2.90235 3.29289 3.29288C2.90237 3.68341 2.90237 4.31659 3.29289 4.70712L10.5859 12.0003L3.2935 19.2929C2.90299 19.6834 2.90299 20.3166 3.2935 20.7071C3.68402 21.0977 4.31718 21.0977 4.7077 20.7071L12 13.4145L19.2924 20.7071C19.6829 21.0976 20.3161 21.0976 20.7066 20.7071C21.0971 20.3166 21.0971 19.6834 20.7066 19.2929L13.4142 12.0003L20.7072 4.70712C21.0977 4.31659 21.0977 3.68342 20.7072 3.29288C20.3167 2.90235 19.6835 2.90235 19.293 3.29288L12 10.5861L4.70708 3.29288Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default Close;
