import { LoginStore, loginData } from 'models/Login/LoginDataStoreTypes';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useLoginStore = create(
    persist<LoginStore>(
        (set) => ({
            loginData: {} as loginData,
            setLoginData: (data) => set((state) => ({ loginData: { ...state.loginData, ...data } })),
            clearLoginData: () => set({ loginData: {} as loginData }),
        }),
        {
            name: 'loginData',
        }
    )
);
