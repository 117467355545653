import React from 'react';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { RemoveDiagnosisModalProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { calculateAge } from 'functions/calculateAge';
import { AgeAndBirthDateWrapper, CloseButtonWrapper, TextInDetailPatientPageModal } from 'styles/Modal/modalStyle';
import { formattedTime } from 'functions/calculateTime';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import BasicButton from 'components/Buttons/BasicButton';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { RoundBadgeS } from 'components/Common/Badge';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_Medium, Body5_Regular } from 'styles/Global/typography';
import ModalDeleteIcon from 'assets/Icons/DeleteIcon/CloseDialog';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';

const RemoveDiagnosisModal = ({ userInfo, diagnosisInfo, toggle, clickRemoveButton }: RemoveDiagnosisModalProps) => {
    const { birthDate, ageString } = calculateAge(diagnosisInfo.patientIdentityNumber);

    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <ModalInnerLayout>
                    <ModalTitleAndContentContainer>
                        <ModalTitle>해당 진료를 삭제할까요?</ModalTitle>
                        <ModalContent>삭제하시면 환자의 정보는 유지되지만, 해당 진료와 관련된 데이터는 더 이상 볼 수 없습니다.</ModalContent>
                    </ModalTitleAndContentContainer>
                    <InfoContainer>
                        <PatientInfoHeaderContainer>
                            <RoundBadgeS>{userInfo.regNum}</RoundBadgeS>
                            <InfoWrapper>
                                <TextInDetailPatientPageModal>{userInfo.name}</TextInDetailPatientPageModal>
                                <TextInDetailPatientPageModal>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {userInfo.gender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                    </span>
                                </TextInDetailPatientPageModal>
                                <AgeAndBirthDateWrapper>
                                    <span>{ageString}</span>
                                    <span>({birthDate})</span>
                                </AgeAndBirthDateWrapper>
                            </InfoWrapper>
                        </PatientInfoHeaderContainer>
                        <PatientInfoBodyContainer>
                            <CellsWrapper>
                                <Label>진료 일시</Label>
                                <Value>{formattedTime(diagnosisInfo.diagnosisDateTime)}</Value>
                            </CellsWrapper>
                            <CellsWrapper>
                                <Label>학령/학기</Label>
                                <Value>{diagnosisInfo.gradeName}</Value>
                            </CellsWrapper>
                            <CellsWrapper>
                                <Label>담당의</Label>
                                <Value>
                                    {diagnosisInfo.doctorName}
                                    <Text>원장님</Text>
                                </Value>
                            </CellsWrapper>
                            <ProcessCheckBarCellsWrapper>
                                <Label>검사 항목</Label>
                                <Value>
                                    <ProcessCheckBar userInfo={userInfo} diagnosisInfo={diagnosisInfo} $height='36px' $width='200px' />
                                </Value>
                            </ProcessCheckBarCellsWrapper>
                        </PatientInfoBodyContainer>
                    </InfoContainer>
                </ModalInnerLayout>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={toggle} $type='anotherFill'>
                        취소
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' $type='fill' onClick={clickRemoveButton}>
                        삭제하기
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={toggle}>
                    <ModalDeleteIcon />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default RemoveDiagnosisModal;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;
const ModalInnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 16px;
`;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 4px;
    gap: 14px;
`;

const PatientInfoHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
const PatientInfoBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    background-color: ${BackgroundColor.color_background_neutral};
    border-radius: ${Radius.radius_4};
    gap: 12px;
`;

const CellsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 22px;
    gap: 12px;
`;
const ProcessCheckBarCellsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    gap: 12px;
`;

const Label = styled(Body5_Regular)`
    width: 100px;
    color: ${TextColor.color_text_light};
`;

const Value = styled(Body4_Medium)`
    width: calc(100% - 84px);
    color: ${TextColor.color_text_normal};
`;

const Text = styled.span`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    color: ${palette.gray};
    margin-left: 2px;
`;
