import { SubTitle, SubTitleLayout, SubTitleText, SubTitleTextContainer } from 'components/Hospital/ViewDetailDiagnosisPageSubTitleForms';
import React from 'react';
import styled from 'styled-components';
import { font } from 'styles/Global/globalStyles';

export const InfoBodyAssessmentListSubTitle = ({ status, editState }: { status: number; editState: boolean }) => {
    return (
        <SubTitleLayout>
            <SubTitle>{status === 0 ? '검사 지정하기' : editState ? '검사 변경하기' : '지정된 검사'}</SubTitle>
            <SubTitleTextContainer>
                <SubTitleText>
                    {status === 0 || editState ? (
                        <>
                            필터로 TLC 프로그램을 추천받아 빠르게 선택하고, 아래의 <WeightBoldText>'검사 지정하기' 버튼</WeightBoldText>을 누르면 검사 지정이 완료됩니다.
                        </>
                    ) : (
                        '지정된 검사는 검사 시작 전까지 변경이 가능합니다.'
                    )}
                </SubTitleText>
                <SubTitleText>{status === 0 || editState ? '지정된 검사는 검사 시작 전까지 변경이 가능합니다.' : '검사가 시작되면 변경 불가능합니다.'}</SubTitleText>
            </SubTitleTextContainer>
        </SubTitleLayout>
    );
};

export const InfoBodySubTitle = ({ title, firstSubText, secondSubText }: { title: string; firstSubText: string; secondSubText: string }) => {
    return (
        <SubTitleLayout>
            <SubTitle>{title}</SubTitle>
            <SubTitleTextContainer>
                <SubTitleText>{firstSubText}</SubTitleText>
                <SubTitleText>{secondSubText}</SubTitleText>
            </SubTitleTextContainer>
        </SubTitleLayout>
    );
};

const WeightBoldText = styled.span`
    font-weight: ${font.weight.bold};
`;
