import React from 'react';

const Page_Next = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.50498 6.50501C9.23161 6.77838 9.23161 7.2216 9.50498 7.49496L14.01 12L9.50498 16.505C9.23161 16.7784 9.23161 17.2216 9.50498 17.495C9.77834 17.7683 10.2216 17.7683 10.4949 17.495L15.4949 12.495C15.7683 12.2216 15.7683 11.7784 15.4949 11.505L10.4949 6.50501C10.2216 6.23165 9.77834 6.23165 9.50498 6.50501Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default Page_Next;
