import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { UpdateAccountApiResponse, UpdateAccountRqData } from 'models/Hospital/hospitalApiTypes';

class AccountSettingApi {
    async updateAccount(rqData: UpdateAccountRqData, token: string): Promise<UpdateAccountApiResponse> {
        try {
            const response = await axios.put(apiPaths.updateAccountInfo, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const accountSettingApi = new AccountSettingApi();
