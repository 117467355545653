import Hospital from 'assets/Icons/HospitalIcon/Hospital';
import { InputContainerStyleProps, InputProps } from 'models/Common/inputTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { InputLabel } from 'styles/Input/inputStyle';

export const HospitalNameWithInput = ({ label, id, disabled, value }: InputProps) => {
    return (
        <Layout>
            <InputLabel $marginBottom='0'>{label}</InputLabel>
            <HospitalNameInputContainer disabled={disabled}>
                <Hospital />
                <HospitalInput id={id} disabled={disabled} value={value} type='text' />
            </HospitalNameInputContainer>
        </Layout>
    );
};

const Layout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const HospitalInput = styled.input<InputContainerStyleProps>`
    width: 100%;
    height: 100%;
    line-height: 24px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:disabled {
        color: ${TextColor.color_text_normal};
        border: none;
    }
`;

const HospitalNameInputContainer = styled.div<InputContainerStyleProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid ${BorderColor.color_border_normal};
    padding: 18px 20px;
    height: 60px;
    width: 100%;
    background-color: ${(props) => (props.disabled ? BackgroundColor.color_background_disabled : palette.white)};
`;
