import React from 'react';
import { palette } from 'styles/Global/globalStyles';

const AddRound = ({ disabled }: { disabled: boolean | undefined }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.6 12C20.6 16.7496 16.7496 20.6 12 20.6C7.25035 20.6 3.4 16.7496 3.4 12C3.4 7.25035 7.25035 3.4 12 3.4C16.7496 3.4 20.6 7.25035 20.6 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11.9998 7.29999C12.3864 7.29999 12.6998 7.61339 12.6998 7.99999V11.3H16.0002C16.3868 11.3 16.7002 11.6134 16.7002 12C16.7002 12.3866 16.3868 12.7 16.0002 12.7H12.6998V16C12.6998 16.3866 12.3864 16.7 11.9998 16.7C11.6132 16.7 11.2998 16.3866 11.2998 16V12.7H8.0002C7.6136 12.7 7.3002 12.3866 7.3002 12C7.3002 11.6134 7.6136 11.3 8.0002 11.3H11.2998V7.99999C11.2998 7.61339 11.6132 7.29999 11.9998 7.29999Z'
                fill='#C2C4C8'
            />
        </svg>
    );
};

export default AddRound;
