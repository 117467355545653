import axios, { AxiosResponse } from "axios";
import { apiPaths } from "constants/path";
import { tokenHeader } from "functions/apiFunctions";
import { AssessmentchangeStatusParam, AxiosResponseType, changeAssessmentApiResponse } from "models/Tablet/AssessmentApiTypes";

class AssessmentApi {
    async changeAssessmentStatus(param:AssessmentchangeStatusParam, token:string): Promise<AxiosResponseType<changeAssessmentApiResponse>>{
        
        const response = await axios.put(`${apiPaths.changeAssessmentStatus}`,param, {
            headers: tokenHeader(token),
        })
        
        return response.data;
    }
}

export const assessmentApi = new AssessmentApi();