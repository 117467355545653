import React, { useState, useEffect } from 'react';
import { BackgroundColor, PrimaryColor } from 'styles/Global/colorSemantic';

const Icon_Select_Checkbox = ({ $disabled, $width, $height, $marginRight }: { $disabled?: boolean; $width?: string; $height?: string; $marginRight?: string }) => {
    const [fillColor, setFillColor] = useState(BackgroundColor.color_background_primary);

    useEffect(() => {
        const svgElement = document.getElementById('checkbox-svg');

        if ($disabled) {
            setFillColor(BackgroundColor.color_background_primary_disabled);
        } else {
            const handleMouseOver = () => {
                if (!$disabled) {
                    setFillColor(PrimaryColor.color_primary_strong);
                }
            };

            const handleMouseOut = () => {
                if (!$disabled) {
                    setFillColor(BackgroundColor.color_background_primary);
                }
            };

            const handleMouseDown = () => {
                if (!$disabled) {
                    setFillColor(PrimaryColor.color_primary_heavy);
                }
            };

            const handleMouseUp = () => {
                if (!$disabled) {
                    setFillColor(BackgroundColor.color_background_primary);
                }
            };

            const handleFocus = () => {
                if (!$disabled) {
                    setFillColor(PrimaryColor.color_primary_heavy);
                }
            };

            const handleBlur = () => {
                if (!$disabled) {
                    setFillColor(BackgroundColor.color_background_primary);
                }
            };

            svgElement?.addEventListener('mouseover', handleMouseOver);
            svgElement?.addEventListener('mouseout', handleMouseOut);
            svgElement?.addEventListener('mousedown', handleMouseDown);
            svgElement?.addEventListener('mouseup', handleMouseUp);
            svgElement?.addEventListener('focus', handleFocus);
            svgElement?.addEventListener('blur', handleBlur);

            return () => {
                svgElement?.removeEventListener('mouseover', handleMouseOver);
                svgElement?.removeEventListener('mouseout', handleMouseOut);
                svgElement?.removeEventListener('mousedown', handleMouseDown);
                svgElement?.removeEventListener('mouseup', handleMouseUp);
                svgElement?.removeEventListener('focus', handleFocus);
                svgElement?.removeEventListener('blur', handleBlur);
            };
        }
    }, [$disabled]);

    return (
        <svg
            style={{ marginRight: $marginRight || 0 }}
            id='checkbox-svg'
            width={$width || '28'}
            height={$height || '28'}
            viewBox='0 0 28 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            tabIndex={0}
        >
            <path d='M28 8C28 3.58172 24.4183 0 20 0H8C3.58172 0 0 3.58172 0 8V20C0 24.4183 3.58172 28 8 28H20C24.4183 28 28 24.4183 28 20V8Z' fill={fillColor} />
            <path d='M8 14L12 18L20 10' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export default Icon_Select_Checkbox;
