import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import BasicButton from 'components/Buttons/BasicButton';
import { font, palette } from 'styles/Global/globalStyles';
import styled from 'styled-components';
import HospitalAccountIcon from 'assets/Icons/Account/Account';
import { TextColor } from 'styles/Global/colorSemantic';
import ModalDeleteIcon from 'assets/Icons/DeleteIcon/CloseDialog';
import { Body3_Medium } from 'styles/Global/typography';
import { MemberWhiteBadge } from 'components/Common/Badge';
import { CloseButtonWrapper } from 'styles/Modal/modalStyle';

const ChangeUserLevelModal = ({ toggle, userName, onClick, userEmail, userLevel }: { toggle: () => void; userName: string; onClick: () => void; userEmail: string; userLevel: number }) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <div style={{ width: '100%' }}>
                    <ModalTitleAndContentWrapper>
                        <ModalTitle>{userLevel === 0 ? '관리자로 변경할까요?' : '멤버로 변경할까요?'}</ModalTitle>
                        <ModalContent>{userLevel === 0 ? '변경하시면, 해당 계정에 관리자 권한이 부여됩니다.' : '변경하시면, 해당 계정의 관리자 권한이 사라집니다.'}</ModalContent>
                    </ModalTitleAndContentWrapper>
                    <DoctorContainer>
                        <HospitalAccountIcon />
                        <UserNameAndIdContainer>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <Text>{userName}</Text>
                                <MemberWhiteBadge>{userLevel === 0 ? '멤버' : '관리자'}</MemberWhiteBadge>
                            </div>
                            <Email>{userEmail}</Email>
                        </UserNameAndIdContainer>
                    </DoctorContainer>
                </div>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={toggle} $type='anotherFill'>
                        취소
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' onClick={onClick} $type='warning'>
                        변경하기
                    </BasicButton>
                    <CloseButtonWrapper onClick={toggle}>
                        <ModalDeleteIcon />
                    </CloseButtonWrapper>
                </ModalFooter>
            </ModalLayout>
        </ModalBackground>
    );
};

export default ChangeUserLevelModal;

const ModalTitleAndContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
`;

const UserNameAndIdContainer = styled.div`
    display: flex;
    width: calc(100% - 24px);
    flex-direction: column;
    gap: 5px;
`;
const DoctorContainer = styled.div`
    display: flex;
    width: 100%;
    height: 86px;
    padding: 18px 20px 20px;
    font-size: 16px;
    gap: 8px;
    border: 1px solid ${palette.testGray};
    border-radius: 4px;
    background-color: ${palette.white};
`;

const Text = styled(Body3_Medium)`
    color: ${TextColor.color_text_normal};
`;
const Email = styled.span`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    line-height: normal;
    color: ${TextColor.color_text_light};
`;
