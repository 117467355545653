export const Radius = {
    radius_0: '0px',
    radius_4: '4px',
    radius_8: '8px',
    radius_12: '12px',
    radius_16: '16px',
    radius_20: '20px',
    radius_24: '24px',
    radius_circle: '9999px',
};

export const Border = {
    border_0: '0px',
    border_1: '1px',
    border_2: '2px',
};
