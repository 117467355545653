import React from 'react';

const Icon_Noti = () => {
    return (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_1464_18737)'>
                <circle cx='16' cy='16' r='16' fill='#E7000E' fillOpacity='0.04' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.0513 17.956L14.5864 9.89741C14.5395 9.08469 15.1859 8.3999 16 8.3999C16.8141 8.3999 17.4605 9.08469 17.4136 9.89741L16.9487 17.956C16.9213 18.4298 16.5292 18.7999 16.0547 18.7999H15.9452C15.4708 18.7999 15.0786 18.4298 15.0513 17.956ZM16 23.3999C16.8284 23.3999 17.5 22.7283 17.5 21.8999C17.5 21.0715 16.8284 20.3999 16 20.3999C15.1716 20.3999 14.5 21.0715 14.5 21.8999C14.5 22.7283 15.1716 23.3999 16 23.3999Z'
                    fill='#FF8A91'
                />
            </g>
            <defs>
                <clipPath id='clip0_1464_18737'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Icon_Noti;
