import { AlarmListStore, GlobalToastStore, ToastStore } from 'models/Common/toastMessegeTypes';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useToastStore = create<ToastStore>((set) => ({
    toastToggle: false,
    comment: '',
    setToast: (setComment) => {
        set(() => ({
            toastToggle: true,
            comment: setComment,
        }));
        setTimeout(() => {
            set(() => ({ toastToggle: false, comment: '' }));
        }, 4000);
    },
}));

export const useGlobalToastStore = create<GlobalToastStore>((set) => ({
    toastList: [],
    toastToggle: false,
    timeoutId: null,
    setToast: (title: string, comment: string, data?: {}) => {
        const id = Date.now();
        set((state) => {
            // Cancel the previous timeout
            if (state.timeoutId) {
                clearTimeout(state.timeoutId);
            }

            // Set a new timeout
            const timeoutId = setTimeout(() => {
                set(() => ({
                    toastList: [],
                    toastToggle: false,
                    timeoutId: null,
                }));
            }, 4000);

            useAlarmListStore.getState().setAlarmList({ id, title, comment, data });

            return {
                toastList: [{ id, title, comment, data }, ...state.toastList],
                toastToggle: true,
                timeoutId,
            };
        });
    },
}));

export const useAlarmListStore = create(
    persist<AlarmListStore>(
        (set) => ({
            alarmList: [],
            unConfirmedAlarmList: [],
            setAlarmList: (data) => set((state) => ({ alarmList: [data, ...state.alarmList], unConfirmedAlarmList: [data, ...state.unConfirmedAlarmList] })),
            resetUnConfirmedAlarmList: () => set(() => ({ unConfirmedAlarmList: [] })),
            removeOldAlarms: () =>
                set((state) => {
                    const startOfToday = new Date();
                    startOfToday.setHours(0, 0, 0, 0);
                    const startOfTodayInMs = startOfToday.getTime();
                    return {
                        alarmList: state.alarmList.filter((alarm) => alarm.id >= startOfTodayInMs),
                        unConfirmedAlarmList: state.unConfirmedAlarmList.filter((alarm) => alarm.id >= startOfTodayInMs),
                    };
                }),
        }),
        {
            name: 'alarmList',
        }
    )
);
