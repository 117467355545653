import ViewDetailDiagnosisFilter from './ViewDetailDiagnosisFilter';
import ViewDetailDiagnosisList from './ViewDetailDiagnosisList';
import styled from 'styled-components';
import BasicButton from 'components/Buttons/BasicButton';
import { InfoBodyAssessmentListProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { InfoBodyAssessmentListSubTitle } from './InfoBodySubTitles';
import { BasicNumberBadge } from 'styles/Global/badgeStyles';

const InfoBodyAssessmentList = ({
    diagnosisInfoData,
    editState,
    selectedTLCProgram,
    setSelectedTLCProgram,
    handleResetFilter,
    filterAssessmentData,
    otherAssessmentData,
    initAssessmentData,
    handleCheck,
    selectedAssessment,
    handleAllCheck,
    allCheck,
    setIsAddAssessmentModal,
    setEditState,
    setIsLeaveModal,
    setClickType,
    allFilterAssessmentCheck,
    allOtherAssessmentCheck,
}: InfoBodyAssessmentListProps) => {
    return (
        <>
            <InfoBodyAssessmentListSubTitle status={diagnosisInfoData.status} editState={editState} />
            {(diagnosisInfoData.status === 0 || editState) && (
                <ViewDetailDiagnosisFilter
                    selectedTLCProgram={selectedTLCProgram}
                    setSelectedTLCProgram={setSelectedTLCProgram}
                    status={diagnosisInfoData.status}
                    editState={editState}
                    filterAssessmentData={diagnosisInfoData.status === 0 || editState ? filterAssessmentData : diagnosisInfoData.assessmentList}
                    initAssessmentData={initAssessmentData}
                    handleAllCheck={handleAllCheck}
                    allCheck={allCheck}
                    handleResetFilter={handleResetFilter}
                />
            )}
            <ViewDetailDiagnosisList
                status={diagnosisInfoData.status}
                editState={editState}
                filterAssessmentData={diagnosisInfoData.status === 0 || editState ? filterAssessmentData : diagnosisInfoData.assessmentList}
                otherAssessmentData={otherAssessmentData}
                handleCheck={handleCheck}
                selectedAssessment={selectedAssessment}
                handleAllCheck={handleAllCheck}
                allCheck={allCheck}
                selectedTLCProgram={selectedTLCProgram}
                handleResetFilter={handleResetFilter}
                allFilterAssessmentCheck={allFilterAssessmentCheck}
                allOtherAssessmentCheck={allOtherAssessmentCheck}
            />
            <ButtonWrapper>
                {diagnosisInfoData.status === 0 && filterAssessmentData?.length !== 0 && (
                    <BasicButton $width='310px' onClick={setIsAddAssessmentModal} disabled={selectedAssessment.length === 0} $type='fill' $gap='8px'>
                        검사 지정하기 {selectedAssessment.length > 0 && <BasicNumberBadge>{selectedAssessment.length}</BasicNumberBadge>}
                    </BasicButton>
                )}
                {diagnosisInfoData.status === 1 && !editState && (
                    <BasicButton $width='310px' $type='outLine' onClick={() => setEditState(true)}>
                        검사 변경하기
                    </BasicButton>
                )}
                {diagnosisInfoData.status === 1 && editState && (
                    <>
                        <BasicButton
                            $width='120px'
                            $type='outLine'
                            onClick={() => {
                                setIsLeaveModal();
                                return setClickType('cancel');
                            }}
                        >
                            취소
                        </BasicButton>
                        <BasicButton $width='310px' $type='fill' onClick={setIsAddAssessmentModal} $gap='8px' disabled={selectedAssessment.length === 0}>
                            검사 변경하기 {selectedAssessment.length > 0 && <BasicNumberBadge>{selectedAssessment.length}</BasicNumberBadge>}
                        </BasicButton>
                    </>
                )}
            </ButtonWrapper>
        </>
    );
};

export default InfoBodyAssessmentList;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 10px;
`;
