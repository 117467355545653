import { ChildrenType, ListWrapperProps as HomeCellButtonType } from 'models/Common/commonTypes';
import styled from 'styled-components';
import { TextColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Body1_SemiBold } from 'styles/Global/typography';

export const HomeCellTitleContainer = ({ children }: ChildrenType) => {
    return <TitleContainer>{children}</TitleContainer>;
};

export const HomeCellTitle = ({ children }: ChildrenType) => {
    return <Text>{children}</Text>;
};

export const HomeCellButton = ({ children, onClick }: HomeCellButtonType) => {
    return <Button onClick={onClick}>{children}</Button>;
};

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    height: 26px;
    justify-content: space-between;
    align-items: center;
`;

const Text = styled(Body1_SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const Button = styled.button`
    display: flex;
    height: 100%;
    align-items: center;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.medium};
    color: ${palette.gray};
    gap: 2px;
`;
