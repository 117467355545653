import CancelBtn from 'assets/Icons/ETC/Button/CancelBtn';
import React from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    changeChoiceAnswer: (type: 'change' | 'prev') => void;
    maxNoResCount: number;
}

const NoResponseAnswerModal = ({ setShowModal, changeChoiceAnswer, maxNoResCount }: Props) => {
    const handleCancelClick = () => {
        changeChoiceAnswer!('prev');
    };

    const handleLogoutClick = () => {
        changeChoiceAnswer!('change');
        setShowModal(false);
    };

    return (
        <Wrapper>
            <ModalLayout>
                <CancelBtnLayout onClick={handleLogoutClick}>
                    <CancelBtn />
                </CancelBtnLayout>
                <ModelInfoLayout>
                    <ModelTextLayout>
                        <ModalTitle>무응답 선택은 최대 {maxNoResCount}개만 가능합니다!</ModalTitle>
                        <ModalDescription>
                            <p>이전 무응답을 선택한 문항으로 이동하여 수정하거나,</p>
                            <p>현재 문항에서 다른 답변을 선택해주세요.</p>
                        </ModalDescription>
                    </ModelTextLayout>
                </ModelInfoLayout>
                <ButtonLayout>
                    <CancelButton onClick={handleCancelClick}>이전 무응답으로 이동</CancelButton>
                    <SubmitButton onClick={handleLogoutClick}>다른 답변 선택하기</SubmitButton>
                </ButtonLayout>
            </ModalLayout>
        </Wrapper>
    );
};

export default NoResponseAnswerModal;

const Wrapper = styled.div`
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalLayout = styled.div`
    position: relative;
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    padding: 48px 36px 32px 36px;
    background-color: ${palette.white};
    border-radius: 16px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

const CancelBtnLayout = styled.button`
    position: absolute;
    right: 14px;
    top: 12px;
`;

const ModelInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const ModelTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const ModalTitle = styled.h1`
    text-align: center;
    font-size: ${font.size[28]};
    font-weight: ${font.weight.bold};
`;

const ModalDescription = styled.p`
    text-align: center;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.regular};
`;

const ButtonLayout = styled.div`
    display: flex;
    column-gap: 8px;
`;

const SubmitButton = styled.button`
    width: 260px;
    height: 68px;
    border-radius: 4px;
    color: ${palette.white};
    background-color: ${palette.gray};
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
`;

const CancelButton = styled.button`
    width: 260px;
    height: 68px;
    border-radius: 4px;
    color: #333;
    background-color: #eee;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
`;
