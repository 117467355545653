import { viewDetailPatientPageApi } from 'api/Hospital/viewDetailPatientPageApi';
import BasicButton from 'components/Buttons/BasicButton';
import { ViewDetailDiagnosisPageInfoBody } from 'components/Hospital/ViewDetailInfoBody';
import { pagePaths } from 'constants/path';
import {
    HavePrescriptionMemoCancelModalText,
    NotPrescriptionMemoCancelModalText,
    diagnosisTaps,
    leaveModalText,
    programList,
    returnSelectedAssessmentModalText,
    viewDetailDiagnosisPageText,
} from 'constants/viewDetailDiagnosisPageConstants';
import { formattedTime } from 'functions/calculateTime';
import { PatientInfoInitDataType } from 'models/Hospital/viewDetailPatientPageTypes';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginStore } from 'store/useLoginDataStore';
import styled from 'styled-components';
import { ColumnDivider, PageTitle } from 'styles/Global/commonStyles';
import { font, palette } from 'styles/Global/globalStyles';
import {
    DetailPageInfoLayout,
    DiagnosisInnerLayout,
    DiagnosisItemButton,
    DiagnosisLayout,
    DiagnosisTapsContainer,
    HospitalPageLabel,
    InfoHeaderButtonContainer,
    InfoHeaderContainer,
    TwoBreadCrumbContainer,
} from 'styles/Global/hospitalStyles';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import { AssessmentItemType, GetAssessmentListApiResponse, InfoBodyUserResponsesProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import useToggle from 'hooks/CustomHooks/useToggle';
import AddAssessmentModal from 'components/Modal/AddAssessmentModal';
import { AddDiagnosisApiResponseData, GetResultDiagnosisApiResponse } from 'models/Hospital/hospitalApiTypes';
import EditDiagnosisInfoModal from 'components/Modal/EditDiagnosisInfoModal';
import { highSchool, middleSchool, primarySchool, schoolAge as schoolName } from 'constants/schoolAgeAndSemester';
import moment from 'moment';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import LeaveModal from 'components/Modal/LeaveModal';
import EditPatientInfoModal from 'components/Modal/EditPatientInfoModal';
import { calculateAge, calculateDaysLived } from 'functions/calculateAge';
import { isValidBirthdate } from 'functions/validationFunctions';
import RemoveDiagnosisModal from 'components/Modal/RemoveDiagnosisModal';
import InfoBodyAssessmentList from './InfoBodyAssessmentList';
import InfoBodyComprehensiveResults from './InfoBodyComprehensiveResults';
import InfoBodyDiagnosisMemo from './InfoBodyDiagnosisMemo';
import DiagnosisMemoCancelConfirmModal from 'components/Modal/DiagnosisMemoCancelConfirmModal';
import _ from 'lodash';
import InfoBodyUserResponses from './InfoBodyUserResponses';
import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';
import useConnectRtdb from 'hooks/FirebaseHooks/useConnectRtdb';
import { useLoadingStore } from 'store/useLoadingStore';

const ViewDetailDiagnosisPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const { loginData, setLoginData } = useLoginStore();

    const [patientInfoInitData, setPatientInfoInitData] = useState<PatientInfoInitDataType>(settingInitPatientInfo);
    const [patientName, setPatientName] = useState(patientInfoInitData.name);
    const [identityNumber, setIdentityNumber] = useState(patientInfoInitData.identityNumber.slice(0, 6));
    const [gender, setGender] = useState(patientInfoInitData.identityNumber.slice(6, 7));
    const [mobileNumber, setMobileNumber] = useState(patientInfoInitData.mobile);
    const [patientMemo, setPatientMemo] = useState(patientInfoInitData.patientMemo);
    const [patientCode, setPatientCode] = useState(patientInfoInitData.patientCode);
    const [birthDate, setBirthDate] = useState('');
    const [age, setAge] = useState('');
    const [identityNumberError, setIdentityNumberError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [editDiagnosisModalButtonDisabled, setEditDiagnosisModalButtonDisabled] = useState(false);
    const [diagnosisInfoData, setDiagnosisData] = useState<AddDiagnosisApiResponseData>(state);
    const [initAssessmentData, setInitAssessmentData] = useState<GetAssessmentListApiResponse['data']>([]);
    const [filterAssessmentData, setFilterAssessmentData] = useState<GetAssessmentListApiResponse['data']>([]);
    const [otherAssessmentData, setOtherAssessmentData] = useState<GetAssessmentListApiResponse['data']>([]);
    const [temporarySelectedTap, setTemporarySelectedTap] = useState(loginData.diagnosisTap);
    const [selectedTLCProgram, setSelectedTLCProgram] = useState(0);
    const [initSelectedAssessment, setInitSelectedAssessment] = useState<GetAssessmentListApiResponse['data']>([]);
    const [selectedAssessment, setSelectedAssessment] = useState<GetAssessmentListApiResponse['data']>([]);
    const [allCheck, setAllCheck] = useState(false);
    const [allFilterAssessmentCheck, setAllFilterAssessmentCheck] = useState(false);
    const [allOtherAssessmentCheck, setAllOtherAssessmentCheck] = useState(false);
    const [schoolAge, setSchoolAge] = useState('');
    const [semester, setSemester] = useState('');
    const [gradeCode, setGradeCode] = useState('');
    const [schoolAgeIdx, setSchoolAgeIdx] = useState<number>();
    const [semesterIdx, setSemesterIdx] = useState<number>();
    const [onSchoolAgeDropdown, setOnSchoolAgeDropdown] = useState(false);
    const [onSemesterDropdown, setOnSemesterDropdown] = useState(false);
    const [onCalendar, setOnCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string | null>(moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD'));
    const [selectedTime, setSelectedTime] = useState<string>(moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('A h:mm'));
    const [selectedDoctor, setSelectedDoctor] = useState<string>('');
    const [doctorsIdx, setDoctorsIdx] = useState<number>();
    const [errMsg, setErrMsg] = useState({ schoolAge: '', semester: '' });
    const [infoBodyComprehensiveResultsSelectedIdx, setInfoBodyComprehensiveResultsSelectedIdx] = useState(0);
    const [infoBodyUserResponsesSelectedIdx, setInfoBodyUserResponsesSelectedIdx] = useState(0);
    const [comprehensiveResults, setComprehensiveResults] = useState<GetResultDiagnosisApiResponse['data']>();
    const [diagnosisMemo, setDiagnosisMemo] = useState('');
    const [initDiagnosisMemo, setInitDiagnosisMemo] = useState('');
    const [memoChangeBtn, setMemoChangeBtn] = useState(false);
    const [findByAssessmentCode, setFindByAssessmentCode] = useState<InfoBodyUserResponsesProps['findByAssessmentCode']>([]);
    const [openTaps, setOpenTaps] = useState(false);

    const { loading, setLoading } = useLoadingStore();

    const [isAddAssessmentModal, setIsAddAssessmentModal] = useToggle();
    const [isEditDiagnosisInfoModal, setIsEditDiagnosisInfoModal] = useToggle();
    const [isChangePatientInfoModal, setIsChangePatientInfoModal] = useToggle();
    const [isRemoveDiagnosisModal, setIsRemoveDiagnosisModal] = useToggle();
    const [isCancelMemoModal, setIsCancelMemoModal] = useToggle();

    const {
        editState,
        setEditState,
        movePath,
        clickType,
        setClickType,
        setInit,
        isLeaveModal,
        setIsLeaveModal,
        returnSelectedAssessmentState,
        isReturnSelectedAssessment,
        setReturnSelectedAssessmentState,
        setIsReturnSelectedAssessment,
    } = useLeaveModalStateStore();

    const sortedData = useConnectRtdb();

    // rendering 시 환자 정보, 검사 목록 불러오기
    useEffect(() => {
        getPatientInfoApiCall();
        setEditState(false);
        setReturnSelectedAssessmentState(false);
    }, []);

    useEffect(() => {
        getDiagnosisInfoApiCall();
        getResultDiagnosisApiCall();

        const open = _.some(diagnosisInfoData.assessmentList, (item) => item.status === 2);
        if (open) {
            return setOpenTaps(_.some(diagnosisInfoData.assessmentList, (item) => item.status === 2));
        }

        return setOpenTaps(false);
    }, [diagnosisInfoData.status, _.find(sortedData, { _id: diagnosisInfoData._id })?.assessmentList]);

    useEffect(() => {
        setLoading(true);
        if (sortedData && !isAddAssessmentModal && !isEditDiagnosisInfoModal && !isChangePatientInfoModal && !isRemoveDiagnosisModal && !isCancelMemoModal) {
            return setLoading(false);
        }
    }, [sortedData]);

    // 각각 check box의 선택에 따른 전체 선택 체크 및 페이지 나가기 모달 조건 구현
    useEffect(() => {
        if (diagnosisInfoData.status === 0 && selectedAssessment.length !== 0) {
            setReturnSelectedAssessmentState(true);
        } else if (diagnosisInfoData.status === 0 && selectedAssessment.length === 0) {
            setReturnSelectedAssessmentState(false);
        }

        if (filterAssessmentData.every((item) => selectedAssessment.includes(item))) {
            setAllFilterAssessmentCheck(true);
        } else {
            setAllFilterAssessmentCheck(false);
        }

        if (otherAssessmentData.every((item) => selectedAssessment.includes(item))) {
            setAllOtherAssessmentCheck(true);
        } else {
            setAllOtherAssessmentCheck(false);
        }

        if (selectedAssessment.length === filterAssessmentData?.length + otherAssessmentData?.length) {
            setAllCheck(true);
        } else {
            setAllCheck(false);
        }
    }, [selectedAssessment, filterAssessmentData]);

    // 버튼 비활성화 로직

    useEffect(() => {
        if (identityNumberError || mobileNumberError || !patientName || !identityNumber || !gender || !mobileNumber) {
            return setButtonDisabled(true);
        } else if (
            patientName === patientInfoInitData.name &&
            identityNumber === patientInfoInitData.identityNumber.slice(0, 6) &&
            gender === patientInfoInitData.identityNumber.slice(6, 7) &&
            mobileNumber === patientInfoInitData.mobile &&
            patientCode === patientInfoInitData.patientCode &&
            patientMemo === patientInfoInitData.patientMemo
        ) {
            return setButtonDisabled(true);
        }

        return setButtonDisabled(false);
    }, [gender, identityNumber, identityNumberError, patientMemo, mobileNumber, mobileNumberError, patientInfoInitData, patientName, patientCode]);

    useEffect(() => {
        if (
            (diagnosisInfoData.gradeName === '초등학교 이전 ' && semester && schoolAge + ' ' + semester !== diagnosisInfoData.gradeName) ||
            (diagnosisInfoData.gradeName !== '초등학교 이전 ' &&
                ((schoolAge === '초등학교 이전' && !semester) || (schoolAge !== '초등학교 이전' && semester)) &&
                schoolAge + ' ' + semester !== diagnosisInfoData.gradeName) ||
            selectedDoctor !== diagnosisInfoData.doctorName ||
            selectedDate !== moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD') ||
            selectedTime !== moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('A h:mm')
        ) {
            return setEditDiagnosisModalButtonDisabled(false);
        } else if (schoolAge + ' ' + semester === diagnosisInfoData.gradeName) {
            return setEditDiagnosisModalButtonDisabled(true);
        } else if (
            selectedDoctor === diagnosisInfoData.doctorName &&
            selectedDate === moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD') &&
            selectedTime === moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('A h:mm') &&
            schoolAge + ' ' + semester === diagnosisInfoData.gradeName
        ) {
            return setEditDiagnosisModalButtonDisabled(true);
        }
        return setEditDiagnosisModalButtonDisabled(true);
    }, [selectedDoctor, selectedDate, selectedTime, schoolAge, semester, isEditDiagnosisInfoModal]);

    // filtering
    useEffect(() => {
        if (selectedTLCProgram === 0) {
            setSelectedAssessment([]);
            setFilterAssessmentData(initAssessmentData);
            setOtherAssessmentData([]);
        } else {
            const filteredData = initAssessmentData?.filter((item) => item.tags.includes(programList[selectedTLCProgram]));
            const otherData = initAssessmentData?.filter((item) => !item.tags.includes(programList[selectedTLCProgram]));

            setFilterAssessmentData(filteredData);
            setOtherAssessmentData(otherData);

            setSelectedAssessment(filteredData);
        }
    }, [selectedTLCProgram]);

    // memo 변경 시 editAssessmentState를 변경하여 leaveModal을 띄우기 위한 조건
    useEffect(() => {
        if (loginData.diagnosisTap === 3 && diagnosisMemo && diagnosisMemo !== diagnosisInfoData.prescriptionMemo) {
            return setEditState(true);
        } else {
            return setEditState(false);
        }
    }, [diagnosisMemo, diagnosisInfoData.prescriptionMemo]);

    // assessment filter init
    const handleResetFilter = async () => {
        if (selectedTLCProgram === 0) {
            selectedAssessment.length !== 0 && setSelectedAssessment([]);
        } else {
            setSelectedTLCProgram(0);
        }
    };

    // check box handler
    const handleCheck = (item: AssessmentItemType) => {
        const isItemInSelectedAssessment = selectedAssessment.some((selectedItem) => selectedItem._id === item._id);

        if (isItemInSelectedAssessment) {
            setSelectedAssessment(selectedAssessment.filter((selectedItem) => selectedItem._id !== item._id));
        } else {
            setSelectedAssessment([...selectedAssessment, item]);
        }
    };

    // all check box handler
    const handleAllCheck = (type?: string) => {
        if (type === 'filteringData') {
            if (allFilterAssessmentCheck) {
                setAllFilterAssessmentCheck(false);
                setSelectedAssessment(selectedAssessment.filter((item) => !filterAssessmentData.includes(item)));
            } else {
                setAllFilterAssessmentCheck(true);
                setSelectedAssessment([...selectedAssessment, ...filterAssessmentData]);
            }
        } else if (type === 'others') {
            if (allOtherAssessmentCheck) {
                setAllOtherAssessmentCheck(false);
                setSelectedAssessment(selectedAssessment.filter((item) => !otherAssessmentData.includes(item)));
            } else {
                setAllOtherAssessmentCheck(true);
                setSelectedAssessment([...selectedAssessment, ...otherAssessmentData]);
            }
        } else {
            if (allCheck) {
                setAllCheck(false);
                setSelectedAssessment([]);
            } else {
                setAllCheck(true);
                setSelectedAssessment([...filterAssessmentData, ...otherAssessmentData]);
            }
        }
    };

    // 환자 정보 불러오기 API
    const getPatientInfoApiCall = async () => {
        try {
            const response = await viewDetailPatientPageApi.getPatientInfo(state.patientId, loginData.token);

            if (response.status === 'ok') {
                const { birthDate, ageString } = calculateAge(response.data.identityNumber);

                setBirthDate(birthDate);
                setAge(ageString);
                setPatientName(response.data.name);
                setMobileNumber(response.data.mobile);
                setIdentityNumber(response.data.identityNumber.slice(0, 6));
                setGender(response.data.identityNumber.slice(6, 7));
                setPatientMemo(response.data.patientMemo);
                setPatientCode(response.data.patientCode);
                setPatientInfoInitData(response.data);
                getDiagnosisInfoApiCall();
            } else {
                setLoading(false);
                console.log('환자 정보 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 진료 정보 불러오기 API
    const getDiagnosisInfoApiCall = async () => {
        try {
            const response = await viewDetailDiagnosisPageApi.getDiagnosis(diagnosisInfoData._id, loginData.token);

            if (response.status === 'ok') {
                setDiagnosisData(response.data);
                setSelectedDoctor(response.data.doctorName);
                setInitSelectedAssessment(response.data.assessmentList);
                setSelectedAssessment(response.data.assessmentList);
                setDiagnosisMemo(response.data.prescriptionMemo);
                setInitDiagnosisMemo(response.data.prescriptionMemo);
                setDoctorsIdx(loginData.doctors.findIndex((item) => item.name === response.data.doctorName));
                setSelectedDate(moment(response.data.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD'));
                setSelectedTime(moment(response.data.diagnosisDateTime, 'YYYYMMDDHHmm').format('A h:mm'));
                await getAssessmentApiCall(response.data.diagnosisDateTime, response.data.patientIdentityNumber, response.data.gradeCode);
                setLoading(false);
            } else {
                setLoading(false);
                console.log('환자 정보 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 검사 목록 불러오기 API
    const getAssessmentApiCall = async (diagnosisDateTime: string, patientIdentityNumber: string, gradeCode: string) => {
        try {
            const response = await viewDetailDiagnosisPageApi.getAssessmentList(loginData.token);
            const monthLived = calculateDaysLived(patientIdentityNumber, diagnosisDateTime);

            if (response.status === 'ok') {
                const resultData: any[] = [];
                const assessmentRange: { [key: string]: number[] } = {
                    RS_CO_SG_CA: [2020, 2055 + 1],
                    SCI2_CO_SG_E13: [2020, 2025 + 1],
                    MindFitR_CO_SG_E: [2024, 2031 + 1],
                    KBASC2_EX_SG_A: [2030, 2055 + 1],
                    KRCMAS2_CO_SG_E: [2024, 2031 + 1],
                    KRCMAS2_CO_SG_A: [2040, 2055 + 1],
                };

                const ageMonthfilteredData = _.filter(response.data, (item) => _.inRange(monthLived, _.get(item, 'minAgeMonth'), _.get(item, 'maxAgeMonth') + 1));
                const notMonthDaysFilteredData = _.filter(response.data, (item) => _.get(item, 'minAgeMonth') === _.get(item, 'maxAgeMonth'));
                const filteredData = [...ageMonthfilteredData, ...notMonthDaysFilteredData];

                _.filter(filteredData, (item) => {
                    if (!assessmentRange[item.assessmentCode]) {
                        resultData.push(item);
                    } else {
                        if (_.inRange(Number(gradeCode), assessmentRange[item.assessmentCode][0], assessmentRange[item.assessmentCode][1])) {
                            resultData.push(item);
                        }
                    }
                });

                setInitAssessmentData(resultData);
                setFilterAssessmentData(resultData);
                // setLoading(false);
                // if(diagnosisInfoData.status === 3){
                //     window.location.reload();
                // }
            } else {
                setLoading(false);
                console.log('검사 목록 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 진료 정보 변경 API, type이 있으면 diagnosisMemo만 변경   없으면 나머지 정보 변경
    const updateDiagnosisInfoApiCall = async (type?: string, identityNumber?: string) => {
        const selectedMoment = moment(selectedDate, 'YYYY/MM/DD');
        const birthMoment = moment(identityNumber || diagnosisInfoData.patientIdentityNumber, 'YYMMDD');
        const years = selectedMoment.diff(birthMoment, 'years');
        const months = selectedMoment.diff(birthMoment, 'months') - years * 12;

        try {
            const rqData =
                type && type !== 'updatePatientInfo'
                    ? {
                          _id: diagnosisInfoData._id,
                          prescriptionMemo: diagnosisMemo,
                      }
                    : type === 'updatePatientInfo'
                    ? {
                          _id: diagnosisInfoData._id,
                          assessmentAge: `만 ${years}세 ${months}개월`,
                      }
                    : {
                          _id: diagnosisInfoData._id,
                          diagnosisDateTime: moment(selectedDate).format('YYYYMMDD') + moment(selectedTime, 'A h:mm').format('HHmm'),
                          diagnosisTimestamp: moment(selectedDate + ' ' + moment(selectedTime, 'A h:mm').format('HHmm'), 'YYYYMMDD HHmm').valueOf(),
                          doctorName: selectedDoctor,
                          gradeCode: gradeCode,
                          gradeName: `${schoolAge} ${semester}`,
                          assessmentAge: `만 ${years}세 ${months}개월`,
                      };
            const response = await viewDetailDiagnosisPageApi.updateDiagnosisInfo(rqData, loginData.token);

            if (response.status === 'ok') {
                console.log('진료 정보 변경 성공');
                setLoading(false);
                handleResetFilter();
                if ((type === 'updatePatientInfo' || !type) && diagnosisInfoData.status === 1) {
                    const response = await viewDetailDiagnosisPageApi.clearDiagnosis(diagnosisInfoData._id, loginData.token);
                    if (response.status === 'ok') {
                        console.log(response, '진료 초기화 성공');
                        handleResetFilter();
                    } else {
                        console.log(response, '진료 초기화 실패');
                    }
                }
                if (!type) setIsEditDiagnosisInfoModal();
                getDiagnosisInfoApiCall();
            } else {
                setLoading(false);
                console.log('환자 정보 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 검사 결과 불러오기 API
    const getResultDiagnosisApiCall = async () => {
        try {
            const response = await viewDetailPatientPageApi.getResultDiagnosis(diagnosisInfoData._id, loginData.token);

            if (response.status === 'ok') {
                setComprehensiveResults(response.data);
                const findByAssessmentCodeResults = await Promise.all(
                    response.data.map(async (item) => {
                        const getAssessmentCodeResultApiCallResponse = await getAssessmentCodeResultApiCall(item.ozJsonInfoData.PSY_ITEM_ID);
                        return getAssessmentCodeResultApiCallResponse.data;
                    })
                );
                setFindByAssessmentCode(findByAssessmentCodeResults.flat());
            } else {
                setLoading(false);
                console.log('검사 결과 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 검사 받은 항목의 상세 정보 불러오기 API
    const getAssessmentCodeResultApiCall = async (code: string) => {
        try {
            const response = await viewDetailPatientPageApi.getAssessmentCodeResult(code, loginData.token);

            if (response.status === 'ok') {
                console.log(response, '검사 결과 불러오기 성공');
                return response;
            } else {
                setLoading(false);
                console.log('검사 결과 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 진료 지정 추가
    const handleAddAssessment = async (code: number) => {
        setLoading(true);
        try {
            const selectedAssessmentCodeList: string[] = [];
            selectedAssessment.filter((item) => selectedAssessmentCodeList.push(item.assessmentCode));
            const rqData = {
                _id: diagnosisInfoData._id,
                codeList: selectedAssessmentCodeList,
                notificationCode: code,
            };

            const response = await viewDetailDiagnosisPageApi.addAssessment(rqData, loginData.token);
            if (response.status === 'ok') {
                setIsAddAssessmentModal();
                if (diagnosisInfoData.status === 1) {
                    handleResetFilter();
                    setEditState(false);
                    return getDiagnosisInfoApiCall();
                } else if (diagnosisInfoData.status === 0) {
                    const rqData = {
                        _id: diagnosisInfoData._id,
                        status: 1,
                    };
                    const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, loginData.token);

                    if (response.status === 'ok') {
                        console.log(response, 'response');
                        handleResetFilter();
                        return getDiagnosisInfoApiCall();
                    } else {
                        setLoading(false);
                        console.log('stats 변경 실패');
                    }
                }
            } else {
                setLoading(false);
                console.log('검사 지정 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 진료 삭제 API
    const removeDiagnosisApiCall = async () => {
        const rqData = {
            _id: diagnosisInfoData._id,
            status: -1,
        };
        const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, loginData.token);

        if (response.status === 'ok') {
            console.log(response, 'response');
            navigate(pagePaths.diagnosisList, { replace: true });
            setLoading(false);
        } else {
            setLoading(false);
            console.log('stats 변경 실패');
        }
    };

    // 환자 정보 update
    const clickUpdateButton = async () => {
        setLoading(true);
        try {
            const rqData = {
                _id: patientInfoInitData._id,
                name: patientName,
                mobile: mobileNumber,
                identityNumber: identityNumber + gender,
                hospitalId: patientInfoInitData.hospitalId,
                patientCode: patientCode,
                patientMemo: patientMemo,
            };
            const response = await viewDetailPatientPageApi.updatePatientInfo(rqData, loginData.token);

            if (response.status === 'ok') {
                console.log(response, '환자 정보 변경 성공');
                setIsChangePatientInfoModal();
                getPatientInfoApiCall();
                updateDiagnosisInfoApiCall('updatePatientInfo', response.data.identityNumber);
                handleResetFilter();
            } else {
                console.log('환자 정보 변경 실패');
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // 초기 데이터 설정
    const handleInitSchooAgeAndSemester = () => {
        const findItem = (school: { school: string; semester: string; value: string }[]) => school.find((item) => item.value === diagnosisInfoData.gradeCode);

        const foundItem = findItem(primarySchool) || findItem(middleSchool) || findItem(highSchool);

        if (foundItem) {
            const schoolAgeIdx = schoolName.findIndex((item) => item === foundItem.school);
            let semesterIdx = -1;
            if (foundItem.school === '초등학교') {
                semesterIdx = primarySchool.findIndex((item) => item.semester === foundItem.semester);
            } else if (foundItem.school === '중학교') {
                semesterIdx = middleSchool.findIndex((item) => item.semester === foundItem.semester);
            } else if (foundItem.school === '고등학교') {
                semesterIdx = highSchool.findIndex((item) => item.semester === foundItem.semester);
            }
            setSchoolAge(foundItem.school);
            setSemester(foundItem.semester);
            setGradeCode(foundItem.value);
            setSchoolAgeIdx(schoolAgeIdx);
            setSemesterIdx(semesterIdx);
        } else {
            setSchoolAge('초등학교 이전');
            setSemester('');
            setGradeCode('');
            setSchoolAgeIdx(0);
        }
    };

    // 학령 / 학기 드롭다운 핸들러
    const handleSchoolAgeAndSemesterDropDown = (id: string, idx: number | undefined, name: string, value: string) => {
        if (id === 'schoolAge' && idx === 0) {
            setSchoolAge(name);
            setSemester('');
            setGradeCode('');
            setSemesterIdx(undefined);
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setSchoolAgeIdx(idx);
            setErrMsg({ schoolAge: '', semester: '' });
        } else if (id === 'schoolAge' && idx !== undefined) {
            setSchoolAge(name);
            setSemester('');
            setGradeCode('');
            setSemesterIdx(undefined);
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setSchoolAgeIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: '필수 정보입니다. 학령과 학기 모두 선택해주세요.' }));
        } else if (id === 'schoolAge') {
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setOnSemesterDropdown(false);
        } else if (id === 'semester' && idx !== undefined) {
            setSemester(name);
            setGradeCode(value);
            setOnSemesterDropdown(!onSemesterDropdown);
            setSemesterIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else {
            setOnSemesterDropdown(!onSemesterDropdown);
            setOnSchoolAgeDropdown(false);
        }
    };

    // 오늘로 이동 버튼 함수
    const initDate = () => {
        moment.locale('ko');
        const now = moment();
        const roundedNow = now.minute() < 30 ? now.startOf('hour').add(30, 'minutes') : now.startOf('hour').add(1, 'hours');
        setSelectedDate(now.format('YYYY/MM/DD'));
        setSelectedTime(roundedNow.format('A h:mm'));
    };

    // 담당의 변경
    const handleDoctorData = (idx: number) => {
        setDoctorsIdx(idx);
        setSelectedDoctor(loginData.doctors[idx].name);
    };

    // 검사 변경 취소 버튼 function
    const clickCancelButtonInEditAssessment = async () => {
        await handleResetFilter();
        setSelectedAssessment(initSelectedAssessment);
        setFilterAssessmentData(initAssessmentData);
        setIsLeaveModal();
        setInit();
    };

    // 진료 목록 BreadCrumb 클릭
    const clickBreadCrumbDiagnosisList = () => {
        if (returnSelectedAssessmentState) {
            setIsReturnSelectedAssessment();
            return setClickType('breadCrumb');
        } else if (editState) {
            setClickType('breadCrumb');
            return setIsLeaveModal();
        } else {
            return navigate(pagePaths.diagnosisList);
        }
    };

    // modal 나가기 버튼
    const clickLeaveButtonInLeaveModal = () => {
        if (returnSelectedAssessmentState && clickType === 'breadCrumb') {
            setIsReturnSelectedAssessment();
            setInit();
            setLoginData({ ...loginData, diagnosisTap: 0 });
            return navigate(pagePaths.diagnosisList);
        } else if (returnSelectedAssessmentState && (clickType === 'sideBar' || clickType === 'header')) {
            setInit();
            setLoginData({ ...loginData, diagnosisTap: 0 });
            return navigate(movePath);
        } else if (clickType === 'cancel') {
            return clickCancelButtonInEditAssessment();
        } else if (clickType === 'breadCrumb') {
            setIsLeaveModal();
            setLoginData({ ...loginData, diagnosisTap: 0 });
            setInit();
            return navigate(pagePaths.diagnosisList);
        } else if (clickType === 'sideBar' || clickType === 'header') {
            navigate(movePath);
            setLoginData({ ...loginData, diagnosisTap: 0 });
            return setInit();
        }
    };

    // memo 취소 버튼
    const clickCancelButtonInMemo = () => {
        if (diagnosisInfoData.prescriptionMemo === diagnosisMemo) {
            setMemoChangeBtn(true);
        } else {
            setIsCancelMemoModal();
        }
    };

    // memo 취소 모달 나가기 버튼
    const clickCancelButtonInCancelMemoModal = () => {
        if (diagnosisMemo !== diagnosisInfoData.prescriptionMemo) {
            setDiagnosisMemo(diagnosisInfoData.prescriptionMemo || '');
            setIsCancelMemoModal();
            setLoginData({ ...loginData, diagnosisTap: temporarySelectedTap });
            if (diagnosisInfoData.prescriptionMemo) {
                setMemoChangeBtn(true);
            } else {
                setMemoChangeBtn(false);
            }
        } else {
            setDiagnosisMemo('');
            setIsCancelMemoModal();
            setMemoChangeBtn(false);
        }
    };

    // 주민등록번호 앞자리 입력 및 유효성 검사
    const handleIdentityNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIdentityNumber(e.target.value);
        const { genderCodeError } = calculateAge(e.target.value + gender);
        if (e.target.value.length > 0) {
            const valid = isValidBirthdate(e.target.value);
            if (!valid || genderCodeError) return setIdentityNumberError('주민등록번호를 정확히 입력해주세요.');
        }
        return setIdentityNumberError('');
    };

    // 주민번호 뒷자리(성별) 입력 및 유효성 검사
    const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGender(e.target.value);
        if (e.target.value.length > 0) {
            const { genderCodeError } = calculateAge(identityNumber + e.target.value);
            if (genderCodeError) return setIdentityNumberError('주민등록번호를 정확히 입력해주세요.');
        }
        return setIdentityNumberError('');
    };

    // mobileNumber 입력 및 유효성 검사
    const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');

        setMobileNumber(newValue);
        if (newValue.length > 0 && newValue.length < 9) {
            return setMobileNumberError('연락처를 정확히 입력해주세요.');
        }
        return setMobileNumberError('');
    };

    // Blur 이벤트 핸들러, input 내용을 지운 후 Focus out을 하면 기존 데이터 자동으로 입력
    const handleBlur = (valueLength: number, setState: Dispatch<SetStateAction<string>>, value: string) => {
        if (valueLength === 0) {
            setState(value);
        }
    };

    // 환자 정보 변경 취소 버튼
    const clickCancelButtonInEditPatientInfo = () => {
        setPatientName(patientInfoInitData.name);
        setIdentityNumber(patientInfoInitData.identityNumber.slice(0, 6));
        setGender(patientInfoInitData.identityNumber.slice(6, 7));
        setMobileNumber(patientInfoInitData.mobile);
        setPatientMemo(patientInfoInitData.patientMemo);
        setPatientCode(patientInfoInitData.patientCode);
        setIdentityNumberError('');
        setMobileNumberError('');
        setIsChangePatientInfoModal();
    };

    // 환자 정보 변경 취소 버튼
    const clickCancelButtonInEditDiagnosisInfo = () => {
        handleInitSchooAgeAndSemester();
        setSelectedDoctor(diagnosisInfoData.doctorName);
        setDoctorsIdx(loginData.doctors.findIndex((item) => item.name === diagnosisInfoData.doctorName));
        setSelectedDate(moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD'));
        setSelectedTime(moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('A h:mm'));
        setIsEditDiagnosisInfoModal();
    };

    // 진료 상세보기 탭 이동
    const handleTaps = (idx: number) => {
        setTemporarySelectedTap(idx);

        if (
            loginData.diagnosisTap === 3 &&
            (diagnosisInfoData.prescriptionMemo || (!diagnosisInfoData.prescriptionMemo && diagnosisMemo !== '')) &&
            diagnosisMemo !== diagnosisInfoData.prescriptionMemo
        ) {
            return setIsCancelMemoModal();
        } else if (loginData.diagnosisTap === idx) {
            return window.location.reload();
        } else {
            return setLoginData({ ...loginData, diagnosisTap: idx });
        }
    };

    // 진료 메모 확인 모달 취소 버튼
    const DiagnosisMemoCancelButton = () => {
        setLoginData({ ...loginData, diagnosisTap: 3 });
        setIsCancelMemoModal();
    };

    return (
        <DiagnosisLayout>
            {((loginData.diagnosisTap === 3 && isLeaveModal) || isCancelMemoModal) && (
                <DiagnosisMemoCancelConfirmModal
                    title={NotPrescriptionMemoCancelModalText.title}
                    firstContent={diagnosisInfoData.prescriptionMemo ? HavePrescriptionMemoCancelModalText.firstSubText : NotPrescriptionMemoCancelModalText.subText}
                    secondContent={diagnosisInfoData.prescriptionMemo && HavePrescriptionMemoCancelModalText.secondSubText}
                    firstButton={NotPrescriptionMemoCancelModalText.firstButton}
                    secondButton={NotPrescriptionMemoCancelModalText.secondButton}
                    setIsLeaveModal={isLeaveModal ? setIsLeaveModal : DiagnosisMemoCancelButton}
                    clickLeaveButtonInLeaveModal={isLeaveModal ? clickLeaveButtonInLeaveModal : clickCancelButtonInCancelMemoModal}
                    temporarySelectedTap={temporarySelectedTap}
                />
            )}
            {isRemoveDiagnosisModal && (
                <RemoveDiagnosisModal userInfo={patientInfoInitData} diagnosisInfo={diagnosisInfoData} toggle={setIsRemoveDiagnosisModal} clickRemoveButton={removeDiagnosisApiCall} />
            )}

            {isChangePatientInfoModal && (
                <EditPatientInfoModal
                    toggle={clickCancelButtonInEditPatientInfo}
                    userInfo={patientInfoInitData}
                    birthDate={birthDate}
                    age={age}
                    patientName={patientName}
                    setPatientName={setPatientName}
                    identityNumber={identityNumber}
                    setIdentityNumber={setIdentityNumber}
                    gender={gender}
                    setGender={setGender}
                    patientCode={patientCode}
                    setPatientCode={setPatientCode}
                    mobileNumber={mobileNumber}
                    setMobileNumber={setMobileNumber}
                    patientMemo={patientMemo}
                    setPatientMemo={setPatientMemo}
                    handleBlur={handleBlur}
                    handleIdentityNumberChange={handleIdentityNumberChange}
                    identityNumberError={identityNumberError}
                    handleGenderChange={handleGenderChange}
                    handleMobileChange={handleMobileChange}
                    mobileNumberError={mobileNumberError}
                    buttonDisabled={buttonDisabled}
                    clickUpdateButton={clickUpdateButton}
                />
            )}
            {loginData.diagnosisTap === 0 && isReturnSelectedAssessment && (
                <LeaveModal
                    setIsLeaveModal={setIsReturnSelectedAssessment}
                    clickLeaveButtonInLeaveModal={clickLeaveButtonInLeaveModal}
                    title={returnSelectedAssessmentModalText.title}
                    content={returnSelectedAssessmentModalText.content}
                    firstButton={returnSelectedAssessmentModalText.firstButton}
                    secondButton={returnSelectedAssessmentModalText.secondButton}
                />
            )}
            {loginData.diagnosisTap === 0 && isLeaveModal && (
                <LeaveModal
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButtonInLeaveModal}
                    title={leaveModalText.title}
                    content={leaveModalText.content}
                    firstButton={leaveModalText.firstButton}
                    secondButton={leaveModalText.secondButton}
                />
            )}
            {isAddAssessmentModal && <AddAssessmentModal editState={editState} selectedAssessment={selectedAssessment} toggle={setIsAddAssessmentModal} handleAddAssessment={handleAddAssessment} />}
            {isEditDiagnosisInfoModal && (
                <EditDiagnosisInfoModal
                    diagnosisInfo={diagnosisInfoData}
                    userInfo={patientInfoInitData}
                    birthDate={birthDate}
                    age={age}
                    handleInitSchooAgeAndSemester={handleInitSchooAgeAndSemester}
                    schoolAge={schoolAge}
                    semester={semester}
                    schoolAgeIdx={schoolAgeIdx}
                    semesterIdx={semesterIdx}
                    handleSchoolAgeAndSemesterDropDown={handleSchoolAgeAndSemesterDropDown}
                    setOnSchoolAgeDropdown={setOnSchoolAgeDropdown}
                    onSchoolAgeDropdown={onSchoolAgeDropdown}
                    setOnSemesterDropdown={setOnSemesterDropdown}
                    onSemesterDropdown={onSemesterDropdown}
                    errMsg={errMsg}
                    initDate={initDate}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    setSelectedDate={setSelectedDate}
                    setSelectedTime={setSelectedTime}
                    setDoctors={handleDoctorData}
                    doctorsIdx={doctorsIdx}
                    selectedDoctor={selectedDoctor}
                    setOnCalendar={setOnCalendar}
                    onCalendar={onCalendar}
                    buttonDisabled={editDiagnosisModalButtonDisabled}
                    // setIsEditDiagnosisInfoModal={setIsEditDiagnosisInfoModal}
                    toggle={clickCancelButtonInEditDiagnosisInfo}
                    updateDiagnosisInfoApiCall={updateDiagnosisInfoApiCall}
                />
            )}
            {!loading && sortedData && (
                <DiagnosisInnerLayout>
                    <TwoBreadCrumbContainer>
                        <HospitalPageLabel onClick={clickBreadCrumbDiagnosisList}>{viewDetailDiagnosisPageText.pageLabel}</HospitalPageLabel>
                        <IconWrapper>
                            <ArrowRightSlim />
                        </IconWrapper>
                        <HospitalPageLabel onClick={() => window.location.reload()}>{viewDetailDiagnosisPageText.pageTitle}</HospitalPageLabel>
                    </TwoBreadCrumbContainer>
                    <PageTitle $marginbottom='20px'>{viewDetailDiagnosisPageText.pageTitle}</PageTitle>
                    <InfoHeaderContainer>
                        <DiagnosisTime>
                            {formattedTime(diagnosisInfoData.diagnosisDateTime)} <span style={{ color: palette.gray }}>진료</span>
                        </DiagnosisTime>
                        <InfoHeaderButtonContainer>
                            {(diagnosisInfoData.status === 0 || diagnosisInfoData.status === 1) && (
                                <>
                                    <BasicButton onClick={setIsChangePatientInfoModal} $width='112px' $height='100%' $type='anotherFill'>
                                        {viewDetailDiagnosisPageText.changePatientInfo}
                                    </BasicButton>
                                    <ColumnDivider $height='24px' $backgroundcolor={palette.testGray} />
                                    <BasicButton onClick={setIsRemoveDiagnosisModal} $width='84px' $height='100%' $type='anotherFill'>
                                        {viewDetailDiagnosisPageText.deleteDiagnosis}
                                    </BasicButton>
                                    <BasicButton onClick={setIsEditDiagnosisInfoModal} $width='112px' $height='100%' $type='anotherFill'>
                                        {viewDetailDiagnosisPageText.changeDiagnosisInfo}
                                    </BasicButton>
                                </>
                            )}
                        </InfoHeaderButtonContainer>
                    </InfoHeaderContainer>
                    <DetailPageInfoLayout>
                        <ViewDetailDiagnosisPageInfoBody userInfo={patientInfoInitData} diagnosisInfo={diagnosisInfoData} />
                    </DetailPageInfoLayout>
                    <DiagnosisTapsContainer>
                        {diagnosisTaps.map((item, idx) => {
                            return (
                                <DiagnosisItemButton
                                    key={idx}
                                    $idx={idx}
                                    $selected={loginData.diagnosisTap}
                                    onClick={() => handleTaps(idx)}
                                    disabled={!openTaps && diagnosisInfoData.status !== 3 && idx !== 0}
                                >
                                    {/* <DiagnosisItemButton key={idx} $idx={idx} $selected={selectedTap} onClick={() => setSelectedTap(idx)}> */}
                                    {item}
                                    {idx === loginData.diagnosisTap && <Border />}
                                </DiagnosisItemButton>
                            );
                        })}
                    </DiagnosisTapsContainer>
                    {loginData.diagnosisTap === 0 && (
                        <InfoBodyAssessmentList
                            diagnosisInfoData={diagnosisInfoData}
                            editState={editState}
                            selectedTLCProgram={selectedTLCProgram}
                            setSelectedTLCProgram={setSelectedTLCProgram}
                            handleResetFilter={handleResetFilter}
                            filterAssessmentData={filterAssessmentData}
                            otherAssessmentData={otherAssessmentData}
                            initAssessmentData={initAssessmentData}
                            handleCheck={handleCheck}
                            selectedAssessment={selectedAssessment}
                            handleAllCheck={handleAllCheck}
                            allCheck={allCheck}
                            allFilterAssessmentCheck={allFilterAssessmentCheck}
                            allOtherAssessmentCheck={allOtherAssessmentCheck}
                            setIsAddAssessmentModal={setIsAddAssessmentModal}
                            setEditState={setEditState}
                            setIsLeaveModal={setIsLeaveModal}
                            setClickType={setClickType}
                        />
                    )}
                    {loginData.diagnosisTap === 1 && (openTaps || diagnosisInfoData.status === 3) && (
                        <InfoBodyComprehensiveResults
                            diagnosisInfoData={diagnosisInfoData}
                            infoBodyComprehensiveResultsSelectedIdx={infoBodyComprehensiveResultsSelectedIdx}
                            setInfoBodyComprehensiveResultsSelectedIdx={setInfoBodyComprehensiveResultsSelectedIdx}
                            comprehensiveResults={comprehensiveResults}
                            getResultDiagnosisApiCall={getResultDiagnosisApiCall}
                        />
                    )}
                    {loginData.diagnosisTap === 2 && (openTaps || diagnosisInfoData.status === 3) && (
                        <InfoBodyUserResponses
                            comprehensiveResults={comprehensiveResults}
                            diagnosisInfoData={diagnosisInfoData}
                            infoBodyUserResponsesSelectedIdx={infoBodyUserResponsesSelectedIdx}
                            setInfoBodyUserResponsesSelectedIdx={setInfoBodyUserResponsesSelectedIdx}
                            findByAssessmentCode={findByAssessmentCode}
                        />
                    )}
                    {loginData.diagnosisTap === 3 && (openTaps || diagnosisInfoData.status === 3) && (
                        <InfoBodyDiagnosisMemo
                            setDiagnosisMemo={setDiagnosisMemo}
                            diagnosisMemo={diagnosisMemo}
                            initDiagnosisMemo={initDiagnosisMemo}
                            updateDiagnosisInfoApiCall={updateDiagnosisInfoApiCall}
                            diagnosisInfoData={diagnosisInfoData}
                            memoChangeBtn={memoChangeBtn}
                            setMemoChangeBtn={setMemoChangeBtn}
                            clickCancelButtonInMemo={clickCancelButtonInMemo}
                        />
                    )}
                </DiagnosisInnerLayout>
            )}
        </DiagnosisLayout>
    );
};

export default ViewDetailDiagnosisPage;

const DiagnosisTime = styled.span`
    display: flex;
    font-size: ${font.size[18]};
    font-weight: 600;
    color: ${palette.black};
    // margin-bottom: 8px;
    gap: 4px;
`;

const Border = styled.div`
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${palette.black};
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const settingInitPatientInfo = {
    _id: '',
    regNum: '',
    name: '',
    mobile: '',
    identityNumber: '',
    gender: '',
    hospitalId: '',
    patientCode: '',
    patientMemo: '',
    diagnosisIdList: [],
    timestamp: 0,
    updateTimestamp: 0,
    lastDiagnosisTimestamp: 0,
    status: 0,
};
