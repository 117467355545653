import Account from 'assets/Icons/Account/Account';
import AccountAdmin from 'assets/Icons/Account/AccountAdmin';
import UserLevelToolTipHeader from 'assets/Icons/ToolTip/UserLevelToolTipHeader';
import { MemberWhiteBadge } from 'components/Common/Badge';
import { UserLevelInfoToolTip } from 'components/ToolTip/UserLevelInfoToolTip';
import { EditHospitalAccountProps } from 'models/Hospital/settingHospitalTypes';
import React, { useState } from 'react';
import { useLoginStore } from 'store/useLoginDataStore';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { ColumnDivider } from 'styles/Global/commonStyles';
import { font } from 'styles/Global/globalStyles';
import { InputLabel, InputSubLabel } from 'styles/Input/inputStyle';

const EditHospitalAccount = ({ label, id, userList, clickDeleteUserButton, clickChangeUserLevelButton }: EditHospitalAccountProps) => {
    const { loginData } = useLoginStore();
    const [toolTipStates, setToolTipStates] = useState(Array(userList?.length).fill(false));

    const handleMouseEnter = (index: number) => {
        const newToolTipStates = [...toolTipStates];
        newToolTipStates[index] = true;
        setToolTipStates(newToolTipStates);
    };

    const handleMouseLeave = (index: number) => {
        const newToolTipStates = [...toolTipStates];
        newToolTipStates[index] = false;
        setToolTipStates(newToolTipStates);
    };

    return (
        <Layout>
            <LabelContainer>
                <InputLabel $marginBottom='0'>{label}</InputLabel>
                <InputSubLabel $marginBottom='0'>{`총 ${userList?.length || 0}명`}</InputSubLabel>
            </LabelContainer>
            <DoctorListsLayout>
                {userList?.map(
                    (user, index) =>
                        user.status === 1 && (
                            <DoctorListsContainer key={index}>
                                {index === 0 && <Badge>나</Badge>}
                                {user.level === 0 ? <Account /> : <AccountAdmin />}

                                <UserNameAndIdContainer>
                                    <UserNameAndLevelWrapper>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                            <Name>{user.name}</Name>
                                            <MemberWhiteBadge onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)} $hover={true}>
                                                {user.level === 0 ? '멤버' : '관리자'}
                                                {toolTipStates[index] && <UserLevelToolTipHeader />}
                                                {toolTipStates[index] && <UserLevelInfoToolTip level={user.level} />}
                                            </MemberWhiteBadge>
                                        </div>
                                        {index !== 0 && loginData.level !== 0 && (
                                            <ButtonWrapper>
                                                <ButtonText onClick={clickDeleteUserButton && (() => clickDeleteUserButton(user._id, user.name, user.email, user.level))}>계정 삭제</ButtonText>
                                                <ColumnDivider $height='12px' $backgroundcolor={BackgroundColor.color_background_normal} />
                                                <ButtonText onClick={clickChangeUserLevelButton && (() => clickChangeUserLevelButton(user._id, user.name, user.email, user.level))}>
                                                    {user.level === 0 ? '관리자로 변경' : '멤버로 변경'}
                                                </ButtonText>
                                            </ButtonWrapper>
                                        )}
                                    </UserNameAndLevelWrapper>
                                    <Email>{user.email}</Email>
                                </UserNameAndIdContainer>
                            </DoctorListsContainer>
                        )
                )}
            </DoctorListsLayout>
        </Layout>
    );
};

export default EditHospitalAccount;

const Layout = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const DoctorListsLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 8px 20px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 4px;
    & > div:last-child {
        border-bottom: none;
    }
`;

const DoctorListsContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 78px;
    padding: 16px 0;
    gap: 8px;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
`;

const UserNameAndIdContainer = styled.div`
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const UserNameAndLevelWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 24px;
    justify-content: space-between;
`;

// const UserNameAndLevelWrapper = styled.div`
//     display: flex;
//     align-items: center;
//     height: 24px;
//     gap: 4px;
// `;

const Name = styled.span`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.semiBold};
    line-height: normal;
`;

const Email = styled.span`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    line-height: normal;
    color: ${TextColor.color_text_light};
`;

const LevelWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 20px;
    color: ${TextColor.color_text_light};
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 4px;
    font-size: ${font.size[13]};
    font-weight: ${font.weight.medium};
    line-height: normal;
    cursor: pointer;
`;

const Badge = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: ${TextColor.color_text_normal};
    font-size: ${font.size[13]};
    font-weight: ${font.weight.semiBold};
    line-height: normal;
    background-color: ${BackgroundColor.color_background_primary_neutral};
    right: 0;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    hieght: 100%;
    padding: 6px 0px;
    gap: 6px;
`;

const ButtonText = styled.button`
    height: 100%;
    font-size: ${font.size[13]};
    font-weight: ${font.weight.regular};
    color: ${TextColor.color_text_light};
    line-height: normal;
`;
