export const viewDetailPatientPageText = {
    redirectLabel: '등록 환자 관리',
    pageLabel: '등록 환자 상세보기',
    title: '등록 환자 상세보기',
};

export const viewDetailPatientNothingLists = {
    text: '표시할 진료가 없습니다.',
    subText: '해당 환자의 진료 내역이 없습니다.',
};
