import Icon_arrow_right from 'assets/Icons/Arrow/Icon_arrow_right';
import Icon_Select_Checkbox from 'assets/Icons/CheckBox/Icon_Select_Checkbox';
import Icon_Unselect_Fill from 'assets/Icons/CheckBox/Icon_Unselect_Fill';
import { MobileBadgeCyan } from 'components/Common/Badge';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, IconColor, PrimaryColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3_Bold, Body3_Medium, Body5_Medium, Body5_SemiBold, Caption2_Regular } from 'styles/Global/typography';

interface MobileLabelSelectButtonProps {
    $selected: boolean;
    $onClick: () => void;
    $title: string;
    $subTitle: string;
    $desc: string;
    $label: string;
}

interface MobileSelectButtonProps {
    $selected: boolean;
    $onClick: () => void;
    $containerClick?: () => void;
    $title: string;
    $subTitle?: string;
}

// 디자인시스템 MobileLabelSelectButton
export const MobileLabelSelectButton = ({ $selected, $onClick, $title, $subTitle, $desc, $label }: MobileLabelSelectButtonProps) => {
    return (
        <LabelAssessmentContainer $selected={$selected} onClick={$onClick}>
            {$selected ? <Icon_Select_Checkbox $width='24px' $height='24px' /> : <CheckBox />}
            <AssessmentTextWrapper>
                <AssessmentTitleWrapper>
                    <LabelAssessmentTitle>{$title}</LabelAssessmentTitle>
                    <LabelAssessmentSubTitle>{$subTitle}</LabelAssessmentSubTitle>
                </AssessmentTitleWrapper>
                <AssessmentDesc>{$desc}</AssessmentDesc>
            </AssessmentTextWrapper>
            <MobileBadgeCyan $position='absolute'>{$label}</MobileBadgeCyan>
        </LabelAssessmentContainer>
    );
};

export const MobileSelectButton = ({ $selected, $onClick, $title, $subTitle, $containerClick }: MobileSelectButtonProps) => {
    return (
        <AssessmentContainer $selected={$selected} onClick={$containerClick}>
            {$selected ? <Icon_Select_Checkbox $width='24px' $height='24px' /> : <Icon_Unselect_Fill />}
            <AssessmentTitleWrapper>
                <AssessmentTitle>{$title}</AssessmentTitle>
                <AssessmentSubTitle>{$subTitle}</AssessmentSubTitle>
            </AssessmentTitleWrapper>
            <ClickArea
                onClick={(e) => {
                    e.stopPropagation();
                    $onClick();
                }}
            >
                <Icon_arrow_right />
            </ClickArea>
        </AssessmentContainer>
    );
};

const LabelAssessmentContainer = styled.div<{ $selected: boolean }>`
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    border: 1px solid ${(props) => (props.$selected ? PrimaryColor['color_primary_light-8'] : BorderColor.color_border_weak)};
    border-radius: ${Radius.radius_8};
    background-color: ${BackgroundColor.color_background_primary_neutral};
    padding: 14px 16px 16px;
    gap: 8px;
    cursor: pointer;
`;

const AssessmentContainer = styled.div<{ $selected: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${(props) => (props.$selected ? PrimaryColor['color_primary_light-8'] : BorderColor.color_border_weak)};
    border-radius: ${Radius.radius_8};
    background-color: ${(props) => (props.$selected ? BackgroundColor.color_background_primary_neutral : BackgroundColor.color_background_light)};
    padding: 8px 4px 8px 16px;
    gap: 8px;
    cursor: pointer;
`;

const AssessmentTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const AssessmentTitleWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    gap: 4px;
`;

const LabelAssessmentTitle = styled(Body3_Bold)``;

const AssessmentTitle = styled(Body5_SemiBold)``;

const LabelAssessmentSubTitle = styled(Body3_Medium)``;

const AssessmentSubTitle = styled(Body5_Medium)`
    color: ${PrimaryColor.color_primary_light};
`;

const AssessmentDesc = styled(Caption2_Regular)``;

const CheckBox = styled.div<{ disabled?: boolean }>`
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid ${IconColor.color_icon_light};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        border: 1px solid ${IconColor.color_icon_neutral};
    }
    &:active {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    &:focus {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${BorderColor.color_border_neutral};
        background-color: ${BackgroundColor.color_background_disabled};
    `}
`;

const ClickArea = styled.button`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
`;
