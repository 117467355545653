import React from 'react';

const Noti_Start = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '32'} height={$height || '32'} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_627_71875)'>
                <circle cx='16' cy='16' r='16' fill='#00C0DA' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.4571 10.0429C18.0666 9.65237 17.4334 9.65237 17.0429 10.0429C16.6524 10.4334 16.6524 11.0666 17.0429 11.4571L20.5858 15H9C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H20.5858L17.0429 20.5429C16.6524 20.9334 16.6524 21.5666 17.0429 21.9571C17.4334 22.3476 18.0666 22.3476 18.4571 21.9571L23.7065 16.7077L23.7071 16.7071C23.7093 16.7049 23.7115 16.7027 23.7136 16.7005C23.9038 16.5069 23.9992 16.2551 24 16.003M23.7136 15.2995C23.8063 15.3938 23.8764 15.502 23.9241 15.6172C23.8746 15.4979 23.8027 15.3902 23.7136 15.2995ZM18.4571 10.0429L23.7065 15.2923L18.4571 10.0429Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_627_71875'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Noti_Start;
