import { BasicButtonType } from 'models/Common/buttonTypes';
import styled from 'styled-components';
import { BackgroundColor, PrimaryColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';
import { Body4_Medium_Button } from 'styles/Global/typography';

export const IconTextButton = ({ children, $width, onClick, disabled, $height, $gap, $type, buttonType }: BasicButtonType) => {
    return (
        <Button $width={$width} onClick={onClick} disabled={disabled} $height={$height} $gap={$gap} $type={$type} type={buttonType}>
            {children}
        </Button>
    );
};
export const AddDoctorIconTextButton = ({ children, $width, onClick, disabled, $height, $gap, $type, buttonType }: BasicButtonType) => {
    return (
        <AddDoctorButton $width={$width} onClick={onClick} disabled={disabled} $height={$height} $gap={$gap} $type={$type} type={buttonType}>
            {children}
        </AddDoctorButton>
    );
};

export const DeleteDoctorIconTextButton = ({ children, $width, onClick, disabled, $height, $gap, $type, buttonType }: BasicButtonType) => {
    return (
        <DeleteDoctorButton $width={$width} onClick={onClick} disabled={disabled} $height={$height} $gap={$gap} $type={$type} type={buttonType}>
            {children}
        </DeleteDoctorButton>
    );
};

const DeleteDoctorButton = styled(Body4_Medium_Button)<BasicButtonType>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    line-height: 22px;
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
    color: ${TextColor.color_text_light};
    gap: 4px;
    &:hover {
        color: ${WarningColor.color_warning_normal};
    }
    &:active {
        color: ${WarningColor.color_warning_normal_press};
    }
    &:focus {
        color: ${WarningColor.color_warning_normal_press};
    }
    &:disabled {
        display: none;
    }
`;

const Button = styled.button<BasicButtonType>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 32px;
    padding: 4px;
    border-radius: 4px;
    line-height: 22px;
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
    color: ${TextColor.color_text_light};
    gap: 4px;
    &:hover {
        color: ${TextColor.color_text_neutral};
    }
    &:active {
        color: ${TextColor.color_text_normal};
    }
    &:focus {
        color: ${TextColor.color_text_normal};
    }
    &:disabled {
        color: ${TextColor.color_text_weak};
        cursor: not-allowed;
    }
`;
const AddDoctorButton = styled.button<BasicButtonType>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    line-height: 22px;
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
    color: ${TextColor.color_text_light};
    gap: 4px;
    &:hover {
        color: ${PrimaryColor.color_primary_normal};
        background-color: ${BackgroundColor.color_background_primary_neutral};
    }
    &:active {
        color: ${PrimaryColor.color_primary_normal};
        background-color: ${BackgroundColor.color_background_primary_normal};
    }
    &:focus {
        color: ${TextColor.color_text_normal};
        background-color: ${BackgroundColor.color_background_primary_normal};
    }
    &:disabled {
        color: ${TextColor.color_text_weak};
        background-color: transparent;
        cursor: not-allowed;
        border: none;
    }
`;

// const CheckRegistrationButton = styled.button<BasicButtonType>`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: ${(props) => props.$width ?? '100%'};
//     height: 60px;
//     border: ${(props) => props.$border || 'none'};
//     border-radius: 4px;
//     color: ${palette.gray};
//     font-size: ${font.size[16]};
//     opacity: ${(props) => props.opacity ?? '1'};
//     border: 1px solid ${palette.gray};
//     background-color: transparent;
//     &:disabled {
//         background-color: ${palette.testGray};
//         color: ${palette.gray};
//         opacity: 0.4;
//     }
// `;
