import React from 'react';
import { IconType } from 'models/Assets/iconType';

const PasswordHide = ({ width, height, color }: IconType) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.53033 4.46967C5.23744 4.17678 4.76256 4.17678 4.46967 4.46967C4.17678 4.76256 4.17678 5.23744 4.46967 5.53033L6.48758 7.54824C6.22895 7.70303 5.97833 7.86378 5.73644 8.02834C4.00589 9.20558 2.66148 10.6177 1.93981 11.58C1.76173 11.8174 1.75274 12.1413 1.91737 12.3883C5.34803 17.5342 9.82163 18.1285 11.9998 18.1285C13.0261 18.1285 14.5619 17.9966 16.2582 17.3188L18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L5.53033 4.46967ZM15.167 16.2276L13.7706 14.8313C13.2574 15.1529 12.6504 15.3389 12 15.3389C10.156 15.3389 8.66113 13.844 8.66113 12C8.66113 11.3496 8.84709 10.7427 9.16875 10.2294L7.51167 8.57234C7.17162 8.76362 6.84178 8.96964 6.52389 9.18589C5.15862 10.1146 4.05752 11.2028 3.37023 12.0284C6.40726 16.267 10.165 16.7285 11.9998 16.7285C12.7773 16.7285 13.9001 16.6457 15.167 16.2276ZM10.205 11.2657C10.1123 11.4922 10.0611 11.7401 10.0611 12C10.0611 13.0708 10.9292 13.9389 12 13.9389C12.2599 13.9389 12.5079 13.8878 12.7344 13.795L10.205 11.2657ZM18.1988 14.5973C19.0266 13.9516 19.8516 13.1139 20.6294 12.0284C19.9421 11.2028 18.841 10.1146 17.4757 9.18589C15.8787 8.09946 13.98 7.2714 11.9998 7.2714C11.6464 7.2714 11.2956 7.29777 10.9487 7.34728L9.75847 6.15704C10.4795 5.97571 11.2296 5.8714 11.9998 5.8714C14.3625 5.8714 16.5352 6.85287 18.2632 8.02834C19.9937 9.20559 21.3381 10.6177 22.0598 11.58C22.2379 11.8174 22.2469 12.1413 22.0822 12.3883C21.1728 13.7524 20.1901 14.7967 19.1963 15.5948L18.1988 14.5973Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default PasswordHide;
