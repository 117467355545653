export const homeConstants = {
    subTitle: '의 상담검사',
};

export const DiagnosisConfirmationConstants = {
    title: '오늘의 진료',
    notAssignedAssessment: '미지정인 검사',
    pendingAssessment: '시작 전인 검사',
    inprogressAssessment: '진행 중인 검사',
    completedAssessment: '완료된 검사',
};
