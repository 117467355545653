import React from 'react';

const ToolTipEnable = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='4' y='4' width='16' height='16' rx='8' fill='#EEEEEE' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 14.6C13.4359 14.6 14.6 13.4359 14.6 12C14.6 10.5641 13.4359 9.4 12 9.4C10.5641 9.4 9.4 10.5641 9.4 12C9.4 13.4359 10.5641 14.6 12 14.6ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z'
                fill='#264C97'
            />
        </svg>
    );
};

export default ToolTipEnable;
