import { HospitalAdminSideBarConstants, HospitalSideBarConstants } from 'constants/SideBarConstants';
import { pagePaths } from 'constants/path';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { useLoginStore } from 'store/useLoginDataStore';
import { NormalLNBButton, ProminentLNBButton } from 'styles/Global/buttonStyles';

const AdminSideBar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [path, setPath] = useState('');

    // sidebar hover 및 selected 적용을 위한 useEffect
    useEffect(() => {
        if (pathname === pagePaths.viewDetailPatient) {
            return setPath(pagePaths.managingPatients);
        } else if (pathname === pagePaths.viewDetaildiagnosis) {
            return setPath(pagePaths.diagnosisList);
        }
        return setPath(pathname);
    }, [pathname]);

    // 조건에 따라 sidebar 클릭 시 modal 띄우기
    const handleSideBarOnModal = (nowPagePath: string, listPath: string) => {
        return navigate(listPath);
    };

    return (
        <>
            {HospitalAdminSideBarConstants(path).map((list, idx) => {
                return (
                    <NormalLNBButton key={idx} $idx={idx} $path={path} $listPath={list.path} onClick={() => handleSideBarOnModal(pathname, list.path)}>
                        {list.icon}
                        <span>{list.name}</span>
                    </NormalLNBButton>
                );
            })}
        </>
    );
};

export default AdminSideBar;
