import React from 'react';

const DeleteRound = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_93_2315)'>
                <path
                    d='M15.995 8.00503C15.7217 7.73166 15.2784 7.73166 15.0051 8.00503L12.0001 11.01L8.99512 8.00503C8.72176 7.73166 8.27854 7.73166 8.00517 8.00503C7.73181 8.2784 7.73181 8.72161 8.00517 8.99498L11.0101 12L8.00507 15.005C7.73171 15.2784 7.73171 15.7216 8.00507 15.995C8.27844 16.2683 8.72166 16.2683 8.99502 15.995L12.0001 12.9899L15.0052 15.995C15.2785 16.2683 15.7218 16.2683 15.9951 15.995C16.2685 15.7216 16.2685 15.2784 15.9951 15.005L12.99 12L15.995 8.99498C16.2684 8.72161 16.2684 8.2784 15.995 8.00503Z'
                    fill='#5A5C63'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM22.6 12C22.6 17.8542 17.8542 22.6 12 22.6C6.14578 22.6 1.4 17.8542 1.4 12C1.4 6.14578 6.14578 1.4 12 1.4C17.8542 1.4 22.6 6.14578 22.6 12Z'
                    fill='#5A5C63'
                />
            </g>
            <defs>
                <clipPath id='clip0_93_2315'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DeleteRound;
