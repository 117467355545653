import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const Lnb_PatientList = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM5 19C5 16.4595 7.05949 14.4 9.60001 14.4H14.4C16.9405 14.4 19 16.4595 19 19L5 19ZM3 19C3 15.3549 5.95492 12.4 9.60001 12.4H14.4C18.0451 12.4 21 15.3549 21 19V19.6C21 20.3732 20.3732 21 19.6 21H4.4C3.6268 21 3 20.3732 3 19.6V19Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default Lnb_PatientList;
