import React from "react";
import styled from "styled-components";
import { font } from "styles/Global/globalStyles";

type Props = {
  question: string;
  index: number;
};

const Question = ({ question , index }: Props) => {
  return <QuestionLayout>
            <QuestionNumContainer>{(index + 1).toString().padStart(2 , '0')}</QuestionNumContainer>
            <QuestionText>{question}</QuestionText>
        </QuestionLayout>;
};

export default Question;

const QuestionLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`

const QuestionNumContainer = styled.div`
    font-size: ${font.size[24]};
    font-weight: ${font.weight.semiBold};
`

const QuestionText = styled.div`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
`