import { hospitalAPI } from 'api/Auth/Hospital/hospitalApi';
import IdentityNumber from 'assets/Icons/IdentityNumber/IdentityNumber';
import { calculateAge } from 'functions/calculateAge';
import { isValidBirthdate, validateCheck } from 'functions/validationFunctions';
import _ from 'lodash';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginStore } from 'store/useLoginDataStore';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import styled from 'styled-components';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import NonAssessmentListModal from './NonAssessmentListModal';
import { PageTitle } from 'styles/Global/commonStyles';
import { Body1_Medium_button, Body1_Medium_input, Body3_Regular } from 'styles/Global/typography';
import { Neutral } from 'styles/Global/colorPrimitive';
import { BackgroundColor, BorderColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { tabletPaths } from 'constants/path';
import { useAssessmentStore } from 'store/useAssessmentDataStore';

interface InputType {
    width: number;
}

const PatientLogin = () => {
    const [patientInfo, setpatientInfo] = useState({ name: '', birthDate: '', gender: '' });
    const [errorMsg, setErrorMsg] = useState({ name: '', birthDate: '', gender: '' });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();
    const birthDateRef = useRef<HTMLInputElement>(null);
    const genderRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [showModel, setShowModel] = useState(false);

    const { loginData } = useLoginStore();
    const { setPatientData } = usePatientStore();
    const { resetAssessment } = useAssessmentStore();

    const isNumberTypeValidation = (value: string) => {
        const regex = /^[0-9]*$/;
        return value.match(regex) ? true : false;
    };

    const nameValidation = (id: string, value: string) => {
        const nameErr = validateCheck.name(value);
        if (nameErr) {
            setErrorMsg((prev) => ({ ...prev, [id]: '필수 항목입니다. 검사 대상자 이름을 입력해주세요.' }));
        } else {
            setErrorMsg((prev) => ({ ...prev, [id]: '' }));
        }
    };

    const birthDateValidation = (id: string, value: string) => {
        if (isNumberTypeValidation(value)) {
            setErrorMsg((prev) => ({ ...prev, [id]: '' }));
        } else if (!isNumberTypeValidation(value)) {
            setErrorMsg((prev) => ({ ...prev, [id]: '숫자만 입력해주세요' }));
        }
    };

    const checkValidation = (id: string, value: string) => {
        if (id === 'name') {
            nameValidation(id, value);
        } else {
            if (!_.isEmpty(value)) {
                birthDateValidation(id, value);
            } else {
                setErrorMsg((prev) => ({ ...prev, [id]: '필수 항목입니다. 주민등록번호를 입력해주세요.' }));
            }
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setErrorMsg((prev) => ({ ...prev, name: '', birthDate: '', gender: '' }));
        checkValidation(id, value);
        if (id === 'name') {
            setpatientInfo((prev) => ({ ...prev, [id]: value }));
        } else {
            setpatientInfo({ ...patientInfo, [id]: e.target.value.replace(/[^0-9]/g, '') });
        }
    };

    const startInspectionApi = async () => {
        try {
            const { name, birthDate, gender } = patientInfo;
            const rqData = {
                name,
                identityNumber: birthDate + gender,
                hospitalId: loginData.hospitalId,
            };
            const patientDataRes = await hospitalAPI.patientFind(rqData, loginData.token);

            if (patientDataRes.status === 'ok') {
                const [data] = patientDataRes.data;
                if (_.isUndefined(data) || data.status === 2) {
                    setLoading(false);
                    setErrorMsg({ name: '일치하는 검사 대상자가 없습니다. 다시입력해주세요.', gender: '일치하는 검사 대상자가 없습니다. 다시입력해주세요.', birthDate: '' });
                } else {
                    setPatientData({ name, birthDate, gender });
                    setLoading(true);
                    navigate(tabletPaths.information, { state: data });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const patientInfoValidation = () => {
        setLoading(true);
        const { name, birthDate, gender } = patientInfo;
        const nameCheck = validateCheck.name(name);
        const birthDateCheck = isValidBirthdate(birthDate);
        const { genderCodeError } = calculateAge(birthDate + gender);

        if (_.isEmpty(nameCheck) && birthDateCheck === true && genderCodeError === false) {
            return true;
        } else {
            return false;
        }
    };

    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.currentTarget.id === 'birthDate' && e.currentTarget.maxLength === e.currentTarget.value.length) {
            genderRef.current?.focus();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && e.currentTarget.id === 'name') {
            birthDateRef.current?.focus();
        }
        if (e.key === 'Enter' && e.currentTarget.id === 'gender') {
            buttonRef.current?.focus();
        }
    };

    const handleClickStartInspection = () => {
        const isValid = patientInfoValidation();
        if (isValid) {
            startInspectionApi();
        } else {
            setLoading(false);
            setErrorMsg({ name: '입력하신 정보를 확인해주세요', gender: '입력하신 정보를 확인해주세요', birthDate: '' });
        }
    };

    const getPatienLocalstorage = () => {
        const storagePatientData = localStorage.getItem('patientData');
        if (storagePatientData) {
            const parsingPatientData = JSON.parse(storagePatientData);
            const { patientData } = parsingPatientData.state;
            setpatientInfo({ name: patientData.name, birthDate: patientData.birthDate, gender: patientData.gender });
        }
    };

    //! 모달 뜨는 조건 체킹 및 데이터도 리셋이 되는지 확인
    useEffect(() => {
        if (state) {
            const showModel = state.showModel;
            if (showModel) {
                setShowModel(true);
            }
        }
    }, [state]);

    useEffect(() => {
        getPatienLocalstorage();
        setpatientInfo({ name: '', birthDate: '', gender: '' });
        resetAssessment();
    }, []);

    return (
        <PageLayout>
            {showModel && <NonAssessmentListModal setShowModel={setShowModel} />}
            <PageInnerLayOut>
                <PageTextLayout>
                    <PageTitle>검사 준비하기</PageTitle>
                    <PageDescription>검사 대상자인지 확인을 위해서 정보를 입력해주세요.</PageDescription>
                </PageTextLayout>
                <InputWrapper>
                    <InputLayout>
                        <label>이름</label>
                        <Input autoComplete='off' spellCheck={false} width={520} placeholder='환자이름' onKeyDown={handleKeyDown} onChange={handleChange} id='name' value={patientInfo.name} />
                        {errorMsg.name && <ErrorMsg>{errorMsg.name}</ErrorMsg>}
                    </InputLayout>
                    <InputLayout>
                        <label>주민등록번호</label>
                        <IdentityNumberInputWrapper>
                            <Input
                                autoComplete='off'
                                spellCheck={false}
                                width={245}
                                placeholder='000000'
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                id='birthDate'
                                value={patientInfo.birthDate}
                                maxLength={6}
                                ref={birthDateRef}
                            />
                            <span>-</span>
                            <InputIconsLayout>
                                <Input
                                    autoComplete='off'
                                    spellCheck={false}
                                    width={245}
                                    placeholder='0'
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    id='gender'
                                    maxLength={1}
                                    value={patientInfo.gender}
                                    ref={genderRef}
                                />
                                <IdentityNumberWrapper>
                                    <IdentityNumber />
                                </IdentityNumberWrapper>
                            </InputIconsLayout>
                        </IdentityNumberInputWrapper>
                        {errorMsg.birthDate && <ErrorMsg>{errorMsg.birthDate}</ErrorMsg>}
                        {errorMsg.gender && <ErrorMsg>{errorMsg.gender}</ErrorMsg>}
                    </InputLayout>
                </InputWrapper>
                <BasicButton onClick={handleClickStartInspection} ref={buttonRef}>
                    검사 시작하기
                </BasicButton>
            </PageInnerLayOut>
        </PageLayout>
    );
};

export default PatientLogin;

const PageLayout = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 100vh;
`;

const IdentityNumberInputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

const PageInnerLayOut = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 520px;
    row-gap: 40px;
`;

const InputIconsLayout = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const IdentityNumberWrapper = styled.div`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    left: 49px;
`;

const PageTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const PageDescription = styled(Body3_Regular)`
    color: ${TextColor.color_text_light};
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 32px;
`;

const InputLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const Input = styled(Body1_Medium_input)<InputType>`
    width: ${(props) => props.width}px;
    height: 72px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    background: ${Static.color_static_white};
    padding: 12px 24px;
`;

const BasicButton = styled(Body1_Medium_button)`
    width: 100%;
    height: 72px;
    border: none;
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_primary};
    color: ${Static.color_static_white};

    &:active {
        background-color: ${PrimaryColor.color_primary_heavy};
    }
`;
