import axios from 'axios';
import { apiPaths } from 'constants/path';
import { CommonApiTypes } from 'models/Common/commonApiTypes';

interface LoginInfoType {
    email: string;
    password: string;
}

interface UpdateFcmTokenRqData {
    email: string | null;
    fcmToken: string | undefined;
}

export const header = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_AUTH_KEY,
};

class AuthApi {
    login(info: LoginInfoType): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(apiPaths.login, info, {
                    headers: header,
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    resetPasswordLink(email: string): Promise<CommonApiTypes> {
        return new Promise((resolve, reject) => {
            axios
                .get(apiPaths.resetPasswordLink + email, {
                    headers: header,
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateFcmToken(rqData: UpdateFcmTokenRqData, token: string): Promise<CommonApiTypes> {
        return new Promise((resolve, reject) => {
            axios
                .put(apiPaths.updateFcmToken, rqData, {
                    headers: header,
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
export const authApi = new AuthApi();
