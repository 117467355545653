import Hospital from 'assets/Icons/HospitalIcon/Hospital';
import { HospitalCompactListDropdownProps } from 'models/SignUp/hospitalCompactListDropdownTypes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { DropdownLayout, DropdownItem } from 'styles/Global/dropdownStyles';
import { font } from 'styles/Global/globalStyles';

const HospitalCompactListDropdown = ({ hospitalData, onClick, value }: HospitalCompactListDropdownProps) => {
    const [selectedItem, setSelectedItem] = useState(-1); // 키보드로 선택된 아이템

    // 키보드 이벤트 핸들러
    const handleKeyDown = (event: KeyboardEvent) => {
        if (hospitalData) {
            if (event.key === 'Icon_arrow_down') {
                event.preventDefault();
                setSelectedItem((prev) => (prev + 1) % hospitalData.length);
            } else if (event.key === 'Icon_arrow_up') {
                event.preventDefault();
                setSelectedItem((prev) => (prev - 1 + hospitalData.length) % hospitalData.length);
            } else if (selectedItem >= 0 && event.key === 'Enter' && onClick) {
                event.preventDefault();
                onClick(hospitalData[selectedItem]);
            }
        }
    };

    // 키보드 이벤트 리스너 등록
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hospitalData, handleKeyDown]);

    const highlightSearchTerm = (name: string, searchTerm: string) => {
        let highlightedParts = [];
        let currentIndex = 0;

        for (const char of searchTerm) {
            const index = name.toLowerCase().indexOf(char.toLowerCase(), currentIndex);
            if (index !== -1) {
                highlightedParts.push(name.substring(currentIndex, index));
                highlightedParts.push(<span style={{ color: PrimaryColor.color_primary_light }}>{name[index]}</span>);
                currentIndex = index + 1;
            } else {
                break;
            }
        }

        highlightedParts.push(name.substring(currentIndex));

        return highlightedParts;
    };

    return (
        <DropdownLayout $height='252px' $padding='6px 0' selected={selectedItem !== -1}>
            {hospitalData && hospitalData.length !== 0 ? (
                hospitalData.map((data, idx) => (
                    <DropdownItem key={data.id} onMouseDown={() => onClick && onClick(data)} onMouseEnter={() => setSelectedItem(idx)} $padding='0 20px' selected={idx === selectedItem}>
                        <Hospital key={idx} />
                        <div>{highlightSearchTerm(data.name, value || '')}</div>
                    </DropdownItem>
                ))
            ) : (
                <NothingNameContainer>
                    <NothingText>검색 결과가 없습니다!</NothingText>
                    <NothingText>병원명을 다시 입력해주세요.</NothingText>
                </NothingNameContainer>
            )}
        </DropdownLayout>
    );
};

export default HospitalCompactListDropdown;

const NothingNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    padding: 12px 20px;
`;

const NothingText = styled.span`
    font-size: ${font.size[16]};
    color: ${TextColor.color_text_weak};
    font-weight: ${font.weight.medium};
    line-height: 24px;
`;
