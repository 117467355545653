import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { diagnosisListPageText } from 'constants/diagnosisListPageConstants';
import { PageTitle, RowDivider } from 'styles/Global/commonStyles';
import { palette } from 'styles/Global/globalStyles';
import moment from 'moment';
import useToggle from 'hooks/CustomHooks/useToggle';
import { diagnosisListApi } from 'api/Hospital/diagnosisListApi';
import { useLoginStore } from 'store/useLoginDataStore';
import { GetDiagnosisListApiResponse } from 'models/Hospital/hospitalApiTypes';
import { useToastStore } from 'store/useToastStore';
import { HospitalPageLabel, FilterWrapper, DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import AdminDiagnosisFilter from './AdminDiagnosisFilter';
import { diagnosisPaginationSorting } from 'functions/paginationSorting';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import AdminDiagnosisListPagination from './AdminDiagnosisListPagination';
import adminDiagnosisPaginationSearchFilter from 'functions/adminDiagnosisPaginationSearchFilter';
import { Cool_Neutral } from 'styles/Global/colorPrimitive';
import { BorderColor } from 'styles/Global/colorSemantic';

const AdminDiagnosisListPage = () => {
    const [afterSelectedDate, setAfterSelectedDate] = useState<string | null>(moment().format('YYYY/MM/DD'));
    const [beforeSelectedDate, setBeforeSelectedDate] = useState<string | null>(moment().subtract(1, 'months').format('YYYY/MM/DD'));
    const [initData, setInitData] = useState<GetDiagnosisListApiResponse['data']>();
    const [filterData, setFilterData] = useState<GetDiagnosisListApiResponse['data'] | []>(initData || []);

    const [selectedPeriod, setSelectedPeriod] = useState<number>(1);
    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectSequence, setSelectSequence] = useState<string>('');
    const [sortField, setSortField] = useState<string>('');
    const [sortDirections, setSortDirections] = useState<Record<string, boolean>>({ patientName: false, patientIdentityNumber: false, diagnosisDateTime: false });
    const [afterCalenderDisabled, setAfterCalenderDsiabled] = useState(true);
    const [beforeCalendarInputWarning, setBeforeCalendarInputWarning] = useState(false);
    const [afterCalendarInputWarning, setAfterCalendarInputWarning] = useState(false);

    const [onBeforeCalendar, setOnBeforeCalendar] = useToggle();
    const [onAfterCalendar, setOnAfterCalendar] = useToggle();
    const [onSelectedPeriodDropdown, setOnSelectedPeriodDropdown] = useToggle();
    const [onSearchFilterDropdown, setOnSearchFilterDropdown] = useToggle();

    const { toastToggle, comment, setToast } = useToastStore();
    const { loginData } = useLoginStore();

    const [totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData] = usePaginationStateHooks(filterData || []);

    // period filter
    useEffect(() => {
        switch (selectedPeriod) {
            case 0:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'years').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 1:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'months').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 2:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'weeks').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 3:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 4:
                setAfterSelectedDate('');
                setBeforeSelectedDate('');
                setOnBeforeCalendar();
                break;
            default:
                break;
        }
        setBeforeCalendarInputWarning(false);
        setAfterCalendarInputWarning(false);
    }, [selectedPeriod]);

    // 진료목록에서 before date를 넣어야 after date를 선택할 수 있도록 설정
    useEffect(() => {
        if (selectedPeriod === 4 && beforeSelectedDate) {
            setAfterCalenderDsiabled(false);
        }
    }, [beforeSelectedDate]);

    // Data Fetching
    useEffect(() => {
        const beforeDate = moment(beforeSelectedDate, 'YYYY/MM/DD');
        const afterDate = moment(afterSelectedDate, 'YYYY/MM/DD');
        const diff = afterDate.diff(beforeDate, 'days');
        const getDiagnosisList = async () => {
            return await diagnosisListApi
                .getDiagnosisList(`${moment(beforeSelectedDate, 'YYYY/MM/DD').format('YYYYMMDD')}/${moment(afterSelectedDate, 'YYYY/MM/DD').format('YYYYMMDD')}`, loginData.token)
                .then((res) => {
                    console.log(res);
                    const initData =
                        res.data &&
                        res.data
                            .filter((item) => item.status !== -1)
                            .sort((a, b) => {
                                return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
                            });
                    setSortField('diagnosisDateTime');
                    setSortDirections((prev) => ({
                        ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
                        diagnosisDateTime: true,
                    }));
                    setInitData(initData);
                    setFilterData(initData);
                })
                .catch((err) => console.log(err));
        };

        if (selectedPeriod === 4) {
            if (beforeSelectedDate && onBeforeCalendar && !afterSelectedDate) {
                setOnBeforeCalendar();
                setOnAfterCalendar();
            } else if (beforeSelectedDate && onBeforeCalendar && afterSelectedDate) {
                setOnBeforeCalendar();
            } else if (afterSelectedDate && onAfterCalendar && !beforeSelectedDate) {
                setOnBeforeCalendar();
                setOnAfterCalendar();
            } else if (afterSelectedDate && onAfterCalendar && beforeSelectedDate) {
                setOnAfterCalendar();
            }

            if (diff >= 0 && diff <= 365) {
                getDiagnosisList();
            } else if (diff < 0 || diff > 365) {
                if (selectSequence === 'before') {
                    setAfterSelectedDate(null);
                    setOnAfterCalendar();
                } else if (selectSequence === 'after') {
                    setBeforeSelectedDate(null);
                    setOnBeforeCalendar();
                }
                setToast('최대 1년 내에서만 검색이 가능합니다.');
                setSelectSequence('');
            }
        } else {
            getDiagnosisList();
        }
    }, [beforeSelectedDate, afterSelectedDate, loginData.token]);

    // handlePeriodFilter Dropdown
    const handleDropdown = (e: React.MouseEvent, idx: number, type: string) => {
        e.stopPropagation();
        if (type === 'period') {
            setOnSelectedPeriodDropdown();
            setSelectedPeriod(idx);
        } else {
            setOnSearchFilterDropdown();
            setselectedSearchFilter(idx);
        }
        setSearchValue('');
        setPage(1);
    };

    // 기본 input 클릭 시 calendar dropdown
    const handleCalendarDropdown = (type: string) => {
        if (type === 'before' && onAfterCalendar) {
            setOnBeforeCalendar();
            setOnAfterCalendar();
        } else if (type === 'before' && !onAfterCalendar) {
            setOnBeforeCalendar();
        } else if (type === 'after' && onBeforeCalendar) {
            setOnBeforeCalendar();
            setOnAfterCalendar();
        } else if (type === 'after' && !onBeforeCalendar) {
            setOnAfterCalendar();
        }
    };

    // SearchFilter function
    const handleSearchFilter = () => {
        adminDiagnosisPaginationSearchFilter(searchValue, setSortDirections, setSortField, selectedSearchFilter, initData, setPage, handlePageGroup, setFilterData);
    };

    // reset Button function
    const handleResetButton = () => {
        setSearchValue('');
        setFilterData(initData);
        setSelectedPeriod(1);
        setPage(1);
        handlePageGroup(0);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            diagnosisDateTime: true,
        }));
        setSortField('diagnosisDateTime');
    };

    // sorting function

    const handleSorting = (type: string) => {
        const direction = !sortDirections[type];
        console.log(type, direction);
        setSortField(type);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [type]: direction,
        }));
        setFilterData(diagnosisPaginationSorting(type, filterData, direction));
    };

    return (
        <DiagnosisLayout>
            <DiagnosisInnerLayout>
                <HospitalPageLabel onClick={() => window.location.reload()}>{diagnosisListPageText.pageLabel}</HospitalPageLabel>
                <PageTitle $marginbottom='24px'>{diagnosisListPageText.pageTitle}</PageTitle>
                <RowDivider $backgroundcolor={BorderColor.color_border_neutral} $marginbottom='16px' />
                <FilterWrapper>
                    <AdminDiagnosisFilter
                        diagnosisNumber={filterData?.length}
                        afterSelectedDate={afterSelectedDate}
                        setAfterSelectedDate={setAfterSelectedDate}
                        beforeSelectedDate={beforeSelectedDate}
                        setBeforeSelectedDate={setBeforeSelectedDate}
                        handleCalendarDropdown={handleCalendarDropdown}
                        onBeforeCalendar={onBeforeCalendar}
                        setOnBeforeCalendar={setOnBeforeCalendar}
                        onAfterCalendar={onAfterCalendar}
                        setOnAfterCalendar={setOnAfterCalendar}
                        selectedPeriod={selectedPeriod}
                        selectedSearchFilter={selectedSearchFilter}
                        handleDropdown={handleDropdown}
                        setOnSelectedPeriodDropdown={setOnSelectedPeriodDropdown}
                        onSelectedPeriodDropdown={onSelectedPeriodDropdown}
                        setOnSearchFilterDropdown={setOnSearchFilterDropdown}
                        onSearchFilterDropdown={onSearchFilterDropdown}
                        maxDate={afterSelectedDate}
                        minDate={beforeSelectedDate}
                        onChange={setSearchValue}
                        searchValue={searchValue}
                        handleSearchFilter={handleSearchFilter}
                        setSelectSequence={setSelectSequence}
                        toastToggle={toastToggle}
                        comment={comment}
                        afterCalenderDisabled={afterCalenderDisabled}
                        setBeforeCalendarInputWarning={setBeforeCalendarInputWarning}
                        setAfterCalendarInputWarning={setAfterCalendarInputWarning}
                        beforeCalendarInputWarning={beforeCalendarInputWarning}
                        afterCalendarInputWarning={afterCalendarInputWarning}
                    />
                </FilterWrapper>
                <AdminDiagnosisListPagination
                    initNumber={initData?.length}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageGroup={pageGroup}
                    handlePageGroup={handlePageGroup}
                    handlePageChange={setPage}
                    currentData={currentData}
                    handleSorting={handleSorting}
                    sortField={sortField}
                    sortDirections={sortDirections}
                    handleResetButton={handleResetButton}
                />
            </DiagnosisInnerLayout>
        </DiagnosisLayout>
    );
};

export default AdminDiagnosisListPage;
