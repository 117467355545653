export const signUpErrText = {
    selectHospitalErr: '필수 정보입니다. 병원을 지정해주세요.',
    requiredConfirmPasswordErr: '확인을 위해 비밀번호를 한 번 더 입력해주세요.',
};

export const signUpLeaveModalText = {
    title: '회원가입을 진행하지 않고 나갈까요?',
    content: '지금 나가시면, 작성하신 내용이 모두 삭제됩니다.',
};

export const signUpDescriptionText = {
    inputEmail: '아이디로 사용할 이메일 주소를 입력해주세요.',
    inputHospitalName: '병원명을 검색으로 찾아 지정해주세요.',
    inputDoctor: '병원 내 담당자 구분을 위해 작성해주세요.',
    inputPhoneNumber: '연락처 혹은 전화번호를 입력해주세요.',
};

export const signUpPlaceholderText = {
    id: '아이디(이메일)',
    passwordCondition: '영문+숫자+8자 이상',
    searchHospital: '병원명 검색',
    phoneNumberCondition: '연락처 (숫자만 입력)',
};

export const signUpButtonText = {
    submitSignUp: '회원가입 신청하기',
};

export const completeSignUpText = {
    firstTitle: '가입신청이',
    secondTitle: '완료되었습니다.',
    firstExplainText: '확인과 승인까지는 최대 하루가 소요될 예정입니다.',
    secondExplainText: '확인 결과는 이메일로 보내드리니 참고 바랍니다.',
    firstInfoText: '추가 문의사항은 ',
    numberText: '031-705-1426',
    secondInfoText: '로 연락바랍니다.',
};
