import React from 'react';
import { BackgroundColor } from 'styles/Global/colorSemantic';

const CheckBox = () => {
    return (
        <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='28' height='28' rx='8' fill='#264C97' />
            <path d='M9 12.6667L13.1935 17L19 11' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export default CheckBox;
