import React from 'react';

const DckIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.79521 8.00586C6.13974 8.00586 4.79281 6.6588 4.79281 5.00293C4.79281 3.34706 6.13974 2 7.79521 2C9.45067 2 10.7976 3.34706 10.7976 5.00293C10.7976 6.6588 9.45067 8.00586 7.79521 8.00586ZM7.79521 3.6016C7.02254 3.6016 6.39401 4.23026 6.39401 5.00293C6.39401 5.7756 7.02254 6.40426 7.79521 6.40426C8.56787 6.40426 9.19641 5.7756 9.19641 5.00293C9.19641 4.23026 8.56787 3.6016 7.79521 3.6016ZM12.7944 5.01079C12.7944 6.66666 14.1413 8.01373 15.7968 8.01373C17.4523 8.01373 18.7992 6.66666 18.7992 5.01079C18.7992 3.35493 17.4523 2.00786 15.7968 2.00786C14.1413 2.00786 12.7944 3.35493 12.7944 5.01079ZM14.3956 5.01079C14.3956 4.23813 15.0241 3.60946 15.7968 3.60946C16.5695 3.60946 17.198 4.23813 17.198 5.01079C17.198 5.78346 16.5695 6.41213 15.7968 6.41213C15.0241 6.41213 14.3956 5.78346 14.3956 5.01079ZM18.474 14.9303C18.474 15.7403 17.8174 16.3969 17.0073 16.3969C16.1973 16.3969 15.5407 15.7403 15.5407 14.9303C15.5407 14.1202 16.1973 13.4636 17.0073 13.4636C17.8174 13.4636 18.474 14.1202 18.474 14.9303ZM22 16.2203V20.3964L21.9999 20.3965V21.2015L21.1877 22.0003H20.3985V21.998H14.9216L14.1265 21.2029V20.3965H20.3987V16.2204C20.3987 13.4099 18.3744 11.1235 15.886 11.1235H15.7076C13.2192 11.1235 11.1949 13.4099 11.1949 16.2204V21.2103L10.394 21.998H2.80067L2 21.1972V16.2367C2 12.5431 4.75373 9.53825 8.1384 9.53825H8.91587L7.98893 11.1441C5.55613 11.2332 3.60133 13.4825 3.60133 16.2367V20.3964H9.5936V16.2203C9.5936 12.5267 12.3364 9.52172 15.7076 9.52172H15.886C19.2572 9.52172 22 12.5268 22 16.2203Z'
                fill='white'
            />
        </svg>
    );
};

export default DckIcon;
