import React from 'react';

const GenderWoman = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '20'} height={$height || '20'} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.1177 12.2C14.4373 12.2 16.3177 10.3196 16.3177 8C16.3177 5.6804 14.4373 3.8 12.1177 3.8C9.79808 3.8 7.91768 5.6804 7.91768 8C7.91768 10.3196 9.79808 12.2 12.1177 12.2ZM18.1177 8C18.1177 11.0485 15.8441 13.566 12.9001 13.9494V16.4H15.8002C16.2973 16.4 16.7002 16.8029 16.7002 17.3C16.7002 17.797 16.2973 18.2 15.8002 18.2H12.9001V21.1C12.9001 21.5971 12.4972 22 12.0001 22C11.503 22 11.1001 21.5971 11.1001 21.1V18.2H8.2002C7.70314 18.2 7.3002 17.797 7.3002 17.3C7.3002 16.8029 7.70314 16.4 8.2002 16.4H11.1001V13.9141C8.27104 13.4308 6.11768 10.9669 6.11768 8C6.11768 4.68629 8.80397 2 12.1177 2C15.4314 2 18.1177 4.68629 18.1177 8Z'
                fill='#FF8A91'
            />
        </svg>
    );
};

export default GenderWoman;
