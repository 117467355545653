import { UseGovernmentHeaderStoreType } from 'models/Government/headerTypes';
import { ReactNode } from 'react';
import { create } from 'zustand';

export const useGovernmentHeaderStore = create<UseGovernmentHeaderStoreType>((set) => ({
    headerText: '',
    setHeaderText: (state: ReactNode) => {
        set(() => ({
            headerText: state,
        }));
    },
    setInit: () => {
        set(() => ({
            headerText: '',
        }));
    },
}));
