import React from 'react';

const Noti_Add = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '32'} height={$height || '32'} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_627_69881)'>
                <circle cx='16' cy='16' r='16' fill='#F4C10B' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17 9.5C17 8.94772 16.5523 8.5 16 8.5C15.4477 8.5 15 8.94772 15 9.5V15H9.5C8.94772 15 8.5 15.4477 8.5 16C8.5 16.5523 8.94772 17 9.5 17H15V22.5C15 23.0523 15.4477 23.5 16 23.5C16.5523 23.5 17 23.0523 17 22.5V17H22.5C23.0523 17 23.5 16.5523 23.5 16C23.5 15.4477 23.0523 15 22.5 15H17V9.5Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_627_69881'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Noti_Add;
