import CancelBtn from 'assets/Icons/ETC/Button/CancelBtn';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { calculateAge } from 'functions/calculateAge';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Neutral } from 'styles/Global/colorPrimitive';
import { BackgroundColor, BorderColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body1_Regular_Button, Body1_SemiBold, Body2_Regular, Title1_Bold } from 'styles/Global/typography';

interface Props {
    setModelMode: React.Dispatch<React.SetStateAction<boolean>>;
    changeDiagnosisStatus: (assessmentStatus: number) => Promise<void>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ColorType {
    color?: string;
}

const Modal = ({ setModelMode, changeDiagnosisStatus, setLoading }: Props) => {
    const [patientInfo, setpatientInfo] = useState({ name: '', birthDate: '', gender: '' });
    const [ageText, setAgeText] = useState('');
    const handleCancelClick = () => {
        setModelMode(false);
    };

    const handleClickSubmit = () => {
        setLoading(true);
        changeDiagnosisStatus(2);
    };

    const ageCalculation = (date: string) => {
        const { ageString } = calculateAge(date);
        setAgeText(ageString);
    };

    const getLocalstoragePatientData = () => {
        const storagePatientData = localStorage.getItem('patientData');
        if (storagePatientData) {
            const parsingPatientData = JSON.parse(storagePatientData);
            const { patientData } = parsingPatientData.state;
            setpatientInfo({ name: patientData.name, birthDate: patientData.birthDate, gender: patientData.gender });
            ageCalculation(`${patientData.birthDate}${patientData.gender}`);
        }
    };

    useEffect(() => {
        getLocalstoragePatientData();
    }, []);

    return (
        <Wrapper>
            <ModalLayout>
                <CancelBtnLayout onClick={handleCancelClick}>
                    <CancelBtn />
                </CancelBtnLayout>
                <ModelInfoLayout>
                    <ModelTextLayout>
                        <ModalTitle>검사를 진행할까요?</ModalTitle>
                        <ModalDescription>검사가 시작되면, 인적 사항 및 검사를 수정할 수 없습니다.</ModalDescription>
                    </ModelTextLayout>
                    <PatientInfoLayout>
                        <NameText>{patientInfo.name}</NameText>
                        {patientInfo.gender === '1' ||
                            (patientInfo.gender === '3' && (
                                <GenderIcon>
                                    <GenderMan />
                                </GenderIcon>
                            ))}
                        {patientInfo.gender === '2' ||
                            (patientInfo.gender === '4' && (
                                <GenderIcon>
                                    <GenderWoman />
                                </GenderIcon>
                            ))}
                        <DesText>
                            {ageText} ({patientInfo.birthDate.slice(0, 2)}/{patientInfo.birthDate.slice(2, 4)}/{patientInfo.birthDate.slice(4, 6)})
                        </DesText>
                    </PatientInfoLayout>
                </ModelInfoLayout>
                <ButtonLayout>
                    <CancelButton onClick={handleCancelClick}>취소</CancelButton>
                    <SubmitButton onClick={handleClickSubmit}>진행하기</SubmitButton>
                </ButtonLayout>
            </ModalLayout>
        </Wrapper>
    );
};

export default Modal;

const Wrapper = styled.div`
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalLayout = styled.div`
    position: relative;
    width: 600px;
    height: 348px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 48px 36px 32px 36px;
    background-color: ${Static.color_static_white};
    border-radius: ${Radius.radius_16};
    box-shadow: 0px 4px 20px 0px ${Neutral.color_neutral_900};
`;

const CancelBtnLayout = styled.button`
    position: absolute;
    right: -16px;
    top: -16px;
`;

const ModelInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const ModelTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const ModalTitle = styled(Title1_Bold)`
    text-align: center;
`;

const ModalDescription = styled(Body2_Regular)`
    text-align: center;
`;

const PatientInfoLayout = styled.div`
    height: 68px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: ${Border.border_1} solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_4};
    padding: 18px 20px;
    column-gap: 6px;
`;

const NameText = styled(Body1_SemiBold)``;

const DesText = styled(Body2_Regular)``;

const ButtonLayout = styled.div`
    display: flex;
    column-gap: 8px;
`;

const SubmitButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${Static.color_static_white};
    background-color: ${BackgroundColor.color_background_primary};

    &:active {
        background-color: ${PrimaryColor.color_primary_heavy};
    }
`;

const CancelButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${TextColor.color_text_normal};
    background-color: ${BackgroundColor.color_background_strong};
`;

const GenderIcon = styled.div<ColorType>`
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.color};
`;
