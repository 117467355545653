import { GetDiagnosisListApiResponse, GetPatientsListApiResponse } from 'models/Hospital/hospitalApiTypes';
import { calculateAge } from './calculateAge';

export const diagnosisPaginationSorting = (type: string, filterData: GetDiagnosisListApiResponse['data'] | [], direction: boolean) => {
    let sortedData;

    switch (type) {
        case 'hospitalName':
            sortedData = filterData ? [...filterData].sort((a, b) => (direction ? a.hospitalName.localeCompare(b.hospitalName) : b.hospitalName.localeCompare(a.hospitalName))) : [];
            break;
        case 'patientName':
            sortedData = filterData ? [...filterData].sort((a, b) => (direction ? a.patientName.localeCompare(b.patientName) : b.patientName.localeCompare(a.patientName))) : [];
            break;
        case 'patientIdentityNumber':
            sortedData = filterData
                ? [...filterData].sort((a, b) =>
                      direction
                          ? calculateAge(a.patientIdentityNumber).birthDate.localeCompare(calculateAge(b.patientIdentityNumber).birthDate)
                          : calculateAge(b.patientIdentityNumber).birthDate.localeCompare(calculateAge(a.patientIdentityNumber).birthDate)
                  )
                : [];
            break;
        case 'diagnosisDateTime':
            sortedData = filterData
                ? [...filterData].sort((a, b) => (direction ? b.diagnosisDateTime.localeCompare(a.diagnosisDateTime) : a.diagnosisDateTime.localeCompare(b.diagnosisDateTime)))
                : [];
            break;
        case 'status':
            sortedData = filterData ? [...filterData].sort((a, b) => (direction ? a.status - b.status : b.status - a.status)) : [];
            break;
        case 'doctorName':
            sortedData = filterData ? [...filterData].sort((a, b) => (direction ? a.doctorName.localeCompare(b.doctorName) : b.doctorName.localeCompare(a.doctorName))) : [];
            break;
        default:
            sortedData = filterData;
            break;
    }

    return sortedData;
};

export const managingPatientsPaginationSorting = (type: string, filterData: GetPatientsListApiResponse['data'], direction: boolean) => {
    filterData = filterData.filter((data) => data.status === 0);
    let sortedData;

    switch (type) {
        case 'regNum':
            sortedData = filterData
                ? [...filterData].sort((a, b) => {
                      const [letterA, numberA] = a.regNum.split('-');
                      const [letterB, numberB] = b.regNum.split('-');
                      const letterCompare = letterA.localeCompare(letterB);
                      if (letterCompare !== 0) {
                          return direction ? letterCompare : -letterCompare;
                      } else {
                          const numA = parseInt(numberA, 10);
                          const numB = parseInt(numberB, 10);
                          return direction ? numA - numB : numB - numA;
                      }
                  })
                : [];
            break;
        case 'name':
            sortedData = filterData ? [...filterData].sort((a, b) => (direction ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))) : [];
            break;
        case 'identityNumber':
            sortedData = filterData
                ? [...filterData].sort((a, b) =>
                      direction
                          ? calculateAge(a.identityNumber).birthDate.localeCompare(calculateAge(b.identityNumber).birthDate)
                          : calculateAge(b.identityNumber).birthDate.localeCompare(calculateAge(a.identityNumber).birthDate)
                  )
                : [];
            break;
        case 'lastDiagnosisTimestamp':
            sortedData = filterData ? [...filterData].sort((a, b) => (direction ? b.lastDiagnosisTimestamp - a.lastDiagnosisTimestamp : a.lastDiagnosisTimestamp - b.lastDiagnosisTimestamp)) : [];
            break;
        default:
            sortedData = filterData;
            break;
    }

    return sortedData;
};
