import { ModalStatusStore } from "models/Tablet/ModalStatus";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useTabletModalStatus = create(
    persist<ModalStatusStore>(
        (set) => ({
            modalStatus: false,
            setModalStatus: (status) => set((state) => ({ modalStatus: status })),
        }),
        {
            name: 'tabletModalStatus',
        }
    )
)