import Icon_arrow_down from 'assets/Icons/Arrow/Icon_arrow_down';
import { AddDiagnosisPageCalendar } from 'components/Common/Calendar';
import TimeInCalendar from 'components/Hospital/TimeInCalendar';
import { InputContainerStyleProps, InputProps } from 'models/Common/inputTypes';

import { forwardRef } from 'react';
import styled from 'styled-components';
import { font, palette, zIndex } from 'styles/Global/globalStyles';
import { BasicInput, InputWithLabelIconWrapper } from 'styles/Input/inputStyle';
import Icon_arrow_up from 'assets/Icons/Arrow/Icon_arrow_up';
import Calender from 'assets/Icons/Calender/Calender';
import SchoolAgeSemesterDropdown from 'components/Dropdown/SchoolAgeSemesterDropdown';

export const SchoolAgeInput = forwardRef<HTMLDivElement, InputProps>(({ id, value, onDropdown, schoolAgeIdx, semesterIdx, setDropdown }, ref) => {
    return (
        <Container ref={ref}>
            <BasicInput
                padding='12px 20px'
                $height='100%'
                $width='100%'
                value={value}
                onClick={() => setDropdown && setDropdown(id, undefined, '', '')}
                $cursor='pointer'
                // $warning={errorMsg}
                // onBlur={() => onBlur && onBlur(id)}
                readOnly
            />
            <InputWithLabelIconWrapper onClick={() => setDropdown && setDropdown(id, undefined, '', '')} $top='12px'>
                {!onDropdown ? <Icon_arrow_down /> : <Icon_arrow_up />}
            </InputWithLabelIconWrapper>
            {onDropdown && <SchoolAgeSemesterDropdown id={id} setDropdown={setDropdown} schoolAgeIdx={schoolAgeIdx} semesterIdx={semesterIdx} $height='176px' $top='52px' />}
        </Container>
    );
});

export const SemesterInput = forwardRef<HTMLDivElement, InputProps>(({ id, value, onDropdown, schoolAgeIdx, semesterIdx, setDropdown }, ref) => {
    return (
        <Container ref={ref}>
            <BasicInput
                padding='12px 20px'
                $height='100%'
                $width='100%'
                value={value}
                disabled={schoolAgeIdx === 0 || !schoolAgeIdx}
                onClick={() => setDropdown && setDropdown(id, undefined, '', '')}
                $cursor='pointer'
                readOnly
            />
            <InputWithLabelIconWrapper onClick={() => setDropdown && setDropdown(id, undefined, '', '')} disabled={schoolAgeIdx === 0 || !schoolAgeIdx} $top='12px'>
                {!onDropdown ? <Icon_arrow_down /> : <Icon_arrow_up />}
            </InputWithLabelIconWrapper>
            {onDropdown && <SchoolAgeSemesterDropdown id={id} setDropdown={setDropdown} schoolAgeIdx={schoolAgeIdx} semesterIdx={semesterIdx} $top='52px' $height='216px' />}
        </Container>
    );
});

export const CalendarInput = forwardRef<HTMLDivElement, InputProps>(({ onCalendar, setOnCalendar, initDate, selectedDate, selectedTime, setSelectedDate, setSelectedTime }, ref) => {
    return (
        <Container ref={ref}>
            <BasicInput
                padding='12px 20px'
                $height='100%'
                $width='100%'
                value={selectedDate + ' ' + selectedTime}
                onClick={() => setOnCalendar && setOnCalendar(!onCalendar)}
                $cursor='pointer'
                readOnly
            />

            <InputWithLabelIconWrapper onClick={() => setOnCalendar && setOnCalendar(!onCalendar)} $top='12px'>
                <Calender />
            </InputWithLabelIconWrapper>
            {onCalendar && (
                <CalenderContainer>
                    <CalenderWrapper>
                        <AddDiagnosisPageCalendar setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                    </CalenderWrapper>
                    <TimeWrapper>
                        <ResetTimeButton onClick={initDate}>오늘로 이동</ResetTimeButton>
                        <TimeInCalendar setSelectedTime={setSelectedTime} selectedTime={selectedTime} selectedDate={selectedDate} />
                    </TimeWrapper>
                </CalenderContainer>
            )}
        </Container>
    );
});

const Container = styled.div<InputContainerStyleProps>`
    position: relative;
    width: 100%;
    height: 48px;
    overflow: visible;
`;

const CalenderContainer = styled.div`
    position: relative;
    display: flex;
    padding: 24px 24px 28px;
    width: 100%;
    height: 385px;
    border-radius: 4px;
    border: 1px solid ${palette.black};
    margin-top: 8px;
    background-color: ${palette.white};
    z-index: ${zIndex.dropdown};
`;

const CalenderWrapper = styled.div`
    width: 280px;
    height: 100%;
    background-color: ${palette.white};
`;

const TimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
    height: 270px;
    margin-top: 51px;
    overflow-y: auto;
    gap: 6px;
`;

const ResetTimeButton = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 88px;
    height: 40px;
    font-size: ${font.size[14]};
    border-radius: 4px;
    margin-left: 12px;
    right: 34px;
    top: 24px;
    &:hover {
        background-color: ${palette.gray};
    }
`;
