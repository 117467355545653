const Icon_CheckRound_fill = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='10' r='7.5' fill='#2B55AE' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.3147 7.65689C13.5503 7.87671 13.563 8.24583 13.3432 8.48135L9.45429 12.648C9.34397 12.7662 9.18953 12.8333 9.02784 12.8333C8.86615 12.8333 8.71172 12.7662 8.60139 12.648L6.65695 10.5647C6.43713 10.3292 6.44986 9.96004 6.68538 9.74022C6.9209 9.5204 7.29003 9.53313 7.50985 9.76865L9.02784 11.3951L12.4903 7.68532C12.7101 7.4498 13.0792 7.43707 13.3147 7.65689Z'
                fill='white'
            />
        </svg>
    );
};

export default Icon_CheckRound_fill;
