import { useState } from 'react';
import { AdminHeaderWrapper, FooterLayout, HeaderWrapper, Layout, TableCell, TableLayout } from 'components/Hospital/PaginationForms';
import { adminCalculateListHeader, adminDiagnosisListHeader, adminDiagnosisListWidth, diagnosisListWidth } from 'constants/pagination';
import ArrowLineDown from 'assets/Icons/Arrow/ArrowLineDown';
import { LabelSelectButton } from 'components/Common/SortingButton';
import { OtherSortingButton } from 'styles/Global/buttonStyles';
import ArrowLineUp from 'assets/Icons/Arrow/ArrowLineUp';
import NothingDataInPagination from 'pages/Hospital/AddDiagnosisPage/NothingDataInPagination';
import DiagnosisListpaginationFooter from 'pages/Hospital/DiagnosisListPage/DiagnosisListPaginationFooter';
import AdminCalculatePaginationTable from './AdminCalculatePaginationTable';
import { AdminCalculatePaginationProps } from 'models/Admin/calculatePageApiTypes';

const AdminCalculatePagination = ({ initNumber, currentData, handleResetButton }: AdminCalculatePaginationProps) => {
    const [selectHeader, setSelectHeader] = useState<string>('');

    return (
        <Layout>
            <TableLayout>
                <AdminHeaderWrapper>
                    {adminCalculateListHeader.map((name, idx) => {
                        return (
                            <TableCell
                                key={idx}
                                $idx={idx === 2 ? 3 : idx === 3 ? 2 : idx}
                                $width={adminDiagnosisListWidth[idx]}
                                $type='header'
                                $justifyContent='space-between'
                                hover={setSelectHeader}
                                cellType={name.type !== 'null' && name.type !== 'patientMobile' ? name.type : undefined}
                            >
                                {name.title}
                                {/* {name.type === 'diagnosisDateTime' && (sortField === name.type || selectHeader === 'diagnosisDateTime') && (
                                    <LabelSelectButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? '최신순' : '오래된순'}</LabelSelectButton>
                                )}
                                {(sortField === name.type || selectHeader === name.type) &&
                                    name.type !== 'null' &&
                                    name.type !== 'gradeName' &&
                                    name.type !== 'patientMobile' &&
                                    name.type !== 'diagnosisDateTime' && (
                                        <OtherSortingButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? <ArrowLineUp /> : <ArrowLineDown />}</OtherSortingButton>
                                    )} */}
                            </TableCell>
                        );
                    })}
                </AdminHeaderWrapper>
                {!currentData || currentData?.length === 0 ? (
                    <NothingDataInPagination initNumber={initNumber} handleResetButton={handleResetButton} />
                ) : (
                    <AdminCalculatePaginationTable currentData={currentData} />
                )}
            </TableLayout>
            {/* {currentData && totalPages !== 0 && (
                <FooterLayout>
                    <DiagnosisListpaginationFooter currentPage={currentPage} handlePageChange={handlePageChange} handlePageGroup={handlePageGroup} pageGroup={pageGroup} totalPages={totalPages} />
                </FooterLayout>
            )} */}
        </Layout>
    );
};

export default AdminCalculatePagination;
