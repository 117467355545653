import React, { useState } from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { useLoginStore } from '../../store/useLoginDataStore';
import { useLocation } from 'react-router-dom';
import { tabletPaths } from 'constants/path';
import { BorderColor, Static } from 'styles/Global/colorSemantic';
import Company from 'assets/Icons/Logo/Company';
import { Border } from 'styles/Global/objectStyles';
import { Body2_SemiBold } from 'styles/Global/typography';
import SettingCancelBtn from 'assets/Icons/ETC/Button/SettingCancelBtn';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { useTabletModalStatus } from 'store/useTabletModalStatus';
import LogoutModal from './LogoutModal';
import ArrowLineRight from 'assets/Icons/Arrow/ArrowLineRight';

interface Props {
    setShowSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface BorderStyle {
    borderRight?: string;
}

interface WrapperStyle {
    borderBottom?: string;
}

const TableSettings = ({ setShowSettingsModal, setShowModal }: Props) => {
    const location = useLocation();
    const { modalStatus, setModalStatus } = useTabletModalStatus();
    const { loginData } = useLoginStore();
    const [view, setView] = useState('');

    const handleClick = (view: 'login' | 'manager-login') => {
        setModalStatus(true);
        setView(view);
    };

    const handleCancelClick = () => {
        setShowSettingsModal(false);
        setShowModal(false);
    };

    return (
        <Wrapper>
            <PageLayout>
                {modalStatus && <LogoutModal setlogoutShowModal={setShowModal} setShowSettingsModal={setShowSettingsModal} view={view} />}
                <Header>
                    <LogoIcon borderRight={`${Border.border_1} solid ${BorderColor.color_border_neutral}`}>
                        <Company />
                    </LogoIcon>
                    <SubNavLayout>
                        <AssessmentLayout>
                            <InformationText>관리자 설정</InformationText>
                        </AssessmentLayout>
                        <InfoLayout>
                            <CancelBtnLayout onClick={handleCancelClick}>
                                <SettingCancelBtn />
                            </CancelBtnLayout>
                        </InfoLayout>
                    </SubNavLayout>
                </Header>
                <PageInnerLayOut>
                    <PageTextLayout>
                        <TextLayout>
                            <PageTitle>관리자 설정</PageTitle>
                            <HospitalInfoLayout>
                                <HospitalNameLayout>
                                    <span>병원명</span>
                                    <span>{loginData.hospitalName}</span>
                                </HospitalNameLayout>
                                <HospitalNameLayout>
                                    <span>로그인 정보</span>
                                    <span>{loginData.email}</span>
                                </HospitalNameLayout>
                            </HospitalInfoLayout>
                        </TextLayout>
                        {location.pathname !== tabletPaths.login && (
                            <>
                                <LogoutButton onClick={() => handleClick('login')}>
                                    <span>환자 변경하러 나가기</span>
                                    <ArrowLineRight />
                                </LogoutButton>
                            </>
                        )}
                        <LogoutButton onClick={() => handleClick('manager-login')}>
                            <span>로그아웃</span>
                            <ArrowLineRight />
                        </LogoutButton>
                    </PageTextLayout>
                </PageInnerLayOut>
            </PageLayout>
        </Wrapper>
    );
};

export default TableSettings;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const PageLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${Static.color_static_white};
`;

const Header = styled.div<WrapperStyle>`
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${Static.color_static_white};
    border-bottom: ${Border.border_1} solid ${BorderColor.color_border_neutral};
`;

const PageInnerLayOut = styled.div`
    width: 520px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`;

const AssessmentLayout = styled.div`
    padding: 0px 20px;
`;

const CancelBtnLayout = styled.div`
    position: absolute;
    right: 0px;
    width: 67px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InformationText = styled(Body2_SemiBold)`
    color: ${Static.color_static_black};
`;

const LogoIcon = styled.div<BorderStyle>`
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: ${(props) => props.borderRight};
`;

const SubNavLayout = styled.div`
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const PageTextLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const PageTitle = styled.span`
    font-size: 32px;
    font-weight: ${font.weight.bold};
`;

const HospitalInfoLayout = styled.div`
    width: 100%;
    height: 144px;
    padding: 0px 24px;
    border-radius: 4px;
    background-color: #f9f9f9;
    border: 1px solid #dbdbdb;

    & div:first-child {
        border-bottom: 1px solid #e5e5e5;
    }
`;

const TextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

const HospitalNameLayout = styled.div`
    width: 100%;
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;

    span:first-child {
        color: ${palette.gray};
        font-size: ${font.size[18]};
    }

    span:last-child {
        color: ${palette.black};
        font-size: ${font.size[20]};
        font-weight: ${font.weight.medium};
    }
`;

const LogoutButton = styled.button`
    height: 68px;
    padding: 12px 16px 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #dbdbdb;

    & span {
        color: ${palette.black};
        font-size: ${font.size[20]};
        font-weight: ${font.weight.medium};
    }
`;

const InfoLayout = styled.div`
    display: flex;
    column-gap: 12px;
    justify-content: right;
    align-items: center;
    height: 100%;
    border-right: ${Border.border_1} solid ${BorderColor.color_border_neutral};
`;
