import React from 'react';

const AlarmNone = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 2.08826C10.7038 2.08826 9.64932 3.12349 9.61835 4.41228C9.47695 4.46684 9.3379 4.52612 9.20138 4.58993L18.6176 14.0062V10.5883C18.6176 7.77236 16.8592 5.36836 14.3817 4.41228C14.3507 3.12349 13.2962 2.08826 12 2.08826ZM17.1093 18.5236L19.7929 21.2071C20.1834 21.5976 20.8166 21.5976 21.2071 21.2071C21.5976 20.8166 21.5976 20.1834 21.2071 19.7929L4.20711 2.79289C3.81658 2.40237 3.18342 2.40237 2.79289 2.79289C2.40237 3.18342 2.40237 3.81658 2.79289 4.20711L6.12505 7.53926C5.65056 8.45163 5.38235 9.48853 5.38235 10.5883V14.6595L3.69083 16.96C3.56686 17.1286 3.5 17.3324 3.5 17.5417C3.5 18.0839 3.93961 18.5236 4.4819 18.5236H8.69244C8.85452 20.3702 10.2173 21.9118 11.9998 21.9118C13.7823 21.9118 15.1451 20.3702 15.3072 18.5236H17.1093Z'
                fill='#DBDCDF'
            />
        </svg>
    );
};

export default AlarmNone;
