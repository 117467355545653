import React from 'react'

const CancelBtn = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_810_5520)">
        <path d="M4 28C4 13.6406 15.6406 2 30 2C44.3594 2 56 13.6406 56 28C56 42.3594 44.3594 54 30 54C15.6406 54 4 42.3594 4 28Z" fill="#333438"/>
        <path d="M38.8216 21.2217C39.3857 20.6576 39.3857 19.743 38.8216 19.1789C38.2575 18.6148 37.343 18.6148 36.7789 19.1789L30.0001 25.9579L23.2214 19.179C22.6573 18.6149 21.7428 18.6149 21.1787 19.179C20.6146 19.7431 20.6146 20.6576 21.1787 21.2217L27.9574 28.0007L21.1794 34.7789C20.6153 35.343 20.6153 36.2576 21.1794 36.8217C21.7435 37.3858 22.658 37.3858 23.2221 36.8217L30.0001 30.0435L36.7782 36.8217C37.3423 37.3858 38.2568 37.3858 38.8209 36.8217C39.385 36.2576 39.385 35.3431 38.8209 34.779L32.0429 28.0007L38.8216 21.2217Z" fill="white"/>
      </g>
        <defs>
          <filter id="filter0_d_810_5520" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_810_5520"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_810_5520" result="shape"/>
          </filter>
        </defs>
    </svg>
  )
}

export default CancelBtn