import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetPaymentListApiResponse } from 'models/Admin/calculatePageApiTypes';

class CalculatePageApi {
    async getPaymentList(page: string, token: string): Promise<GetPaymentListApiResponse> {
        try {
            const resopnse = await axios.get(apiPaths.paymentList + page + '/999', { headers: tokenHeader(token) });
            return resopnse.data;
        } catch (error) {
            throw error;
        }
    }
}

export const calculatePageApi = new CalculatePageApi();
