import ArrowLineDown from 'assets/Icons/Arrow/ArrowLineDown';
import { FooterLayout, HeaderWrapper, Layout, TableCell, TableLayout } from 'components/Hospital/PaginationForms';
import { viewDetailPatientListHeader, viewDetailPatientListWidth } from 'constants/pagination';
import { ViewDetailPatientPaginationProps } from 'models/Hospital/paginationTypes';
import ViewDetailPatientPaginationTable from './ViewDetailPatientPaginationTable';
import DiagnosisListpaginationFooter from '../DiagnosisListPage/DiagnosisListPaginationFooter';
import { NothingDataPatientDiagnosis } from '../AddDiagnosisPage/NothingDataInPagination';
import { OtherSortingButton } from 'styles/Global/buttonStyles';
import ArrowLineUp from 'assets/Icons/Arrow/ArrowLineUp';

const ViewDetailPatientPagination = ({ handleSorting, sortDirections, currentPage, totalPages, pageGroup, handlePageGroup, handlePageChange, currentData }: ViewDetailPatientPaginationProps) => {
    return (
        <Layout>
            <TableLayout>
                <HeaderWrapper>
                    {viewDetailPatientListHeader.map((name, idx) => {
                        return (
                            <TableCell
                                key={idx}
                                $idx={idx}
                                $width={viewDetailPatientListWidth[idx]}
                                $type='header'
                                $justifyContent='space-between'
                                // hover={setSelectHeader}
                                cellType={name.type}
                            >
                                {name.title}
                                {name.title === 'No.' && <OtherSortingButton onClick={handleSorting}>{sortDirections ? <ArrowLineUp /> : <ArrowLineDown />}</OtherSortingButton>}
                            </TableCell>
                        );
                    })}
                </HeaderWrapper>
                {!currentData || currentData?.length === 0 ? <NothingDataPatientDiagnosis /> : <ViewDetailPatientPaginationTable currentData={currentData} />}
            </TableLayout>
            {currentData && totalPages !== 0 && (
                <FooterLayout>
                    <DiagnosisListpaginationFooter currentPage={currentPage} handlePageChange={handlePageChange} handlePageGroup={handlePageGroup} pageGroup={pageGroup} totalPages={totalPages} />
                </FooterLayout>
            )}
        </Layout>
    );
};

export default ViewDetailPatientPagination;
