import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import React from 'react'
import styled from 'styled-components'
import { font, palette } from 'styles/Global/globalStyles';

interface Style {
    opacity: string;
}

interface Props {
    choiceListLength: number;
    moveMissingQuestion: () => void;
    assessmentQuestionsLength: number;
}

const AnswerStatusBadge = ({choiceListLength , moveMissingQuestion , assessmentQuestionsLength}: Props) => {

    const handleClick = () => {
        moveMissingQuestion();
    }

  return (
    <Wrapper>
        <StatusText><TextBold>{choiceListLength}</TextBold>/{assessmentQuestionsLength} 문항</StatusText>
        <Button opacity={choiceListLength === assessmentQuestionsLength ? '.1' : '1' } onClick={handleClick}>놓친 문항으로</Button>
    </Wrapper>
  )
}

export default AnswerStatusBadge

const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 158px;
    height: 101px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`

const StatusText = styled.span`
    text-align: center;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
`
const TextBold = styled.span`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
`

const Button = styled.button<Style>`
    width: 138px;
    height: 58px;
    border-radius: 99px;
    color: ${palette.white};
    background-color: #264C97;
    align-self: flex-end;
    opacity: ${props => props.opacity}
`