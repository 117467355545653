import { LeaveModalProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import React from 'react';
import styled from 'styled-components';
import { MobileModalContent, MobileModalLayout, MobileModalLeftTitle, ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalLeftTitle, ModalTitle } from './ModalForms';
import BasicButton from 'components/Buttons/BasicButton';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';

const MobileLeaveModal = ({ setIsLeaveModal, clickLeaveButtonInLeaveModal, title, subTitle, content, firstButton, secondButton }: LeaveModalProps) => {
    return (
        <ModalBackground $type='mobile'>
            <MobileModalLayout $width='300px'>
                <ModalTitleAndContentContainer>
                    <TitleWrapper>
                        <MobileModalLeftTitle>{title}</MobileModalLeftTitle>
                        <MobileModalLeftTitle>{subTitle}</MobileModalLeftTitle>
                    </TitleWrapper>
                    <MobileModalContent>{content}</MobileModalContent>
                </ModalTitleAndContentContainer>
                <ModalFooter>
                    <MobileBasicButton $width='122px' $height='52px' onClick={setIsLeaveModal} $type='anotherFill'>
                        {firstButton}
                    </MobileBasicButton>
                    <MobileBasicButton $width='122px' $height='52px' onClick={clickLeaveButtonInLeaveModal} $type='warning'>
                        {secondButton}
                    </MobileBasicButton>
                </ModalFooter>
                {/* <CloseButtonWrapper onClick={setIsLeaveModal}>
                    <CloseDialog />
                </CloseButtonWrapper> */}
            </MobileModalLayout>
        </ModalBackground>
    );
};

export default MobileLeaveModal;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
