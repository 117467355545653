import React from 'react';
import { AdminListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import moment from 'moment';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import { changeMobileForm } from 'functions/changeMobileForm';
import { useNavigate } from 'react-router-dom';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { adminDiagnosisListWidth } from 'constants/pagination';
import { DiagnosisListpaginationTableProps } from 'models/Hospital/paginationTypes';

const AdminDiagnosisListPaginationTable = ({ currentData }: DiagnosisListpaginationTableProps) => {
    const navigate = useNavigate();

    return (
        <>
            {currentData?.map((item, idx) => {
                const { birthDate } = calculateAge(item.patientIdentityNumber);
                return (
                    // <AdminListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                    <AdminListWrapper key={idx}>
                        <TableCell $width={adminDiagnosisListWidth[0]} $type='hospitalName'>
                            {item.hospitalName}
                        </TableCell>
                        <TableCell $width={adminDiagnosisListWidth[1]} $type='name'>
                            {item.patientName + ' '}
                            {item.patientGender === 'male' ? <GenderMan /> : <GenderWoman />}
                        </TableCell>
                        <TableCell $width={adminDiagnosisListWidth[2]}>{moment(birthDate, 'YYYYMMDD').format('YYYY/MM/DD')}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[3]} $type='gradeName'>
                            {item.gradeName}
                        </TableCell>
                        <TableCell $width={adminDiagnosisListWidth[4]}>{changeMobileForm(item.patientMobile)}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[5]}>{formattedTime(item.diagnosisDateTime)}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[6]}>{item.doctorName}</TableCell>
                        {/* <TableCell cellType='processBarCell' $width={adminDiagnosisListWidth[7]} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}> */}
                        <TableCell cellType='processBarCell' $width={adminDiagnosisListWidth[7]}>
                            <ProcessCheckBar diagnosisInfo={item} $height='40px' />
                        </TableCell>
                    </AdminListWrapper>
                );
            })}
        </>
    );
};

export default AdminDiagnosisListPaginationTable;
