import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import RadioButton from 'assets/Icons/RadioButton/RadioButton';
import { AddDiagnosisDoctorsDropdownProps, DoctorsDropdownLayoutType } from 'models/Hospital/addDiagnosisPageTypes';
import { RefObject, createRef, useEffect, useRef } from 'react';
import { useLoginStore } from 'store/useLoginDataStore';
import styled from 'styled-components';
import { TextColor } from 'styles/Global/colorSemantic';
import { DefaultRadioButton } from 'styles/Global/commonStyles';
import { DropdownItem } from 'styles/Global/dropdownStyles';
import { palette } from 'styles/Global/globalStyles';
import { Body3_Medium, Body5_Regular } from 'styles/Global/typography';

const AddDiagnosisDoctorsDropdown = ({ setDoctors, doctorsIdx }: AddDiagnosisDoctorsDropdownProps) => {
    const { loginData } = useLoginStore();
    const itemRefs = useRef([] as RefObject<HTMLButtonElement>[]);
    itemRefs.current = loginData.doctors.map((_, i) => itemRefs.current[i] ?? createRef());

    useEffect(() => {
        if (doctorsIdx !== undefined && itemRefs.current[doctorsIdx]?.current) {
            const scrollContainer = itemRefs.current[doctorsIdx].current?.parentElement;
            if (scrollContainer) {
                const elementTop = (itemRefs.current[doctorsIdx].current?.offsetTop || 0) - 12;
                scrollContainer.scrollTop = elementTop;
            }
        }
    }, [doctorsIdx]);

    return (
        <DropdownLayout $padding={loginData.doctors.length < 2 ? '6px 8px 6px 20px' : '6px 0'} $height={loginData.doctors.length < 2 ? '60px' : ''} $number={loginData.doctors.length < 2}>
            {loginData.doctors.length >= 2 ? (
                loginData.doctors.map((item, idx) => {
                    return (
                        <DropdownItem
                            key={idx}
                            $height='48px'
                            $minHeight='48px'
                            $padding='0 8px 0 20px'
                            $gap='4px'
                            selected={idx === doctorsIdx}
                            onClick={() => setDoctors && setDoctors(idx)}
                            ref={itemRefs.current[idx]}
                        >
                            {idx === doctorsIdx ? <RadioButton $marginRight='8px' /> : <DefaultRadioButton />}

                            <Text>{item.name}</Text>
                            <SemiText>원장님</SemiText>
                        </DropdownItem>
                    );
                })
            ) : (
                <DropdownItem $height='0' $padding='0' $gap='4px'>
                    <DoctorIcon />
                    <Text>{loginData.doctors[0].name}</Text>
                    <SemiText>원장님</SemiText>
                </DropdownItem>
            )}
        </DropdownLayout>
    );
};

export default AddDiagnosisDoctorsDropdown;

const DropdownLayout = styled.div<DoctorsDropdownLayoutType>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.$number && 'center'};
    width: 100%;
    max-height: 255px;
    padding: ${(props) => props.$padding};
    height: ${(props) => props.$height || 'auto'};
    border: ${(props) => (props.$warning ? `1px solid ${palette.warning}` : `1px solid ${palette.testGray}`)};
    border-radius: 4px;
    overflow-y: auto;
    background-color: ${palette.white};
`;

const Text = styled(Body3_Medium)`
    color: ${TextColor.color_text_normal};
`;
const SemiText = styled(Body5_Regular)`
    color: ${TextColor.color_text_light};
`;
