import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import ToastNotice from 'assets/Icons/Notice/ToastNotice';
import Noti_Add from 'assets/Icons/ToastIcons/Noti_Add';
import Noti_Change from 'assets/Icons/ToastIcons/Noti_Change';
import Noti_Check from 'assets/Icons/ToastIcons/Noti_Check';
import Noti_Delete from 'assets/Icons/ToastIcons/Noti_Delete';
import Noti_Start from 'assets/Icons/ToastIcons/Noti_Start';
import { MemberWhiteBadge, RoundBadgeXS } from 'components/Common/Badge';
import { ToastContainerProps } from 'models/Common/toastMessegeTypes';
import { useGlobalToastStore } from 'store/useToastStore';
import styled, { keyframes } from 'styled-components';
import { font, palette, zIndex } from 'styles/Global/globalStyles';
import {
    Comment,
    Date,
    DiagnosisTimeWrapper,
    DoctoInfoWrapper,
    GlobalToastTextWrapper,
    InfoContainer,
    InfoWrapper,
    MainText,
    SubText,
    Title,
    ToastPatientName,
    UserInfoWrapper,
} from 'styles/Toast/toastStyles';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';

export const ToastContainer = ({ comment }: ToastContainerProps) => {
    return (
        <ToastWrapper>
            <ToastNotice />
            {comment}
        </ToastWrapper>
    );
};

export const GlobalToast = () => {
    const { toastList } = useGlobalToastStore();

    return (
        <GlobalToastLayout>
            {toastList.map((toast, idx) => {
                // console.log(toast.data);
                return (
                    <GlobalToastContainer key={toast.id}>
                        <IconWrapper>
                            {(toast.data?.code === '100' || toast.data?.code === '400') && <Noti_Add />}
                            {(toast.data?.code === '101' || toast.data?.code === '300' || toast.data?.code === '501' || toast.data?.code === '602') && <Noti_Change />}
                            {(toast.data?.code === '102' || toast.data?.code === '500' || toast.data?.code === '401' || toast.data?.code === '603') && <Noti_Delete />}
                            {toast.data?.code === '202' && <Noti_Start />}
                            {(toast.data?.code === '200' || toast.data?.code === '201' || toast.data?.code === '203' || toast.data?.code === '204') && <Noti_Check />}
                        </IconWrapper>
                        <GlobalToastTextWrapper>
                            <Title>{toast.title}</Title>
                            <Comment>{toast.comment}</Comment>
                            {/* 담당의 추가, 제거 toast */}
                            {toast.data?.doctorName && (
                                <InfoContainer>
                                    <DoctoInfoWrapper>
                                        <DoctorIcon />
                                        <MainText>{toast.data.doctorName}</MainText>
                                        <SubText>원장님</SubText>
                                    </DoctoInfoWrapper>
                                </InfoContainer>
                            )}
                            {/* 유저 정보 변경 toast */}
                            {toast.data?.userName && (
                                <InfoContainer>
                                    <DoctoInfoWrapper>
                                        <DoctorIcon />
                                        <MainText>{toast.data.userName}</MainText>
                                        <MemberWhiteBadge>{toast.data.userLevel === '0' ? '멤버' : '관리자'}</MemberWhiteBadge>
                                    </DoctoInfoWrapper>
                                </InfoContainer>
                            )}
                            {/* 담당의 제외한 toast */}
                            {toast.data?.patientName && (
                                <InfoContainer>
                                    <InfoWrapper>
                                        <RoundBadgeXS>{toast.data.regNum}</RoundBadgeXS>
                                        <UserInfoWrapper>
                                            <ToastPatientName>{toast.data.patientName}</ToastPatientName>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                {toast.data.patientGender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                            </span>
                                            <span style={{ fontWeight: '400' }}>({toast.data.patientIdentityNumber})</span>
                                        </UserInfoWrapper>
                                    </InfoWrapper>
                                    {toast.data.diagnosisTime && <DiagnosisTimeWrapper>{toast.data.diagnosisTime} 진료</DiagnosisTimeWrapper>}
                                </InfoContainer>
                            )}

                            <Date>{toast.data?.addTime}</Date>
                        </GlobalToastTextWrapper>
                    </GlobalToastContainer>
                );
            })}
        </GlobalToastLayout>
    );
};

const slideIn = keyframes`
  0% {
    transform: translateX(30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(30%);
    opacity: 0;
  }
`;

const IconWrapper = styled.div`
    padding-top: 4px;
    width: 32px;
    height: 100%;
`;

const ToastWrapper = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: -40px;
    width: 228px;
    height: 30px;
    border-radius: 99px;
    background: rgba(0, 0, 0, 0.6);
    z-index: ${zIndex.toast};
    animation: ${slideIn} 0.3s forwards, ${slideOut} 0.3s 3s forwards;
    color: ${palette.white};
    font-size: ${font.size[13]};
    gap: 2px;
`;

const GlobalToastLayout = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 360px;
    top: 40px;
    right: 0px;
    gap: 12px;
    // background-color: orange;
`;

const GlobalToastContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 18px 24px 20px 24px;
    justify-content: space-between;
    background-color: ${palette.white};
    border: 1px solid ${palette.gray};
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    z-index: ${zIndex.toast};
    gap: 10px;
    animation: ${slideIn} 0.3s forwards, ${slideOut} 0.3s 3s forwards;
`;

const PlusIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: ${palette.lightGray};
    border-radius: 99px;
    margin-top: 4px;
`;
