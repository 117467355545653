import React, { useEffect, useState } from 'react';
import { BackgroundColor, PrimaryColor } from 'styles/Global/colorSemantic';

const RadioButton = ({ $disabled, $width, $height, $marginRight }: { $disabled?: boolean; $width?: string; $height?: string; $marginRight?: string }) => {
    const [fillColor, setFillColor] = useState(BackgroundColor.color_background_primary);

    useEffect(() => {
        const svgElement = document.getElementById('radioButton-svg');

        if ($disabled) {
            setFillColor(BackgroundColor.color_background_primary_disabled);
        } else {
            const handleMouseOver = () => {
                if (!$disabled) {
                    setFillColor(PrimaryColor.color_primary_strong);
                }
            };

            const handleMouseOut = () => {
                if (!$disabled) {
                    setFillColor(BackgroundColor.color_background_primary);
                }
            };

            const handleMouseDown = () => {
                if (!$disabled) {
                    setFillColor(PrimaryColor.color_primary_heavy);
                }
            };

            const handleMouseUp = () => {
                if (!$disabled) {
                    setFillColor(BackgroundColor.color_background_primary);
                }
            };

            const handleFocus = () => {
                if (!$disabled) {
                    setFillColor(PrimaryColor.color_primary_heavy);
                }
            };

            const handleBlur = () => {
                if (!$disabled) {
                    setFillColor(BackgroundColor.color_background_primary);
                }
            };

            svgElement?.addEventListener('mouseover', handleMouseOver);
            svgElement?.addEventListener('mouseout', handleMouseOut);
            svgElement?.addEventListener('mousedown', handleMouseDown);
            svgElement?.addEventListener('mouseup', handleMouseUp);
            svgElement?.addEventListener('focus', handleFocus);
            svgElement?.addEventListener('blur', handleBlur);

            return () => {
                svgElement?.removeEventListener('mouseover', handleMouseOver);
                svgElement?.removeEventListener('mouseout', handleMouseOut);
                svgElement?.removeEventListener('mousedown', handleMouseDown);
                svgElement?.removeEventListener('mouseup', handleMouseUp);
                svgElement?.removeEventListener('focus', handleFocus);
                svgElement?.removeEventListener('blur', handleBlur);
            };
        }
    }, [$disabled]);
    return (
        <svg style={{ marginRight: $marginRight || 0 }} id='radioButton-svg' width={$width || '24'} height={$height || '24'} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z' fill={fillColor} />
            <path d='M7 12L10.3333 15.3333L17 8.66666' stroke='white' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export default RadioButton;
