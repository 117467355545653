import React from 'react';

const CloseDialog = () => {
    return (
        <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_453_1328)'>
                <path d='M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z' fill='#333438' />
                <path
                    d='M24.1073 13.3072C24.4979 12.9167 24.4979 12.2835 24.1073 11.893C23.7168 11.5024 23.0837 11.5024 22.6931 11.893L18.0002 16.5861L13.3072 11.893C12.9167 11.5025 12.2835 11.5025 11.893 11.893C11.5025 12.2835 11.5025 12.9167 11.893 13.3072L16.586 18.0003L11.8935 22.693C11.503 23.0835 11.503 23.7167 11.8935 24.1072C12.284 24.4977 12.9172 24.4977 13.3077 24.1072L18.0002 19.4146L22.6926 24.1072C23.0832 24.4978 23.7163 24.4978 24.1068 24.1072C24.4974 23.7167 24.4974 23.0835 24.1068 22.693L19.4143 18.0003L24.1073 13.3072Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_453_1328'>
                    <rect width='36' height='36' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CloseDialog;
