import styled from 'styled-components';
import { font, palette, zIndex } from './globalStyles';
import { BackgroundColor, BorderColor } from './colorSemantic';
import { Border, Radius } from './objectStyles';

export interface DropdownLayoutType {
    $height?: string;
    $padding?: string;
    selected?: boolean;
    $gap?: string;
    $top?: string;
    $minHeight?: string;
    $type?: string;
    $borderRadius?: string;
}

export const DropdownLayout = styled.div<DropdownLayoutType>`
    position: ${(props) => props.$type && 'absolute'};
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: ${(props) => (props.$type === 'mobile' ? '248px' : '216px')};
    padding: ${(props) => (props.$type === 'mobile' ? '4px 0' : '8px 0')};
    border: ${Border.border_1} solid ${BorderColor.color_border_normal_press};
    border-radius: ${(props) => props.$borderRadius};
    overflow-y: auto;
    background-color: ${palette.white};
    top: ${(props) => props.$top || (props.$type && '64px')};
    z-index: ${(props) => props.$type && zIndex.dropdown};
    box-shadow: 0 2px 12px 0 rgba(16, 16, 16, 0.12);
`;

export const DropdownItem = styled.button<DropdownLayoutType>`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.$type && 'space-between'};
    width: 100%;
    height: ${(props) => props.$height || '48px'};
    min-height: ${(props) => props.$minHeight};
    padding: ${(props) => props.$padding || '48px'};
    font-weight: ${font.weight.medium};
    cursor: pointer;
    gap: ${(props) => (props.$gap ? props.$gap : '8px')};
    background-color: ${(props) => props.selected && BackgroundColor.color_background_primary_normal};
    // ! 환자 추가 학령 / 학기에서 사용
    &:hover {
        background-color: ${(props) => (props.selected ? BackgroundColor.color_background_primary_strong : BackgroundColor.color_background_neutral)};
    }
    &:active {
        background-color: ${(props) => (props.selected ? BackgroundColor.color_background_primary_heavy : BackgroundColor.color_background_normal)};
    }
    &:focus {
        background-color: ${(props) => (props.selected ? BackgroundColor.color_background_primary_heavy : BackgroundColor.color_background_normal)};
    }
`;
