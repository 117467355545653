import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import { signUpApi } from 'api/SignUp/signUpApi';
import { pagePaths } from 'constants/path';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getPermission } from 'firebaseConfig';
import { ValueType } from 'models/Common/commonTypes';
import { AddDiagnosisApiCallFunction, LoginApiCallFunction, SignUpApiCallFunction } from 'models/Hospital/hospitalApiTypes';
import moment from 'moment';
import { LoginPageError } from 'constants/loginPageConstants';

export const tokenHeader = (token: string) => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };
};

// 진료 추가 api call
export const addDiagnosisApiCall: AddDiagnosisApiCallFunction = async (value, loginData, selectedDate, selectedTime, navigate, setInit) => {
    const gender = value.gender === '1' || value.gender === '3' ? 'male' : 'female';
    const selectedMoment = moment(selectedDate, 'YYYY/MM/DD');
    const birthMoment = moment(value.birthDate, 'YYMMDD');
    const years = selectedMoment.diff(birthMoment, 'years');
    const months = selectedMoment.diff(birthMoment, 'months') - years * 12;

    const rqData = {
        patientCode: value.patientCode,
        patientName: value.patientName,
        patientMobile: value.patientMobile,
        patientIdentityNumber: value.birthDate + value.gender,
        patientGender: gender,
        gradeCode: value.grade,
        gradeName: value.schoolAge + ' ' + value.semester,
        hospitalId: loginData.hospitalId,
        hospitalName: loginData.hospitalName,
        doctorName: value.doctorName,
        patientMemo: value.patientMemo,
        diagnosisDateTime: moment(selectedDate).format('YYYYMMDD') + moment(selectedTime, 'A h:mm').format('HHmm'),
        diagnosisTimestamp: moment(selectedDate + ' ' + moment(selectedTime, 'A h:mm').format('HHmm'), 'YYYYMMDD HHmm').valueOf(),
        assessmentAge: `만 ${years}세 ${months}개월`,
    };

    const response = await addDiagnosisApi.addDiagnosis(rqData, loginData.token);

    if (response.status === 'ok' && response.data) {
        setInit();
        navigate(pagePaths.viewDetaildiagnosis, { state: response.data });
    } else {
        console.log('network error');
    }
};

// 회원가입 api call
export const signUpApiCall: SignUpApiCallFunction = async (value, selectedHospital, setSignUpComplete, setErrMsg, setLoading, emailInputRef) => {
    setLoading(true);
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, value.email, value.password)
        .then(async (userCredential) => {
            const version = require('../../package.json').version;
            const user = userCredential.user;
            const idToken = await user.getIdToken();
            // const fcmToken = await getPermission();
            const rqData = {
                idToken: idToken,
                deviceType: 3,
                os: 'web',
                model: 'chrome',
                version: version,
                // token: fcmToken,
                token: 'Notification permission denied',
                mobile: value.phoneNumber,
                picture: '',
                name: value.name,
                hospitalId: selectedHospital?.id,
                hospitalName: selectedHospital?.name,
            };
            const response = await signUpApi.oauth(rqData);
            if (response.status === 'ok') {
                setLoading(false);
                return setSignUpComplete(true);
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            setLoading(false);
            if (errorCode === 'auth/email-already-in-use') {
                setErrMsg((prevErrMsg: ValueType) => ({ ...prevErrMsg, email: '이미 가입된 이메일입니다.' }));
                console.log('이미 가입된 이메일입니다.');
                emailInputRef.current?.focus();
            } else {
                console.log('회원가입에 실패하였습니다.');
            }
        });
};

// Login api call
export const loginApiCall: LoginApiCallFunction = async (value, setLoginData, setErrMsg, navigate, setLoading, emailRef, passwordRef) => {
    setLoading(true);
    const auth = getAuth();
    const response = await signUpApi.existUser(value.email);
    if (response.status === 'ok') {
        signInWithEmailAndPassword(auth, value.email, value.password)
            .then(async (userCredential) => {
                const version = require('../../package.json').version;
                console.log(version);
                const user = userCredential.user;
                const idToken = await user.getIdToken();
                const fcmToken = await getPermission();
                const rqData = {
                    idToken: idToken,
                    deviceType: 3,
                    os: 'web',
                    model: 'chrome',
                    version: version,
                    token: fcmToken,
                };
                const response = await signUpApi.oauth(rqData);

                if (response.status === 'ok') {
                    const getHospitalDataApiResponse = await addDiagnosisApi.getHospitalData(response.data.user.hospitalId, response.data.bearerToken);
                    if (getHospitalDataApiResponse.status === 'ok') {
                        setLoginData({
                            id: response.data.user._id,
                            email: response.data.user.email,
                            hospitalName: response.data.user.hospitalName,
                            hospitalId: response.data.user.hospitalId,
                            level: response.data.user.level,
                            mobile: response.data.user.mobile,
                            name: response.data.user.name,
                            status: response.data.user.status,
                            token: response.data.bearerToken,
                            doctors: getHospitalDataApiResponse.data.doctors,
                            diagnosisTap: 0,
                        });
                        setLoading(false);

                        if (response.data.user.status === 1) {
                            if (response.data.user.level !== 100) {
                                navigate(pagePaths.home, { replace: true });
                                return getHospitalDataApiResponse.data;
                            } else {
                                navigate(pagePaths.adminDiagnosisList, { replace: true });
                                return getHospitalDataApiResponse.data;
                            }
                        } else {
                            console.log('panding 상태, home으로 이동');
                        }
                    } else {
                        console.log('HospitalData 가져오기 실패');
                        setLoading(false);
                    }
                } else {
                    console.log('oauth 로그인 실패');
                    setLoading(false);
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage, 'RtError');
                // 비밀번호 체크
                setErrMsg((prevErrMsg) => ({ ...prevErrMsg, password: LoginPageError.passwordCheckError }));
                passwordRef.current?.focus();
                setLoading(false);
            });
    } else {
        // 아이디 중복체크로 아이디가 있는 아이디인지 없는 아이디인지 체크
        setErrMsg((prevErrMsg) => ({ ...prevErrMsg, email: LoginPageError.loginIdExistanceCheckError }));
        emailRef.current?.focus();
        // setLoading(false);
    }
};
