import React from 'react';

const ArrowRightSlim = () => {
    return (
        <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.97578 12.4243C4.2101 12.6586 4.59 12.6586 4.82431 12.4243L8.82431 8.42427C9.05863 8.18996 9.05863 7.81006 8.82431 7.57574L4.82431 3.57574C4.59 3.34143 4.2101 3.34143 3.97578 3.57574C3.74147 3.81006 3.74147 4.18995 3.97578 4.42427L7.55152 8.00001L3.97578 11.5757C3.74147 11.8101 3.74147 12.19 3.97578 12.4243Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default ArrowRightSlim;
