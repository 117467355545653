import { PaginationIconWrapper, PaginationPageButton, PaginationPageButtonWrapper } from 'styles/Global/buttonStyles';
import Page_Next from 'assets/Icons/Pagination/Page_Next';
import Page_NextSection from 'assets/Icons/Pagination/Page_NextSection';
import Page_Before from 'assets/Icons/Pagination/Page_Before';
import { DiagnosisListpaginationFooterProps } from 'models/Hospital/paginationTypes';
import Page_BeforeSection from 'assets/Icons/Pagination/Page_BeforeSection';

const DiagnosisListpaginationFooter = ({ currentPage, handlePageChange, handlePageGroup, pageGroup, totalPages }: DiagnosisListpaginationFooterProps) => {
    return (
        <>
            {totalPages && totalPages > 10 && (
                <PaginationIconWrapper
                    disabled={currentPage <= 10}
                    onClick={() => {
                        const newPage = Math.floor((currentPage - 1) / 10 - 1) * 10 + 1;
                        handlePageChange(newPage);
                        handlePageGroup(Math.floor((newPage - 1) / 10));
                    }}
                >
                    <Page_BeforeSection />
                </PaginationIconWrapper>
            )}
            <PaginationIconWrapper
                disabled={currentPage === 1}
                onClick={() => {
                    handlePageChange(currentPage - 1);
                    if (currentPage % 10 === 1) {
                        handlePageGroup(pageGroup - 1);
                    }
                }}
            >
                <Page_Before />
            </PaginationIconWrapper>
            <PaginationPageButtonWrapper>
                {Array.from({ length: totalPages || 0 }, (_, index) => index + 1)
                    .slice(pageGroup * 10, pageGroup * 10 + 10)
                    .map((page) => (
                        <PaginationPageButton key={page} onClick={() => handlePageChange(page)} selected={page === currentPage}>
                            {page}
                        </PaginationPageButton>
                    ))}
            </PaginationPageButtonWrapper>
            <PaginationIconWrapper
                disabled={currentPage === totalPages}
                onClick={() => {
                    handlePageChange(currentPage + 1);
                    if (currentPage % 10 === 0) {
                        handlePageGroup(pageGroup + 1);
                    }
                }}
            >
                <Page_Next />
            </PaginationIconWrapper>
            {totalPages && totalPages > 10 && (
                <PaginationIconWrapper
                    disabled={totalPages && (pageGroup + 1) * 10 >= totalPages ? true : false}
                    onClick={() => {
                        const newPage = Math.floor((currentPage - 1) / 10 + 1) * 10 + 1;
                        handlePageChange(newPage);
                        handlePageGroup(Math.floor((newPage - 1) / 10));
                    }}
                >
                    <Page_NextSection />
                </PaginationIconWrapper>
            )}
        </>
    );
};

export default DiagnosisListpaginationFooter;
