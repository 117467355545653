export const LoginPageError = {
    idExistenceCheckError: '입력한 아이디를 찾을 수 없습니다. 다시 입력해주세요.',
    loginIdExistanceCheckError: '가입되지 않은 이메일 아이디입니다.',
    passwordCheckError: '잘못된 비밀번호입니다. 다시 입력해주세요.',
    changeEmailForm: '이메일 형식으로 입력해주세요.',
    enterEmailId: '이메일 아이디를 입력해주세요.',
};

export const signUpAlertContants = {
    title: '가입 미승인 계정입니다.',
    content: '아직 가입이 승인되지 않은 계정입니다. 확인과 승인까지는',
    subContent: '최대 하루가 소요됩니다. 궁금하신 점은 문의 전화로 연락바랍니다.',
    label: '문의 전화',
    labelText: '031-705-1426',
};

export const findIdAndPasswordConstants = {
    frontTitle: '비밀번호 재설정 링크가',
    backTitle: '전송되었습니다!',
    frontSemiText: '추가 문의사항은',
    number: '031-705-1426',
    backSemiText: '로 연락바랍니다.',
};

export const inputWithLabelConstants = {
    same: '일치',
};

export const resetPasswordConstants = {
    title: '비밀번호 재설정 링크가 전송되었습니다!',
    explainText: ['아래의 이메일로 비밀번호 재설정 링크가 전송되었습니다.', '메일함을 확인해주세요.'],
};
