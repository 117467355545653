import React from 'react';

const Icon_arrow_up = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.37273 16.0453C4.72911 16.3918 5.29891 16.3837 5.64539 16.0274L12.0001 9.49109L18.3548 16.0274C18.7013 16.3837 19.2711 16.3918 19.6275 16.0453C19.9839 15.6988 19.9919 15.129 19.6454 14.7726L12.6454 7.57262C12.4759 7.39832 12.2432 7.29999 12.0001 7.29999C11.757 7.29999 11.5243 7.39832 11.3548 7.57262L4.3548 14.7726C4.00832 15.129 4.01634 15.6988 4.37273 16.0453Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default Icon_arrow_up;
