import { UseEditAssessmentStateStoreType } from 'models/Login/editAssessmentStateStoreType';
import { create } from 'zustand';

export const useLeaveModalStateStore = create<UseEditAssessmentStateStoreType>((set) => ({
    movePageState: false,
    editState: false,
    clickType: '',
    movePath: '',
    isLeaveModal: false,
    isLogoutModal: false,
    returnSelectedAssessmentState: false,
    isReturnSelectedAssessment: false,
    setMovePageState: (state: boolean) => {
        set(() => ({
            movePageState: state,
        }));
    },
    setEditState: (state: boolean) => {
        set(() => ({
            editState: state,
        }));
    },
    setClickType: (state: string) => {
        set(() => ({
            clickType: state,
        }));
    },
    setMovePath: (state: string) => {
        set(() => ({
            movePath: state,
        }));
    },
    setIsLeaveModal: () => {
        set((state) => ({
            isLeaveModal: !state.isLeaveModal,
        }));
    },
    setIsLogoutModal: (state: boolean) => {
        set(() => ({
            isLogoutModal: state,
        }));
    },
    setReturnSelectedAssessmentState: (state: boolean) => {
        set(() => ({
            returnSelectedAssessmentState: state,
        }));
    },
    setIsReturnSelectedAssessment: () => {
        set((state) => ({
            isReturnSelectedAssessment: !state.isReturnSelectedAssessment,
        }));
    },
    setInit: () => {
        set(() => ({
            movdPageState: false,
            editState: false,
            clickType: '',
            movePath: '',
            isLeaveModal: false,
            isReturnSelectedAssessment: false,
            returnSelectedAssessmentState: false,
        }));
    },
}));
