import React, { useState } from 'react';
import { palette } from 'styles/Global/globalStyles';

const Icon_refresh = () => {
    const [fillColor, setFillColor] = useState('#999999');

    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' onMouseEnter={() => setFillColor(palette.black)} onMouseLeave={() => setFillColor('#999999')}>
            <path
                d='M12 18.4C14.8849 18.4 17.324 16.4912 18.1232 13.8677C18.2372 13.4934 18.568 13.2134 18.9592 13.2134C19.4587 13.2134 19.8435 13.6602 19.7101 14.1415C18.7738 17.5199 15.6765 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C14.6169 4 16.9403 5.25649 18.3999 7.19909V5.20002C18.3999 4.7582 18.7581 4.40002 19.1999 4.40002C19.6417 4.40002 19.9999 4.7582 19.9999 5.20002V9.20002C19.9999 9.64185 19.6417 10 19.1999 10H15.1999C14.7581 10 14.3999 9.64185 14.3999 9.20002C14.3999 8.7582 14.7581 8.40002 15.1999 8.40002H17.2923C16.1403 6.70979 14.1997 5.6 12 5.6C8.46538 5.6 5.6 8.46538 5.6 12C5.6 15.5346 8.46538 18.4 12 18.4Z'
                fill={fillColor}
            />
        </svg>
    );
};

export default Icon_refresh;
