import React from 'react';

const None = () => {
    return (
        <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='43' cy='42' r='24' fill='#ECEDEF' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M40 65.6C54.1385 65.6 65.6 54.1385 65.6 40C65.6 25.8615 54.1385 14.4 40 14.4C25.8615 14.4 14.4 25.8615 14.4 40C14.4 54.1385 25.8615 65.6 40 65.6ZM40 68C55.464 68 68 55.464 68 40C68 24.536 55.464 12 40 12C24.536 12 12 24.536 12 40C12 55.464 24.536 68 40 68Z'
                fill='#989BA2'
            />
            <path d='M32.5 40C32.5 41.3807 31.3807 42.5 30 42.5C28.6193 42.5 27.5 41.3807 27.5 40C27.5 38.6193 28.6193 37.5 30 37.5C31.3807 37.5 32.5 38.6193 32.5 40Z' fill='#989BA2' />
            <path d='M42.5 40C42.5 41.3807 41.3807 42.5 40 42.5C38.6193 42.5 37.5 41.3807 37.5 40C37.5 38.6193 38.6193 37.5 40 37.5C41.3807 37.5 42.5 38.6193 42.5 40Z' fill='#989BA2' />
            <path d='M52.5 40C52.5 41.3807 51.3807 42.5 50 42.5C48.6193 42.5 47.5 41.3807 47.5 40C47.5 38.6193 48.6193 37.5 50 37.5C51.3807 37.5 52.5 38.6193 52.5 40Z' fill='#989BA2' />
        </svg>
    );
};

export default None;
