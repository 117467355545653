import React from 'react';

const ToolTipDisabled = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='4' y='4' width='16' height='16' rx='8' fill='#EEEEEE' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.82828 8.83833C9.55491 8.56496 9.11169 8.56496 8.83833 8.83833C8.56496 9.11169 8.56496 9.55491 8.83833 9.82828L11.0101 12L8.83839 14.1717C8.56502 14.445 8.56502 14.8882 8.83839 15.1616C9.11176 15.435 9.55497 15.435 9.82834 15.1616L12 12.9899L14.1717 15.1616C14.445 15.435 14.8882 15.435 15.1616 15.1616C15.435 14.8882 15.435 14.445 15.1616 14.1717L12.9899 12L15.1617 9.82828C15.435 9.55491 15.435 9.11169 15.1617 8.83833C14.8883 8.56496 14.4451 8.56496 14.1717 8.83833L12 11.0101L9.82828 8.83833Z'
                fill='#E7000E'
            />
        </svg>
    );
};

export default ToolTipDisabled;
