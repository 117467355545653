import React from 'react';

const Search = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.2 10C15.2 12.8719 12.8719 15.2 10 15.2C7.12812 15.2 4.8 12.8719 4.8 10C4.8 7.12812 7.12812 4.8 10 4.8C12.8719 4.8 15.2 7.12812 15.2 10ZM14.2725 15.5453C13.0904 16.4574 11.6085 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.6086 16.4574 13.0905 15.5453 14.2726L19.6363 18.3636C19.9878 18.7151 19.9878 19.2849 19.6363 19.6364C19.2848 19.9879 18.715 19.9879 18.3635 19.6364L14.2725 15.5453Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default Search;
