import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { AddAndDeleteDoctorResponse, AddAndDeleteDoctorRqData, GetReadByHospitalIdResponse } from 'models/Hospital/hospitalApiTypes';

class HospitalSettingApi {
    async AddDoctor(rqData: AddAndDeleteDoctorRqData, token: string): Promise<AddAndDeleteDoctorResponse> {
        try {
            const response = await axios.post(apiPaths.addDoctor, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async DeleteDoctor(rqData: AddAndDeleteDoctorRqData, token: string): Promise<AddAndDeleteDoctorResponse> {
        try {
            const response = await axios.post(apiPaths.deleteDoctor, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async GetReadByHospitalId(url: string, token: string): Promise<GetReadByHospitalIdResponse> {
        try {
            const response = await axios.get(apiPaths.GetReadByHospitalId + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async DeleteUser(id: string, token: string): Promise<GetReadByHospitalIdResponse> {
        try {
            const response = await axios.delete(apiPaths.deleteUser + id, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async UserChangeLevel(rqData: { _id: string; level: number }, token: string): Promise<GetReadByHospitalIdResponse> {
        try {
            const response = await axios.put(apiPaths.changeUserLevel, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const hospitalSettingApi = new HospitalSettingApi();
