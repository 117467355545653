import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import styled from 'styled-components';
import BasicButton from 'components/Buttons/BasicButton';
import { LeaveModalProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { CloseButtonWrapper } from 'styles/Modal/modalStyle';
import Noti_Warning from 'assets/Icons/ToastIcons/Noti_Warning';
import CloseDialog from 'assets/Icons/DeleteIcon/CloseDialog';

const LeaveModal = ({ setIsLeaveModal, clickLeaveButtonInLeaveModal, title, content, firstButton, secondButton }: LeaveModalProps) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <ModalTitleAndContentContainer>
                    <TitleAndIconWrapper>
                        <Noti_Warning />
                        <ModalTitle>{title}</ModalTitle>
                    </TitleAndIconWrapper>
                    <ModalContent>{content}</ModalContent>
                </ModalTitleAndContentContainer>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={setIsLeaveModal} $type='anotherFill'>
                        {firstButton}
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' onClick={clickLeaveButtonInLeaveModal} $type='warning'>
                        {secondButton}
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={setIsLeaveModal}>
                    <CloseDialog />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default LeaveModal;
const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const TitleAndIconWrapper = styled.div`
    display: flex;
    gap: 8px;
`;
