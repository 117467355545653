import React from 'react';

const Ico_logo = () => {
    return (
        <svg width='120' height='24' viewBox='0 0 120 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M28.3316 0.638005C27.4228 0.590015 26.5698 0.81397 25.8364 1.22189C24.7602 1.82977 23.4449 1.82177 22.3767 1.22189C21.6991 0.853962 20.9338 0.638005 20.1127 0.638005C18.5742 0.638005 17.219 1.38985 16.374 2.54162C15.7442 3.40545 14.7398 3.90135 13.6795 3.90135C12.5794 3.90135 11.5112 3.40545 10.8575 2.51763C9.94078 1.27788 8.41818 0.510031 6.73614 0.654002C4.47217 0.845963 2.66258 2.70959 2.52706 4.98113C2.35966 7.67659 4.48811 9.91614 7.1427 9.91614C8.64139 9.91614 9.97267 9.20428 10.8177 8.09251C11.5032 7.19669 12.5475 6.6448 13.6795 6.6448C14.7477 6.6448 15.7522 7.1487 16.3819 8.01252C17.219 9.16429 18.5821 9.91614 20.1127 9.91614C20.9338 9.91614 21.6991 9.70018 22.3687 9.32426C23.4449 8.71638 24.7602 8.71638 25.8364 9.32426C26.506 9.70018 27.2713 9.91614 28.0924 9.91614C30.747 9.91614 32.8755 7.67659 32.7081 4.98113C32.5646 2.6456 30.6593 0.757981 28.3316 0.646004V0.638005Z'
                fill='#41667C'
            />
            <path d='M5.96958 14.3875H2.51782V12.3479H11.9165V14.3875H8.46474V23.1697H5.96958V14.3875Z' fill='#231916' />
            <path d='M13.5669 12.3479H16.0621V21.1301H21.4749V23.1697H13.5669V12.3479Z' fill='#231916' />
            <path
                d='M22.3198 17.7617C22.3198 14.4984 24.8149 12.1628 28.179 12.1628C30.0444 12.1628 31.5989 12.8427 32.6193 14.0825L31.017 15.5702C30.2915 14.7383 29.3828 14.3064 28.3066 14.3064C26.2897 14.3064 24.8548 15.7301 24.8548 17.7697C24.8548 19.8093 26.2897 21.233 28.3066 21.233C29.3828 21.233 30.2915 20.8011 31.017 19.9453L32.6193 21.433C31.5989 22.6887 30.0444 23.3686 28.1631 23.3686C24.8149 23.3686 22.3198 21.0331 22.3198 17.7697V17.7617Z'
                fill='#231916'
            />
            <path d='M46.2147 12.7871V23.089H45.4653V18.186H38.6017V23.089H37.8523V12.7871H38.6017V17.5222H45.4653V12.7871H46.2147Z' fill='#231916' />
            <path
                d='M56.3226 19.4166H49.738C49.7539 20.0165 49.9054 20.5444 50.1924 21.0163C50.4793 21.4802 50.862 21.8402 51.3403 22.0961C51.8266 22.3521 52.3687 22.48 52.9745 22.48C53.4767 22.48 53.9391 22.392 54.3616 22.2081C54.7841 22.0241 55.1428 21.7602 55.4218 21.4082L55.8443 21.8961C55.5095 22.2961 55.095 22.608 54.5928 22.824C54.0905 23.0399 53.5405 23.1439 52.9586 23.1439C52.1933 23.1439 51.5157 22.9759 50.9178 22.648C50.3199 22.3201 49.8576 21.8562 49.5148 21.2643C49.18 20.6724 49.0046 20.0005 49.0046 19.2487C49.0046 18.4968 49.164 17.833 49.4829 17.2411C49.8018 16.6492 50.2402 16.1853 50.7982 15.8494C51.3562 15.5134 51.978 15.3455 52.6716 15.3455C53.3651 15.3455 53.9869 15.5134 54.5449 15.8414C55.095 16.1693 55.5334 16.6252 55.8523 17.2171C56.1712 17.809 56.3306 18.4728 56.3306 19.2167L56.3147 19.4246L56.3226 19.4166ZM51.2367 16.3453C50.8062 16.5852 50.4634 16.9211 50.2003 17.3611C49.9373 17.801 49.7938 18.2889 49.7539 18.8408H55.6211C55.5892 18.2889 55.4457 17.801 55.1827 17.3691C54.9196 16.9371 54.5689 16.6012 54.1384 16.3533C53.7079 16.1053 53.2216 15.9853 52.6875 15.9853C52.1534 15.9853 51.6671 16.1053 51.2367 16.3453Z'
                fill='#231916'
            />
            <path
                d='M63.4498 16.0739C63.96 16.5618 64.2151 17.2736 64.2151 18.2174V23.0885H63.5136V21.7207C63.2665 22.1686 62.9157 22.5206 62.4454 22.7765C61.9751 23.0325 61.417 23.1524 60.7554 23.1524C59.9024 23.1524 59.2328 22.9525 58.7385 22.5526C58.2443 22.1526 57.9972 21.6167 57.9972 20.9609C57.9972 20.305 58.2283 19.8091 58.6827 19.4092C59.1371 19.0093 59.8625 18.8093 60.859 18.8093H63.4817V18.1934C63.4817 17.4736 63.2904 16.9297 62.8998 16.5618C62.5171 16.1858 61.9511 16.0019 61.2098 16.0019C60.6996 16.0019 60.2133 16.0899 59.7509 16.2658C59.2886 16.4418 58.89 16.6817 58.5711 16.9697L58.2044 16.4418C58.5871 16.0979 59.0415 15.8339 59.5835 15.6419C60.1176 15.45 60.6836 15.354 61.2735 15.354C62.2222 15.354 62.9476 15.594 63.4578 16.0819L63.4498 16.0739ZM62.4773 22.1046C62.9237 21.8087 63.2585 21.3848 63.4817 20.8249V19.3852H60.867C60.1176 19.3852 59.5676 19.5212 59.2328 19.7931C58.898 20.0651 58.7226 20.449 58.7226 20.9289C58.7226 21.4088 58.9059 21.8247 59.2806 22.1126C59.6553 22.4006 60.1734 22.5446 60.851 22.5446C61.4888 22.5446 62.0229 22.4006 62.4693 22.1046H62.4773Z'
                fill='#231916'
            />
            <path d='M67.5231 12.1628H68.2565V23.0807H67.5231V12.1628Z' fill='#231916' />
            <path
                d='M75.3438 22.6009C75.1604 22.7769 74.9292 22.9129 74.6502 23.0088C74.3712 23.1048 74.0842 23.1448 73.7813 23.1448C73.1196 23.1448 72.6015 22.9609 72.2427 22.5929C71.884 22.225 71.7007 21.7131 71.7007 21.0572V16.0422H70.2897V15.4104H71.7007V13.7307H72.4341V15.4104H74.8654V16.0422H72.4341V20.9853C72.4341 21.4812 72.5536 21.8651 72.7928 22.129C73.0319 22.393 73.3907 22.5209 73.853 22.5209C74.3154 22.5209 74.7299 22.377 75.0408 22.097L75.3517 22.6089L75.3438 22.6009Z'
                fill='#231916'
            />
            <path
                d='M83.69 16.186C84.248 16.7459 84.527 17.5538 84.527 18.6016V23.0886H83.7937V18.6575C83.7937 17.7937 83.5784 17.1378 83.14 16.6819C82.7015 16.226 82.0957 16.0021 81.3144 16.0021C80.4136 16.0021 79.7041 16.274 79.186 16.8099C78.6678 17.3458 78.4048 18.0737 78.4048 18.9855V23.0807H77.6714V12.1628H78.4048V17.0019C78.6678 16.474 79.0584 16.0661 79.5766 15.7701C80.0948 15.4822 80.7086 15.3382 81.4101 15.3382C82.3667 15.3382 83.124 15.6181 83.682 16.178L83.69 16.186Z'
                fill='#231916'
            />
            <path
                d='M88.895 22.6404C88.3051 22.3045 87.8507 21.8406 87.5159 21.2487C87.1811 20.6568 87.0137 19.985 87.0137 19.2411C87.0137 18.4973 87.1811 17.8094 87.5159 17.2175C87.8507 16.6256 88.3051 16.1617 88.895 15.8338C89.4849 15.5059 90.1466 15.3379 90.888 15.3379C91.5018 15.3379 92.0598 15.4579 92.57 15.6978C93.0722 15.9378 93.4788 16.2897 93.7977 16.7536L93.2556 17.1535C92.9845 16.7696 92.6418 16.4817 92.2352 16.2897C91.8286 16.0977 91.3822 16.0018 90.8959 16.0018C90.2981 16.0018 89.764 16.1377 89.2936 16.4097C88.8153 16.6816 88.4486 17.0575 88.1776 17.5534C87.9065 18.0413 87.771 18.6092 87.771 19.2491C87.771 19.889 87.9065 20.4489 88.1776 20.9448C88.4486 21.4327 88.8153 21.8166 89.2936 22.0885C89.764 22.3605 90.306 22.4965 90.8959 22.4965C91.3822 22.4965 91.8286 22.4005 92.2352 22.2085C92.6418 22.0165 92.9845 21.7286 93.2556 21.3447L93.7977 21.7446C93.4868 22.2085 93.0802 22.5524 92.57 22.8004C92.0678 23.0403 91.5098 23.1603 90.888 23.1603C90.1466 23.1603 89.477 22.9924 88.895 22.6644V22.6404Z'
                fill='#231916'
            />
            <path
                d='M101.06 16.0739C101.57 16.5618 101.826 17.2736 101.826 18.2174V23.0885H101.124V21.7207C100.877 22.1686 100.526 22.5206 100.056 22.7765C99.5855 23.0325 99.0274 23.1524 98.3658 23.1524C97.5128 23.1524 96.8432 22.9525 96.3489 22.5526C95.8547 22.1526 95.6076 21.6167 95.6076 20.9609C95.6076 20.305 95.8387 19.8091 96.2931 19.4092C96.7475 19.0093 97.473 18.8093 98.4694 18.8093H101.092V18.1934C101.092 17.4736 100.901 16.9297 100.51 16.5618C100.128 16.1858 99.5616 16.0019 98.8202 16.0019C98.31 16.0019 97.8237 16.0899 97.3614 16.2658C96.899 16.4418 96.5004 16.6817 96.1815 16.9697L95.8148 16.4418C96.1975 16.0979 96.6519 15.8339 97.1939 15.6419C97.7281 15.45 98.294 15.354 98.884 15.354C99.8326 15.354 100.558 15.594 101.068 16.0819L101.06 16.0739ZM100.088 22.1046C100.534 21.8087 100.869 21.3848 101.092 20.8249V19.3852H98.4774C97.7281 19.3852 97.178 19.5212 96.8432 19.7931C96.5084 20.0651 96.333 20.449 96.333 20.9289C96.333 21.4088 96.5163 21.8247 96.891 22.1126C97.2657 22.4006 97.7839 22.5446 98.4615 22.5446C99.0992 22.5446 99.6333 22.4006 100.08 22.1046H100.088Z'
                fill='#231916'
            />
            <path
                d='M106.935 15.7934C107.438 15.4974 108.044 15.3455 108.753 15.3455V16.0653L108.578 16.0493C107.725 16.0493 107.063 16.3133 106.585 16.8492C106.106 17.3851 105.867 18.1289 105.867 19.0807V23.0799H105.134V15.4014H105.835V17.0811C106.067 16.5212 106.433 16.0893 106.935 15.7934Z'
                fill='#231916'
            />
            <path
                d='M117.474 19.4166H110.889C110.905 20.0165 111.057 20.5444 111.344 21.0163C111.631 21.4802 112.014 21.8402 112.492 22.0961C112.978 22.3521 113.52 22.48 114.126 22.48C114.628 22.48 115.091 22.392 115.513 22.2081C115.936 22.0241 116.294 21.7602 116.573 21.4082L116.996 21.8961C116.661 22.2961 116.247 22.608 115.744 22.824C115.242 23.0399 114.692 23.1439 114.11 23.1439C113.345 23.1439 112.667 22.9759 112.069 22.648C111.471 22.3201 111.009 21.8562 110.666 21.2643C110.331 20.6724 110.156 20.0005 110.156 19.2487C110.156 18.4968 110.316 17.833 110.634 17.2411C110.953 16.6492 111.392 16.1853 111.95 15.8494C112.508 15.5134 113.13 15.3455 113.823 15.3455C114.517 15.3455 115.138 15.5134 115.696 15.8414C116.247 16.1693 116.685 16.6252 117.004 17.2171C117.323 17.809 117.482 18.4728 117.482 19.2167L117.466 19.4246L117.474 19.4166ZM112.388 16.3453C111.958 16.5852 111.615 16.9211 111.352 17.3611C111.089 17.801 110.945 18.2889 110.905 18.8408H116.773C116.741 18.2889 116.597 17.801 116.334 17.3691C116.071 16.9371 115.72 16.6012 115.29 16.3533C114.859 16.1053 114.373 15.9853 113.839 15.9853C113.305 15.9853 112.819 16.1053 112.388 16.3453Z'
                fill='#231916'
            />
        </svg>
    );
};

export default Ico_logo;
