import React from 'react';
import App from './App';
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import SignUp from 'pages/SignUp/SignUp';
import { governmentPaths, pagePaths, tabletPaths } from 'constants/path';
import Login from 'pages/Login/Login';
import AddDiagnosisPage from 'pages/Hospital/AddDiagnosisPage/AddDiagnosisPage';
import DiagnosisListPage from 'pages/Hospital/DiagnosisListPage/DiagnosisListPage';
import TodayDiagnosisPage from 'pages/Hospital/TodayDiagnosisPage/TodayDiagnosisPage';
import ManagingPatientsPage from 'pages/Hospital/ManagingPatients/ManagingPatientsPage';
import AccountSettingPage from 'pages/AccountInfo/AccountSettingPage';
import HospitalSettingPage from 'pages/Hospital/HospitalSettingPage/HospitalSettingPage';
import PrivateRoute from 'components/Common/PrivateRoute';
import ViewDetailPatientPage from 'pages/Hospital/ViewDetailPatientPage/ViewDetailPatientPage';
import Home from 'pages/Home/Home';
import ViewDetailDiagnosisPage from 'pages/Hospital/ViewDetailDiagnosisPage/ViewDetailDiagnosisPage';
import ManagerLogin from 'pages/tablet/ManagerLogin';
import PatientLogin from 'pages/tablet/PatienLogin';
import StartExamination from 'pages/tablet/StartExamination';
import Information from 'pages/tablet/Information';
import TabletApp from 'pages/tablet/TabletApp';
import UserSurvey from 'pages/tablet/UserSurvey';
import TableSettings from 'pages/tablet/TableSettings';
import ScrollToTop from 'components/Common/ScrollToTop';
import AdminDiagnosisListPage from 'pages/Admin/AdminDiagnosisListPage/AdminDiagnosisListPage';
import AdminCalculatePage from 'pages/Admin/AdminCalculate/AdminCalculatePage';
import SungnamApp from 'pages/Government/GovernmentApp';
import GovernmentAgreePage from 'pages/Government/Agree/GovernmentAgreePage';
import GovernmentCodeConfirmPage from 'pages/Government/CodeConfirm/GovernmentCodeConfirmPage';
import GovernmentApp from 'pages/Government/GovernmentApp';
import GovernmentReadyPage from 'pages/Government/Confirm/GovernmentReadyPage';
import GovernmentSelectAssessmentPage from 'pages/Government/SelectAssessment/GovernmentSelectAssessmentPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <>
                <ScrollToTop />
                <App />
            </>
        ),
        errorElement: <ErrorPage />,
        children: [
            { path: pagePaths.login, element: <Login /> },
            { path: pagePaths.signUp, element: <SignUp /> },
            { path: pagePaths.home, element: <PrivateRoute component={Home} /> },
            { path: pagePaths.addDiagnosis, element: <PrivateRoute component={AddDiagnosisPage} /> },
            { path: pagePaths.diagnosisList, element: <PrivateRoute component={DiagnosisListPage} /> },
            { path: pagePaths.viewDetaildiagnosis, element: <PrivateRoute component={ViewDetailDiagnosisPage} /> },
            { path: pagePaths.todayDiagnosis, element: <PrivateRoute component={TodayDiagnosisPage} /> },
            { path: pagePaths.managingPatients, element: <PrivateRoute component={ManagingPatientsPage} /> },
            { path: pagePaths.accountSetting, element: <PrivateRoute component={AccountSettingPage} /> },
            { path: pagePaths.hospitalSetting, element: <PrivateRoute component={HospitalSettingPage} /> },
            { path: pagePaths.viewDetailPatient, element: <PrivateRoute component={ViewDetailPatientPage} /> },
            { path: pagePaths.adminDiagnosisList, element: <PrivateRoute component={AdminDiagnosisListPage} /> },
            { path: pagePaths.calculate, element: <PrivateRoute component={AdminCalculatePage} /> },
        ],
    },
    {
        path: '/government',
        element: (
            <>
                <ScrollToTop />
                <GovernmentApp />
            </>
        ),
        errorElement: <ErrorPage />,
        children: [
            { path: governmentPaths.agree, element: <GovernmentAgreePage /> },
            { path: governmentPaths.ready, element: <GovernmentReadyPage /> },
            { path: governmentPaths.codeConfirm, element: <GovernmentCodeConfirmPage /> },
            { path: governmentPaths.selectAssessment, element: <GovernmentSelectAssessmentPage /> },
        ],
    },
    {
        path: '/tablet',
        element: <TabletApp />,
        errorElement: <ErrorPage />,
        children: [
            { path: tabletPaths.managerLogin, element: <ManagerLogin /> },
            { path: tabletPaths.login, element: <PatientLogin /> },
            { path: tabletPaths.information, element: <Information /> },
            { path: tabletPaths.startExamination, element: <StartExamination /> },
            { path: tabletPaths.userSurvey, element: <UserSurvey /> },
            { path: tabletPaths.managerLogin, element: <ManagerLogin /> },
        ],
    },
]);

export default router;
