import { ViewDetailDiagnosisListProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AssessmentList from './AssessmentList';

const ViewDetailDiagnosisList = ({
    status,
    filterAssessmentData,
    handleCheck,
    selectedAssessment,
    otherAssessmentData,
    handleAllCheck,
    allCheck,
    selectedTLCProgram,
    handleResetFilter,
    editState,
    allFilterAssessmentCheck,
    allOtherAssessmentCheck,
}: ViewDetailDiagnosisListProps) => {
    const [count, setCount] = useState<number>(0);
    const [assessmentData, setAssessmentData] = useState<ViewDetailDiagnosisListProps['filterAssessmentData']>(filterAssessmentData);

    useEffect(() => {
        if (status === 3) {
            const completedAssessment = filterAssessmentData?.filter((item) => item.status === 2);
            setAssessmentData(completedAssessment);
            setCount(completedAssessment?.length || 0);
        } else {
            const completedAssessmentCount = filterAssessmentData?.reduce((acc, item) => {
                return item.status !== -1 ? acc + 1 : acc;
            }, 0);
            setAssessmentData(filterAssessmentData);
            setCount(completedAssessmentCount || 0);
        }
    }, [filterAssessmentData, status]);

    return (
        <ListLayout>
            <ListContainer>
                {selectedTLCProgram === 0 ? (
                    <AssessmentList
                        status={status}
                        handleCheck={handleCheck}
                        selectedAssessment={selectedAssessment}
                        selectedTLCProgram={selectedTLCProgram}
                        assessmentData={assessmentData}
                        editState={editState}
                        handleAllCheck={handleAllCheck}
                        allCheck={allCheck}
                        handleResetFilter={handleResetFilter}
                        count={count}
                    />
                ) : (
                    <>
                        <AssessmentList
                            status={status}
                            handleCheck={handleCheck}
                            selectedAssessment={selectedAssessment}
                            selectedTLCProgram={selectedTLCProgram}
                            assessmentData={assessmentData}
                            editState={editState}
                            handleAllCheck={handleAllCheck}
                            allCheck={allFilterAssessmentCheck}
                            handleResetFilter={handleResetFilter}
                            filterAssessmentData={filterAssessmentData}
                            count={filterAssessmentData?.length || 0}
                            $type='filteringData'
                        />
                        <AssessmentList
                            status={status}
                            handleCheck={handleCheck}
                            selectedAssessment={selectedAssessment}
                            selectedTLCProgram={selectedTLCProgram}
                            assessmentData={otherAssessmentData}
                            editState={editState}
                            handleAllCheck={handleAllCheck}
                            allCheck={allOtherAssessmentCheck}
                            handleResetFilter={handleResetFilter}
                            filterAssessmentData={otherAssessmentData}
                            count={otherAssessmentData?.length || 0}
                            $type='others'
                        />
                    </>
                )}
            </ListContainer>
        </ListLayout>
    );
};

export default ViewDetailDiagnosisList;

const ListLayout = styled.section`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-top: 28px;
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 28px;
`;
