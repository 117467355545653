import React from 'react';

const Print = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.6 4.6V8H17.4V4.6H6.6ZM5 4V8H4C2.89543 8 2 8.89543 2 10V16C2 16.5523 2.44772 17 3 17H6V20C6 20.5523 6.44771 21 7 21H17C17.5523 21 18 20.5523 18 20V17H21C21.5523 17 22 16.5523 22 16V10C22 8.89543 21.1046 8 20 8H19V4C19 3.44772 18.5523 3 18 3H6C5.44772 3 5 3.44771 5 4ZM6 14.5V15.4H3.6V10C3.6 9.77909 3.77909 9.6 4 9.6H6H18H20C20.2209 9.6 20.4 9.77909 20.4 10V15.4H18V14.5C18 13.9477 17.5523 13.5 17 13.5H7C6.44772 13.5 6 13.9477 6 14.5ZM7.6 15.1V19.4H16.4V15.1H7.6ZM18.5 12.5C19.0523 12.5 19.5 12.0523 19.5 11.5C19.5 10.9477 19.0523 10.5 18.5 10.5C17.9477 10.5 17.5 10.9477 17.5 11.5C17.5 12.0523 17.9477 12.5 18.5 12.5Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default Print;
