import React from 'react';

const Noti_Change = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '32'} height={$height || '32'} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_627_69764)'>
                <circle cx='16' cy='16' r='16' fill='#5ED30A' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.49376 14.6833C9.90664 14.6105 10.2296 14.2974 10.3761 13.9045C11.2261 11.6241 13.4234 10 16 10C17.8717 10 19.5432 10.857 20.6435 12.2H19.0001C18.4478 12.2 18.0001 12.6477 18.0001 13.2C18.0001 13.7523 18.4478 14.2 19.0001 14.2H23.0001C23.5523 14.2 24.0001 13.7523 24.0001 13.2V9.2C24.0001 8.64772 23.5523 8.2 23.0001 8.2C22.4478 8.2 22.0001 8.64772 22.0001 9.2V10.7084C20.5342 9.04754 18.3894 8 16 8C12.445 8 9.43156 10.3188 8.38972 13.5265C8.16864 14.2071 8.78898 14.8075 9.49376 14.6833ZM22.8505 17.3167C22.4377 17.3895 22.1147 17.7026 21.9682 18.0955C21.1182 20.3759 18.9209 22 16.3443 22C14.4726 22 12.8011 21.143 11.7008 19.8H13.3442C13.8965 19.8 14.3442 19.3523 14.3442 18.8C14.3442 18.2477 13.8965 17.8 13.3442 17.8H9.34424C8.79195 17.8 8.34424 18.2477 8.34424 18.8V22.8C8.34424 23.3523 8.79195 23.8 9.34424 23.8C9.89652 23.8 10.3442 23.3523 10.3442 22.8V21.2916C11.8101 22.9525 13.9549 24 16.3443 24C19.8993 24 22.9127 21.6812 23.9546 18.4735C24.1756 17.7929 23.5553 17.1925 22.8505 17.3167Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_627_69764'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Noti_Change;
