import Icon_arrow_down from 'assets/Icons/Arrow/Icon_arrow_down';
import DropdownButton from 'components/Buttons/DropdownButton';
import DiagnosisListSearchFilterDropdown from 'components/Dropdown/DiagnosisListSearchFilterDropdown';
import SearchInput from 'components/Input/SearchInput';
import { diagnosisListPageText, searchFilterList } from 'constants/diagnosisListPageConstants';
import { managingPatientsFilterList } from 'constants/managingPatientsPageConstants';
import { TodayDiagnosisFilterProps } from 'models/Hospital/diagnosisListPageTypes';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { DropdownIconWrapper, ResetIconWrapper } from 'styles/Input/inputStyle';
import Icon_refresh from 'assets/Icons/Common/Icon_refresh';
import Icon_arrow_up from 'assets/Icons/Arrow/Icon_arrow_up';

const TodayDiagnosisFilter = ({
    diagnosisNumber,
    selectedSearchFilter,
    handleDropdown,
    isSearchFilterDropdown,
    setIsSearchFilterDropdown,
    onChange,
    searchValue,
    handleSearchFilter,
    type,
}: TodayDiagnosisFilterProps) => {
    const dropdownRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isSearchFilterDropdown) {
                setIsSearchFilterDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSearchFilterDropdown]);

    return (
        <>
            {(diagnosisNumber || 0) + diagnosisListPageText.countDiagnosis}
            <FilterButtonWrapper>
                <DropdownButton onClick={setIsSearchFilterDropdown} ref={dropdownRef}>
                    {type ? managingPatientsFilterList[selectedSearchFilter] : searchFilterList[selectedSearchFilter]}
                    <DropdownIconWrapper>
                        {isSearchFilterDropdown ? (
                            <>
                                <Icon_arrow_up />
                                <DiagnosisListSearchFilterDropdown type={type} selectedSearchFilter={selectedSearchFilter} handleDropdown={handleDropdown} />
                            </>
                        ) : (
                            <Icon_arrow_down />
                        )}
                    </DropdownIconWrapper>
                </DropdownButton>
                <SearchInputWrapper>
                    <SearchInput onChange={onChange} searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
                </SearchInputWrapper>
                <ResetIconWrapper onClick={() => window.location.reload()}>
                    <Icon_refresh />
                </ResetIconWrapper>
            </FilterButtonWrapper>
        </>
    );
};

export default TodayDiagnosisFilter;

const FilterButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
`;

const SearchInputWrapper = styled.div`
    position: relative;
    width: 220px;
    height: 100%;
    border-radius: 4px;
`;
