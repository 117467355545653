import { getJamo } from 'pages/SignUp/getJamo';
import { calculateAge } from './calculateAge';
import { changeMobileForm } from './changeMobileForm';
import { DiagnosisPaginationSearchFilterProps } from 'models/Hospital/diagnosisListPageTypes';

const adminDiagnosisPaginationSearchFilter: DiagnosisPaginationSearchFilterProps = (
    searchValue,
    setSortDirections,
    setSortField,
    selectedSearchFilter,
    initData,
    setPage,
    handlePageGroup,
    setFilterData
) => {
    const disassembledName = getJamo(searchValue);
    const filterByHospitalName = (data: { hospitalName: string }) => getJamo(data.hospitalName).includes(disassembledName);
    const filterByName = (data: { patientName: string }) => getJamo(data.patientName).includes(disassembledName);
    const filterByIdentityNumber = (data: { patientIdentityNumber: string }) => {
        const { birthDate } = calculateAge(data.patientIdentityNumber);
        return getJamo(birthDate).includes(disassembledName);
    };
    const filterByMobile = (data: { patientMobile: string }) => changeMobileForm(data.patientMobile).includes(disassembledName);
    const filterByDoctorName = (data: { doctorName: string }) => getJamo(data.doctorName).includes(disassembledName);
    const resetSorting = () => {
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            diagnosisDateTime: true,
        }));
        setSortField('diagnosisDateTime');
    };

    let result;
    switch (selectedSearchFilter) {
        case 0:
            result = initData
                ?.filter((data) => filterByHospitalName(data) || filterByName(data) || filterByIdentityNumber(data) || filterByMobile(data) || filterByDoctorName(data))
                .sort((a, b) => {
                    return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
                });
            resetSorting();
            break;
        case 1:
            result = initData?.filter(filterByHospitalName).sort((a, b) => {
                return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
            });
            resetSorting();
            break;
        case 2:
            result = initData?.filter(filterByName).sort((a, b) => {
                return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
            });
            resetSorting();
            break;
        case 3:
            result = initData?.filter(filterByIdentityNumber).sort((a, b) => {
                return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
            });
            resetSorting();
            break;
        case 4:
            result = initData?.filter(filterByMobile).sort((a, b) => {
                return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
            });
            resetSorting();
            break;
        case 5:
            result = initData?.filter(filterByDoctorName).sort((a, b) => {
                return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
            });
            resetSorting();
            break;
    }
    setPage(1);
    handlePageGroup(0);
    return setFilterData(result);
};

export default adminDiagnosisPaginationSearchFilter;
