import React from 'react';

const Alarm = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 1.58826C10.5453 1.58826 9.34236 2.66584 9.14578 4.0663C6.63666 5.16595 4.88235 7.67112 4.88235 10.5883V14.4955L3.28801 16.6638C3.10091 16.9182 3 17.2258 3 17.5417C3 18.3601 3.66347 19.0236 4.4819 19.0236H8.2581C8.60891 20.8743 10.0567 22.4118 11.9998 22.4118C13.9429 22.4118 15.3907 20.8743 15.7415 19.0236H19.5181C20.3365 19.0236 21 18.3601 21 17.5417C21 17.2258 20.8991 16.9182 20.712 16.6638L19.1176 14.4955V10.5883C19.1176 7.67112 17.3633 5.16595 14.8542 4.0663C14.6576 2.66584 13.4547 1.58826 12 1.58826ZM11.1176 4.47061C11.1176 3.9833 11.5127 3.58826 12 3.58826C12.4873 3.58826 12.8824 3.9833 12.8824 4.47061V5.49351L13.5749 5.71725C15.6319 6.38173 17.1176 8.31279 17.1176 10.5883V15.1516L18.4941 17.0236H5.50594L6.88235 15.1516V10.5883C6.88235 8.31279 8.36811 6.38173 10.4251 5.71725L11.1176 5.49351V4.47061ZM11.9998 20.4118C11.3239 20.4118 10.6074 19.8946 10.3138 19.0236H13.6858C13.3922 19.8946 12.6757 20.4118 11.9998 20.4118Z'
                fill='#333438'
            />
        </svg>
    );
};

export default Alarm;
