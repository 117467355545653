import { getJamo } from 'pages/SignUp/getJamo';
import { ManagingPatientsPaginationSearchFilterProps } from 'models/Hospital/managingPatientsPageTypes';
import { calculateAge } from './calculateAge';
import { changeMobileForm } from './changeMobileForm';

const managingPatientsPaginationSearchFilter: ManagingPatientsPaginationSearchFilterProps = (
    searchValue,
    setSortDirections,
    setSortField,
    selectedSearchFilter,
    initData,
    setPage,
    handlePageGroup,
    setFilterData
) => {
    const disassembledName = getJamo(searchValue);
    const filterByName = (data: { name: string }) => getJamo(data.name).includes(disassembledName);
    const filterByIdentityNumber = (data: { identityNumber: string }) => {
        const { birthDate } = calculateAge(data.identityNumber);
        return getJamo(birthDate).includes(disassembledName);
    };
    const filterByMobile = (data: { mobile: string }) => changeMobileForm(data.mobile).includes(disassembledName);
    const resetSorting = () => {
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        }));
        setSortField('regNum');
    };

    let result;
    switch (selectedSearchFilter) {
        case 0:
            result = initData
                ?.filter((data) => filterByName(data) || filterByIdentityNumber(data) || filterByMobile(data))
                .sort((a, b) => {
                    return b._id.localeCompare(a._id);
                });
            resetSorting();
            break;
        case 1:
            result = initData?.filter(filterByName).sort((a, b) => {
                return b.name.localeCompare(a.name);
            });
            resetSorting();
            break;
        case 2:
            result = initData?.filter(filterByIdentityNumber).sort((a, b) => {
                return b.identityNumber.localeCompare(a.identityNumber);
            });
            resetSorting();
            break;
        case 3:
            result = initData?.filter(filterByMobile).sort((a, b) => {
                return b.mobile.localeCompare(a.mobile);
            });
            resetSorting();
            break;
        // case 4:
        //     result = initData?.filter(filterByDoctorName).sort((a, b) => {
        //         return b.registrationNumber.localeCompare(a.registrationNumber);
        //     });
        //     resetSorting();
        //     break;
    }
    setPage(1);
    handlePageGroup(0);
    return setFilterData(result || []);
};

export default managingPatientsPaginationSearchFilter;
