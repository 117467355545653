import { authApi } from 'api/Auth/auth';
import { accountSettingApi } from 'api/Hospital/accountSettingApi';
import BasicButton from 'components/Buttons/BasicButton';
import { HospitalNameWithInput } from 'components/Input/AccountSettingInput';
import { BasicInputWithLabel, LabelWithButton, WithOutSubLabelInputWithLabel } from 'components/Input/BasicInput';
import AccountSettingPageCompleteSendLinkModal from 'components/Modal/AccountSettingPageCompleteSendLinkModal';
import LeaveModal from 'components/Modal/LeaveModal';
import { accountSettingPageText } from 'constants/accountSettingPageConstants';
import { validateCheck } from 'functions/validationFunctions';
import useToggle from 'hooks/CustomHooks/useToggle';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { useLoginStore } from 'store/useLoginDataStore';
import { ButtonContainer } from 'styles/Global/buttonStyles';
import { ExplainText, ExplainTextWrapper, InputContainer, PageLabel, PageLabelAndTitleWrapper, PageTitle } from 'styles/Global/commonStyles';
import { font } from 'styles/Global/globalStyles';
import { CounselingPageLayout, CounslingInnerLayout } from 'styles/Global/hospitalStyles';

const AccountSettingPage = () => {
    const navigate = useNavigate();

    const { loginData, setLoginData } = useLoginStore();
    const { movePageState, setMovePageState, movePath, clickType, setInit, isLeaveModal, setIsLeaveModal } = useLeaveModalStateStore();

    // rendering 시 환자 정보, 검사 목록

    const [isReSendLink, setIsReSendLink] = useState(false);
    const [name, setName] = useState(loginData.name);
    const [mobileNumber, setMobileNumber] = useState(loginData.mobile);
    const [nameErr, setNameErr] = useState('');
    const [mobileNumberErr, setMobileNumberErr] = useState('');

    const [isCompleteSendLink, setIsCompleteSendLink] = useToggle();

    useEffect(() => {
        if (loginData.name === name && loginData.mobile === mobileNumber) {
            setMovePageState(false);
        } else {
            setMovePageState(true);
        }
    }, [name, mobileNumber]);

    // 계정 정보 변경 Api call
    const clickChangeAccountInfo = async () => {
        if (nameErr || mobileNumberErr) {
            return;
        } else {
            try {
                const rqData = { _id: loginData.id, name: name, picture: '', mobile: mobileNumber, hospitalId: loginData.hospitalId };
                const changeAccountInfoApiResponse = await accountSettingApi.updateAccount(rqData, loginData.token);
                console.log(changeAccountInfoApiResponse, 'changeAccountInfoApiResponse');
                if (changeAccountInfoApiResponse.status === 'ok') {
                    setLoginData({ ...loginData, name: changeAccountInfoApiResponse.data.name, mobile: changeAccountInfoApiResponse.data.mobile });
                    setMovePageState(false);
                } else {
                    console.log('AccountSettingPage 계정 정보 변경 error');
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    // 이메일로 비밀번호 재설정 링크 전송 버튼 클릭
    const clickSendLinkToEmailButton = async () => {
        try {
            const resetPasswordLinkToEmailApiResponse = await authApi.resetPasswordLink(loginData.email);
            if (resetPasswordLinkToEmailApiResponse.status === 'ok') {
                setIsCompleteSendLink();
            } else {
                console.log('AccountSettingPage 비밀번호 재설정 error');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // 비밀번호 재설정 링크 전송 완료 버튼 클릭
    const clickConfirmButtonInModal = () => {
        setIsCompleteSendLink();
        setIsReSendLink(true);
    };

    // name change
    const handleName = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const errMsg = validateCheck.name(newValue);

        setName(newValue);
        setNameErr(errMsg || '');
    };

    // mobile number change
    const handleMobileNumber = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (id === 'error') return;

        const newValue = e.target.value.replace(/[^0-9]/g, '');
        const errMsg = validateCheck.phoneNumber(newValue);

        setMobileNumber(newValue);
        setMobileNumberErr(errMsg || '');
    };

    // page 나가기 버튼
    const handleLeaveModal = () => {
        if (nameErr.length === 0 && mobileNumberErr.length === 0 && !movePageState) {
            return navigate(-1);
        } else {
            setIsLeaveModal();
        }
    };

    // modal 나가기 버튼
    const clickLeaveButtonInLeaveModal = () => {
        if (clickType === 'sideBar') {
            setInit();
            return navigate(movePath);
        } else {
            navigate(-1);
            return setIsLeaveModal();
        }
    };

    return (
        <CounselingPageLayout>
            {isLeaveModal && (
                <LeaveModal
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButtonInLeaveModal}
                    title={accountSettingPageText.cancelModalTitle}
                    content={accountSettingPageText.cancelModalContent}
                    firstButton='머무르기'
                    secondButton='나가기'
                />
            )}
            {isCompleteSendLink && <AccountSettingPageCompleteSendLinkModal email={loginData.email} onClick={clickConfirmButtonInModal} />}
            <CounslingInnerLayout>
                <PageLabelAndTitleWrapper>
                    <PageLabel>계정 설정</PageLabel>
                    <PageTitle $marginbottom='16px'>계정 설정</PageTitle>
                    <ExplainTextWrapper>
                        <ExplainText>계정 설정에서는 비밀번호를 재설정하거나</ExplainText>
                        <ExplainText>이름과 연락처를 변경할 수 있습니다.</ExplainText>
                    </ExplainTextWrapper>
                </PageLabelAndTitleWrapper>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        clickChangeAccountInfo();
                    }}
                >
                    <InputContainer>
                        <WithOutSubLabelInputWithLabel $labelfontWeight={font.weight.medium} id='email' label='이메일 아이디' value={loginData.email} disabled={true} />
                        <LabelWithButton
                            label='비밀번호 재설정'
                            desc='아래 버튼을 클릭하면, 비밀번호 재설정 링크를 이메일로 전달드립니다.'
                            buttonText={!isReSendLink ? '이메일로 링크 보내기' : '이메일로 링크 다시 보내기'}
                            id='resetPassword'
                            onClick={clickSendLinkToEmailButton}
                            $type='outLine'
                            buttonType='button'
                        />
                        <HospitalNameWithInput id='hospitalName' label='병원명' value={loginData.hospitalName} disabled={true} />
                        <WithOutSubLabelInputWithLabel $labelfontWeight={font.weight.medium} id='name' label='이름' value={name} onChange={handleName} errorMsg={nameErr} />
                        <BasicInputWithLabel id='mobile' label='연락처' desc='연락처 혹은 병원 전화번호입니다.' value={mobileNumber} onChange={handleMobileNumber} errorMsg={mobileNumberErr} />
                    </InputContainer>
                    <ButtonContainer $margintop='12px'>
                        <BasicButton $width='215px' $type='anotherFill' buttonType='button' onClick={handleLeaveModal}>
                            나가기
                        </BasicButton>
                        <BasicButton
                            $width='215px'
                            $type='fill'
                            disabled={nameErr.length !== 0 || mobileNumberErr.length !== 0 || (loginData.name === name && loginData.mobile === mobileNumber)}
                            // onClick={clickChangeAccountInfo}
                            buttonType='submit'
                        >
                            변경하기
                        </BasicButton>
                    </ButtonContainer>
                </form>
            </CounslingInnerLayout>
        </CounselingPageLayout>
    );
};

export default AccountSettingPage;
