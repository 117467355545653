import { DropdownButtonType } from 'models/Common/buttonTypes';
import React, { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body5_Medium_button } from 'styles/Global/typography';

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonType>(({ children, onClick, disabled, $err, $width }, ref) => {
    return (
        <Button ref={ref} onClick={onClick} disabled={disabled} $err={$err} $width={$width}>
            {children}
        </Button>
    );
});

export default DropdownButton;

const Button = styled(Body5_Medium_button)<DropdownButtonType>`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    min-width: ${(props) => props.$width || '100px'};
    padding: 0 12px;
    height: 40px;
    gap: 4px;
    border: ${(props) => (props.$err ? `${Border.border_1} solid ${WarningColor.color_warning_normal}` : `${Border.border_1} solid ${BorderColor.color_border_normal}`)};
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_white};
    color: ${TextColor.color_text_normal};
    &:hover {
        border: ${(props) => (props.$err ? `${Border.border_1} solid ${WarningColor.color_warning_normal_hover}` : `${Border.border_1} solid ${BorderColor.color_border_normal_hover}`)};
    }
    &:focus {
        border: ${(props) => (props.$err ? `${Border.border_1} solid ${WarningColor.color_warning_normal_hover}` : `${Border.border_1} solid ${BorderColor.color_border_normal_press}`)};
    }
    &:disabled {
        border: ${Border.border_1} solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
    }
`;
