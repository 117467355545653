import React from 'react';

const ArrowLineUp = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.8 18C12.8 18.4418 12.4418 18.8 12 18.8C11.5581 18.8 11.2 18.4418 11.2 18L11.2 7.9011L8.05925 10.972C7.74334 11.2809 7.23684 11.2752 6.92795 10.9593C6.61906 10.6434 6.62475 10.1369 6.94066 9.82801L11.4407 5.42801C11.7516 5.12401 12.2483 5.12401 12.5592 5.42801L17.0592 9.82801C17.3752 10.1369 17.3808 10.6434 17.072 10.9593C16.7631 11.2752 16.2566 11.2809 15.9407 10.972L12.8 7.9011L12.8 18Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default ArrowLineUp;
