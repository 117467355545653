export const pagePaths = {
    login: '/',
    home: '/home',
    signUp: '/sign-up',
    addDiagnosis: '/counseling/add-diagnosis',
    diagnosisList: '/counseling/diagnosis-list',
    viewDetaildiagnosis: '/counseling/detail-diagnosis',
    todayDiagnosis: '/counseling/today-diagnosis',
    managingPatients: '/counseling/managing-patients',
    viewDetailPatient: '/counseling/detail-patient',
    accountSetting: '/counseling/account-info',
    hospitalSetting: '/counseling/hospital-setting',
    adminDiagnosisList: '/admin/diagnosis-list',
    calculate: '/admin/calculate',
};

export const tabletPaths = {
    managerLogin: '/tablet/manager-login',
    login: '/tablet/login',
    information: '/tablet/information',
    startExamination: '/tablet/start-examination',
    userSurvey: '/tablet/userSurvey',
};

export const governmentPaths = {
    agree: '/government/agree',
    ready: '/government/ready',
    codeConfirm: '/government/code-confirm',
    selectAssessment: '/government/select-assessment/',
};

export const apiPaths = {
    login: `${process.env.REACT_APP_API_NODE}/auth/signin`,
    signUp: `${process.env.REACT_APP_API_NODE}/auth/oauth`,
    updateFcmToken: `${process.env.REACT_APP_API_NODE}/auth/updateFcmToken`,
    resetPasswordLink: `${process.env.REACT_APP_API_NODE}/user/sendPasswordResetLink/`,
    existUser: `${process.env.REACT_APP_API_NODE}/user/existUser/`,
    updateAccountInfo: `${process.env.REACT_APP_API_NODE}/user/update`,
    GetReadByHospitalId: `${process.env.REACT_APP_API_NODE}/user/readByHospitalId/`,
    deleteUser: `${process.env.REACT_APP_API_NODE}/user/deleteUser/`,
    changeUserLevel: `${process.env.REACT_APP_API_NODE}/user/changeLevel`,
    sendPushNotification: `${process.env.REACT_APP_API_NODE}/diagnosis/sendPushNotification`,
    addDiagnosis: `${process.env.REACT_APP_API_NODE}/diagnosis/create`,
    getDiagnosisList: `${process.env.REACT_APP_API_NODE}/diagnosis/getList/`,
    getPatientDiagnosisList: `${process.env.REACT_APP_API_NODE}/diagnosis/getListByPatientId/`,
    assignAssessment: `${process.env.REACT_APP_API_NODE}/diagnosis/assignAssessment`,
    changeDiagnosisStatus: `${process.env.REACT_APP_API_NODE}/diagnosis/changeStatus`,
    updateDiagnosisInfo: `${process.env.REACT_APP_API_NODE}/diagnosis/update`,
    getDiagnosis: `${process.env.REACT_APP_API_NODE}/diagnosis/findById/`,
    compactList: `${process.env.REACT_APP_API_NODE}/hospital/getCompactList`,
    addDoctor: `${process.env.REACT_APP_API_NODE}/hospital/addDoctor`,
    deleteDoctor: `${process.env.REACT_APP_API_NODE}/hospital/removeDoctor`,
    getHospitalData: `${process.env.REACT_APP_API_NODE}/hospital/findById/`,
    getPatientInfo: `${process.env.REACT_APP_API_NODE}/patient/findById/`,
    updatePatient: `${process.env.REACT_APP_API_NODE}/patient/update`,
    getPatientList: `${process.env.REACT_APP_API_NODE}/patient/getList/`,
    patientChangeStatus: `${process.env.REACT_APP_API_NODE}/patient/changeStatus`,
    deletePatient: `${process.env.REACT_APP_API_NODE}/patient/deletePatient/`,
    getAssessmentList: `${process.env.REACT_APP_API_NODE}/assessment/getList/`,
    clearDiagnosis: `${process.env.REACT_APP_API_NODE}/diagnosis/clearDiagnosis/`,
    patientFind: `${process.env.REACT_APP_API_NODE}/patient/find`,
    getResultDiagnosis: `${process.env.REACT_APP_API_NODE}/assessment/getResultByDiagnosisId/`,
    findByCode: `${process.env.REACT_APP_API_NODE}/assessment/findByCode/`,
    paymentList: `${process.env.REACT_APP_API_NODE}/payment/getList/`,

    //태블릿
    getDiagnosisListData: `${process.env.REACT_APP_API_NODE}/diagnosis/findByDate`,
    getAssessmentData: `${process.env.REACT_APP_API_NODE}/assessment/findById`,
    getInpsytResult: `${process.env.REACT_APP_API_NODE}/assessment/getInpsytResult`,
    changeAssessmentStatus: `${process.env.REACT_APP_API_NODE}/assessment/changeStatus`,
    postSendPushNotification: `${process.env.REACT_APP_API_NODE}/notification/sendPushNotification`,
};
