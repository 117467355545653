export const Common = {
    color_white: '#ffffff',
    color_black: '#000000',
};

export const Neutral = {
    color_neutral_50: '#F9F9F9',
    color_neutral_100: '#f5f5f5',
    color_neutral_150: '#EEEEEE',
    color_neutral_200: '#E5E5E5',
    color_neutral_300: '#DBDBDB',
    color_neutral_400: '#CCCCCC',
    color_neutral_500: '#AAAAAA',
    color_neutral_600: '#999999',
    color_neutral_700: '#666666',
    color_neutral_800: '#333333',
    color_neutral_900: '#101010',
};

export const Cool_Neutral = {
    color_coolNeutral_30: '#FAFAFB',
    color_coolNeutral_50: '#F7F7F8',
    color_coolNeutral_100: '#F4F4F5',
    color_coolNeutral_150: '#ECEDEF',
    color_coolNeutral_200: '#E7E9EB',
    color_coolNeutral_300: '#DBDCDF',
    color_coolNeutral_400: '#C2C4C8',
    color_coolNeutral_500: '#AEB0B6',
    color_coolNeutral_600: '#989BA2',
    color_coolNeutral_650: '#7A7E89',
    color_coolNeutral_700: '#5A5C63',
    color_coolNeutral_800: '#333438',
    color_coolNeutral_900: '#1B1C1E',
};

export const Indigo = {
    color_indigo_50: '#F6FAFF',
    color_indigo_70: '#F0F6FF',
    color_indigo_100: '#E8F1FE',
    color_indigo_150: '#E0ECFF',
    color_indigo_200: '#D0E2FE',
    color_indigo_250: '#C0D8FD',
    color_indigo_300: '#A2C2F5',
    color_indigo_400: '#92B7F1',
    color_indigo_450: '#799CDE',
    color_indigo_500: '#6287D0',
    color_indigo_600: '#406CC5',
    color_indigo_700: '#2B55AE',
    color_indigo_750: '#264C97',
    color_indigo_800: '#20438B',
    color_indigo_900: '#1C3972',
    color_indigo_950: '#142342',
};

export const Cyan = {
    color_cyan_50: '#E5F9FB',
    color_cyan_80: '#E0F9FC',
    color_cyan_100: '#CBF5FB',
    color_cyan_200: '#9DF2FD',
    color_cyan_300: '#6FE8FA',
    color_cyan_400: '#0ED8F3',
    color_cyan_500: '#00C0DA',
    color_cyan_600: '#00B2CA',
    color_cyan_700: '#01A0B5',
    color_cyan_800: '#00899B',
    color_cyan_900: '#03626F',
    color_cyan_950: '#003C44',
};

export const Lime = {
    color_lime_50: '#F9F9F9',
    color_lime_80: '#EBFDDC',
    color_lime_100: '#E0FFC8',
    color_lime_200: '#C4FE99',
    color_lime_300: '#97F354',
    color_lime_400: '#6BE016',
    color_lime_500: '#5ED30A',
    color_lime_600: '#4EBA00',
    color_lime_700: '#409900',
    color_lime_800: '#317600',
    color_lime_900: '#225200',
    color_lime_950: '#153300',
};

export const Red = {
    color_red_50: '#FFFAFA',
    color_red_80: '#FEECEC',
    color_red_100: '#FFDEDF',
    color_red_200: '#FFB3B7',
    color_red_300: '#FF8A91',
    color_red_400: '#FF5F69',
    color_red_500: '#FF3A46',
    color_red_600: '#F92431',
    color_red_650: '#F20C1A',
    color_red_700: '#E7000E',
    color_red_750: '#D5000D',
    color_red_800: '#BE000B',
    color_red_900: '#790108',
    color_red_950: '#380003',
};

export const Yellow = {
    color_yellow_50: '#FEFAEC',
    color_yellow_100: '#FFF7DB',
    color_yellow_200: '#FFF3CA',
    color_yellow_300: '#FFEA9F',
    color_yellow_400: '#FFE072',
    color_yellow_500: '#FFD74B',
    color_yellow_600: '#FFCF25',
    color_yellow_700: '#F4C10B',
    color_yellow_800: '#E2A301',
    color_yellow_900: '#BB8509',
    color_yellow_920: '#8E6300',
    color_yellow_950: '#413000',
};
