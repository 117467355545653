import React, { useEffect } from 'react';
import styled from 'styled-components';
import { font, palette, zIndex } from 'styles/Global/globalStyles';
import { useLoginStore } from 'store/useLoginDataStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import TodayAlarmDropdown from 'components/Dropdown/TodayAlarmDropdown';
import { useAlarmListStore, useGlobalToastStore } from 'store/useToastStore';
import UnConfirmAlarmIcon from 'assets/Icons/Header/UnConfirmAlarmIcon';
import { GlobalToast } from 'components/Toast/ToastMessage';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import LeaveModal from 'components/Modal/LeaveModal';
import moment from 'moment';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import Logout from 'assets/Icons/Header/Logout';
import Alarm from 'assets/Icons/Alarm/Alarm';
import AlarmFill from 'assets/Icons/Alarm/AlarmFill';
import SettingUser from 'assets/Icons/Header/SettingUser';
import { logoutModalContants } from 'constants/headerConstants';
import { commonText } from 'constants/commonConstants';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';

const Header = () => {
    const { loginData, clearLoginData } = useLoginStore();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [isLogoutModal, setIsLogoutModal] = useToggle();
    const [isAlarmDropdown, setIsAlarmDropdown] = useToggle();

    const { unConfirmedAlarmList, removeOldAlarms, resetUnConfirmedAlarmList } = useAlarmListStore();
    const { setToast } = useGlobalToastStore();
    const { editState, movePageState, setIsLeaveModal, setClickType, setMovePath } = useLeaveModalStateStore();

    // AlarmDropdown 상태에 따른 미확인 알람 리스트 초기화
    useEffect(() => {
        isAlarmDropdown && resetUnConfirmedAlarmList();
    }, [isAlarmDropdown]);

    // 첫 렌더링 시 오늘 날짜 기준 이전 알람 삭제
    useEffect(() => {
        removeOldAlarms();

        const handleMessage = (event: MessageEvent) => {
            if (event.data && event.data.command === 'setToast') {
                const payload = event.data;

                let toastData = {};
                console.log('Foreground Message background:', payload, messaging);
                if (
                    payload.data &&
                    (payload.data.code === '100' ||
                        payload.data.code === '101' ||
                        payload.data.code === '102' ||
                        payload.data.code === '200' ||
                        payload.data.code === '201' ||
                        payload.data.code === '202' ||
                        payload.data.code === '203' ||
                        payload.data.code === '204')
                ) {
                    toastData = {
                        addTime: moment().format('A h:mm'),
                        diagnosisTime: formattedTime(payload.data.diagnosisDateTime),
                        patientGender: payload.data.patientGender,
                        patientIdentityNumber: calculateAge(payload.data.patientIdentityNumber).birthDate,
                        patientName: payload.data.patientName,
                        regNum: payload.data.regNum,
                        code: payload.data.code,
                    };
                } else if (payload.data && (payload.data.code === '400' || payload.data.code === '401')) {
                    toastData = { doctorName: payload.data.doctorName, addTime: moment().format('A h:mm'), code: payload.data.code };
                } else if (payload.data && (payload.data.code === '500' || payload.data.code === '501')) {
                    toastData = {
                        regNum: payload.data.regNum,
                        patientName: payload.data.patientName,
                        patientGender: payload.data.patientGender,
                        patientIdentityNumber: calculateAge(payload.data.patientIdentityNumber).birthDate,
                        addTime: moment().format('A h:mm'),
                        code: payload.data.code,
                    };
                } else if (payload.data && (payload.data.code === '601' || payload.data.code === '602' || payload.data.code === '603')) {
                    toastData = { userName: payload.data.userName, userLevel: payload.data.userLevel, addTime: moment().format('A h:mm'), code: payload.data.code };
                } else if (payload.data && payload.data.code === '300') {
                    toastData = {
                        addTime: moment().format('A h:mm'),
                        code: payload.data.code,
                    };
                }
                setToast(payload.title, payload.body, toastData);
            }
        };

        navigator.serviceWorker.addEventListener('message', handleMessage);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            navigator.serviceWorker.removeEventListener('message', handleMessage);
        };
    }, [messaging]);

    onMessage(messaging, (payload: any) => {
        console.log('Foreground Message:', payload, messaging);
        let toastData = {};

        if (
            payload.data &&
            (payload.data.code === '100' ||
                payload.data.code === '101' ||
                payload.data.code === '102' ||
                payload.data.code === '200' ||
                payload.data.code === '201' ||
                payload.data.code === '202' ||
                payload.data.code === '203' ||
                payload.data.code === '204')
        ) {
            toastData = {
                addTime: moment().format('A h:mm'),
                diagnosisTime: formattedTime(payload.data.diagnosisDateTime),
                patientGender: payload.data.patientGender,
                patientIdentityNumber: calculateAge(payload.data.patientIdentityNumber).birthDate,
                patientName: payload.data.patientName,
                regNum: payload.data.regNum,
                code: payload.data.code,
            };
        } else if (payload.data && (payload.data.code === '400' || payload.data.code === '401')) {
            toastData = { doctorName: payload.data.doctorName, addTime: moment().format('A h:mm'), code: payload.data.code };
        } else if (payload.data && (payload.data.code === '500' || payload.data.code === '501')) {
            toastData = {
                regNum: payload.data.regNum,
                patientName: payload.data.patientName,
                patientGender: payload.data.patientGender,
                patientIdentityNumber: calculateAge(payload.data.patientIdentityNumber).birthDate,
                addTime: moment().format('A h:mm'),
                code: payload.data.code,
            };
        } else if (payload.data && (payload.data.code === '601' || payload.data.code === '602' || payload.data.code === '603')) {
            toastData = { userName: payload.data.userName, userLevel: payload.data.userLevel, addTime: moment().format('A h:mm'), code: payload.data.code };
        } else if (payload.data && payload.data.code === '300') {
            toastData = {
                addTime: moment().format('A h:mm'),
                code: payload.data.code,
            };
        }
        setToast(payload.notification.title, payload.notification.body, toastData);

        // Handle the notification or update your UI
    });

    const logout = () => {
        localStorage.clear();
        clearLoginData();
        navigate(pagePaths.login, { replace: true });
    };

    const clickAccountSetting = () => {
        if ((pathname === pagePaths.viewDetaildiagnosis && editState) || (pathname === pagePaths.addDiagnosis && movePageState)) {
            setClickType('header');
            setMovePath(pagePaths.accountSetting);
            return setIsLeaveModal();
        } else {
            navigate(pagePaths.accountSetting);
        }
    };

    return (
        <Layout>
            {isLogoutModal && (
                <LeaveModal
                    title={logoutModalContants.title}
                    content={logoutModalContants.content}
                    setIsLeaveModal={setIsLogoutModal}
                    clickLeaveButtonInLeaveModal={logout}
                    firstButton={commonText.cancel}
                    secondButton={commonText.logout}
                />
            )}
            <HeaderContainer>
                <GlobalToast />
                {isAlarmDropdown && <TodayAlarmDropdown setIsAlarmDropdown={setIsAlarmDropdown} />}
                <HospitalAndNameWrapper>
                    <Text>{loginData.hospitalName}</Text>
                    <Divide />
                    <Text>
                        {loginData.name}
                        <TextWeight>님</TextWeight>
                    </Text>
                </HospitalAndNameWrapper>
                <IconsContainer>
                    <IconWrapper onClick={setIsLogoutModal}>
                        <Logout />
                    </IconWrapper>
                    <IconWrapper onClick={setIsAlarmDropdown}>{unConfirmedAlarmList.length !== 0 ? <UnConfirmAlarmIcon /> : !isAlarmDropdown ? <Alarm /> : <AlarmFill />}</IconWrapper>
                    {/* <IconWrapper onClick={() => navigate(pagePaths.accountSetting)}> */}
                    <IconWrapper onClick={clickAccountSetting}>
                        <SettingUser />
                    </IconWrapper>
                </IconsContainer>
            </HeaderContainer>
        </Layout>
    );
};

export default Header;

const Layout = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: right;
    height: 60px;
    width: calc(100vw - 332px);
    min-width: 950px; //1280px - 330px
    padding: 0 20px;
    background-color: ${palette.white};
    z-index: ${zIndex.basic};
`;

const HeaderContainer = styled.div`
    position: relative;
    display: flex;
    gap: 8px;
`;

const HospitalAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Text = styled.span`
    display: flex;
    height: 19px;
    align-items: end;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.semiBold};
`;

const TextWeight = styled.span`
    font-weight: ${font.weight.regular};
`;

const IconsContainer = styled.div`
    display: flex;
    gap: 2px;
`;

const IconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
`;

const Divide = styled.div`
    width: 1px;
    height: 12px;
    background-color: ${palette.black};
    opacity: 0.3;
`;
