import { InfoContainerProps } from 'models/Common/toastMessegeTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Radius } from 'styles/Global/objectStyles';
import { Body3_SemiBold, Body4_SemiBold, Body5_Regular, Body5_SemiBold, Caption1_Medium, Caption1_Regular } from 'styles/Global/typography';

export const GlobalToastTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 44px);
    height: 100%;
`;

export const Comment = styled(Body5_Regular)`
    margin-bottom: 8px;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 8px 10px;
    width: 100%;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_8};
    margin-bottom: 8px;
`;

export const DoctoInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.semiBold};
    gap: 6px;
`;

export const MainText = styled(Body5_SemiBold)`
    color: ${TextColor.color_text_normal};
    margin-right: -4px;
`;

export const UserInfoWrapper = styled(Body5_SemiBold)`
    display: flex;
    color: ${TextColor.color_text_normal};
    gap: 2px;
`;

export const SubText = styled(Caption1_Regular)`
    color: ${TextColor.color_text_light};
`;

export const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.semiBold};
    gap: 6px;
`;

export const RegNumWrapper = styled.div<InfoContainerProps>`
    display: flex;
    justify-content: center;
    width: ${(props) => props.$width || '45px'};
    padding: 0 6px;
    height: ${(props) => props.$height || '22px'};
    line-height: ${(props) => props.$height || '22px'};
    border-radius: 4px;
    border: 1px solid ${palette.gray};
    font-size: ${font.size[13]};
    color: ${palette.gray};
    margin-right: 4px;
`;

export const DiagnosisTimeWrapper = styled(Caption1_Medium)`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 8px;
    background-color: ${BackgroundColor.color_background_primary_neutral};
    border-radius: ${Radius.radius_4};
    color: ${TextColor.color_text_light};
`;

export const Date = styled(Caption1_Regular)`
    color: ${TextColor.color_text_weak};
`;

export const Title = styled(Body3_SemiBold)`
    height: 24px;
    margin-bottom: 4px;
`;

export const TodayAlarmTitle = styled(Body4_SemiBold)`
    color: ${TextColor.color_text_normal};
`;

export const ToastPatientName = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
    // max-width: 90px;
`;
