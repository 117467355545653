import Ico_logo from 'assets/SungnamIcons/Header/Ico_logo';
import React from 'react';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { zIndex } from 'styles/Global/globalStyles';
import { Body3_Medium_Section } from 'styles/Global/typography';

const GovernmentHeader = () => {
    const { headerText } = useGovernmentHeaderStore();

    return <Layout>{headerText || <Ico_logo />}</Layout>;
};

export default GovernmentHeader;

const Layout = styled(Body3_Medium_Section)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndex.floating};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 52px;
    border-bottom: 1px solid ${BorderColor.color_border_weak};
    background-color: ${BackgroundColor.color_background_white};
`;
