import React from 'react';
import { BackgroundColor, IconColor } from 'styles/Global/colorSemantic';

const Noti_Warning = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_755_1371)'>
                <circle cx='16' cy='16' r='16' fill={BackgroundColor.color_background_normal} />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.0513 17.9561L14.5864 9.8975C14.5395 9.08478 15.1859 8.39999 16 8.39999C16.8141 8.39999 17.4605 9.08478 17.4136 9.8975L16.9487 17.9561C16.9213 18.4298 16.5292 18.8 16.0547 18.8H15.9452C15.4708 18.8 15.0786 18.4298 15.0513 17.9561ZM16 23.4C16.8284 23.4 17.5 22.7284 17.5 21.9C17.5 21.0716 16.8284 20.4 16 20.4C15.1716 20.4 14.5 21.0716 14.5 21.9C14.5 22.7284 15.1716 23.4 16 23.4Z'
                    fill={IconColor.color_icon_strong}
                />
            </g>
            <defs>
                <clipPath id='clip0_755_1371'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Noti_Warning;
