import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileBasicInputWithLabel } from 'components/Input/BasicInput';
import { governmentCodeConfirmPageConstants } from 'constants/\bGovernment/governmentCodeConfirmPageConstants';
import { governmentPaths } from 'constants/path';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { AgreeAndCodeConfirmTitle, TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';

const GovernmentCodeConfirmPage = () => {
    const navigate = useNavigate();

    const [code, setCode] = useState('');
    const [codeErr, setCodeErr] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();

    useEffect(() => {
        setHeaderText(governmentCodeConfirmPageConstants.headerText);
        return () => setInit();
    }, []);

    useEffect(() => {
        if (codeErr) {
            setButtonDisabled(true);
        } else if (code.length !== 0 && !codeErr) {
            setButtonDisabled(false);
        }

        // return () => setButtonDisabled(true);
    }, [codeErr, code]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
        setCodeErr('');
    };

    const handleBlurError = () => {
        if (!code) {
            setCodeErr(governmentCodeConfirmPageConstants.errMsg);
        } else {
            setCodeErr('');
        }
    };

    //! 검사코드 입력 후 검사 준비하기로 진입
    const apiCall = () => {
        // setCodeErr(governmentCodeConfirmPageConstants.apiErrText);
        navigate(governmentPaths.ready);
    };

    const enterKey = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            apiCall();
        }
    };

    return (
        <Layout>
            <TitleContainer>
                <AgreeAndCodeConfirmTitle>{governmentCodeConfirmPageConstants.title1}</AgreeAndCodeConfirmTitle>
                <AgreeAndCodeConfirmTitle>{governmentCodeConfirmPageConstants.title2}</AgreeAndCodeConfirmTitle>
            </TitleContainer>
            <MobileBasicInputWithLabel
                label={governmentCodeConfirmPageConstants.label}
                id='code'
                value={code}
                placeholder={governmentCodeConfirmPageConstants.placeholder}
                onChange={handleInputChange}
                onBlur={handleBlurError}
                errorMsg={codeErr}
                enterKey={enterKey}
            />
            <MobileBasicButton onClick={apiCall} $type='fill' disabled={buttonDisabled}>
                {governmentCodeConfirmPageConstants.buttonText}
            </MobileBasicButton>
        </Layout>
    );
};

export default GovernmentCodeConfirmPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 32px;
`;
