import React from 'react';

const Icon_arrow_down = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.37273 7.55469C4.72911 7.20821 5.29891 7.21623 5.64539 7.57262L12.0001 14.1089L18.3548 7.57262C18.7013 7.21623 19.2711 7.20821 19.6275 7.55469C19.9839 7.90118 19.9919 8.47097 19.6454 8.82736L12.6454 16.0274C12.4759 16.2017 12.2432 16.3 12.0001 16.3C11.757 16.3 11.5243 16.2017 11.3548 16.0274L4.3548 8.82736C4.00832 8.47097 4.01634 7.90118 4.37273 7.55469Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default Icon_arrow_down;
