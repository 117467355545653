import React from 'react';

const Page_NextSection = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.50507 7.49496C6.23171 7.2216 6.23171 6.77838 6.50507 6.50501C6.77844 6.23165 7.22166 6.23165 7.49502 6.50501L12.495 11.505C12.7684 11.7784 12.7684 12.2216 12.495 12.495L7.49502 17.495C7.22166 17.7683 6.77844 17.7683 6.50507 17.495C6.23171 17.2216 6.23171 16.7784 6.50507 16.505L11.0101 12L6.50507 7.49496ZM12.5051 7.49496C12.2317 7.2216 12.2317 6.77838 12.5051 6.50501C12.7784 6.23165 13.2217 6.23165 13.495 6.50501L18.495 11.505C18.7684 11.7784 18.7684 12.2216 18.495 12.495L13.495 17.495C13.2217 17.7683 12.7784 17.7683 12.5051 17.495C12.2317 17.2216 12.2317 16.7784 12.5051 16.505L17.0101 12L12.5051 7.49496Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default Page_NextSection;
