import Lnb_HospitalHome from 'assets/Icons/SideBarList/Lnb_HospitalHome';
import { pagePaths } from './path';

import Add from 'assets/Icons/AddIcons/Add';
import Lnb_DiagnosisToday from 'assets/Icons/SideBarList/Lnb_DiagnosisToday';
import Lnb_DiagnosisList from 'assets/Icons/SideBarList/Lnb_DiagnosisList';
import Lnb_PatientList from 'assets/Icons/SideBarList/Lnb_PatientList';
import Lnb_SettingHospital from 'assets/Icons/SideBarList/Lnb_SettingHospital';

export const HospitalSideBarConstants = (currentPath: string) => {
    return [
        { icon: <Add />, name: '진료 추가', path: pagePaths.addDiagnosis },
        { icon: <Lnb_HospitalHome $active={currentPath === pagePaths.home} />, name: '우리병원 홈', path: pagePaths.home },
        { icon: <Lnb_DiagnosisToday $active={currentPath === pagePaths.todayDiagnosis} />, name: '오늘의 진료', path: pagePaths.todayDiagnosis },
        { icon: <Lnb_DiagnosisList $active={currentPath === pagePaths.diagnosisList} />, name: '진료 목록', path: pagePaths.diagnosisList },
        { icon: <Lnb_PatientList $active={currentPath === pagePaths.managingPatients} />, name: '등록 환자 관리', path: pagePaths.managingPatients },
        { icon: <Lnb_SettingHospital $active={currentPath === pagePaths.hospitalSetting} />, name: '병원 설정', path: pagePaths.hospitalSetting },
    ];
};
export const HospitalAdminSideBarConstants = (currentPath: string) => {
    return [
        { icon: <Lnb_DiagnosisList $active={currentPath === pagePaths.adminDiagnosisList} />, name: '진료 목록', path: pagePaths.adminDiagnosisList },
        { icon: <Lnb_PatientList $active={currentPath === pagePaths.calculate} />, name: '정산', path: pagePaths.calculate },
        // { icon: <SettingHospital $active={currentPath === pagePaths.hospitalSetting} />, name: '병원 설정', path: pagePaths.hospitalSetting },
    ];
};

// export const sideBarConstants = ['상담검사', '칼로리 밸런스'];
export const sideBarConstants = ['상담검사'];
