import React from 'react';

const UnConfirmCheck = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 20.6C16.7496 20.6 20.6 16.7496 20.6 12C20.6 7.25035 16.7496 3.4 12 3.4C7.25035 3.4 3.4 7.25035 3.4 12C3.4 16.7496 7.25035 20.6 12 20.6ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.0576 7.99834L11.3675 13.3708C11.3858 13.6866 11.6472 13.9333 11.9635 13.9333H12.0365C12.3528 13.9333 12.6142 13.6866 12.6325 13.3708L12.9424 7.99834C12.9737 7.45653 12.5427 7 12 7C11.4573 7 11.0263 7.45653 11.0576 7.99834ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z'
                fill='#5ED30A'
            />
        </svg>
    );
};

export default UnConfirmCheck;
