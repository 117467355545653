import styled from 'styled-components';
import { Radius } from './objectStyles';
import { BackgroundColor, InverseColor, PrimaryColor, TextColor } from './colorSemantic';
import { font } from './globalStyles';

export const BasicNumberBadge = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_white};
    color: ${PrimaryColor.color_primary_strong};
    font-size: ${font.size[14]};
    font-weight: ${font.weight.bold};
`;
