export const logoutModalContants = {
    title: '지금 로그아웃 하시겠어요?',
    content: '지금 로그아웃 하시면, 로그인 페이지로 이동합니다.',
};

export const todayAlarmDropdownConstants = {
    alarmTitle: '오늘의 알림',
    doctor: '원장님',
    member: '멤버',
    manager: '관리자',
    lastAlarm: '오늘 받은 알림은 여기까지입니다.',
};
