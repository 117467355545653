import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

console.info('+++++++++++++++ firebase config init +++++++++++++++');

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const realtimeDatabase = getDatabase(app);
const messaging = getMessaging(app);
// const analytics = getAnalytics(app);
// const storage = getStorage(app);
// const firestore = getFirestore(app);
// const googleAuthProvider = new GoogleAuthProvider();

const getPermission = async () => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });

        if (permission === 'granted') {
            console.log('Notification permission granted');
            // Get the FCM token
            // console.log(token, 'token');
            return token;
        } else {
            console.log('Notification permission denied');
            return 'Notification permission denied';
        }
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};

export { realtimeDatabase, messaging, getPermission, auth };
