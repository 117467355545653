import { hospitalSettingApi } from 'api/Hospital/hospitalSettingApi';
import { HospitalNameWithInput } from 'components/Input/AccountSettingInput';
import HospitalSettingInput from 'components/Input/HospitalSettingInput';
import DeleteDoctorModal from 'components/Modal/RemoveDoctorModal';
import useToggle from 'hooks/CustomHooks/useToggle';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLoginStore } from 'store/useLoginDataStore';
import styled from 'styled-components';
import { InputContainer, PageLabel, PageLabelAndTitleWrapper, PageTitle } from 'styles/Global/commonStyles';
import { CounselingPageLayout, CounslingInnerLayout } from 'styles/Global/hospitalStyles';
import { SignUpExplainText } from 'styles/SignUp/signUpStyles';
import { GetHospitalDataApiResponse, GetReadByHospitalIdResponse } from 'models/Hospital/hospitalApiTypes';
import RemoveUserModal from 'components/Modal/RemoveUserModal';
import ChangeUserLevelModal from 'components/Modal/ChangeUserLevelModal';
import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import EditHospitalAccount from './EditHospitalAccount';
import { useLoadingStore } from 'store/useLoadingStore';

const HospitalSettingPage = () => {
    const { loginData, setLoginData } = useLoginStore();

    const [doctorName, setDoctorName] = useState('');
    const [doctorList, setDoctorList] = useState<GetHospitalDataApiResponse['data']['doctors']>();
    const [deleteDoctorName, setDeleteDoctorName] = useState('');
    const [deleteUserName, setDeleteUserName] = useState('');
    const [deleteUserEmail, setDeleteUserEmail] = useState('');
    const [deleteUserLevel, setDeleteUserLevel] = useState(0);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [changeUserLevelId, setChangeUserLevelId] = useState('');
    const [changeLevelUserName, setChangeLevelUserName] = useState('');
    const [changeLevelUserEmail, setChangeLevelUserEmail] = useState('');
    const [changeUserLevelValue, setChangeUserLevelValue] = useState(0);
    const [userList, setUserList] = useState<GetReadByHospitalIdResponse['data']>();

    const { loading, setLoading } = useLoadingStore();

    const [isRemoveModal, setIsRemoveModal] = useToggle();
    const [isRemoveUserModal, setIsRemoveUserModal] = useToggle();
    const [isChangeUserLevelModal, setIsChangeUserLevelModal] = useToggle();

    // 계정 리스트 불러오기
    useEffect(() => {
        getReadByHospitalIdApiCall();
        getHospitalDataApiCall();
    }, []);

    // Doctor name input
    const enterDoctorName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDoctorName(e.target.value);
    };

    // Add doctor button
    const clickAddDoctorButton = async () => {
        const rqData = { hospitalId: loginData.hospitalId, name: doctorName, gender: '', departments: [], picture: '' };
        const addDoctorApiResponse = await hospitalSettingApi.AddDoctor(rqData, loginData.token);

        if (addDoctorApiResponse.status === 'ok') {
            setLoginData({ ...loginData, doctors: addDoctorApiResponse.data.doctors });
            setDoctorName('');
            getHospitalDataApiCall();
        } else {
            console.log('HospitalSettingPage 담당의 추가 error');
        }
    };

    // 병원 유저 계정 불러오기
    const getReadByHospitalIdApiCall = async () => {
        let result;
        const readByHospitalIdApiResponse = await hospitalSettingApi.GetReadByHospitalId(loginData.hospitalId, loginData.token);

        if (readByHospitalIdApiResponse.status === 'ok') {
            const index = readByHospitalIdApiResponse.data.findIndex((item) => item._id === loginData.id);
            if (index !== -1) {
                const item = readByHospitalIdApiResponse.data.splice(index, 1)[0];
                readByHospitalIdApiResponse.data.unshift(item);
                result = readByHospitalIdApiResponse.data.filter((item) => item.level === 0 || item.level === 1 || item.level === 100);
            }

            setUserList(result);
        } else {
            console.log('HospitalSettingPage GetReadByHospitalId error');
        }
    };

    const getHospitalDataApiCall = async () => {
        setLoading(true);
        const getHospitalDataApiResponse = await addDiagnosisApi.getHospitalData(loginData.hospitalId, loginData.token);

        if (getHospitalDataApiResponse.status === 'ok') {
            setDoctorList(getHospitalDataApiResponse.data.doctors);
            setLoading(false);
        } else {
            console.log('HospitalSettingPage getHospitalDataApiCall error');
            setLoading(false);
        }
    };

    // Remove doctor button in modal(최종 삭제 함수)
    const clickDeleteDoctorButtonInModal = async () => {
        const rqData = { hospitalId: loginData.hospitalId, name: deleteDoctorName, gender: '', departments: [], picture: '' };
        const deleteDoctorApiResponse = await hospitalSettingApi.DeleteDoctor(rqData, loginData.token);

        if (deleteDoctorApiResponse.status === 'ok') {
            setLoginData({ ...loginData, doctors: deleteDoctorApiResponse.data.doctors });
            setDeleteDoctorName('');
            setIsRemoveModal();
            getHospitalDataApiCall();
        } else {
            console.log('HospitalSettingPage 담당의 삭제 error');
        }
    };

    // Remove User Button In Modal (최종 삭제 함수)
    const clickDeleteUserButtonInModal = async () => {
        const deleteDoctorApiResponse = await hospitalSettingApi.DeleteUser(deleteUserId, loginData.token);

        if (deleteDoctorApiResponse.status === 'ok') {
            clickCancelButtonInRemoveUserModal();
            getReadByHospitalIdApiCall();
        } else {
            console.log('HospitalSettingPage 담당의 삭제 error');
        }
    };

    // ChangeLevel Api Call
    const changeUserLevel = async () => {
        const rqData = { _id: changeUserLevelId, level: changeUserLevelValue === 0 ? 1 : 0 };
        const updateAccountApiResponse = await hospitalSettingApi.UserChangeLevel(rqData, loginData.token);

        if (updateAccountApiResponse.status === 'ok') {
            clickCancelButtonInChangeUserLevelModal();
            getReadByHospitalIdApiCall();
        } else {
            console.log('HospitalSettingPage updateAccount error');
        }
    };

    // remove Doctor button
    const clickDeleteDoctorButton = (name: string) => {
        setDeleteDoctorName(name);
        setIsRemoveModal();
    };

    // remove User button
    const clickDeleteUserButton = (id: string, name: string, email: string, level: number) => {
        setDeleteUserId(id);
        setDeleteUserName(name);
        setDeleteUserEmail(email);
        setDeleteUserLevel(level);
        setIsRemoveUserModal();
    };

    // change User level button
    const clickChangeUserLevelButton = (id: string, name: string, email: string, level: number) => {
        setChangeUserLevelId(id);
        setChangeLevelUserName(name);
        setChangeLevelUserEmail(email);
        setChangeUserLevelValue(level);
        setIsChangeUserLevelModal();
    };

    // cancel button in remove User modal
    const clickCancelButtonInRemoveUserModal = () => {
        setDeleteUserId('');
        setDeleteUserName('');
        setDeleteUserEmail('');
        setDeleteUserLevel(0);
        setIsRemoveUserModal();
    };
    // cancel button in Change User Level Modal
    const clickCancelButtonInChangeUserLevelModal = () => {
        setChangeUserLevelId('');
        setChangeLevelUserName('');
        setChangeLevelUserEmail('');
        setChangeUserLevelValue(0);
        setIsChangeUserLevelModal();
    };

    return (
        <CounselingPageLayout>
            {isRemoveModal && <DeleteDoctorModal toggle={setIsRemoveModal} doctorName={deleteDoctorName} onClick={clickDeleteDoctorButtonInModal} setDeleteDoctorName={setDeleteDoctorName} />}
            {isRemoveUserModal && (
                <RemoveUserModal toggle={clickCancelButtonInRemoveUserModal} userName={deleteUserName} userEmail={deleteUserEmail} onClick={clickDeleteUserButtonInModal} userLevel={deleteUserLevel} />
            )}
            {isChangeUserLevelModal && (
                <ChangeUserLevelModal
                    toggle={clickCancelButtonInChangeUserLevelModal}
                    userName={changeLevelUserName}
                    userEmail={changeLevelUserEmail}
                    onClick={changeUserLevel}
                    userLevel={changeUserLevelValue}
                />
            )}
            {loading || !doctorList ? null : (
                <CounslingInnerLayout>
                    <div>
                        <PageLabelAndTitleWrapper>
                            <PageLabel>병원 설정</PageLabel>
                            <PageTitle $marginbottom='16px'>병원 설정</PageTitle>
                        </PageLabelAndTitleWrapper>
                        <ExplainTextWrapper>
                            <SignUpExplainText>우리 병원 담당의를 관리할 수 있습니다.</SignUpExplainText>
                        </ExplainTextWrapper>
                    </div>
                    <InputContainer>
                        <HospitalNameWithInput id='hospitalName' label='병원명' value={loginData.hospitalName} disabled={true} />
                        <HospitalSettingInput
                            id='doctors'
                            label='담당의'
                            value={doctorName}
                            subLabel={loginData.doctors.length}
                            placeholder='추가할 담당의를 입력해주세요.'
                            onChange={enterDoctorName}
                            doctorList={doctorList}
                            disabled={doctorName.length === 0 ? true : false}
                            onClick={clickAddDoctorButton}
                            onRemoveButtonClick={clickDeleteDoctorButton}
                            level={loginData.level}
                        />
                    </InputContainer>
                    <InputContainer>
                        <EditHospitalAccount
                            id='userControl'
                            label='병원 계정'
                            userList={userList}
                            clickDeleteUserButton={clickDeleteUserButton}
                            clickChangeUserLevelButton={clickChangeUserLevelButton}
                        />
                    </InputContainer>
                </CounslingInnerLayout>
            )}
        </CounselingPageLayout>
    );
};

export default HospitalSettingPage;

export const ExplainTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;
