import React from 'react'

const Company = () => {
  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.772 8.45552C35.6886 8.39831 34.6718 8.66528 33.7975 9.15155C32.5147 9.87619 30.9467 9.86666 29.6733 9.15155C28.8655 8.71296 27.9533 8.45552 26.9744 8.45552C25.1404 8.45552 23.5249 9.35178 22.5176 10.7248C21.7669 11.7545 20.5695 12.3457 19.3056 12.3457C17.9942 12.3457 16.7208 11.7545 15.9416 10.6962C14.8487 9.2183 13.0337 8.30297 11.0286 8.47459C8.32973 8.70342 6.17257 10.925 6.01102 13.6329C5.81146 16.8461 8.34874 19.5158 11.5132 19.5158C13.2998 19.5158 14.8867 18.6672 15.8941 17.3419C16.7113 16.274 17.9562 15.6161 19.3056 15.6161C20.579 15.6161 21.7764 16.2168 22.5271 17.2465C23.5249 18.6195 25.1499 19.5158 26.9744 19.5158C27.9533 19.5158 28.8655 19.2583 29.6638 18.8102C30.9467 18.0856 32.5147 18.0856 33.7975 18.8102C34.5958 19.2583 35.5081 19.5158 36.4869 19.5158C39.6513 19.5158 42.1886 16.8461 41.9891 13.6329C41.818 10.8487 39.5468 8.59854 36.772 8.46506V8.45552Z" fill="#41667C"/>
      <path d="M10.1148 24.8459H6V22.4146H17.2039V24.8459H13.0892V35.315H10.1148V24.8459Z" fill="#231916"/>
      <path d="M19.1714 22.4146H22.1458V32.8836H28.5983V35.315H19.1714V22.4146Z" fill="#231916"/>
      <path d="M29.6052 28.8683C29.6052 24.9781 32.5796 22.194 36.5899 22.194C38.8136 22.194 40.6666 23.0044 41.883 24.4823L39.9729 26.2557C39.1081 25.2641 38.0248 24.7493 36.7419 24.7493C34.3377 24.7493 32.6272 26.4464 32.6272 28.8778C32.6272 31.3091 34.3377 33.0063 36.7419 33.0063C38.0248 33.0063 39.1081 32.4914 39.9729 31.4712L41.883 33.2447C40.6666 34.7416 38.8136 35.5521 36.5709 35.5521C32.5796 35.5521 29.6052 32.7679 29.6052 28.8778V28.8683Z" fill="#231916"/>
    </svg>

  )
}

export default Company