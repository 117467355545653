export const diagnosisListHeader = [
    { title: '이름', type: 'patientName' },
    { title: '생년월일', type: 'patientIdentityNumber' },
    { title: '학령/학기', type: 'gradeName' },
    { title: '연락처', type: 'patientMobile' },
    { title: '진료일시', type: 'diagnosisDateTime' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
    { title: '', type: 'null' },
];
export const adminDiagnosisListHeader = [
    { title: '소속병원', type: 'hospitalName' },
    { title: '이름', type: 'patientName' },
    { title: '생년월일', type: 'patientIdentityNumber' },
    { title: '학령/학기', type: 'gradeName' },
    { title: '연락처', type: 'patientMobile' },
    { title: '진료일시', type: 'diagnosisDateTime' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
];
export const managingPatientsListHeader = [
    { title: '등록번호', type: 'regNum' },
    { title: '이름', type: 'name' },
    { title: '나이', type: 'age' },
    { title: '생년월일', type: 'identityNumber' },
    { title: '연락처', type: 'mobile' },
    { title: '마지막 진료일시', type: 'lastDiagnosisTimestamp' },
    { title: '총 진료 건수', type: 'allDiagnosisCount' },
    { title: '', type: 'null' },
];

export const viewDetailPatientListHeader = [
    { title: 'No.', type: 'order' },
    { title: '이름', type: 'name' },
    { title: '생년월일', type: 'identityNumber' },
    { title: '학령/학기', type: 'grade' },
    { title: '진료일시', type: 'diagnosisTimestamp' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
    { title: '', type: 'null' },
];

export const adminCalculateListHeader = [
    { title: '병원', type: 'hospitalName' },
    { title: '환자 이름', type: 'patientName' },
    { title: '생년월일', type: 'patientIdentityNumber' },
    { title: '진료일시', type: 'diagnosisDateTime' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
    { title: '공급가', type: 'supplyPrice' },
    { title: '소비자가', type: 'retailPrice' },
    { title: 'Test', type: 'test' },
];

export const todayDiagnosisListInHome = ['이름', '생년월일', '진료일시', '검사 항목'];

export const diagnosisListWidth = ['13%', '10%', '15%', '13%', '19%', '8%', '17%', '5%'];

export const managingPatientsListWidth = ['10%', '15%', '11%', '11%', '15%', '19%', '13%', '5%'];

export const viewDetailPatientListWidth = ['8%', '13%', '11%', '16%', '20%', '10%', '17%', '5%'];

export const todayDiagnosisListInHomeWidth = ['19%', '19%', '31%', '31%'];

export const adminDiagnosisListWidth = ['11%', '10%', '10%', '14%', '13%', '16%', '8%', '10%', '8%'];
