import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';

const PrivateRoute = ({ component: Component }: { component: ComponentType<any> }) => {
    let token = '';

    const item = localStorage.getItem('loginData');

    if (item) {
        try {
            const storedData = JSON.parse(item);
            token = storedData?.state?.loginData?.token || '';
        } catch (error) {
            console.error('Invalid JSON:', item);
        }
    }

    return token ? <Component /> : <Navigate to={pagePaths.login} replace />;
};

export default PrivateRoute;
