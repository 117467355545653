import { signUpApi } from 'api/SignUp/signUpApi';
import CancelBtn from 'assets/Icons/ETC/Button/CancelBtn';
import CloseEye from 'assets/Icons/Password/PasswordHide';
import OpenEye from 'assets/Icons/Password/PasswordShow';
import Loading from 'components/Common/Loading';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getPermission } from 'firebaseConfig';
import { loginValidate } from 'functions/validationFunctions';
import React, { KeyboardEvent, ChangeEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginStore } from 'store/useLoginDataStore';
import styled from 'styled-components';
import { Neutral } from 'styles/Global/colorPrimitive';
import { BackgroundColor, BorderColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body1_Medium_input, Body1_Regular_Button, Body2_Regular, Title1_Bold } from 'styles/Global/typography';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import TableSettings from './TableSettings';

interface InputType {
    padding: string;
    backgroundColor?: string;
}
interface SubmitButtonStyle {
    opacity: string;
}

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsSettingModel = ({ setShowModal }: Props) => {
    const { loginData } = useLoginStore();
    const [loginInfo, setLoginInfo] = useState({ email: loginData.email, password: '' });
    const [errorMsg, setErrorMsg] = useState({ password: '' });
    const inputRef = useRef<HTMLInputElement>(null);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [passwordType, setPassWordType] = useState('password');
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const loginApi = async () => {
        try {
            const version = require('../../../package.json').version;
            const auth = getAuth();
            const { email, password } = loginInfo;
            const { user } = await signInWithEmailAndPassword(auth, email, password);
            const idToken = await user.getIdToken();
            const fcmToken = await getPermission();
            const rqData = {
                idToken: idToken,
                deviceType: 3,
                os: 'web',
                model: 'chrome',
                version: version,
                token: fcmToken,
            };
            const response = await signUpApi.oauth(rqData);

            if (response.status === 'ok') {
                if (response.data.user.status === 1) {
                    setShowSettingsModal(true);
                } else {
                    console.log('panding 상태, home으로 이동');
                }
            } else {
                console.log('oauth 로그인 실패');
            }
        } catch (error) {
            setErrorMsg((prev) => ({ ...prev, password: '잘못된 비밀번호입니다. 다시 입력해주세요.' }));
            setLoginInfo((prev) => ({ ...prev, password: '' }));
        }
    };

    const handleClickSubmit = () => {
        loginApi();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setLoginInfo((prev) => ({ ...prev, [id]: value }));

        const error = loginValidate[id](value);
        setErrorMsg((prev) => ({ ...prev, [id]: error }));
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
            handleClickSubmit();
        }
    };

    const handleClickShowPassword = () => {
        if (passwordType === 'password') {
            setPassWordType('text');
            setIsPasswordVisible(true);
        } else {
            setPassWordType('password');
            setIsPasswordVisible(false);
        }
    };

    return (
        <Wrapper>
            {showSettingsModal && <TableSettings setShowSettingsModal={setShowSettingsModal} setShowModal={setShowModal} />}
            <ModalLayout>
                <CancelBtnLayout onClick={handleCancelClick}>
                    <CancelBtn />
                </CancelBtnLayout>
                <ModelInfoLayout>
                    <ModelTextLayout>
                        <ModalTitle>설정에 접근할까요?</ModalTitle>
                        <ModalDescription>설정에 접근하려면, 로그인된 아이디의 비밀번호가 필요합니다.</ModalDescription>
                    </ModelTextLayout>
                </ModelInfoLayout>
                <InputWrapper>
                    <Input placeholder='아이디 (이메일)' id='email' padding='12px 24px' value={loginInfo.email} disabled />
                    <InputBtnLayout>
                        <Input
                            placeholder='비밀번호'
                            type={passwordType}
                            onChange={handleChange}
                            id='password'
                            value={loginInfo.password}
                            ref={inputRef}
                            padding='12px 72px 12px 24px'
                            onKeyDown={handleKeyDown}
                            backgroundColor={Static.color_static_white}
                        />
                        <InputWithLabelIconWrapper onClick={handleClickShowPassword}>{isPasswordVisible ? <OpenEye /> : <CloseEye />}</InputWithLabelIconWrapper>
                    </InputBtnLayout>
                    {errorMsg.password && <ErrorMsg>{errorMsg.password}</ErrorMsg>}
                </InputWrapper>
                <ButtonLayout>
                    <CancelButton onClick={handleCancelClick}>취소</CancelButton>
                    <SubmitButton onClick={handleClickSubmit} disabled={loginInfo.password.length > 0 ? false : true} opacity={loginInfo.password.length > 0 ? '1' : '.5'}>
                        접근하기
                    </SubmitButton>
                </ButtonLayout>
            </ModalLayout>
        </Wrapper>
    );
};

export default IsSettingModel;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalLayout = styled.div`
    position: relative;
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 48px 36px 32px 36px;
    background-color: ${Static.color_static_white};
    border-radius: ${Radius.radius_16};
    box-shadow: 0px 4px 20px 0px ${Neutral.color_neutral_900};
`;

const CancelBtnLayout = styled.button`
    position: absolute;
    right: -16px;
    top: -16px;
`;

const ModelInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const ModelTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const ModalTitle = styled(Title1_Bold)`
    text-align: center;
`;

const ModalDescription = styled(Body2_Regular)`
    text-align: center;
`;

const ButtonLayout = styled.div`
    display: flex;
    column-gap: 8px;
`;

const SubmitButton = styled(Body1_Regular_Button)<SubmitButtonStyle>`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${palette.white};
    background-color: ${BackgroundColor.color_background_primary};
    opacity: ${(props) => props.opacity};
`;

const CancelButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${TextColor.color_text_normal};
    background-color: ${BackgroundColor.color_background_strong};

    &:active {
        background-color: ${PrimaryColor.color_primary_heavy};
    }
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 32px;
`;

const Input = styled(Body1_Medium_input)<InputType>`
    width: 528px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    background: ${BackgroundColor.color_background_normal};
    padding: ${(props) => props.padding};
    color: ${TextColor.color_text_normal};
    background-color: ${(props) => props.backgroundColor};
`;

const InputBtnLayout = styled.div`
    position: relative;
    display: flex;
`;

const InputWithLabelIconWrapper = styled.button`
    position: absolute;
    width: 72px;
    height: 100%;
    right: 12px;
`;
