import Search from 'assets/Icons/Common/Search';
import { SearchInputProps } from 'models/Common/inputTypes';
import React from 'react';
import styled from 'styled-components';
import { BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';
import { SearchIconWrapper } from 'styles/Input/inputStyle';

const SearchInput = ({ onChange, searchValue, handleSearchFilter }: SearchInputProps) => {
    return (
        <>
            <Input
                placeholder='검색어를 입력해주세요.'
                onChange={(e) => onChange(e.target.value)}
                value={searchValue}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchFilter();
                    }
                }}
            />
            <SearchIconWrapper onClick={handleSearchFilter}>
                <Search />
            </SearchIconWrapper>
        </>
    );
};

export default SearchInput;

const Input = styled.input`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${BorderColor.color_border_normal};
    width: 100%;
    height: 100%;
    font-size: ${font.size[14]};
    font-weight: 500;
    padding: 0 8px 0 12px;
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }
    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
`;
