import React, { Dispatch, SetStateAction } from 'react';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import BasicButton from 'components/Buttons/BasicButton';
import { font, palette } from 'styles/Global/globalStyles';
import styled from 'styled-components';
import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import HospitalAccountIcon from 'assets/Icons/Account/Account';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body3_Medium, Body5_Regular } from 'styles/Global/typography';
import ModalDeleteIcon from 'assets/Icons/DeleteIcon/CloseDialog';
import { MemberWhiteBadge } from 'components/Common/Badge';
import { CloseButtonWrapper } from 'styles/Modal/modalStyle';

const RemoveUserModal = ({ toggle, userName, onClick, userEmail, userLevel }: { toggle: () => void; userName: string; onClick: () => void; userEmail: string; userLevel: number }) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <div style={{ width: '100%' }}>
                    <ModalTitleAndContentWrapper>
                        <ModalTitle>계정을 삭제할까요?</ModalTitle>
                        <ModalContent>삭제하시면, 해당 계정이 완전히 사라집니다.</ModalContent>
                    </ModalTitleAndContentWrapper>
                    <DoctorContainer>
                        <HospitalAccountIcon />
                        <UserNameAndIdContainer>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <Text>{userName}</Text>
                                <MemberWhiteBadge>{userLevel === 0 ? '멤버' : '관리자'}</MemberWhiteBadge>
                            </div>
                            <Email>{userEmail}</Email>
                        </UserNameAndIdContainer>
                    </DoctorContainer>
                </div>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={toggle} $type='anotherFill'>
                        취소
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' onClick={onClick} $type='warning'>
                        삭제하기
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={toggle}>
                    <ModalDeleteIcon />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default RemoveUserModal;

const ModalTitleAndContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
`;
const UserNameAndIdContainer = styled.div`
    display: flex;
    width: calc(100% - 24px);
    flex-direction: column;
    gap: 5px;
`;

const DoctorContainer = styled.div`
    display: flex;
    width: 100%;
    height: 86px;
    padding: 18px 20px 20px;
    gap: 8px;
    border: ${Border.border_1} solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_white};
`;

const Text = styled(Body3_Medium)`
    color: ${TextColor.color_text_normal};
`;

const Email = styled(Body5_Regular)`
    color: ${TextColor.color_text_light};
`;
