import React from 'react'

const Bar = () => {
  return (
    <svg width="1" height="14" viewBox="0 0 1 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1" height="14" fill="#CCCCCC"/>
    </svg>

  )
}

export default Bar