import React from 'react';

const Icon_Unselect_Fill = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 16C0 20.4183 3.58172 24 8 24L16 24C20.4183 24 24 20.4183 24 16L24 8C24 3.58172 20.4183 0 16 0L8 0C3.58172 0 0 3.58172 0 8L0 16Z' fill='#DBDCDF' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.5893 8.07745C17.9147 8.40289 17.9147 8.93053 17.5893 9.25596L10.9226 15.9226C10.5972 16.2481 10.0695 16.2481 9.7441 15.9226L6.41076 12.5893C6.08533 12.2639 6.08533 11.7362 6.41076 11.4108C6.7362 11.0853 7.26384 11.0853 7.58928 11.4108L10.3334 14.1549L16.4108 8.07745C16.7362 7.75201 17.2638 7.75201 17.5893 8.07745Z'
                fill='white'
            />
        </svg>
    );
};

export default Icon_Unselect_Fill;
