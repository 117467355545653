import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetDiagnosisListApiResponse } from 'models/Hospital/hospitalApiTypes';

class DiagnosisListApi {
    async getDiagnosisList(url: string, token: string): Promise<GetDiagnosisListApiResponse> {
        try {
            const resopnse = await axios.get(apiPaths.getDiagnosisList + url, { headers: tokenHeader(token) });
            return resopnse.data;
        } catch (error) {
            throw error;
        }
    }
}

export const diagnosisListApi = new DiagnosisListApi();
