import React from 'react';

const SettingCancelBtn = () => {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.2761 10.3905C11.7554 9.86982 10.9112 9.86982 10.3905 10.3905C9.86983 10.9112 9.86983 11.7555 10.3905 12.2762L20.1145 22.0004L10.3913 31.7239C9.87065 32.2446 9.87065 33.0888 10.3913 33.6095C10.912 34.1302 11.7562 34.1302 12.2769 33.6095L22.0001 23.8861L31.7232 33.6095C32.2439 34.1302 33.0881 34.1302 33.6088 33.6095C34.1295 33.0888 34.1295 32.2446 33.6088 31.7239L23.8857 22.0004L33.6096 12.2762C34.1303 11.7555 34.1303 10.9113 33.6096 10.3905C33.0889 9.86984 32.2447 9.86984 31.724 10.3905L22.0001 20.1148L12.2761 10.3905Z'
                fill='#333438'
            />
        </svg>
    );
};

export default SettingCancelBtn;
