import { diagnosisApi } from 'api/Tablet/diagnosisApi';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginStore } from 'store/useLoginDataStore';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import _ from 'lodash';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import styled from 'styled-components';
import WhiteCheck from 'assets/Icons/ETC/Check/WhiteCheck';
import Modal from './Modal';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import { assessmentApi } from 'api/Tablet/assessmentApi';
import { Body1_Medium_button, Body1_SemiBold, Body3_Medium, Body3_Regular, Body3_SemiBold, Body4_SemiBold, Body5_Medium_button, Display2_Bold } from 'styles/Global/typography';
import { Border, Radius } from 'styles/Global/objectStyles';
import { BackgroundColor, BorderColor, InverseColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { GetAssessmentListApiResponse } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { tabletPaths } from 'constants/path';
import ArrowLineRight from 'assets/Icons/Arrow/ArrowLineRight';

const Information = () => {
    const parent_assessmentCodeList = ['IBS_CO_SG', 'KBASC2_EX_PG_C', 'KBASC2_EX_PG_A'];
    const [loading, setLoading] = useState(false);
    const { assessmentData, setAssessmentData, resetAssessment } = useAssessmentStore();
    const { state } = useLocation();
    const { loginData } = useLoginStore();
    const { patientData, setPatientData } = usePatientStore();
    const [diagnosisInfoList, setDiagnosisInfoList] = useState<GetAssessmentListApiResponse['data']>([]);
    const [assessmentIdList, setAssessmentIdList] = useState<string[]>([]);
    const [diagnosisId, setDiagnosisId] = useState('');
    const [modelMode, setModelMode] = useState(false);

    const navigate = useNavigate();

    const getDiagnosisListData = async () => {
        try {
            const rqData = {
                date: moment(new Date()).format('YYYYMMDD'),
                patientId: state._id,
            };
            const response = await diagnosisApi.getDiagnosisListData(rqData, loginData.token);
            if (_.isNull(response?.data) || _.isUndefined(response?.data) || response?.data.assessmentList.length === 0) {
                navigate(tabletPaths.login, { state: { showModel: true } });
            } else {
                const diagnosisListData = response!.data;
                const { name, birthDate, gender } = patientData;

                setDiagnosisId(diagnosisListData._id);
                setDiagnosisInfoList(diagnosisListData.assessmentList);
                if (assessmentData.assessmentCodeList) {
                    setAssessmentData({
                        assessmentNameList: assessmentData.assessmentNameList,
                        assessmentIdList: assessmentData.assessmentIdList,
                        assessmentGroupList: assessmentData.assessmentGroupList,
                        assessmentCodeList: assessmentData.assessmentCodeList,
                        assessmentCnt: assessmentData.assessmentCnt,
                    });
                } else {
                    setAssessmentData({
                        assessmentNameList: diagnosisListData.assessmentList.map((item) => item.assessmentName),
                        assessmentIdList: diagnosisListData.assessmentList.map((item) => item._id),
                        assessmentGroupList: diagnosisListData.assessmentList.map((item) => item.group),
                        assessmentCodeList: diagnosisListData.assessmentList.map((item) => item.assessmentCode),
                        assessmentCnt: 0,
                    });
                }
                setPatientData({ name, birthDate, gender, patientId: state._id, diagnosisId: diagnosisListData._id });
                setAssessmentIdList(diagnosisListData.assessmentList.map((item) => item._id));
                return diagnosisListData.status;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeDiagnosisStatus = async (assessmentStatus: number) => {
        if (!_.isUndefined(assessmentData.assessmentCodeList) && assessmentData.assessmentCodeList.length === 0) {
            try {
                const rqData = {
                    _id: diagnosisId,
                    status: assessmentStatus,
                };
                const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, loginData.token);
                if (response.status === 'ok') {
                    navigate(tabletPaths.startExamination, { state: { assessmentIdList, diagnosisId } });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            const rqData = {
                _id: diagnosisId,
                status: assessmentStatus,
            };
            const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, loginData.token);
            if (response.status === 'ok') {
                const assessmentIdList = assessmentData.assessmentIdList;
                navigate(tabletPaths.startExamination, { state: { assessmentIdList, diagnosisId } });
            }
        }
    };

    const changeAssessmentStatus = async (assessmentStatus: number) => {
        try {
            const rqData = {
                diagnosisId,
                assessmentCode: assessmentData.assessmentCodeList[0],
                status: assessmentStatus,
            };
            await assessmentApi.changeAssessmentStatus(rqData, loginData.token);
        } catch (error) {}
    };

    // 나가기 버튼
    const handleClickPatienLogin = async () => {
        const diagnosisState = await getDiagnosisListData();
        if (assessmentData.assessmentCodeList[0] !== '2') await changeAssessmentStatus(0);
        if (diagnosisState !== 3) await changeDiagnosisStatus(1);
        resetAssessment();
        return navigate(tabletPaths.login);
    };

    //! 검사 진행하기 눌렀을 시 모달 띄우는 곳.. 여기에서 막으면 될 듯
    const handleClick = async () => {
        getDiagnosisListData();
        setModelMode(true);
    };

    useEffect(() => {
        getDiagnosisListData();
    }, []);

    return (
        <PageLayout>
            {modelMode && <Modal setModelMode={setModelMode} changeDiagnosisStatus={changeDiagnosisStatus} setLoading={setLoading} />}
            <Wrapper>
                <PageWrapper>
                    <PageTitle>검사 전에 안내드립니다</PageTitle>
                    <PageDescription>
                        <PageDescriptionText>
                            <IconWraaper>
                                <WhiteCheck />
                            </IconWraaper>
                            <FontWeightText>본 검사는 시험이 아니므로, 정답과 오답이 따로 없으며, 좋고 나쁜 답도 없습니다.</FontWeightText>
                        </PageDescriptionText>
                        <PageDescriptionText>
                            <IconWraaper>
                                <WhiteCheck />
                            </IconWraaper>
                            <span>자신과 같거나 가깝다고 생각되는 대로 응답해 주시면 됩니다.</span>
                        </PageDescriptionText>
                        <PageDescriptionText>
                            <IconWraaper>
                                <WhiteCheck />
                            </IconWraaper>
                            <span>정확한 검사결과를 위해 한 문장도 빠짐없이 기록해 주시기 바랍니다.</span>
                        </PageDescriptionText>
                    </PageDescription>
                    {_.isUndefined(diagnosisInfoList) || _.isUndefined(assessmentData) ? (
                        <div>데이터가 없습니다.</div>
                    ) : !_.isUndefined(diagnosisInfoList) ? (
                        <ExaminationWrapper>
                            <PageSubTitle>
                                <Title>
                                    진행 예정 검사 <FontWeightText>{diagnosisInfoList.length}</FontWeightText>
                                </Title>
                            </PageSubTitle>
                            {diagnosisInfoList.map((diagnosisInfo, index) => {
                                return (
                                    <DiagnosisItem key={index}>
                                        <ExaminationCnt>{index + 1}</ExaminationCnt>
                                        <DiagnosisTextLayout>
                                            <DiagnosisInfoGroupText>{diagnosisInfo.group}</DiagnosisInfoGroupText>
                                            <DiagnosisText key={diagnosisInfo.assessmentCode}>
                                                {diagnosisInfo.assessmentName.split('(')[0]}
                                                <DiagnosisTag>{diagnosisInfo.assessmentName.split('(')[1] && `(${diagnosisInfo.assessmentName.split('(')[1]}`}</DiagnosisTag>
                                            </DiagnosisText>
                                            {parent_assessmentCodeList.includes(diagnosisInfo.assessmentCode)}
                                        </DiagnosisTextLayout>
                                    </DiagnosisItem>
                                );
                            })}
                        </ExaminationWrapper>
                    ) : (
                        !_.isUndefined(assessmentData) && (
                            <ExaminationWrapper>
                                <PageSubTitle>
                                    <Title>진행 예정 검사 {assessmentData.assessmentNameList.length}</Title>
                                </PageSubTitle>
                                {assessmentData.assessmentNameList.map((_, index) => {
                                    return (
                                        <DiagnosisItem key={index}>
                                            <ExaminationCnt>{index + 1}</ExaminationCnt>
                                            <DiagnosisTextLayout>
                                                <DiagnosisInfoGroupText>{assessmentData.assessmentGroupList[index]}</DiagnosisInfoGroupText>
                                                <DiagnosisText key={assessmentData.assessmentCodeList[index]}>{assessmentData.assessmentNameList[index]}</DiagnosisText>
                                                {parent_assessmentCodeList.includes(assessmentData.assessmentCodeList[index])}
                                            </DiagnosisTextLayout>
                                        </DiagnosisItem>
                                    );
                                })}
                            </ExaminationWrapper>
                        )
                    )}
                </PageWrapper>
                <ButtonLayout>
                    <CancelButton onClick={handleClickPatienLogin}>나가기</CancelButton>
                    <SubmitButton onClick={handleClick}>
                        검사진행하기
                        <ArrowLineRight />
                    </SubmitButton>
                </ButtonLayout>
            </Wrapper>
        </PageLayout>
    );
};

export default Information;
const PageLayout = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 100vh;
`;

const Wrapper = styled.div`
    z-index: 0;
    width: 660px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
`;

const PageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
`;

const PageDescription = styled.div`
    padding: 20px 24px;
    width: 660px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_neutral};
`;

const PageDescriptionText = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    &:not(:first-child) {
        color: ${TextColor.color_text_normal};
    }
`;

const PageSubTitle = styled.div`
    width: 100%;
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 16px;
    border-bottom: ${Border.border_1} solid ${BorderColor.color_border_normal};
    background: ${BackgroundColor.color_background_light};
`;

const Title = styled(Body4_SemiBold)``;

const IconWraaper = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${InverseColor.color_inverse_primary};
    border-radius: ${Radius.radius_circle};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FontWeightText = styled(Body3_SemiBold)`
    color: ${PrimaryColor.color_primary_heavy};
`;

const ExaminationWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_4};
`;

const DiagnosisItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    padding: 16px 20px;
    border-bottom: ${Border.border_1} solid ${BorderColor.color_border_normal};

    &:last-child {
        border-bottom: none;
    }
`;

const ExaminationCnt = styled(Body5_Medium_button)`
    color: ${TextColor.color_text_normal};
    text-align: center;
    width: 28px;
    height: 28px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_8};
    background: ${BackgroundColor.color_background_white};
`;

const DiagnosisTextLayout = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 6px;
`;

const DiagnosisText = styled(Body3_Medium)`
    color: ${Static.color_static_black};
`;

const DiagnosisInfoGroupText = styled(Body1_SemiBold)`
    color: ${Static.color_static_black};
`;

const DiagnosisTag = styled(Body3_Regular)`
    color: ${TextColor.color_text_light};
`;

const ButtonLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
`;

const SubmitButton = styled(Body1_Medium_button)`
    width: 380px;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    row-gap: 6px;
    border-radius: ${Radius.radius_4};
    color: ${Static.color_static_white};
    background-color: ${BackgroundColor.color_background_primary};
    &:active {
        background-color: ${PrimaryColor.color_primary_heavy};
    }
`;

const CancelButton = styled(Body1_Medium_button)`
    width: 130px;
    height: 72px;
    border-radius: ${Radius.radius_4};
    color: ${TextColor.color_text_normal};
    background-color: ${BackgroundColor.color_background_strong};
    &:active {
        background-color: ${BackgroundColor.color_background_over};
    }
`;

const PageTitle = styled(Display2_Bold)``;
