import React from 'react';

const Noti_Delete = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '32'} height={$height || '32'} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_627_69716)'>
                <circle cx='16' cy='16' r='16' fill='#333438' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.7065 11.7072C22.097 11.3166 22.097 10.6834 21.7065 10.2929C21.316 9.90237 20.6829 9.90237 20.2924 10.2929L16.0005 14.585L11.7086 10.2929C11.3181 9.90237 10.6849 9.90237 10.2944 10.2929C9.9039 10.6834 9.9039 11.3166 10.2944 11.7072L14.5863 15.9993L10.2929 20.2929C9.90237 20.6834 9.90237 21.3166 10.2929 21.7072C10.6834 22.0977 11.3165 22.0977 11.7071 21.7072L16.0005 17.4135L20.2939 21.7072C20.6844 22.0977 21.3175 22.0977 21.7081 21.7072C22.0986 21.3166 22.0986 20.6834 21.7081 20.2929L17.4146 15.9993L21.7065 11.7072Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_627_69716'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Noti_Delete;
