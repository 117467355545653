import React from 'react';

const SideLogo = () => {
    return (
        <svg style={{ marginBottom: '20px' }} width='43' height='32' viewBox='0 0 43 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M36.058 0.00895861C34.7885 -0.0580767 33.597 0.254755 32.5726 0.824556C31.0693 1.67367 29.232 1.6625 27.7399 0.824556C26.7934 0.310618 25.7244 0.00895861 24.5774 0.00895861C22.4283 0.00895861 20.5353 1.05918 19.355 2.66803C18.4753 3.87466 17.0722 4.56736 15.5912 4.56736C14.0546 4.56736 12.5624 3.87466 11.6493 2.63451C10.3688 0.902764 8.24191 -0.169802 5.89236 0.0313037C2.72993 0.299445 0.202211 2.90265 0.0129106 6.07566C-0.220931 9.84081 2.7522 12.9691 6.46026 12.9691C8.5537 12.9691 10.4133 11.9748 11.5936 10.4218C12.5513 9.17046 14.01 8.39955 15.5912 8.39955C17.0834 8.39955 18.4864 9.10342 19.3661 10.3101C20.5353 11.9189 22.4395 12.9691 24.5774 12.9691C25.7244 12.9691 26.7934 12.6675 27.7287 12.1424C29.232 11.2932 31.0693 11.2932 32.5726 12.1424C33.508 12.6675 34.577 12.9691 35.7239 12.9691C39.432 12.9691 42.4051 9.84081 42.1712 6.07566C41.9708 2.81327 39.3095 0.176547 36.058 0.0201312V0.00895861Z'
                fill='white'
            />
            <path d='M4.8216 19.2148H0V16.3658H13.1285V19.2148H8.30695V31.4823H4.8216V19.2148Z' fill='white' />
            <path d='M15.4341 16.3658H18.9194V28.6333H26.4803V31.4823H15.4341V16.3658Z' fill='white' />
            <path
                d='M27.6604 23.9282C27.6604 19.3698 31.1458 16.1074 35.8449 16.1074C38.4505 16.1074 40.6219 17.0571 42.0472 18.7888L39.809 20.8669C38.7957 19.705 37.5263 19.1017 36.023 19.1017C33.2058 19.1017 31.2014 21.0904 31.2014 23.9394C31.2014 26.7884 33.2058 28.7771 36.023 28.7771C37.5263 28.7771 38.7957 28.1738 39.809 26.9783L42.0472 29.0564C40.6219 30.8105 38.4505 31.7602 35.8226 31.7602C31.1458 31.7602 27.6604 28.4978 27.6604 23.9394V23.9282Z'
                fill='white'
            />
        </svg>
    );
};

export default SideLogo;
