import { periodFilterList } from 'constants/diagnosisListPageConstants';
import { DropdownCell, DropdownLayout } from './DropdownForms';
import { DiagnosisListPeriodFilterDropdownProps } from 'models/Hospital/diagnosisListPageTypes';

const DiagnosisListPeriodFilterDropdown = ({ selectedPeriod, handleDropdown }: DiagnosisListPeriodFilterDropdownProps) => {
    return (
        <DropdownLayout $position='absolute' $height='208px' $padding='4px 0' $top='44px' $left='0'>
            {periodFilterList.map((item, idx) => {
                return (
                    <DropdownCell key={idx} $height='40px' $padding='0 12px' onClick={(e: React.MouseEvent) => handleDropdown(e, idx, 'period')} $selected={selectedPeriod === idx}>
                        {item}
                    </DropdownCell>
                );
            })}
        </DropdownLayout>
    );
};

export default DiagnosisListPeriodFilterDropdown;
