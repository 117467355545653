import Icon_Select_Checkbox from 'assets/Icons/CheckBox/Icon_Select_Checkbox';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileLabelSelectButton } from 'components/Buttons/MobileSelectButton';
import { MobileBadgePrimary } from 'components/Common/Badge';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { governmentReadyPageConstants } from 'constants/\bGovernment/governmentReadyPageConstants';
import { governmentSelectAssessmentPageConstants } from 'constants/\bGovernment/governmentSelectAssessmentPageConstants';
import { commonText } from 'constants/commonConstants';
import { governmentPaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import { useEffect, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { BasicNumberBadge } from 'styles/Global/badgeStyles';
import { BackgroundColor, BorderColor, IconColor, OpacityColor, PrimaryColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3_Bold, Body3_Medium, Body4_SemiBold, Caption2_Regular, Title2_SemiBold } from 'styles/Global/typography';

// const assessmentChildrenList = [{ title: '불안검사', subTitle: 'RCMAS-2', selected: true }, { title: '우울검사', subTitle: 'K-CDI 2', selected: true }];
// const assessmentParentsList = [{ title: '우울검사', subTitle: 'K-CDI 2', selected: true }, { title: 'ADHD검사', subTitle: 'K-ARS-5', selected: true }];

const GovernmentSelectAssessmentPage = () => {
    const navigate = useNavigate();

    const [assessmentChildrenList, setAssessmentChildrenList] = useState([
        { title: '불안검사', subTitle: 'RCMAS-2', desc: '나의 불안한 마음을 알아보는 검사', selected: true },
        { title: '우울검사', subTitle: 'K-CDI 2', desc: '나의 우울 심리를 알아보는 검사', selected: true },
    ]);
    const [assessmentParentsList, setAssessmentParentsList] = useState([
        { title: '우울검사', subTitle: 'K-CDI 2', desc: '자녀의 우울 심리를 알아보는 검사', selected: true },
        { title: 'ADHD검사', subTitle: 'K-ARS-5', desc: '자녀의 행동 발달 검사', selected: true },
    ]);
    const [count, setCount] = useState(0);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();

    const [isLeaveModal, setIsLeaveModal] = useToggle();

    // header text setting
    useEffect(() => {
        setHeaderText(governmentSelectAssessmentPageConstants.headerText);

        return () => {
            setInit();
        };
    }, []);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        console.log(currentLocation, nextLocation);
        if (currentLocation.pathname === governmentPaths.selectAssessment && nextLocation.pathname === governmentPaths.ready && !isLeaveModal) {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    // assessment count
    useEffect(() => {
        let count = 0;

        assessmentChildrenList.forEach((assessment) => {
            if (assessment.selected) count++;
        });
        assessmentParentsList.forEach((assessment) => {
            if (assessment.selected) count++;
        });

        return setCount(count);
    }, [assessmentChildrenList, assessmentParentsList]);

    // checkBox handler
    const handleCheckBox = (index: number, type: string) => {
        if (type === 'children') {
            const newAssessmentChildrenList = [...assessmentChildrenList];
            newAssessmentChildrenList[index].selected = !newAssessmentChildrenList[index].selected;
            setAssessmentChildrenList(newAssessmentChildrenList);
        } else {
            const newAssessmentParentsList = [...assessmentParentsList];
            newAssessmentParentsList[index].selected = !newAssessmentParentsList[index].selected;
            setAssessmentParentsList(newAssessmentParentsList);
        }
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        setInit();
        setIsLeaveModal();
        navigate(governmentPaths.agree);
    };

    return (
        <Layout>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={governmentReadyPageConstants.modalTitle1}
                    subTitle={governmentReadyPageConstants.modalTitle2}
                    content={governmentReadyPageConstants.modalContent}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton={commonText.no}
                    secondButton={commonText.out}
                />
            )}
            <Title>진행할 검사를 선택해주세요.</Title>
            <AssessmentListContainer>
                <AssessmentListTitle>
                    자녀 검사 <AssessmentListCount>{assessmentChildrenList.length}</AssessmentListCount>
                </AssessmentListTitle>
                {assessmentChildrenList.map((assessment, index) => (
                    <MobileLabelSelectButton
                        $selected={assessment.selected}
                        $onClick={() => handleCheckBox(index, 'children')}
                        $title={assessment.title}
                        $subTitle={assessment.subTitle}
                        $desc={assessment.desc}
                        $label='자녀용'
                    />
                ))}
            </AssessmentListContainer>
            <AssessmentListContainer>
                <AssessmentListTitle>
                    보호자 검사 <AssessmentListCount>{assessmentChildrenList.length}</AssessmentListCount>
                </AssessmentListTitle>
                {assessmentParentsList.map((assessment, index) => (
                    <MobileLabelSelectButton
                        $selected={assessment.selected}
                        $onClick={() => handleCheckBox(index, 'parents')}
                        $title={assessment.title}
                        $subTitle={assessment.subTitle}
                        $desc={assessment.desc}
                        $label='보호자용'
                    />
                ))}
            </AssessmentListContainer>
            <MobileBasicButton onClick={() => console.log(123)} $type='fill' $gap='4px' disabled={count === 0}>
                선택 완료 <BasicNumberBadge>{count}</BasicNumberBadge>
            </MobileBasicButton>
        </Layout>
    );
};

export default GovernmentSelectAssessmentPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
`;

const Title = styled(Title2_SemiBold)``;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const AssessmentListTitle = styled(Body4_SemiBold)``;

const AssessmentListCount = styled(Body4_SemiBold)`
    color: ${PrimaryColor.color_primary_normal};
`;

const AssessmentContainer = styled.div<{ $selected: boolean }>`
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    border: 1px solid ${(props) => (props.$selected ? PrimaryColor['color_primary_light-8'] : BorderColor.color_border_weak)};
    border-radius: ${Radius.radius_8};
    background-color: ${BackgroundColor.color_background_primary_neutral};
    padding: 14px 16px 16px;
    gap: 12px;
    cursor: pointer;
`;

const AssessmentTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const AssessmentTitleWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

const AssessmentTitle = styled(Body3_Bold)``;

const AssessmentSubTitle = styled(Body3_Medium)``;

const AssessmentDesc = styled(Caption2_Regular)``;

const CheckBox = styled.div<{ disabled?: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid ${IconColor.color_icon_light};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        border: 1px solid ${IconColor.color_icon_neutral};
    }
    &:active {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    &:focus {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${BorderColor.color_border_neutral};
        background-color: ${BackgroundColor.color_background_disabled};
    `}
`;
