import { Body3_Medium, Body3_Medium_Input, Body5_Medium, Body5_Regular, Caption1_Regular } from './../Global/typography';
import { InputContainerStyleProps } from 'models/Common/inputTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';

type DropdownIconWrapperProps = {
    color?: string;
};

type InputWithLabelIconWrapperProps = {
    $top?: string;
};

export const InputWithLabelIconWrapper = styled.button<InputWithLabelIconWrapperProps>`
    position: absolute;
    right: 20px;
    top: ${(props) => props.$top || '18px'};
    height: 24px;
`;

export const DropdownIconWrapper = styled.div<DropdownIconWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    gap: 4px;
    color: ${(props) => props.color};
    font-weight: ${font.weight.medium};
`;

export const SearchIconWrapper = styled.button`
    height: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
`;

export const ResetIconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 8px;
`;
export const InputDesc = styled.span`
    opacity: 0.6;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
`;

// !----------------------------------------------- 여기부터

export const InputLabel = styled(Body3_Medium)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_normal};
    margin-bottom: ${(props) => props.$marginBottom || '4px'};
`;
export const MobileInputLabel = styled(Body5_Medium)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_neutral};
    margin-bottom: ${(props) => props.$marginBottom || '4px'};
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const InputSubLabel = styled(Body5_Regular)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_light};
    margin-bottom: ${(props) => props.$marginBottom || '8px'};
`;
export const MobileInputSubLabel = styled(Caption1_Regular)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_light};
    margin-bottom: ${(props) => props.$marginBottom || '8px'};
`;

export const InputSubText = styled(Body5_Regular)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_weak};
    // margin-bottom: ${(props) => props.$marginBottom || '8px'};
`;

export const PasswordInputLabel = styled(Body3_Medium)`
    color: ${TextColor.color_text_normal};
`;

export const BasicInput = styled(Body3_Medium_Input)<InputContainerStyleProps>`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal}`)};
    padding: 18px 20px;
    height: ${(props) => props.$height || '60px'};
    width: ${(props) => props.$width || '100%'};
    letter-spacing: ${(props) => props.id === 'identityNumber' && '3px'};
    caret-color: ${(props) => props.$warning && 'red'};
    cursor: ${(props) => props.$cursor};
    background-color: ${BackgroundColor.color_background_white};
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:hover {
        border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal_hover}` : `1px solid ${BorderColor.color_border_normal_hover}`)};
    }
    &:focus {
        border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal_press}`)};
    }
    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};
    }
`;
export const MobileBasicInput = styled(Body3_Medium_Input)<InputContainerStyleProps>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal}`)};
    padding: 18px 16px;
    height: ${(props) => props.$height || '52px'};
    width: ${(props) => props.$width || '100%'};
    letter-spacing: ${(props) => props.id === 'identityNumber' && '3px'};
    caret-color: ${(props) => props.$warning && 'red'};
    cursor: ${(props) => props.$cursor};
    background-color: ${BackgroundColor.color_background_white};
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus {
        border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal_press}`)};
    }
    &:disabled {
        border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal}`)};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};
    }
`;

export const WithOutBasicInputLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

export const BasicInputLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
