import React from 'react';

const Flag = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 3C3.44772 3 3 3.44772 3 4V13.5172V21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21L5 14.5172H11.074L11.7473 16.3456C11.8921 16.7388 12.2667 17 12.6857 17H20C20.5523 17 21 16.5523 21 16V6.06897C21 5.51668 20.5523 5.06897 20 5.06897H14.4032L14.1193 3.78425C14.0181 3.32615 13.612 3 13.1429 3H4Z'
                fill='#DBDCDF'
            />
        </svg>
    );
};

export default Flag;
