import React from 'react';
import { ModalBackground, ModalContent, ModalLayout, ModalTitle } from './ModalForms';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import BasicButton from 'components/Buttons/BasicButton';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import Noti_Warning from 'assets/Icons/ToastIcons/Noti_Warning';

const AccountSettingPageCompleteSendLinkModal = ({ email, onClick }: { email: string; onClick: () => void }) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px' $height='304px' $gap='36px' $padding='40px 36px 32px 36px'>
                <ModalTitleAndContentWrapper>
                    <TitleAndContentWrapper>
                        <ModalTitle>
                            <NotiAndTextWrapper>
                                <Noti_Warning />
                                링크가 전송되었습니다.
                            </NotiAndTextWrapper>
                        </ModalTitle>
                        <ModalContent>아래의 이메일로 전송된 비밀번호 재설정 링크를 확인해주세요.</ModalContent>
                    </TitleAndContentWrapper>
                    <EmailContainer>{email}</EmailContainer>
                </ModalTitleAndContentWrapper>
                <BasicButton $width='160px' $height='48px' onClick={onClick} $type='fill'>
                    확인
                </BasicButton>
            </ModalLayout>
        </ModalBackground>
    );
};

export default AccountSettingPageCompleteSendLinkModal;

const ModalTitleAndContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
`;

const EmailContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    padding: 18px 20px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 4px;
    background-color: ${BackgroundColor.color_background_disabled};
    color: ${TextColor.color_text_normal};
    word-break: break-all;
`;

const Test = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${palette.gray};
    margin-right: 8px;
`;

const TitleAndContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
`;

const NotiAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
