import { useCallback, useState } from 'react';

const usePaginationStateHooks = <T>(filterData: any) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageGroup, setPageGroup] = useState(0);

    const itemsPerPage = 12;
    const totalPages = filterData && Math.ceil(filterData.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = filterData && filterData.slice(startIndex, endIndex);

    const setPage = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    const handlePageGroup = useCallback((group: number) => {
        setPageGroup(group);
    }, []);

    return [totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData] as const;
};

export default usePaginationStateHooks;
