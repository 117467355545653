import React from 'react';

const Icon_arrow_left = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.1363 18.6364C14.7848 18.9879 14.215 18.9879 13.8635 18.6364L7.86351 12.6364C7.51203 12.2849 7.51203 11.7151 7.86351 11.3636L13.8635 5.36363C14.215 5.01216 14.7848 5.01216 15.1363 5.36363C15.4878 5.7151 15.4878 6.28495 15.1363 6.63642L9.77269 12L15.1363 17.3636C15.4878 17.7151 15.4878 18.2849 15.1363 18.6364Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default Icon_arrow_left;
