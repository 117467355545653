import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import BasicButton from 'components/Buttons/BasicButton';
import { viewDetailPatientPageText } from 'constants/viewDetailPatientPageConstants';
import { calculateAge } from 'functions/calculateAge';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageTitle } from 'styles/Global/commonStyles';
import { palette } from 'styles/Global/globalStyles';
import { HospitalPageLabel, DiagnosisInnerLayout, DiagnosisLayout, TwoBreadCrumbContainer, InfoHeaderContainer, InfoHeaderButtonContainer, DetailPageInfoLayout } from 'styles/Global/hospitalStyles';
import ViewDetailPatientPagination from './ViewDetailPatientPagination';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import { pagePaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import DeletePatientModal from 'components/Modal/DeletePatientModal';
import EditPatientInfoModal from 'components/Modal/EditPatientInfoModal';
import { isValidBirthdate } from 'functions/validationFunctions';
import { useLoginStore } from 'store/useLoginDataStore';
import { viewDetailPatientPageApi } from 'api/Hospital/viewDetailPatientPageApi';
import { PatientDiagnosisList, PatientInfoInitDataType } from 'models/Hospital/viewDetailPatientPageTypes';
import { ViewDetailPatientPageInfoBody } from 'components/Hospital/ViewDetailInfoBody';
import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';
import { Body2_Bold, Body2_Medium, Body2_SemiBold, Body3_Medium } from 'styles/Global/typography';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { useLoadingStore } from 'store/useLoadingStore';
import { set } from 'lodash';

const ViewDetailPatientPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { loginData } = useLoginStore();

    const [birthDate, setBirthDate] = useState('');
    const [age, setAge] = useState('');
    const [sortDirections, setSortDirections] = useState(false);
    const [patientInfoInitData, setPatientInfoInitData] = useState<PatientInfoInitDataType>(state);
    const [initData, setInitData] = useState<PatientDiagnosisList>();
    const [filterData, setFilterData] = useState(initData);
    const [patientName, setPatientName] = useState(patientInfoInitData.name);
    const [identityNumber, setIdentityNumber] = useState(patientInfoInitData.identityNumber.slice(0, 6));
    const [gender, setGender] = useState(patientInfoInitData.identityNumber.slice(6, 7));
    const [mobileNumber, setMobileNumber] = useState(patientInfoInitData.mobile);
    const [patientMemo, setPatientMemo] = useState(patientInfoInitData.patientMemo);
    const [patientCode, setPatientCode] = useState(patientInfoInitData.patientCode);
    const [identityNumberError, setIdentityNumberError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const { loading, setLoading } = useLoadingStore();

    const [isDeleteModal, setIsDeleteModal] = useToggle();
    const [isChangePatientInfoModal, setIsChangePatientInfoModal] = useToggle();
    const [totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData] = usePaginationStateHooks(filterData);

    // 초기 진입 시 환자 정보 불러오기
    useEffect(() => {
        getPatientInfoApiCall();
    }, []);

    useEffect(() => {
        const { birthDate, ageString } = calculateAge(patientInfoInitData.identityNumber);

        setBirthDate(birthDate);
        setAge(ageString);
    }, [patientInfoInitData, state]);

    // 버튼 비활성화 로직
    useEffect(() => {
        if (identityNumberError || mobileNumberError || !patientName || !identityNumber || !gender || !mobileNumber) {
            return setButtonDisabled(true);
        } else if (
            patientName === patientInfoInitData.name &&
            identityNumber === patientInfoInitData.identityNumber.slice(0, 6) &&
            gender === patientInfoInitData.identityNumber.slice(6, 7) &&
            mobileNumber === patientInfoInitData.mobile &&
            patientCode === patientInfoInitData.patientCode &&
            patientMemo === patientInfoInitData.patientMemo
        ) {
            return setButtonDisabled(true);
        }
        return setButtonDisabled(false);
    }, [gender, identityNumber, identityNumberError, patientMemo, mobileNumber, mobileNumberError, patientInfoInitData, patientName, patientCode]);

    // sorting function
    const handleSorting = () => {
        const direction = sortDirections;
        setSortDirections(!sortDirections);
        const sortedData = initData && [...initData].sort((a, b) => (direction ? b.no - a.no : a.no - b.no));
        setFilterData(sortedData || []);
    };

    // 주민등록번호 앞자리 입력 및 유효성 검사
    const handleIdentityNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIdentityNumber(e.target.value);
        const { genderCodeError } = calculateAge(e.target.value + gender);
        if (e.target.value.length > 0) {
            const valid = isValidBirthdate(e.target.value);
            if (!valid || genderCodeError) return setIdentityNumberError('주민등록번호를 정확히 입력해주세요.');
        }
        return setIdentityNumberError('');
    };

    // 주민번호 뒷자리(성별) 입력 및 유효성 검사
    const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGender(e.target.value);
        if (e.target.value.length > 0) {
            const { genderCodeError } = calculateAge(identityNumber + e.target.value);
            if (genderCodeError) return setIdentityNumberError('주민등록번호를 정확히 입력해주세요.');
        }
        return setIdentityNumberError('');
    };

    // mobileNumber 입력 및 유효성 검사
    const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');

        setMobileNumber(newValue);
        if (newValue.length > 0 && newValue.length < 9) {
            return setMobileNumberError('연락처를 정확히 입력해주세요.');
        }
        return setMobileNumberError('');
    };

    // Blur 이벤트 핸들러, input 내용을 지운 후 Focus out을 하면 기존 데이터 자동으로 입력
    const handleBlur = (valueLength: number, setState: Dispatch<SetStateAction<string>>, value: string) => {
        if (valueLength === 0) {
            setState(value);
        }
    };

    // 환자 정보 불러오기 API
    const getPatientInfoApiCall = async () => {
        setLoading(true);
        try {
            const response = await viewDetailPatientPageApi.getPatientInfo(patientInfoInitData._id, loginData.token);

            if (response.status === 'ok') {
                setPatientInfoInitData(response.data);
                getPatientDiagnosisListApiCall(patientInfoInitData._id);
                setLoading(false);
            } else {
                setLoading(false);
                console.log('환자 정보 불러오기 실패');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // 환자 진료 내역 부분 불러오기 API
    const getPatientDiagnosisListApiCall = async (id: string) => {
        try {
            const response = await viewDetailPatientPageApi.getPatientDiagnosisList(id, loginData.token);
            if (response.status === 'ok') {
                const dataWithNo = response.data
                    .sort((a, b) => {
                        return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
                    })
                    .map((item, index) => ({ ...item, no: response.data.length - index }));
                const result = dataWithNo.filter((data) => data.status !== -1);
                setInitData(result);
                setFilterData(result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // 환자 정보 변경 모달 변경하기 버튼 클릭 로직
    const clickUpdateButton = async () => {
        setLoading(true);
        try {
            const rqData = {
                _id: patientInfoInitData._id || state._id,
                name: patientName,
                mobile: mobileNumber,
                identityNumber: identityNumber + gender,
                hospitalId: patientInfoInitData.hospitalId,
                patientMemo: patientMemo,
                patientCode: patientCode,
            };
            const response = await viewDetailPatientPageApi.updatePatientInfo(rqData, loginData.token);

            if (response.status === 'ok') {
                console.log(response, '환자 정보 변경 성공');
                setIsChangePatientInfoModal();
                getPatientInfoApiCall();
            } else {
                console.log('환자 정보 변경 실패');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // delete patient api function

    const handleDeletePatient = async (id: string) => {
        setLoading(true);
        const response = await viewDetailPatientPageApi.deletePatient(id, loginData.token);

        if (response.status === 'ok') {
            setIsDeleteModal();
            navigate(pagePaths.managingPatients, { replace: true });
            setLoading(false);
        } else {
            setLoading(false);
            console.log('환자 삭제 실패');
        }
    };

    // 환자 정보 변경 취소 버튼
    const clickCancelButtonInEditPatientInfo = () => {
        setPatientName(patientInfoInitData.name);
        setIdentityNumber(patientInfoInitData.identityNumber.slice(0, 6));
        setGender(patientInfoInitData.identityNumber.slice(6, 7));
        setMobileNumber(patientInfoInitData.mobile);
        setPatientMemo(patientInfoInitData.patientMemo);
        setPatientCode(patientInfoInitData.patientCode);
        setIdentityNumberError('');
        setMobileNumberError('');
        setIsChangePatientInfoModal();
    };

    return (
        <DiagnosisLayout>
            {isDeleteModal && <DeletePatientModal toggle={setIsDeleteModal} userInfo={patientInfoInitData} birthDate={birthDate} age={age} handleDeletePatient={handleDeletePatient} />}
            {isChangePatientInfoModal && (
                <EditPatientInfoModal
                    toggle={clickCancelButtonInEditPatientInfo}
                    userInfo={patientInfoInitData}
                    birthDate={birthDate}
                    age={age}
                    patientName={patientName}
                    setPatientName={setPatientName}
                    identityNumber={identityNumber}
                    setIdentityNumber={setIdentityNumber}
                    gender={gender}
                    setGender={setGender}
                    mobileNumber={mobileNumber}
                    setMobileNumber={setMobileNumber}
                    patientMemo={patientMemo}
                    setPatientMemo={setPatientMemo}
                    patientCode={patientCode}
                    setPatientCode={setPatientCode}
                    handleBlur={handleBlur}
                    handleIdentityNumberChange={handleIdentityNumberChange}
                    identityNumberError={identityNumberError}
                    handleGenderChange={handleGenderChange}
                    handleMobileChange={handleMobileChange}
                    mobileNumberError={mobileNumberError}
                    buttonDisabled={buttonDisabled}
                    clickUpdateButton={clickUpdateButton}
                />
            )}
            {!loading && currentData && (
                <DiagnosisInnerLayout>
                    <TwoBreadCrumbContainer>
                        <HospitalPageLabel onClick={() => navigate(pagePaths.managingPatients)}>{viewDetailPatientPageText.redirectLabel}</HospitalPageLabel>
                        <IconWrapper>
                            <ArrowRightSlim />
                        </IconWrapper>
                        <HospitalPageLabel onClick={() => window.location.reload()}>{viewDetailPatientPageText.pageLabel}</HospitalPageLabel>
                    </TwoBreadCrumbContainer>
                    <PageTitle $marginbottom='20px'>{viewDetailPatientPageText.title}</PageTitle>
                    <InfoHeaderContainer>
                        <div>
                            <Name>{patientInfoInitData.name}</Name>
                            <SubText>님의 정보</SubText>
                        </div>
                        <InfoHeaderButtonContainer>
                            {loginData.level !== 0 && (
                                <BasicButton onClick={setIsDeleteModal} $width='84px' $height='100%' $type='anotherFill'>
                                    환자 삭제
                                </BasicButton>
                            )}
                            <BasicButton onClick={setIsChangePatientInfoModal} $width='112px' $height='100%' $type='anotherFill'>
                                환자 정보 변경
                            </BasicButton>
                        </InfoHeaderButtonContainer>
                    </InfoHeaderContainer>
                    <DetailPageInfoLayout>
                        <ViewDetailPatientPageInfoBody birthDate={birthDate} age={age} userInfo={patientInfoInitData} />
                    </DetailPageInfoLayout>
                    <Divider>
                        <PaginationHeader>진료 내역</PaginationHeader>
                    </Divider>
                    <DiagnosisCountText>총 {patientInfoInitData.diagnosisIdList.length}건의 진료</DiagnosisCountText>
                    <ViewDetailPatientPagination
                        handleSorting={handleSorting}
                        sortDirections={sortDirections}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pageGroup={pageGroup}
                        handlePageGroup={handlePageGroup}
                        handlePageChange={setPage}
                        currentData={currentData}
                    />
                </DiagnosisInnerLayout>
            )}
        </DiagnosisLayout>
    );
};

export default ViewDetailPatientPage;

const Name = styled(Body2_SemiBold)`
    color: ${TextColor.color_text_neutral};
`;

const SubText = styled(Body2_Medium)`
    color: ${TextColor.color_text_light};
`;

const PaginationHeader = styled(Body2_Bold)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px;
    height: 52px;
    padding: 12px 20px 14px;
    color: ${PrimaryColor.color_primary_strong};
    border-bottom: 2px solid ${PrimaryColor.color_primary_strong};
    bottom: 0px;
`;

const Divider = styled.div`
    position: relative;
    width: 100%;
    height: 1px;
    background-color: ${palette.testGray};
    margin-bottom: 16px;
    margin-top: 83px;
`;

const DiagnosisCountText = styled(Body3_Medium)`
    display: flex;
    align-items: center;
    color: ${TextColor.color_text_light};
    height: 40px;
    margin-bottom: 16px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;
