import { createGlobalStyle } from 'styled-components';
import { TextColor } from './colorSemantic';

export const palette = {
    white: '#ffffff',
    gray: '#666666',
    blue: '#1C0AED',
    black: '#000000',
    warning: '#ff0000',
    testGray: '#cccccc',
    lightGray: '#f5f5f5',
    floatingBlack: 'rgba(0, 0, 0, 0.35)',
};

export const font = {
    size: {
        52: '52px',
        40: '40px',
        32: '32px',
        28: '28px',
        24: '24px',
        22: '22px',
        20: '20px',
        18: '18px',
        17: '17px',
        16: '16px',
        15: '15px',
        14: '14px',
        13: '13px',
        12: '12px',
    },
    weight: {
        bold: 700,
        semiBold: 600,
        medium: 500,
        regular: 400,
        semiLight: 300,
        light: 200,
    },
};

export const zIndex = {
    basic: 1,
    dropdown: 2,
    floating: 3,
    modalPopup: 10,
    toast: 20,
    loading: 100,
};

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
  }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font: inherit;
	font-family: Pretendard;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

html, body {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none; /* Old Firefox */
  overflow-y: scroll; /* Enable scrolling */
  color: ${TextColor.color_text_normal};
}

/* Hide scrollbar for Chrome, Safari, and Opera */
html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

input:focus {
	outline: none;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
}
  `;
