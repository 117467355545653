import { PatientData, PatientStore } from 'models/Login/patientDataStoreTypes';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const usePatientStore = create(
    persist<PatientStore>(
        (set) => ({
            patientData: {} as PatientData,
            setPatientData: (data) => set((state) => ({ patientData: { ...state.patientData, ...data } })),
            resetPatient: () => set(() => ({ patientData: {} as PatientData })),
        }),
        {
            name: 'patientData',
        }
    )
);
