import React from 'react';

const GenderMan = ({ $width, $height }: { $width?: string; $height?: string }) => {
    return (
        <svg width={$width || '20'} height={$height || '20'} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.8999 5.07288V10.067C15.7867 10.5012 18 12.9921 18 16C18 19.3137 15.3137 22 12 22C8.68629 22 6 19.3137 6 16C6 12.9922 8.21319 10.5013 11.0999 10.0671V5.07288L9.6363 6.53648C9.28483 6.88795 8.71498 6.88795 8.36351 6.53648C8.01203 6.18501 8.01203 5.61516 8.36351 5.26369L11.3561 2.27105C11.3929 2.23345 11.4329 2.19906 11.4757 2.16833C11.4992 2.15145 11.5234 2.13583 11.548 2.12149C11.6808 2.04424 11.8352 2 11.9999 2C12.1639 2 12.3177 2.04388 12.4502 2.12055C12.4754 2.13515 12.5001 2.15108 12.5241 2.16833C12.5669 2.19906 12.6069 2.23345 12.6437 2.27104L15.6363 5.26369C15.9878 5.61516 15.9878 6.18501 15.6363 6.53648C15.2848 6.88795 14.715 6.88795 14.3635 6.53648L12.8999 5.07288ZM12 11.8C9.6804 11.8 7.8 13.6804 7.8 16C7.8 18.3196 9.6804 20.2 12 20.2C14.3196 20.2 16.2 18.3196 16.2 16C16.2 13.6804 14.3196 11.8 12 11.8Z'
                fill='#6287D0'
            />
        </svg>
    );
};

export default GenderMan;
