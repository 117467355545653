import { DropdownCell, DropdownLayout } from './DropdownForms';
import { adminSearchFilterList, searchFilterList } from 'constants/diagnosisListPageConstants';
import { managingPatientsFilterList } from 'constants/managingPatientsPageConstants';
import { DiagnosisListSearchFilterDropdownProps } from 'models/Hospital/diagnosisListPageTypes';

const DiagnosisListSearchFilterDropdown = ({ selectedSearchFilter, handleDropdown, type }: DiagnosisListSearchFilterDropdownProps) => {
    console.log();
    return (
        <DropdownLayout $position='absolute' $height={type === 'managingPatients' ? '152px' : '188px'} $padding='4px 0' $top='44px' $left='0'>
            {(type === 'managingPatients' ? managingPatientsFilterList : type === 'admin' ? adminSearchFilterList : searchFilterList).map((item, idx) => {
                return (
                    <DropdownCell key={idx} $height='36px' $padding='8px 12px' onClick={(e: React.MouseEvent) => handleDropdown(e, idx, 'search')} $selected={selectedSearchFilter === idx}>
                        {item}
                    </DropdownCell>
                );
            })}
        </DropdownLayout>
    );
};

export default DiagnosisListSearchFilterDropdown;
