import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { HomeCellButton, HomeCellTitle, HomeCellTitleContainer } from 'components/Hospital/HomeForms';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import { todayDiagnosisListInHome, todayDiagnosisListInHomeWidth } from 'constants/pagination';
import { pagePaths } from 'constants/path';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import { TodayDiagnosisListProps } from 'models/Hospital/homeTypes';
import { TableCellTypes } from 'models/Hospital/paginationTypes';
import moment from 'moment';
import NothingDataInPagination from 'pages/Hospital/AddDiagnosisPage/NothingDataInPagination';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';

const HomeDiagnosisList = ({ slideData }: TodayDiagnosisListProps) => {
    const navigate = useNavigate();

    return (
        <>
            <HomeCellTitleContainer>
                <HomeCellTitle>오늘의 진료</HomeCellTitle>
                <HomeCellButton onClick={() => navigate(pagePaths.todayDiagnosis)}>
                    전체보기 <ArrowRightSlim />
                </HomeCellButton>
            </HomeCellTitleContainer>
            <PaginationLayout>
                <HeaderWrapper>
                    {todayDiagnosisListInHome.map((name, idx) => {
                        return (
                            <TableCell key={idx} $idx={idx} $width={todayDiagnosisListInHomeWidth[idx]} $type='header'>
                                {name}
                            </TableCell>
                        );
                    })}
                </HeaderWrapper>
                <TableLayout>
                    {slideData?.map((item, idx) => {
                        const { birthDate } = calculateAge(item.patientIdentityNumber);
                        return (
                            <ListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                                <TableCell $width={todayDiagnosisListInHomeWidth[0]} $type='name'>
                                    {item.patientName + ' '}
                                    {item.patientGender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                </TableCell>
                                <TableCell $width={todayDiagnosisListInHomeWidth[1]}>{moment(birthDate, 'YYYYMMDD').format('YYYY/MM/DD')}</TableCell>
                                <TableCell $width={todayDiagnosisListInHomeWidth[2]}>{formattedTime(item.diagnosisDateTime)}</TableCell>
                                <TableCell $width={todayDiagnosisListInHomeWidth[3]} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                                    <ProcessCheckBar diagnosisInfo={item} $height='32px' $type='todayDiagnosisList' />
                                </TableCell>
                            </ListWrapper>
                        );
                    })}
                    {(!slideData || slideData?.length === 0) && <NothingDataInPagination initNumber={0} />}
                </TableLayout>
            </PaginationLayout>
        </>
    );
};

export default HomeDiagnosisList;

const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 34px;
    flex-shrink: 0;
    background-color: ${BackgroundColor.color_background_neutral};
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 4px 4px 0 0;
    border-bottom: none;
    & > div:last-child {
        border-right: none;
    }
`;

const TableCell = styled.div<TableCellTypes>`
    display: flex;
    flex-grow: 1;
    align-items: center;
    line-height: normal;
    width: ${(props) => props.$width};
    height: 100%;
    border-right: 1px solid ${BorderColor.color_border_neutral};
    font-size: ${font.size[14]};
    font-weight: ${(props) => (props.$type === 'name' ? font.weight.semiBold : font.weight.regular)};
    color: ${(props) => props.$type === 'header' && palette.gray};
    padding: 0 10px;
    // text ...
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ListWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 46px;
    flex-shrink: 0;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    cursor: pointer;
    & > div:last-child {
        border-right: none;
    }
    &:hover {
        background-color: ${palette.lightGray};
    }
`;

const TableLayout = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
    border-radius: 0 0 4px 4px;
    border: 1px solid ${BorderColor.color_border_normal};
    & > div:last-child {
        border-bottom: none;
    }
`;

const PaginationLayout = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 314px;
    overflow: hidden;
    flex-direction: column;
`;
