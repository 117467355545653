import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const Lnb_HospitalHome = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 12.2971V19.2971C20 20.4017 19.1046 21.2971 18 21.2971H14.5C13.9477 21.2971 13.5 20.8494 13.5 20.2971V15.2971H10.5V20.2971C10.5 20.8494 10.0523 21.2971 9.5 21.2971H6C4.89543 21.2971 4 20.4017 4 19.2971V12.2971L2.68496 12.2971C1.3449 12.2971 0.770578 10.5956 1.8365 9.78352L11.1515 2.68639C11.6527 2.30453 12.3472 2.30453 12.8484 2.68639L22.1634 9.78352C23.2293 10.5956 22.655 12.2971 21.3149 12.2971L20 12.2971ZM11.9999 4.5543L4.46248 10.2971L6 10.2971V19.2971H8.5V14.2971C8.5 13.7448 8.94772 13.2971 9.5 13.2971H14.5C15.0523 13.2971 15.5 13.7448 15.5 14.2971V19.2971H18V10.2971L19.5374 10.2971L11.9999 4.5543Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default Lnb_HospitalHome;
