import DiagnosisListPaginationListButton from 'assets/Icons/Arrow/ArrowLineRoundRight';
import { ListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import { viewDetailPatientListWidth } from 'constants/pagination';
import { pagePaths } from 'constants/path';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import { ViewDetailPatientPaginationTableProps } from 'models/Hospital/paginationTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewDetailPatientPaginationTable = ({ currentData }: ViewDetailPatientPaginationTableProps) => {
    const navigate = useNavigate();

    return (
        <>
            {currentData.map((item, idx) => {
                const { birthDate } = calculateAge(item.patientIdentityNumber);
                const diagnosisTime = formattedTime(item.diagnosisDateTime);
                return (
                    <ListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                        <TableCell $width={viewDetailPatientListWidth[0]}>{item.no}</TableCell>
                        <TableCell $width={viewDetailPatientListWidth[1]} $type='name'>
                            {item.patientName}
                        </TableCell>
                        <TableCell $width={viewDetailPatientListWidth[2]} $type='noneDisplay'>
                            {birthDate}
                        </TableCell>
                        <TableCell $width={viewDetailPatientListWidth[3]}>{item.gradeName}</TableCell>
                        <TableCell $width={viewDetailPatientListWidth[4]}>{diagnosisTime}</TableCell>
                        <TableCell $width={viewDetailPatientListWidth[5]}>{item.doctorName}</TableCell>
                        <TableCell $cellType='processBarCell' $width={viewDetailPatientListWidth[6]}>
                            <ProcessCheckBar diagnosisInfo={item} $height='40px' />
                        </TableCell>
                        <TableCell $width={viewDetailPatientListWidth[7]}>
                            <DiagnosisListPaginationListButton />
                        </TableCell>
                    </ListWrapper>
                );
            })}
        </>
    );
};

export default ViewDetailPatientPaginationTable;
