import React, { useRef } from 'react';
import { ModalBackground, ModalCenterButtonFooter, ModalLayout, ModalLeftTitle } from './ModalForms';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { AgeAndBirthDateWrapper, CloseButtonWrapper, TextInDetailPatientPageModal } from 'styles/Modal/modalStyle';
import { RowDivider } from 'styles/Global/commonStyles';
import { BasicInput } from 'styles/Input/inputStyle';
import { EditPatientInfoModalProps } from 'models/Common/modalTypes';
import IdentityNumber from 'assets/Icons/IdentityNumber/IdentityNumber';
import BasicButton from 'components/Buttons/BasicButton';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { RoundBadgeS } from 'components/Common/Badge';
import { Body3_Medium, Body3_Medium_TextArea, Body5_Regular } from 'styles/Global/typography';
import { Radius } from 'styles/Global/objectStyles';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import CloseDialog from 'assets/Icons/DeleteIcon/CloseDialog';

const EditPatientInfoModal = ({
    toggle,
    userInfo,
    birthDate,
    age,
    patientName,
    setPatientName,
    identityNumber,
    setIdentityNumber,
    gender,
    setGender,
    mobileNumber,
    setMobileNumber,
    patientMemo,
    setPatientMemo,
    handleBlur,
    handleIdentityNumberChange,
    identityNumberError,
    handleGenderChange,
    handleMobileChange,
    mobileNumberError,
    buttonDisabled,
    clickUpdateButton,
    patientCode,
    setPatientCode,
}: EditPatientInfoModalProps) => {
    const genderRef = useRef<HTMLInputElement>(null);

    return (
        <ModalBackground>
            <ModalLayout $width='660px'>
                <ModalInnerLayout>
                    <ModalLeftTitle>환자 정보 변경</ModalLeftTitle>
                    <ModalBodyLayout>
                        <PatientInfoHeaderContainer>
                            <RoundBadgeS>{userInfo.regNum}</RoundBadgeS>
                            <InfoWrapper>
                                <TextInDetailPatientPageModal>{userInfo.name}</TextInDetailPatientPageModal>
                                <TextInDetailPatientPageModal>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {userInfo.gender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                    </span>
                                </TextInDetailPatientPageModal>
                                <AgeAndBirthDateWrapper>
                                    <span>{age}</span>
                                    <span>({birthDate})</span>
                                </AgeAndBirthDateWrapper>
                            </InfoWrapper>
                        </PatientInfoHeaderContainer>
                        <RowDivider $backgroundcolor={BorderColor.color_border_strong} />
                        <PatientInfoBodyContainer>
                            <PatientInfoCellsWrapper>
                                <Label>환자 이름</Label>
                                <BasicInput
                                    value={patientName}
                                    placeholder={userInfo.name}
                                    onChange={(e) => setPatientName(e.target.value)}
                                    onBlur={(e) => {
                                        handleBlur(e.target.value.length, setPatientName, userInfo.name);
                                    }}
                                    $width='440px'
                                    $height='48px'
                                />
                            </PatientInfoCellsWrapper>
                            <RowDivider $backgroundcolor={BorderColor.color_border_light} />
                            <PatientInfoCellsWrapper>
                                <Label>환자 주민등록번호</Label>
                                <InputAndErrMsgWrapper>
                                    <PatientIdentityNumberWrapper>
                                        <BasicInput
                                            value={identityNumber}
                                            placeholder={userInfo.identityNumber.slice(0, 6)}
                                            onChange={(e) => {
                                                handleIdentityNumberChange(e);
                                                if (e.target.value.length === 6) genderRef.current?.focus();
                                            }}
                                            onBlur={(e) => {
                                                handleBlur(e.target.value.length, setIdentityNumber, userInfo.identityNumber.slice(0, 6));
                                            }}
                                            $width='205px'
                                            $height='48px'
                                            maxLength={6}
                                            $warning={identityNumberError ? 'true' : ''}
                                        />
                                        <span>-</span>
                                        <BasicInput
                                            ref={genderRef}
                                            value={gender}
                                            placeholder={userInfo.identityNumber.slice(6, 7)}
                                            onChange={(e) => handleGenderChange(e)}
                                            onBlur={(e) => {
                                                handleBlur(e.target.value.length, setGender, userInfo.identityNumber.slice(6, 7));
                                            }}
                                            $width='205px'
                                            $height='48px'
                                            maxLength={1}
                                            $warning={identityNumberError ? 'true' : ''}
                                        />
                                        <IdentityNumberWrapper>
                                            <IdentityNumber />
                                        </IdentityNumberWrapper>
                                    </PatientIdentityNumberWrapper>
                                    {identityNumberError && <ErrorMsg>{identityNumberError}</ErrorMsg>}
                                </InputAndErrMsgWrapper>
                            </PatientInfoCellsWrapper>
                            <RowDivider $backgroundcolor={BorderColor.color_border_light} />
                            <PatientInfoCellsWrapper>
                                <Label>환자 연락처</Label>
                                <InputAndErrMsgWrapper>
                                    <BasicInput
                                        value={mobileNumber}
                                        placeholder={userInfo.mobile}
                                        onChange={(e) => handleMobileChange(e)}
                                        onBlur={(e) => {
                                            handleBlur(e.target.value.length, setMobileNumber, userInfo.mobile);
                                        }}
                                        $width='440px'
                                        $height='48px'
                                        $warning={mobileNumberError}
                                        maxLength={11}
                                    />
                                    {mobileNumberError && <ErrorMsg>{mobileNumberError}</ErrorMsg>}
                                </InputAndErrMsgWrapper>
                            </PatientInfoCellsWrapper>
                            <RowDivider $backgroundcolor={BorderColor.color_border_light} />
                            <PatientInfoCellsWrapper>
                                <Label>
                                    환자ID <SubLabel>(선택)</SubLabel>
                                </Label>
                                <InputAndErrMsgWrapper>
                                    <BasicInput value={patientCode} placeholder='환자ID' onChange={(e) => setPatientCode(e.target.value)} $width='440px' $height='48px' />
                                </InputAndErrMsgWrapper>
                            </PatientInfoCellsWrapper>
                            <RowDivider $backgroundcolor={BorderColor.color_border_light} />
                            <PatientInfoCellsWrapper>
                                <Label>
                                    메모<SubLabel>(선택)</SubLabel>
                                </Label>
                                <Textarea value={patientMemo} onChange={(e) => setPatientMemo(e.target.value)} placeholder='환자에 대한 메모가 있다면 작성해 주세요.' />
                            </PatientInfoCellsWrapper>
                            <RowDivider $backgroundcolor={BorderColor.color_border_light} />
                        </PatientInfoBodyContainer>
                    </ModalBodyLayout>
                </ModalInnerLayout>
                <ModalCenterButtonFooter>
                    <BasicButton $width='160px' $height='48px' $type='anotherFill' onClick={toggle}>
                        취소
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' $type='fill' onClick={clickUpdateButton} disabled={buttonDisabled}>
                        변경하기
                    </BasicButton>
                </ModalCenterButtonFooter>
                <CloseButtonWrapper onClick={toggle}>
                    <CloseDialog />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default EditPatientInfoModal;

const Textarea = styled(Body3_Medium_TextArea)`
    display: flex;
    alignitems: flex-start;
    width: 440px;
    height: 120px;
    padding: 18px 20px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_4};
    resize: none;
    outline: none;
    &:focus :hover {
        background-color: ${palette.white};
    }

    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }

    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};

        &:hover {
            background-color: inherit;
        }
    }

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
`;

const ModalInnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const ModalBodyLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 14px;
`;

const PatientInfoHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 12px 20px;
    background-color: ${BackgroundColor.color_background_primary_normal};
    gap: 6px;
`;

const PatientInfoBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
`;

const PatientInfoCellsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const Label = styled(Body3_Medium)`
    display: flex;
    align-items: center;
    width: 136px;
    gap: 4px;
`;

const SubLabel = styled(Body5_Regular)`
    color: ${TextColor.color_text_weak};
`;

const PatientIdentityNumberWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 440px;
    height: 100%;
    gap: 12px;
`;

const IdentityNumberWrapper = styled.div`
    position: absolute;
    right: 100px;
    top: 11px;
`;

const InputAndErrMsgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 8px;
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;
