import React from 'react';

const Account = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.3 6.5C14.3 7.77026 13.2703 8.8 12 8.8C10.7297 8.8 9.7 7.77026 9.7 6.5C9.7 5.22975 10.7297 4.2 12 4.2C13.2703 4.2 14.3 5.22975 14.3 6.5ZM15.5 6.5C15.5 8.433 13.933 10 12 10C10.067 10 8.5 8.433 8.5 6.5C8.5 4.567 10.067 3 12 3C13.933 3 15.5 4.567 15.5 6.5ZM11.1744 12.2H12.8256C16.4013 12.2 19.3 15.0987 19.3 18.6744V19.6779C19.3 19.7453 19.2454 19.8 19.1778 19.8H4.82222C4.75472 19.8 4.7 19.7453 4.7 19.6778V18.6744C4.7 15.0987 7.5987 12.2 11.1744 12.2ZM3.5 18.6744C3.5 14.436 6.93596 11 11.1744 11H12.8256C17.064 11 20.5 14.436 20.5 18.6744V19.6779C20.5 20.4081 19.908 21 19.1778 21H4.82222C4.09198 21 3.5 20.408 3.5 19.6778V18.6744Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default Account;
