import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageSubTitle, PageTitle } from 'styles/Global/commonStyles';
import { DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import DiagnosisConfirmation from './DiagnosisConfirmation';
import { HomeCalendar } from 'components/Common/Calendar';
import { HomeContainerProps, HomeWrapperProps } from 'models/Hospital/homeTypes';
import TodayDiagnosisListSlide from './TodayDiagnosisListSlide';
import useConnectRtdb from 'hooks/FirebaseHooks/useConnectRtdb';
import { GetDiagnosisListApiResponse, GetPatientsListApiResponse } from 'models/Hospital/hospitalApiTypes';
import TodayAlarm from './TodayAlarm';
import HomeDiagnosisList from './HomeDiagnosisList';
import { useLoginStore } from 'store/useLoginDataStore';
import { managingPatientsPaginationSorting } from 'functions/paginationSorting';
import CurrentPatientList from './CurrentPatientList';
import { homeApi } from 'api/Hospital/HomeApi';
import { BorderColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { homeConstants } from 'constants/homeConstants';
import { useLoadingStore } from 'store/useLoadingStore';

const Home = () => {
    const { loginData } = useLoginStore();
    const { loading, setLoading } = useLoadingStore();

    const [slideData, setSlideData] = useState<GetDiagnosisListApiResponse['data']>();
    const [todayDiagnosisData, setTodayDiagnosisData] = useState<GetDiagnosisListApiResponse['data']>();
    const [initData, setInitData] = useState<GetPatientsListApiResponse['data']>([]);
    const [devideDiagnosisProcess, setDevideDiagnosisProcess] = useState({ NOT_ASSIGNED: 0, PENDING: 0, INPROGRESS: 0, COMPLETED: 0 });

    const sortedData = useConnectRtdb();

    useEffect(() => {
        setLoading(true);
        let diagnosisProcessCount = { NOT_ASSIGNED: 0, PENDING: 0, INPROGRESS: 0, COMPLETED: 0 };

        getPatientListApiCall();
        if (sortedData) {
            const todayDiagnosisData = sortedData.filter((data) => data.status !== -1);

            setSlideData([...todayDiagnosisData].sort((a, b) => a.diagnosisDateTime.localeCompare(b.diagnosisDateTime)));
            setTodayDiagnosisData([...todayDiagnosisData].sort((a, b) => b.diagnosisDateTime.localeCompare(a.diagnosisDateTime)));

            sortedData.forEach((data) => {
                if (data.status === 0) {
                    diagnosisProcessCount.NOT_ASSIGNED++;
                } else if (data.status === 1) {
                    diagnosisProcessCount.PENDING++;
                } else if (data.status === 2) {
                    diagnosisProcessCount.INPROGRESS++;
                } else if (data.status === 3) {
                    diagnosisProcessCount.COMPLETED++;
                }
            });

            setDevideDiagnosisProcess(diagnosisProcessCount);
        }
        return setLoading(false);
    }, [sortedData]);

    // 최근 등록 환자 리스트 가져오기
    const getPatientListApiCall = async () => {
        try {
            const getPatientsListLimitResponse = await homeApi.getPatientsListLimit(loginData.hospitalId, loginData.token);
            if (getPatientsListLimitResponse.status === 'ok') {
                const initData = managingPatientsPaginationSorting('regNum', getPatientsListLimitResponse.data, false);
                setInitData(initData);
            } else {
                console.log('등록 환자 리스트 error');
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <DiagnosisLayout>
            {!loading && (
                <DiagnosisInnerLayout>
                    <TitleContainer>
                        <PageTitle>
                            {loginData.hospitalName}
                            <PageSubTitle>{homeConstants.subTitle}</PageSubTitle>
                        </PageTitle>
                    </TitleContainer>
                    <DiagnosisConfirmation slideData={slideData} devideDiagnosisProcess={devideDiagnosisProcess} />
                    <Container>
                        <CalendarWrapper className='homeCalendar'>
                            <HomeCalendar />
                        </CalendarWrapper>
                        <Wrapper $width='896px' $padding='24px 28px' $gap='16px'>
                            <TodayDiagnosisListSlide slideData={slideData} />
                        </Wrapper>
                    </Container>
                    <Container $height='400px'>
                        {/* <Wrapper className='todayAlarm' $width='288px' $minWidth='288px' $padding='24px 28px'> */}
                        <Wrapper className='todayAlarm' $width='288px' $minWidth='288px' $padding='24px 23px 24px 28px'>
                            <TodayAlarm />
                        </Wrapper>
                        <Wrapper $width='592px' $padding='24px 23px 24px 28px'>
                            <HomeDiagnosisList slideData={todayDiagnosisData?.slice(0, 20)} />
                        </Wrapper>
                        <Wrapper $width='288px' $padding='24px 23px 24px 28px'>
                            <CurrentPatientList data={initData.slice(0, 10)} />
                        </Wrapper>
                    </Container>
                </DiagnosisInnerLayout>
            )}
        </DiagnosisLayout>
    );
};

export default Home;

const TitleContainer = styled.header`
    display: flex;
    align-items: center;
    height: 48px;
    gap: 12px;
    margin-bottom: 32px;
`;

const Container = styled.div<HomeContainerProps>`
    display: flex;
    gap: 16px;
    width: 100%;
    height: ${(props) => props.$height || '300px'};
    margin-top: 16px;
`;

const Wrapper = styled.div<HomeWrapperProps>`
    display: flex;
    position: relative;
    flex-direction: column;
    width: ${(props) => props.$width};
    min-width: ${(props) => props.$minWidth};
    height: 100%;
    padding: ${(props) => props.$padding};
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_12};
    gap: ${(props) => props.$gap || '16px'};
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        width: 5px;
        background: transparent;
    }
    :hover::-webkit-scrollbar-thumb {
        border-radius: 99px;
        background: #ccc;
    }
    :hover::-webkit-scrollbar {
        width: 5px;
        background: none;
    }
    @media (max-width: 1280px) {
        &.todayAlarm {
            display: none;
        }
    }
`;

const CalendarWrapper = styled.div`
    width: 288px;
    min-width: 288px;
    height: 100%;
    padding: 24px 32px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_12};
    @media (max-width: 1280px) {
        display: none;
    }
`;
