const DoctorIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.3 6.5C14.3 7.77026 13.2703 8.8 12 8.8C10.7297 8.8 9.7 7.77026 9.7 6.5C9.7 5.22975 10.7297 4.2 12 4.2C13.2703 4.2 14.3 5.22975 14.3 6.5ZM15.5 6.5C15.5 8.433 13.933 10 12 10C10.067 10 8.5 8.433 8.5 6.5C8.5 4.567 10.067 3 12 3C13.933 3 15.5 4.567 15.5 6.5ZM16.3 16.5C16.3 16.9418 15.9418 17.3 15.5 17.3C15.0582 17.3 14.7 16.9418 14.7 16.5C14.7 16.0582 15.0582 15.7 15.5 15.7C15.9418 15.7 16.3 16.0582 16.3 16.5ZM17.5 16.5C17.5 17.6046 16.6046 18.5 15.5 18.5C14.3954 18.5 13.5 17.6046 13.5 16.5C13.5 15.6045 14.0885 14.8465 14.8999 14.5916V12.5394C14.2487 12.3193 13.5511 12.2 12.8256 12.2H11.1744C10.4489 12.2 9.75116 12.3194 9.0999 12.5395V14.7L10.8599 16.02C11.011 16.1333 11.0999 16.3111 11.0999 16.5V18C11.0999 18.3314 10.8313 18.6 10.4999 18.6H9.2999V17.4H9.8999V16.8L8.4999 15.75L7.0999 16.8V17.4H7.6999V18.6H6.4999C6.16853 18.6 5.8999 18.3314 5.8999 18V16.5C5.8999 16.3111 5.98882 16.1333 6.1399 16.02L7.8999 14.7V13.0879C5.98529 14.2126 4.7 16.2934 4.7 18.6744V19.6778C4.7 19.7453 4.75472 19.8 4.82222 19.8H19.1778C19.2454 19.8 19.3 19.7453 19.3 19.6779V18.6744C19.3 16.2933 18.0146 14.2124 16.0999 13.0878V14.5915C16.9114 14.8464 17.5 15.6044 17.5 16.5ZM3.5 18.6744C3.5 14.436 6.93596 11 11.1744 11H12.8256C17.064 11 20.5 14.436 20.5 18.6744V19.6779C20.5 20.4081 19.908 21 19.1778 21H4.82222C4.09198 21 3.5 20.408 3.5 19.6778V18.6744Z'
                fill='#5A5C63'
            />
        </svg>
    );
};

export default DoctorIcon;
