import { ChildrenType } from 'models/Common/commonTypes';
import styled from 'styled-components';
import { TextColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';
import { Title1_Bold } from 'styles/Global/typography';

export const SubTitleLayout = ({ children }: ChildrenType) => {
    return <Layout>{children}</Layout>;
};

export const SubTitle = ({ children }: ChildrenType) => {
    return <Title>{children}</Title>;
};

export const SubTitleTextContainer = ({ children }: ChildrenType) => {
    return <Container>{children}</Container>;
};

export const SubTitleText = ({ children }: ChildrenType) => {
    return <Text>{children}</Text>;
};

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const Title = styled(Title1_Bold)`
    color: ${TextColor.color_text_normal};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Text = styled.div`
    font-size: ${font.size[18]};
    color: ${TextColor.color_text_neutral};
    line-height: 26px;
`;
