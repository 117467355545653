import { ListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import { managingPatientsListWidth } from 'constants/pagination';
import moment from 'moment';
import { calculateAge } from 'functions/calculateAge';
import { calculationTimeStamp } from 'functions/calculateTime';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import { changeMobileForm } from 'functions/changeMobileForm';
import ArrowLineRoundRight from 'assets/Icons/Arrow/ArrowLineRoundRight';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { ManagingPatientsPaginationTableProps } from 'models/Hospital/managingPatientsPageTypes';

const ManagingPatientsPaginationTable = ({ data }: ManagingPatientsPaginationTableProps) => {
    const navigate = useNavigate();

    return (
        <>
            {data.map((item, idx) => {
                const { birthDate, ageString, gender } = calculateAge(item.identityNumber);
                return (
                    <ListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetailPatient, { state: item })}>
                        <TableCell $width={managingPatientsListWidth[0]} $type='regNum'>
                            {item.regNum}
                        </TableCell>
                        <TableCell $width={managingPatientsListWidth[1]} $type='name'>
                            {item.name + ' '}
                            {gender === 'male' ? <GenderMan /> : <GenderWoman />}
                        </TableCell>
                        <TableCell $type='noneDisplay' $width={managingPatientsListWidth[2]}>
                            {ageString}
                        </TableCell>
                        <TableCell $width={managingPatientsListWidth[3]} $type='patientIdentityNumber'>
                            {moment(birthDate, 'YYYYMMDD').format('YYYY/MM/DD')}
                        </TableCell>
                        <TableCell $width={managingPatientsListWidth[4]}>{changeMobileForm(item.mobile)}</TableCell>
                        <TableCell $width={managingPatientsListWidth[5]} $type='lastDiagnosisTimestamp'>
                            {calculationTimeStamp(item.lastDiagnosisTimestamp)}
                        </TableCell>
                        <TableCell $width={managingPatientsListWidth[6]}>{`${item.diagnosisIdList.length}건의 진료`}</TableCell>
                        <TableCell $width={managingPatientsListWidth[7]}>
                            <ArrowLineRoundRight />
                        </TableCell>
                    </ListWrapper>
                );
            })}
        </>
    );
};

export default ManagingPatientsPaginationTable;
