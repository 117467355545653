import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetPatientsListApiResponse } from 'models/Hospital/hospitalApiTypes';

class ManagingPatientsApi {
    async getPatientsList(url: string, token: string): Promise<GetPatientsListApiResponse> {
        try {
            const response = await axios.get(apiPaths.getPatientList + url + '/0', {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const managingPatientsApi = new ManagingPatientsApi();
