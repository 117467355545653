import React from 'react';

const AlarmFill = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 2.08826C10.7038 2.08826 9.64932 3.12349 9.61835 4.41228C7.1408 5.36836 5.38235 7.77236 5.38235 10.5883V14.6595L3.69083 16.96C3.56686 17.1286 3.5 17.3324 3.5 17.5417C3.5 18.0839 3.93961 18.5236 4.4819 18.5236H8.69244C8.85452 20.3702 10.2173 21.9118 11.9998 21.9118C13.7823 21.9118 15.1451 20.3702 15.3072 18.5236H19.5181C20.0604 18.5236 20.5 18.0839 20.5 17.5417C20.5 17.3324 20.4331 17.1286 20.3092 16.96L18.6176 14.6595V10.5883C18.6176 7.77236 16.8592 5.36836 14.3817 4.41228C14.3507 3.12349 13.2962 2.08826 12 2.08826Z'
                fill='#333438'
            />
        </svg>
    );
};

export default AlarmFill;
