import React from 'react';

const GraphCheck = () => {
    return (
        <svg style={{ position: 'absolute' }} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M5 12L9.66667 17L19 7' stroke='#264C97' strokeWidth='3.6' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export default GraphCheck;
