import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { AssessmentTagProps, InfoBodyComprehensiveResultsProps, ProgramListFilterCellProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { ColumnDivider } from 'styles/Global/commonStyles';
import BasicButton from 'components/Buttons/BasicButton';
import { InfoBodySubTitle } from './InfoBodySubTitles';
import { InfoBodyComprehensiveResultsText } from 'constants/viewDetailDiagnosisPageConstants';
import { BackgroundColor, BorderColor, InverseColor, OpacityColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Body1_Bold, Body2_SemiBold, Body3_SemiBold, Body5_Medium, Body5_Regular } from 'styles/Global/typography';
import { SelectBadge, UnSelectBadge } from 'components/Common/Badge';
import { BadgeProps } from 'models/Common/badgeTypes';
import { Radius } from 'styles/Global/objectStyles';
import _ from 'lodash';

const InfoBodyComprehensiveResults = ({
    diagnosisInfoData,
    infoBodyComprehensiveResultsSelectedIdx,
    setInfoBodyComprehensiveResultsSelectedIdx,
    comprehensiveResults,
    getResultDiagnosisApiCall,
}: InfoBodyComprehensiveResultsProps) => {
    const [error, setError] = useState(false);

    return (
        <>
            <InfoBodySubTitle
                title={InfoBodyComprehensiveResultsText.title}
                firstSubText={InfoBodyComprehensiveResultsText.firstSubText}
                secondSubText={InfoBodyComprehensiveResultsText.secondSubText}
            />
            <ListLayout>
                <ListContainer>
                    <ListHeaderContainer>
                        <FilterTitle>
                            <AllText>전체</AllText>
                            <Count>{comprehensiveResults?.length}</Count>
                        </FilterTitle>
                        <FilterList>
                            {diagnosisInfoData.assessmentList.map((item, idx) => {
                                if (item.status !== -1 && item.status === 2)
                                    return (
                                        <SelectBadge key={idx} $selected={infoBodyComprehensiveResultsSelectedIdx === idx} onClick={() => setInfoBodyComprehensiveResultsSelectedIdx(idx)}>
                                            {comprehensiveResults?.map((resultItem, idx) => {
                                                return (
                                                    <>
                                                        {item.assessmentCode === resultItem.ozJsonInfoData.PSY_ITEM_ID && (
                                                            <SelectedBedgeText $selected={infoBodyComprehensiveResultsSelectedIdx === idx}>{item.group}</SelectedBedgeText>
                                                        )}
                                                        {item.assessmentCode === resultItem.ozJsonInfoData.PSY_ITEM_ID && item.subName && (
                                                            <SelectedBedgeSubText $selected={infoBodyComprehensiveResultsSelectedIdx === idx}>{`(${item.subName})`}</SelectedBedgeSubText>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </SelectBadge>
                                    );
                            })}
                        </FilterList>
                    </ListHeaderContainer>
                    <ListSelectWrapper>
                        <NumberWrapper>{infoBodyComprehensiveResultsSelectedIdx + 1}</NumberWrapper>
                        <AssessmentWrapper>
                            <AssessmentTitleWrapper>
                                <AssessmentGroup>{diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].group}</AssessmentGroup>
                                <AssessmentName>{diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].assessmentName}</AssessmentName>
                            </AssessmentTitleWrapper>
                            <AssessmentInfoWrapper>
                                <AssessmentTargetWrapper>
                                    <AssessmentInfoTitle>대상 :</AssessmentInfoTitle>
                                    <AssessmentTarget>{diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].targetAge}</AssessmentTarget>
                                </AssessmentTargetWrapper>
                                <ColumnDivider $height='12px' $backgroundcolor={BorderColor.color_border_neutral} />
                                <AssessmentTargetWrapper>
                                    <AssessmentInfoTitle>문항수 :</AssessmentInfoTitle>
                                    <AssessmentTarget>{diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].questionCount}문항</AssessmentTarget>
                                </AssessmentTargetWrapper>
                                <ColumnDivider $height='12px' $backgroundcolor={BorderColor.color_border_neutral} />
                                <AssessmentTargetWrapper>
                                    <AssessmentInfoTitle>소요시간 :</AssessmentInfoTitle>
                                    <AssessmentTarget>약 {diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].elapsedTime}분</AssessmentTarget>
                                </AssessmentTargetWrapper>
                            </AssessmentInfoWrapper>
                            <AssessmentTagsWrapper>
                                {diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].tags.map((tag, idx) => {
                                    return <UnSelectBadge key={idx}>{tag}</UnSelectBadge>;
                                })}
                            </AssessmentTagsWrapper>
                        </AssessmentWrapper>
                    </ListSelectWrapper>
                </ListContainer>
            </ListLayout>
            {!error ? (
                <>
                    {comprehensiveResults?.map((resultItem, idx) => {
                        return (
                            diagnosisInfoData.assessmentList[infoBodyComprehensiveResultsSelectedIdx].assessmentCode === resultItem.ozJsonInfoData.PSY_ITEM_ID && (
                                <ResultIframe
                                    key={idx}
                                    src={resultItem.ozUrl}
                                    onError={(e) => {
                                        (e.target as HTMLElement).style.display = 'none';
                                        return setError(true);
                                    }}
                                />
                            )
                        );
                    })}
                </>
            ) : (
                <ErrorContainer>
                    <ErrorTitle>오류가 발생했습니다.</ErrorTitle>
                    <ErrorContent>결과를 불러오는 과정에서 오류가 발생했습니다. 결과를 다시 요청해주세요.</ErrorContent>
                    <BasicButton
                        $width='200px'
                        $height='44px'
                        $padding='12px 16px'
                        $type='fill'
                        onClick={() => {
                            getResultDiagnosisApiCall();
                            setError(false);
                        }}
                    >
                        다시 요청하기
                    </BasicButton>
                </ErrorContainer>
            )}
        </>
    );
};

export default InfoBodyComprehensiveResults;

const SelectedBedgeText = styled(Body3_SemiBold)<BadgeProps>`
    color: ${(props) => (props.$selected ? InverseColor.color_inverse_white : TextColor.color_text_neutral)};
`;
const SelectedBedgeSubText = styled(Body3_SemiBold)<BadgeProps>`
    color: ${(props) => (props.$selected ? OpacityColor.color_opacity_white_80 : TextColor.color_text_light)};
`;

const ListLayout = styled.section`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 40px;
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 8px;
    overflow: hidden;
`;

const ListHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 68px;
    padding: 20px 28px;
    gap: 20px;
    background-color: ${BackgroundColor.color_background_neutral};
    font-size: ${font.size[18]};
    font-weight: ${font.weight.semiBold};
`;

const FilterList = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    gap: 10px;
`;

const FilterTitle = styled.span`
    display: flex;
    align-items: center;
    min-width: 48px;
    height: 44px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.semiBold};
    line-height: normal;
`;
const ListSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 148px;
    padding: 26px 28px 28px;
    gap: 16px;
    border-top: 1px solid ${BorderColor.color_border_normal};
`;
const CheckBoxWrapper = styled.div`
    width: 28px;
    height: 100%;
`;

const NumberWrapper = styled(Body5_Medium)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_8};
`;

const AssessmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const AssessmentTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const AssessmentGroup = styled(Body1_Bold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentName = styled(Body2_SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 17px;
    margin-top: 8px;
    gap: 12px;
`;

const AssessmentTargetWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4px;
`;

const AssessmentInfoTitle = styled(Body5_Regular)`
    color: ${TextColor.color_text_weak};
`;

const AssessmentTarget = styled(Body5_Medium)`
    color: ${TextColor.color_text_light};
`;

const AssessmentTagsWrapper = styled.div`
    display: flex;
    height: 28px;
    gap: 8px;
    margin-top: 12px;
`;

const AssessmentTag = styled.div<AssessmentTagProps>`
    line-height: 21px;
    height: 100%;
    color: ${palette.gray};
    padding: 4px 10px;
    border-radius: 99px;
    background-color: ${(props) => (props.$tag ? palette.testGray : palette.lightGray)};
`;

const ResultIframe = styled.iframe`
    display: flex;
    justify-content: center;
    width: 104%;
    height: 100vh;
    margin-left: -8px;
    padding-right: 0px;
`;

const ErrorContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border: 1px solid ${palette.testGray};
    border-radius: 4px;
`;

const ErrorTitle = styled.span`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.medium};
    color: ${palette.gray};
    line-height: normal;
`;
const ErrorContent = styled.span`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    color: ${palette.gray};
    margin-top: 8px;
    margin-bottom: 20px;
    line-height: normal;
`;

const AllText = styled(Body2_SemiBold)`
    color: ${TextColor.color_text_normal};
    margin-right: 4px;
`;
const Count = styled(Body2_SemiBold)`
    color: ${PrimaryColor.color_primary_strong};
`;
