import styled from 'styled-components';
import BasicButton from 'components/Buttons/BasicButton';
import { font, palette } from 'styles/Global/globalStyles';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import { SignUpExplainText } from 'styles/SignUp/signUpStyles';
import { completeSignUpText } from 'constants/signUpPageConstants';
import { ButtonText } from 'constants/commonConstants';

const CompleteSignUp = () => {
    const navigate = useNavigate();

    return (
        <>
            <InnerLayout>
                <div>
                    <PageTitle>{completeSignUpText.firstTitle}</PageTitle>
                    <PageTitle>{completeSignUpText.secondTitle}</PageTitle>
                </div>
                <div>
                    <ExplainTextWrapper>
                        <SignUpExplainText>{completeSignUpText.firstExplainText}</SignUpExplainText>
                        <SignUpExplainText>{completeSignUpText.secondExplainText}</SignUpExplainText>
                    </ExplainTextWrapper>
                    <InfoText>{completeSignUpText.firstInfoText}</InfoText>
                    <NumberText>{completeSignUpText.numberText}</NumberText>
                    <InfoText>{completeSignUpText.secondInfoText}</InfoText>
                </div>
                <BasicButton onClick={() => navigate(pagePaths.login)} $type='fill'>
                    {ButtonText.goLoginPage}
                </BasicButton>
            </InnerLayout>
        </>
    );
};

export default CompleteSignUp;

const InnerLayout = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 440px;
    gap: 40px;
`;

const PageTitle = styled.h1`
    font-size: ${font.size[40]};
    font-weight: ${font.weight.bold};
    line-height: normal;
`;

const ExplainTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
`;
const InfoText = styled.span`
    color: ${palette.gray};
    font-weight: ${font.weight.regular};
    font-size: ${font.size[16]};
    line-height: normal;
`;
const NumberText = styled.span`
    color: ${palette.gray};
    font-weight: ${font.weight.regular};
    font-size: ${font.size[16]};
    text-decoration: underline;
    line-height: normal;
`;
