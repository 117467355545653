import { AssessmentData, AssessmentStore } from "models/Assessment/assessmentDataStoreTypes";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAssessmentStore = create(
    persist<AssessmentStore>(
        (set) => ({
            assessmentData: {} as AssessmentData,
            setAssessmentData: (data) => set((state) => ({ assessmentData: {...state.assessmentData,...data } })),
            resetAssessment: () => set((state) => ({ assessmentData: {} as AssessmentData })),
        }),
        {
            name: 'assessmentData',
        }
    )
)