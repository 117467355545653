import React from 'react';
import { AdminListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import moment from 'moment';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import { changeMobileForm } from 'functions/changeMobileForm';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { adminDiagnosisListWidth } from 'constants/pagination';
import { AdminCalculatePaginationTableProps } from 'models/Admin/calculatePageApiTypes';

const AdminCalculatePaginationTable = ({ currentData }: AdminCalculatePaginationTableProps) => {
    const navigate = useNavigate();

    return (
        <>
            {currentData?.map((item, idx) => {
                return (
                    // <AdminListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                    <AdminListWrapper key={idx}>
                        <TableCell $width={adminDiagnosisListWidth[0]} $type='hospitalName'>
                            {item.ozJsonInfoData.TESTING_ORG}
                        </TableCell>
                        <TableCell $width={adminDiagnosisListWidth[1]} $type='name'>
                            {item.ozJsonInfoData.NAME + ' '}
                            {item.ozJsonInfoData.SEX === '남' ? <GenderMan /> : <GenderWoman />}
                        </TableCell>
                        <TableCell $width={adminDiagnosisListWidth[2]}>{item.ozJsonInfoData.BIRTHDAY}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[3]}>{item.ozJsonInfoData.TESTING_DATE}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[4]}>{item.ozJsonInfoData.TESTER_NAME}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[5]}>{item.ozJsonInfoData.PSY_ITEM_ID}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[6]}>{item.retailPrice.toLocaleString()}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[7]}>{item.supplyPrice.toLocaleString()}</TableCell>
                        <TableCell $width={adminDiagnosisListWidth[8]}>{item.isTest && 'true'}</TableCell>
                    </AdminListWrapper>
                );
            })}
        </>
    );
};

export default AdminCalculatePaginationTable;
