import { InpsytParamData, InpsytParamDataStore } from "models/Tablet/InpsytParamType";
import { SetStateAction } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useInpsytParamDataStore = create(
    persist<InpsytParamDataStore>(
        (set) => ({
            inpsytParamData: [] as InpsytParamData,
            setInpsytParamData: (prev: SetStateAction<InpsytParamData>) => {
                prev instanceof Function
                  ? set(state => ({ inpsytParamData: prev(state.inpsytParamData) }))
                  : set({ inpsytParamData: prev });
              },
            resetInpsytParamData: () => set(() => ({ inpsytParamData: [] as InpsytParamData })),
        }),
        {
            name: 'inpsytParamData',
        }
    )
)