import styled from 'styled-components';
import { InverseColor } from 'styles/Global/colorSemantic';
import { font, zIndex } from 'styles/Global/globalStyles';
import { Radius } from 'styles/Global/objectStyles';
import ToolTipEnable from 'assets/Icons/ToolTip/ToolTipEnable';
import ToolTipDisabled from 'assets/Icons/ToolTip/ToolTipDisabled';
import Icon_CheckRound_fill from 'assets/Icons/Check/Icon_CheckRound_fill';
import { programToolTip } from 'constants/viewDetailDiagnosisPageConstants';
import UserLevelToolTipHeader from 'assets/Icons/ToolTip/UserLevelToolTipHeader';

export const UserLevelInfoToolTip = ({ level }: { level: number }) => {
    return (
        <Layout>
            <CellContainer>
                {level === 0 ? <ToolTipDisabled /> : <ToolTipEnable />}
                {level === 0 ? '환자 삭제 불가' : '환자 삭제 가능'}
            </CellContainer>
            <CellContainer>
                {level === 0 ? <ToolTipDisabled /> : <ToolTipEnable />}
                {level === 0 ? '담당의 추가, 삭제 불가' : '담당의 추가, 삭제 가능'}
            </CellContainer>
            <CellContainer>
                {level === 0 ? <ToolTipDisabled /> : <ToolTipEnable />}
                {level === 0 ? '계정 관리 불가' : '계정 관리 가능'}
            </CellContainer>
        </Layout>
    );
};

export const ToolTipNormal = ({ $index }: { $index: number }) => {
    return (
        <TooTipNormalLayout>
            <UserLevelToolTipHeader $top='-4px' $left='50%' />
            <CellContainer>
                <Icon_CheckRound_fill />
                {programToolTip[$index].age}
            </CellContainer>
            <CellContainer>
                <Icon_CheckRound_fill />
                {programToolTip[$index].schoolAge}
            </CellContainer>
        </TooTipNormalLayout>
    );
};

const TooTipNormalLayout = styled.section`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: max-content;
    padding: 12px 20px 12px 10px;
    gap: 2px;
    border-radius: ${Radius.radius_8};
    background-color: ${InverseColor.color_inverse_primary_over};
    top: 44px;
    z-index: ${zIndex.floating};
    pointer-events: none;
`;
const Layout = styled.section`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 88px;
    padding: 12px 16px 12px 10px;
    gap: 2px;
    border-radius: ${Radius.radius_8};
    background-color: ${InverseColor.color_inverse_primary_over};
    top: 26px;
    right: 0px;
    z-index: ${zIndex.floating};
    pointer-events: none;
`;

const CellContainer = styled.div`
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    color: #fff;
    line-height: normal;
    gap: 4px;
`;
