import Check from 'assets/Icons/Check/Check';
import { highSchool, middleSchool, primarySchool, schoolAge } from 'constants/schoolAgeAndSemester';
import { SchoolAgeSemesterDropdownProps } from 'models/Common/dropdownTypes';
import styled from 'styled-components';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { DropdownItem, DropdownLayout } from 'styles/Global/dropdownStyles';
import { font } from 'styles/Global/globalStyles';
import { Body3_Regular } from 'styles/Global/typography';

const SchoolAgeSemesterDropdown = ({ id, setDropdown, schoolAgeIdx, semesterIdx, $top, $height, $type }: SchoolAgeSemesterDropdownProps) => {
    return (
        <DropdownLayout $height={$height} $type={$type || 'diagnosis'} $top={$top} $borderRadius={$type === 'mobile' ? '8px' : '4px'}>
            {id === 'schoolAge'
                ? schoolAge.map((item, idx) => {
                      return (
                          <DropdownItem
                              className='dropdown-item'
                              key={idx}
                              $height={$type === 'mobile' ? '48px' : '40px'}
                              $padding={$type === 'mobile' ? '0 16px' : '0 16px 0 20px'}
                              $type={$type || 'addDiagnosis'}
                              selected={schoolAgeIdx === idx}
                              onMouseDown={() => setDropdown && setDropdown(id, idx, item, '')}
                          >
                              <DropdownText $selected={schoolAgeIdx === idx}>{item}</DropdownText>
                              {schoolAgeIdx === idx && <Check $color={PrimaryColor.color_primary_normal} />}
                          </DropdownItem>
                      );
                  })
                : id === 'semester' && schoolAgeIdx === 1
                ? primarySchool.map((item, idx) => {
                      return (
                          <DropdownItem
                              key={idx}
                              $height={$type === 'mobile' ? '48px' : '40px'}
                              $minHeight={$type === 'mobile' ? '48px' : '40px'}
                              $padding={$type === 'mobile' ? '0 16px' : '0 16px 0 20px'}
                              $type={$type || 'addDiagnosis'}
                              selected={semesterIdx === idx}
                              onMouseDown={() => setDropdown && setDropdown(id, idx, item.semester, item.value)}
                          >
                              <DropdownText $selected={semesterIdx === idx}>{item.semester}</DropdownText>
                              {semesterIdx === idx && <Check $color={PrimaryColor.color_primary_normal} />}
                          </DropdownItem>
                      );
                  })
                : id === 'semester' && schoolAgeIdx === 2
                ? middleSchool.map((item, idx) => {
                      return (
                          <DropdownItem
                              key={idx}
                              $height={$type === 'mobile' ? '48px' : '40px'}
                              $minHeight={$type === 'mobile' ? '48px' : '40px'}
                              $padding={$type === 'mobile' ? '0 16px' : '0 16px 0 20px'}
                              $type={$type || 'addDiagnosis'}
                              selected={semesterIdx === idx}
                              onMouseDown={() => setDropdown && setDropdown(id, idx, item.semester, item.value)}
                          >
                              <DropdownText $selected={semesterIdx === idx}>{item.semester}</DropdownText>
                              {semesterIdx === idx && <Check $color={PrimaryColor.color_primary_normal} />}
                          </DropdownItem>
                      );
                  })
                : id === 'semester' && schoolAgeIdx === 3
                ? highSchool.map((item, idx) => {
                      return (
                          <DropdownItem
                              key={idx}
                              $height={$type === 'mobile' ? '48px' : '40px'}
                              $minHeight={$type === 'mobile' ? '48px' : '40px'}
                              $padding={$type === 'mobile' ? '0 16px' : '0 16px 0 20px'}
                              $type={$type || 'addDiagnosis'}
                              selected={semesterIdx === idx}
                              onMouseDown={() => setDropdown && setDropdown(id, idx, item.semester, item.value)}
                          >
                              <DropdownText $selected={semesterIdx === idx}>{item.semester}</DropdownText>
                              {semesterIdx === idx && <Check $color={PrimaryColor.color_primary_normal} />}
                          </DropdownItem>
                      );
                  })
                : ''}
            {/* : id === 'doctorName'
                ? loginData.doctors.map((item, idx) => {
                      return (
                          <DropdownItem key={idx} $height='48px' $padding='12px' selected={semesterIdx === idx} onClick={() => console.log(!23)}>
                              {semesterIdx === idx && <SelectedCheck />}
                              <DropdownText>{item.name}</DropdownText>
                          </DropdownItem>
                      );
                  }) */}
        </DropdownLayout>
    );
};

export default SchoolAgeSemesterDropdown;

const DropdownText = styled(Body3_Regular)<{ $selected?: boolean }>`
    color: ${TextColor.color_text_normal};
    font-weight: ${(props) => props.$selected && font.weight.semiBold};
`;
