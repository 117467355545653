import React from 'react';

const IdentityNumber = () => {
    return (
        <svg width='64' height='4' viewBox='0 0 64 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='2' cy='2' r='2' fill='#666666' />
            <circle cx='14' cy='2' r='2' fill='#666666' />
            <circle cx='26' cy='2' r='2' fill='#666666' />
            <circle cx='38' cy='2' r='2' fill='#666666' />
            <circle cx='50' cy='2' r='2' fill='#666666' />
            <circle cx='62' cy='2' r='2' fill='#666666' />
        </svg>
    );
};

export default IdentityNumber;
