import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { diagnosisListPageText } from 'constants/diagnosisListPageConstants';
import { PageTitle } from 'styles/Global/commonStyles';
import { palette } from 'styles/Global/globalStyles';
import moment from 'moment';
import useToggle from 'hooks/CustomHooks/useToggle';
import { diagnosisListApi } from 'api/Hospital/diagnosisListApi';
import { useLoginStore } from 'store/useLoginDataStore';
import { GetDiagnosisListApiResponse } from 'models/Hospital/hospitalApiTypes';
import { useToastStore } from 'store/useToastStore';
import { HospitalPageLabel, FilterWrapper, DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import { diagnosisPaginationSorting } from 'functions/paginationSorting';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import adminDiagnosisPaginationSearchFilter from 'functions/adminDiagnosisPaginationSearchFilter';
import AdminDiagnosisFilter from '../AdminDiagnosisListPage/AdminDiagnosisFilter';
import AdminDiagnosisListPagination from '../AdminDiagnosisListPage/AdminDiagnosisListPagination';
import { calculatePageText } from 'constants/calculatePageConstants';
import AdminCalculateFilter from './AdminCalculateFilter';
import { calculatePageApi } from 'api/Admin/calculatePageApi';
import { GetPaymentListApiResponse } from 'models/Admin/calculatePageApiTypes';
import AdminCalculatePaginationTable from './AdminCalculatePaginationTable';
import AdminCalculatePagination from './AdminCalculatePagination';

const AdminCalculatePage = () => {
    const [afterSelectedDate, setAfterSelectedDate] = useState<string | null>(moment().format('YYYY/MM/DD'));
    const [beforeSelectedDate, setBeforeSelectedDate] = useState<string | null>(moment().subtract(1, 'months').format('YYYY/MM/DD'));
    const [initData, setInitData] = useState<GetPaymentListApiResponse['data']['list']>();
    const [filterData, setFilterData] = useState<GetPaymentListApiResponse['data']['list'] | []>(initData || []);

    const [selectedPeriod, setSelectedPeriod] = useState<number>(1);
    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectSequence, setSelectSequence] = useState<string>('');
    const [sortField, setSortField] = useState<string>('');
    const [sortDirections, setSortDirections] = useState<Record<string, boolean>>({ patientName: false, patientIdentityNumber: false, diagnosisDateTime: false });
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [onBeforeCalendar, setOnBeforeCalendar] = useToggle();
    const [onAfterCalendar, setOnAfterCalendar] = useToggle();
    const [onSelectedPeriodDropdown, setOnSelectedPeriodDropdown] = useToggle();
    const [onSearchFilterDropdown, setOnSearchFilterDropdown] = useToggle();

    const { loginData } = useLoginStore();

    // const [totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData] = usePaginationStateHooks(filterData || []);

    // period filter
    useEffect(() => {
        switch (selectedPeriod) {
            case 0:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'years').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 1:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'months').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 2:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'weeks').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 3:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 4:
                setAfterSelectedDate('');
                setBeforeSelectedDate('');
                setOnBeforeCalendar();
                break;
            default:
                break;
        }
    }, [selectedPeriod]);

    // Data Fetching
    useEffect(() => {
        // const beforeDate = moment(beforeSelectedDate, 'YYYY/MM/DD');
        // const afterDate = moment(afterSelectedDate, 'YYYY/MM/DD');
        // const diff = afterDate.diff(beforeDate, 'days');
        const getDiagnosisList = async () => {
            return await calculatePageApi
                .getPaymentList('1', loginData.token)
                .then((res) => {
                    console.log(res);
                    const sortedData = res.data.list.sort((a, b) => {
                        return b.diagnosisTimestamp - a.diagnosisTimestamp;
                    });
                    setInitData(sortedData);
                })
                .catch((err) => console.log(err));

            // return await diagnosisListApi
            //     .getDiagnosisList(`${moment(beforeSelectedDate, 'YYYY/MM/DD').format('YYYYMMDD')}/${moment(afterSelectedDate, 'YYYY/MM/DD').format('YYYYMMDD')}`, loginData.token)
            //     .then((res) => {
            //         console.log(res);
            //         const initData =
            //             res.data &&
            //             res.data
            //                 .filter((item) => item.status !== -1)
            //                 .sort((a, b) => {
            //                     return b.diagnosisDateTime.localeCompare(a.diagnosisDateTime);
            //                 });
            //         setSortField('diagnosisDateTime');
            //         setSortDirections((prev) => ({
            //             ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            //             diagnosisDateTime: true,
            //         }));
            //         setInitData(initData);
            //         setFilterData(initData);
            //     })
            //     .catch((err) => console.log(err));
        };

        // if (selectedPeriod === 4) {
        //     if (beforeSelectedDate && onBeforeCalendar && !afterSelectedDate) {
        //         setOnBeforeCalendar();
        //         setOnAfterCalendar();
        //     } else if (beforeSelectedDate && onBeforeCalendar && afterSelectedDate) {
        //         setOnBeforeCalendar();
        //     } else if (afterSelectedDate && onAfterCalendar && !beforeSelectedDate) {
        //         setOnBeforeCalendar();
        //         setOnAfterCalendar();
        //     } else if (afterSelectedDate && onAfterCalendar && beforeSelectedDate) {
        //         setOnAfterCalendar();
        //     }

        //     if (diff >= 0 && diff <= 365) {
        //         getDiagnosisList();
        //     } else if (diff < 0 || diff > 365) {
        //         if (selectSequence === 'before') {
        //             setAfterSelectedDate(null);
        //             setOnAfterCalendar();
        //         } else if (selectSequence === 'after') {
        //             setBeforeSelectedDate(null);
        //             setOnBeforeCalendar();
        //         }
        //         setToast('최대 1년 내에서만 검색이 가능합니다.');
        //         setSelectSequence('');
        //     }
        // } else {
        //     getDiagnosisList();
        // }
        getDiagnosisList();
    }, [beforeSelectedDate, afterSelectedDate, loginData.token]);

    // handlePeriodFilter Dropdown
    const handleDropdown = (e: React.MouseEvent, idx: number, type: string) => {
        e.stopPropagation();
        if (type === 'period') {
            setOnSelectedPeriodDropdown();
            setSelectedPeriod(idx);
        } else {
            setOnSearchFilterDropdown();
            setselectedSearchFilter(idx);
        }
        setSearchValue('');
        setPage(1);
    };

    // reset Button function
    const handleResetButton = () => {
        console.log('reset');
        // setSearchValue('');
        // setFilterData(initData || []);
        // setSelectedPeriod(1);
        // setPage(1);
        // handlePageGroup(0);
        // setSortDirections((prev) => ({
        //     ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        //     diagnosisDateTime: true,
        // }));
        // setSortField('diagnosisDateTime');
    };

    return (
        <DiagnosisLayout>
            <DiagnosisInnerLayout>
                <HospitalPageLabel onClick={() => window.location.reload()}>{calculatePageText.pageLabel}</HospitalPageLabel>
                <PageTitle $marginbottom='24px'>{calculatePageText.pageTitle}</PageTitle>
                <Divider />
                <FilterWrapper>
                    {/* <AdminCalculateFilter
                        diagnosisNumber={filterData?.length}
                        afterSelectedDate={afterSelectedDate}
                        setAfterSelectedDate={setAfterSelectedDate}
                        beforeSelectedDate={beforeSelectedDate}
                        setBeforeSelectedDate={setBeforeSelectedDate}
                        handleCalendarDropdown={handleCalendarDropdown}
                        onBeforeCalendar={onBeforeCalendar}
                        setOnBeforeCalendar={setOnBeforeCalendar}
                        onAfterCalendar={onAfterCalendar}
                        setOnAfterCalendar={setOnAfterCalendar}
                        selectedPeriod={selectedPeriod}
                        selectedSearchFilter={selectedSearchFilter}
                        handleDropdown={handleDropdown}
                        setOnSelectedPeriodDropdown={setOnSelectedPeriodDropdown}
                        onSelectedPeriodDropdown={onSelectedPeriodDropdown}
                        setOnSearchFilterDropdown={setOnSearchFilterDropdown}
                        onSearchFilterDropdown={onSearchFilterDropdown}
                        maxDate={afterSelectedDate}
                        minDate={beforeSelectedDate}
                        onChange={setSearchValue}
                        searchValue={searchValue}
                        handleSearchFilter={handleSearchFilter}
                        setSelectSequence={setSelectSequence}
                        toastToggle={toastToggle}
                        comment={comment}
                    /> */}
                </FilterWrapper>
                <AdminCalculatePagination initNumber={initData?.length} currentData={initData} handleResetButton={handleResetButton} />
            </DiagnosisInnerLayout>
        </DiagnosisLayout>
    );
};

export default AdminCalculatePage;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${palette.testGray};
    margin-bottom: 16px;
`;
