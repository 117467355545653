const ConfirmCheck = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.6 12C20.6 16.7496 16.7496 20.6 12 20.6C7.25035 20.6 3.4 16.7496 3.4 12C3.4 7.25035 7.25035 3.4 12 3.4C16.7496 3.4 20.6 7.25035 20.6 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.2605 10.2787C16.5249 9.9967 16.5106 9.55371 16.2286 9.2893C15.9465 9.02489 15.5035 9.03918 15.2391 9.32122L11.0623 13.7765L8.76048 11.3212C8.49607 11.0392 8.05309 11.0249 7.77105 11.2893C7.48901 11.5537 7.47472 11.9967 7.73913 12.2787L10.5516 15.2787C10.684 15.4199 10.8688 15.5 11.0623 15.5C11.2558 15.5 11.4406 15.4199 11.573 15.2787L16.2605 10.2787Z'
                fill='#264C97'
            />
        </svg>
    );
};

export default ConfirmCheck;
