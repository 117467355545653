import React from 'react';

const ArrowLineRight = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.99995 11.2C5.55812 11.2 5.19995 11.5582 5.19995 12C5.19995 12.4418 5.55812 12.8 5.99995 12.8L16.0987 12.8L13.0278 15.9407C12.7189 16.2566 12.7246 16.7631 13.0405 17.072C13.3564 17.3809 13.8629 17.3752 14.1718 17.0593L18.5718 12.5593C18.8758 12.2484 18.8758 11.7516 18.5718 11.4407L14.1718 6.94072C13.8629 6.62481 13.3564 6.61912 13.0405 6.92801C12.7246 7.2369 12.7189 7.7434 13.0278 8.05931L16.0987 11.2L5.99995 11.2Z'
                fill='white'
            />
        </svg>
    );
};

export default ArrowLineRight;
